import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';

const selectSchema = yup
	.object()
	.shape({
		value: yup.number().required(ErrorMessages.REQUIRED),
		label: yup.string().required(ErrorMessages.REQUIRED),
		isStripeConnected: yup.boolean(),
	})
	.nullable()
	.test('custom', ErrorMessages.REQUIRED, function (value) {
		return value !== null && !isNaN(value?.value);
	});

const TenantSchema = yup.object().shape({
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	email: yup
		.string()
		.email(ErrorMessages.EMAIL_INVALID)
		.required(ErrorMessages.REQUIRED),
	phone: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.min(12, 'Please enter full phone number'),
	property: selectSchema,
	unit: selectSchema,
	leaseStartDate: yup.string().required(ErrorMessages.REQUIRED),
	leaseEndDate: yup.string().required(ErrorMessages.REQUIRED),
	billingCycles: yup.array().of(
		yup.object().shape({
			id: yup.number().optional(),
			invoiceName: yup.string().required(ErrorMessages.REQUIRED),
			invoiceType: selectSchema,
			amount: yup
				.number()
				.nullable()
				.test('custom', ErrorMessages.REQUIRED, function (value) {
					return !!value;
				})
				.typeError(ErrorMessages.REQUIRED),
			invoiceDate: yup.string().required(ErrorMessages.REQUIRED),
			dueDays: yup
				.number()
				.positive(ErrorMessages.POSITIVE_OR_ZERO)
				.typeError(ErrorMessages.REQUIRED),
			paymentMethod: selectSchema,
			billingCycle: selectSchema,
		}),
	),
	leaseAgreement: yup.mixed<File>(),
	// isSentLeaseAgreement: yup.boolean(),
});

export type TenantFormType = yup.InferType<typeof TenantSchema>;

export const tenantResolver = yupResolver(TenantSchema);
