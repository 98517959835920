import React from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	InputProps,
	FormControlProps,
	FormLabelProps,
} from '@chakra-ui/react';

interface IInputProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
}

export const CustomInput = React.forwardRef<null, IInputProps>(
	({ errorMsg, label, formControlProps, formLabelProps, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						mb="8px"
						fontSize="12px"
						lineHeight="16px"
						fontWeight={600}
						{...formLabelProps}>
						{label}
					</FormLabel>
				) : null}
				<Input
					fontWeight={'500'}
					border={'none'}
					bg={'blue.200'}
					color={'white'}
					{...rest}
					ref={ref}
				/>
				<FormErrorMessage mt={'5px'} color={rest.errorBorderColor}>
					{errorMsg}
				</FormErrorMessage>
			</FormControl>
		);
	},
);
CustomInput.displayName = 'CustomInput';
