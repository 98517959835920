import { PropertyExtendInfoType } from '../../../types';
import { transformAmount } from '../../../utils';

export const getPropertyValues = (data: PropertyExtendInfoType) => {
	const pinnedImageId = data.images.find(it => it.isPinned);
	return {
		name: data.name,
		type: data.type,
		label: data.label,
		ownerId: data.owner.id,
		units: data.units.map(it => ({
			...it,
			monthlyRent: transformAmount(it.monthlyRent, true),
			deposit: transformAmount(it.deposit, true),
		})),
		location: data.location,
		imageIdsToDelete: [],
		pinnedImageId: pinnedImageId?.id,
	};
};
