import { FC } from 'react';

interface NewDividerBadgeProps {
	color?: string;
}

export const NewDividerBadgeIcon: FC<NewDividerBadgeProps> = ({
	color = '#C14B4B',
}) => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 41.8 17">
		<path
			fill={color}
			d="M6.3,0.9L0.9,6.4c-1.2,1.2-1.2,3.1,0,4.2l5.5,5.5C6.9,16.7,7.7,17,8.5,17H38c0.8,0,1.6-0.3,2.1-0.9l0.8-0.8
	c0.6-0.6,0.9-1.3,0.9-2.1V3.8c0-0.8-0.3-1.6-0.9-2.1l-0.8-0.8C39.6,0.3,38.8,0,38,0H8.5C7.7,0,6.9,0.3,6.3,0.9z"
		/>
		<path
			fill="white"
			d="M16.8,4.3V13h-1.2l-4.4-6.4h-0.1V13H9.7V4.3H11l4.4,6.4h0.1V4.3H16.8z M18.7,13V4.3h5.5v1.1H20v2.7h3.9v1.1H20
	v2.7h4.2V13H18.7z M27.6,13l-2.4-8.7h1.4l1.7,6.8h0.1l1.8-6.8h1.4l1.8,6.8h0.1l1.7-6.8h1.4L34,13h-1.3l-1.8-6.5h-0.1L29,13H27.6z"
		/>
	</svg>
);
