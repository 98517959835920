import { InvoiceTypeEnum, PaymentMethodEnum } from '../types';

export const PAYMENT_METHOD_OPTIONS = [
	{
		label: 'Stripe',
		value: PaymentMethodEnum.Stripe,
	},
	{
		label: 'Cash',
		value: PaymentMethodEnum.Cash,
	},
	{
		label: 'Money Order',
		value: PaymentMethodEnum.MoneyOrder,
	},
	{
		label: 'Personal Check',
		value: PaymentMethodEnum.PersonalCheck,
	},
];

export const INVOICE_OPTIONS = [
	{
		value: InvoiceTypeEnum.SecurityDeposit,
		label: 'Security Deposit',
	},
	{
		value: InvoiceTypeEnum.Private,
		label: 'Private',
	},
	{
		value: InvoiceTypeEnum.Trillium,
		label: 'Trillium',
	},
	{
		value: InvoiceTypeEnum.Section8,
		label: 'Section 8',
	},
];

export const MAX_SIZE_25 = 25 * 1024 * 1024;

export const MAX_SIZE_5 = 5 * 1024 * 1024;
