/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
	useDisclosure,
	Image,
	IconButton,
	Center,
	FlexProps,
	Flex,
	Text,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { Controls } from './Controls';
import { PlayIcon } from '../../assets';
import { BUTTONS_SIZE_VARIANTS } from './constants';
import { PlayerButtonsSize } from './types';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>;
		msExitFullscreen?: () => Promise<void>;
		webkitExitFullscreen?: () => Promise<void>;
		mozFullScreenElement?: Element;
		msFullscreenElement?: Element;
		webkitFullscreenElement?: Element;
	}

	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>;
		mozRequestFullscreen?: () => Promise<void>;
		webkitRequestFullscreen?: () => Promise<void>;
	}
}

interface ICustomPlayerProps {
	imageLink?: string;
	previewLink?: string;
	videoLink?: string;
	wrapperProps?: FlexProps;
	onDownload?: VoidFunction;
	downloadLoading?: boolean;
	setIsVideoReady?: (bool: boolean) => void;
	playerButtonsSize?: PlayerButtonsSize;
	enableSpaceBarPlayToggle: boolean;
	preload?: 'auto' | 'none' | 'metadata';
	errorCoverProps?: FlexProps;
}
export const CustomPlayer: React.FC<ICustomPlayerProps> = ({
	imageLink,
	videoLink,
	previewLink,
	wrapperProps,
	onDownload,
	downloadLoading,
	setIsVideoReady,
	enableSpaceBarPlayToggle,
	errorCoverProps,
	preload = 'auto',
	playerButtonsSize = PlayerButtonsSize.BIG,
}) => {
	const playerRef = useRef<ReactPlayer | null>(null);
	const boxRef = useRef<any>(null);

	const userAgent = navigator.userAgent.toLowerCase();

	const isIOS = /iphone|ipad|ipod/.test(userAgent);
	const isNewIpad =
		navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1; // for safari
	// const isAndroid = /android/.test(userAgent);

	const isMobile = isIOS || isNewIpad;

	const [currentTime, setCurrentTime] = useState<number>(0);
	const [duration, setDuration] = useState<number>(0);
	const [volume, setVolume] = useState(0.7);
	const [playing, setPlaying] = useState(false);
	const [controlsHided, setControlsHided] = useState(false);
	// const [videoLoading, setVideoLoading] = useState(true);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [hasError, setHasError] = useState(false);

	let hideControlsTimeout: any;

	const playerButtonsSizeProps =
		BUTTONS_SIZE_VARIANTS[
			`${isFullscreen ? PlayerButtonsSize.BIG : playerButtonsSize}`
		];

	const {
		isOpen: volumeIsOpen,
		onOpen: volumeOnOpen,
		onClose: volumeOnClose,
	} = useDisclosure();

	const handleVideoReady = useCallback(() => {
		// setVideoLoading(false);
		setIsVideoReady?.(true);
	}, []);

	const handleVideoError = useCallback((error: any) => {
		console.log('player error: ', error, videoLink);
		setIsVideoReady?.(true);
		setHasError(true);
		// if (!playerRef.current || !playerRef.current.props.style) return;
		// playerRef.current.props.style.display = 'none';
	}, []);

	const togglePlay = useCallback(() => {
		setPlaying((prevState: boolean) => !prevState);
	}, []);

	const handleSeek = useCallback(
		(seconds: number) => {
			playerRef.current?.seekTo(seconds);
		},
		[playerRef],
	);

	const handleMouseMove = useCallback(() => {
		setControlsHided(false);
		clearTimeout(hideControlsTimeout);
		hideControlsTimeout = setTimeout(() => {
			setControlsHided(true);
		}, 2000); // Hide controls after 2 seconds of inactivity
	}, [hideControlsTimeout, controlsHided]);

	const toggleFullscreen = useCallback(() => {
		const fullscreenElement: any =
			document['fullscreenElement'] ||
			document.mozFullScreenElement ||
			document.webkitFullscreenElement ||
			document.msFullscreenElement;

		if (!fullscreenElement) {
			if (boxRef.current?.requestFullscreen) {
				boxRef.current?.requestFullscreen();
			} else if (boxRef.current?.mozRequestFullScreen) {
				boxRef.current?.mozRequestFullScreen();
			} else if (boxRef.current?.webkitRequestFullscreen) {
				boxRef.current?.webkitRequestFullscreen();
			} else if (boxRef.current?.msRequestFullscreen) {
				boxRef.current?.msRequestFullscreen();
			}
			setIsFullscreen(true);
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			setIsFullscreen(false);
		}
	}, [boxRef, document]);

	useEffect(() => {
		boxRef.current?.addEventListener('mousemove', handleMouseMove);
		return () => {
			clearTimeout(hideControlsTimeout);
		};
	}, []);

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.code === 'Space' && enableSpaceBarPlayToggle) {
				togglePlay();
			}
		};

		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [playing]);

	if (hasError) {
		return (
			<Flex
				w="100%"
				h="auto"
				bg="black"
				align="center"
				justify="center"
				direction="column"
				rowGap="8px"
				{...errorCoverProps}>
				<AiOutlineExclamationCircle fontSize="40px" color="white" />
				<Text
					color="white"
					textAlign="center"
					variant="bodyMedium"
					px="20px">
					Something went wrong or video playback is not supported by
					your browser
				</Text>
			</Flex>
		);
	}
	return (
		<Center
			w={{ base: 'full', lg: '75%' }}
			mb={{ base: '10px', lg: 0 }}
			h={'500px'}
			zIndex={9}
			top={0}
			left={0}
			borderTopLeftRadius="10px"
			borderBottomLeftRadius="10px"
			overflow="hidden"
			position={'relative'}
			ref={boxRef}
			{...wrapperProps}>
			{videoLink ? (
				<ReactPlayer
					ref={playerRef}
					url={videoLink}
					height={'100%'}
					width={'100%'}
					controls={isMobile}
					playing={playing}
					volume={volume}
					onError={handleVideoError}
					playsinline={true}
					// controlsList="nodownload"
					onProgress={progress => {
						setCurrentTime(progress.playedSeconds);
					}}
					config={{
						file: {
							attributes: {
								preload,
							},
						},
					}}
					onDuration={time => {
						setDuration(time);
					}}
					onReady={handleVideoReady}
					playIcon={
						<IconButton
							w={playerButtonsSizeProps.mainPlayPauseButtonSize}
							minW={
								playerButtonsSizeProps.mainPlayPauseButtonSize
							}
							h={playerButtonsSizeProps.mainPlayPauseButtonSize}
							icon={
								<PlayIcon
									isFilled={true}
									fillColor="black"
									color="black"
									width={
										playerButtonsSizeProps.bottomPlayPauseIconSize
									}
									height={
										playerButtonsSizeProps.bottomPlayPauseIconSize
									}
								/>
							}
							aria-label={'Play'}
						/>
					}
					light={playing || currentTime > 0 ? false : previewLink}
				/>
			) : (
				<Image h="full" w="full" src={imageLink} objectFit="contain" />
			)}
			{!isMobile && videoLink ? (
				<Controls
					controlsHided={controlsHided}
					playing={playing}
					volumeIsOpen={volumeIsOpen}
					currentTime={currentTime}
					duration={duration}
					volume={volume}
					handleSeek={handleSeek}
					togglePlay={togglePlay}
					volumeOnClose={volumeOnClose}
					volumeOnOpen={volumeOnOpen}
					toggleFullscreen={toggleFullscreen}
					setVolume={setVolume}
					onDownload={onDownload}
					downloadLoading={downloadLoading}
					playerButtonsSizeProps={playerButtonsSizeProps}
				/>
			) : null}
			{/* {videoLoading && (
						<Image
							h="full"
							w="full"
							top={0}
							left={0}
							position="absolute"
							zIndex={14}
							objectFit="contain"
							src={previewLink}
						/>
					)} */}
		</Center>
	);
};
