import React, { useCallback, useState } from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { ItemList, NavAccordion } from '../../../../../../components';
import { BaseRoutes, ConfigurationRoutes } from '../../../../constants';
import { SidebarItem } from './SidebarItem';
import { SideBarHeader } from './SideBarHeader';
import {
	MainLogoIcon,
	MobileLogo,
	RightPaginationArrowIcon,
	SettingsIcon,
} from '../../../../../../assets';
import { Link } from 'react-router-dom';
import { SideBarFooter } from '../../../../../../components/DashboardLayout/components';
import { useSignOut } from '../../../../../../utils';
import { ICurrentUser } from '../../../../../../types';

interface DesktopSidebarProps {
	data: ICurrentUser | undefined;
	isLoading: boolean;
}

export const DesktopSidebar: React.FC<DesktopSidebarProps> = ({
	data,
	isLoading,
}) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const onSignOut = useSignOut();

	const onToggle = useCallback(() => {
		setIsExpanded(prevState => !prevState);
	}, []);

	const { firstName, lastName } = data || {};

	const fullName = `${firstName} ${lastName}`;

	return (
		<Flex
			display={{ base: 'none', md: 'flex' }}
			direction={'column'}
			pos={'relative'}
			w={isExpanded ? '250px' : '120px'}
			bg={'blue.100'}
			transition=".3s"
			pb={'10px'}
			h="100vh">
			<SideBarHeader isExpanded={isExpanded} onAction={onToggle} />
			<Flex
				px={'10px'}
				align={'center'}
				overflowY={'auto'}
				overflowX={'hidden'}
				direction={'column'}
				className={'custom-scroll'}
				flex={'fit-content'}>
				<VStack
					mb={'4px'}
					align={'stretch'}
					spacing={'4px'}
					mt={'10px'}>
					<ItemList
						items={BaseRoutes}
						renderItem={item => (
							<SidebarItem
								isExpanded={isExpanded}
								name={item.name}
								icon={item.icon}
								to={item.to}
							/>
						)}
						keyExtractor={item => item.id}
					/>
					<NavAccordion
						buttonProps={{
							px: '10px',
							py: '14px',
						}}
						menuExpanded={isExpanded}
						icon={<SettingsIcon strokeColor={'white'} />}
						title={'Configurations'}>
						<VStack
							align={isExpanded ? 'stretch' : 'center'}
							spacing={'4px'}>
							<ItemList
								items={ConfigurationRoutes}
								renderItem={item => (
									<SidebarItem
										activeColor={'blue.100'}
										flexProps={{
											p: '10px',
											borderRadius: '4px',
											_hover: {
												bg: 'blue.100',
											},
										}}
										textProps={{
											variant: 'bodyMedium',
										}}
										isExpanded={isExpanded}
										name={item.name}
										icon={item.icon}
										to={item.to}
									/>
								)}
								keyExtractor={item => item.id}
							/>
						</VStack>
					</NavAccordion>
				</VStack>
			</Flex>
			<VStack mt={'auto'} spacing={'4px'} align={'stretch'} px={'10px'}>
				<Button
					px={'10px'}
					to={'/'}
					as={Link}
					h={'48px'}
					_hover={{
						bg: 'blue.200',
					}}
					color={'white'}
					bg={'transparent'}
					justifyContent={'space-between'}>
					{isExpanded ? <MainLogoIcon /> : <MobileLogo />}
					<RightPaginationArrowIcon
						width={20}
						height={20}
						fill={'currentcolor'}
					/>
				</Button>
				<SideBarFooter
					isLoading={isLoading}
					menuExpanded={isExpanded}
					data={data}
					fullName={fullName}
					onSignOut={onSignOut}
				/>
			</VStack>
		</Flex>
	);
};
