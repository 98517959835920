import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	categoryId: number | null;
	categoryName: string;
	onClose: VoidFunction;
	onOpen: VoidFunction;
	setCategoryId: (value: number) => void;
	setCategoryName: (value: string) => void;
}

export const useCreateChannelModalStore = create<IState>(set => ({
	isOpen: false,
	categoryId: null,
	categoryName: '',
	setCategoryId: id => set(() => ({ categoryId: id })),
	setCategoryName: name => set(() => ({ categoryName: name })),
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () =>
		set(() => ({ isOpen: false, categoryId: null, categoryName: '' })),
}));
