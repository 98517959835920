/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import {
	IconButton,
	Button,
	HStack,
	Box,
	useDisclosure,
	Flex,
	VStack,
	useOutsideClick,
} from '@chakra-ui/react';
import {
	ReplyIcon,
	EditPencilIcon,
	DotsHorizontalIcon,
	TrashIcon,
	PinIcon,
	XCloseIcon,
	BanIcon,
	CheckIcon,
	CopyIcon,
} from '../../../../../../assets';
import { VerticalButtonGroup } from '../VerticalButtonGroup';
import { useChatUserStore } from '../../../../../../store';
import { ViewReactionsModal } from '../../../../modals/ViewReactionsModal/ViewReactionsModal';
import { Alerter } from '../../../../../../utils';
import { MessageReactionsPicker } from './MessageReactionsPicker';
import { MessageMenuReactionsList } from './MessageMenuReactionsList';
import { ChatType, MessageReactionType } from '../../../../types';
import {
	RadixPopover,
	RadixPopoverContent,
	RadixPopoverPortal,
} from '../../../../common-components';
import { colors } from '../../../../../../theme';

import './MessageMenuPopup.css';

type PopoverStylesState = {
	top: number;
	left: number;
	applyStyles: boolean;
};

interface IMessageMenuProps {
	onEdit: VoidFunction;
	onDelete: VoidFunction;
	onReply: VoidFunction;
	onPin: VoidFunction;
	allowFullAccess: boolean;
	includePin?: boolean;
	includeEdit?: boolean;
	onSuspendUser?: VoidFunction;
	onSuspendAndPurgeMessagesUser?: VoidFunction;
	onUnSuspendUser?: VoidFunction;
	isAdmin?: boolean;
	sentById?: number;
	isSenderDeleted?: boolean;
	text: string | null;
	id?: number;
	chatType?: ChatType;
	hasReactions?: boolean;
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	userReactedId?: MessageReactionType | null;
	isMessageHovered?: boolean;
	onInteractWithMenu?: (bool: boolean) => void;
	isMenuOpened?: boolean;
	messageRef?: React.MutableRefObject<HTMLDivElement | null>;
	onRemoveMessageHover?: VoidFunction;
}

export const MessageMenu: React.FC<Partial<IMessageMenuProps>> = ({
	onEdit,
	onDelete,
	onReply,
	onPin,
	allowFullAccess,
	includePin,
	includeEdit,
	onSuspendAndPurgeMessagesUser,
	onSuspendUser,
	onUnSuspendUser,
	isAdmin,
	sentById,
	isSenderDeleted,
	text,
	id,
	chatType,
	hasReactions,
	onReact,
	userReactedId,
	isMessageHovered,
	onInteractWithMenu,
	onRemoveMessageHover,
	isMenuOpened,
}) => {
	const { user } = useChatUserStore();
	const [popoverStyles, setPopoverStyles] =
		useState<PopoverStylesState | null>(null);
	const popoverWrapperRef = useRef<HTMLDivElement | null>(null);
	const popoverContentRef = useRef<HTMLDivElement | null>(null);

	const {
		isOpen: isReactionsModalOpen,
		onOpen: onOpenReactionsModal,
		onClose: onCloseReactionsModal,
	} = useDisclosure();

	const handleCloseMessageActions = () => {
		onRemoveMessageHover?.();
		onInteractWithMenu?.(false);
	};

	const handleEdit = () => {
		onEdit?.();
		handleCloseMessageActions();
	};

	const handleUserSuspend = () => {
		onSuspendUser?.();
		handleCloseMessageActions();
	};

	const handleUserActivate = () => {
		onUnSuspendUser?.();
		handleCloseMessageActions();
	};

	const handleUserSuspendWithPurge = () => {
		onSuspendAndPurgeMessagesUser?.();
		handleCloseMessageActions();
	};

	const handleCopy = (msg: string) => {
		navigator.clipboard.writeText(msg);
		Alerter.success('Message copied to clipboard');
		handleCloseMessageActions();
	};

	useEffect(() => {
		if (isMenuOpened) {
			const rerenderOnPopoverAppears = setTimeout(() => {
				if (popoverContentRef?.current) {
					setPopoverStyles(prev => ({
						top: prev?.top || 0,
						left: prev?.left || 0,
						applyStyles: true,
					}));
				}
			}, 0);

			return () => {
				clearTimeout(rerenderOnPopoverAppears);
			};
		}
	}, [isMenuOpened]);

	useOutsideClick({
		ref: popoverWrapperRef,
		handler: event => {
			if (
				popoverContentRef?.current?.contains(event.target as Node) ||
				!isMenuOpened
			) {
				return;
			}

			handleCloseMessageActions();
		},
	});

	const onOpenMessageMenu = () => {
		if (isMenuOpened) {
			onInteractWithMenu?.(false);
			return;
		}
		onInteractWithMenu?.(true);
		if (!popoverWrapperRef.current) {
			return;
		}

		const buttonRect = popoverWrapperRef.current.getBoundingClientRect();

		setPopoverStyles({
			top: buttonRect.top,
			left: buttonRect.left,
			applyStyles: false,
		});
	};

	const calculateTopPosition = (
		popoverState: PopoverStylesState | null,
		contentHeight: number,
	) => {
		if (!popoverState) {
			return 60;
		}

		const { top } = popoverState;
		const shouldAdjust = top && top + contentHeight > window.innerHeight;

		return shouldAdjust ? window.innerHeight - contentHeight - 20 : top;
	};

	const topPosition = calculateTopPosition(
		popoverStyles,
		popoverContentRef?.current?.getBoundingClientRect().height || 0,
	);

	const showButtonGroup = includeEdit || text?.length || allowFullAccess;

	return (
		<Box
			position="absolute"
			right="0"
			display={isMessageHovered ? 'flex' : 'none'}
			zIndex={15}>
			<HStack bg="transparent" spacing="10px" borderRadius="4px">
				<IconButton
					aria-label="Reply"
					minW="auto"
					boxSize="28px"
					onClick={onReply}>
					<ReplyIcon />
				</IconButton>

				<Box position="relative" ref={popoverWrapperRef}>
					<IconButton
						aria-label="More options"
						bg={isMenuOpened ? 'gray.300' : colors.mainBg}
						minW="auto"
						boxSize="28px"
						onClick={onOpenMessageMenu}>
						<DotsHorizontalIcon />
					</IconButton>
					<RadixPopover open={!!isMenuOpened}>
						<RadixPopoverPortal>
							<RadixPopoverContent
								ref={popoverContentRef}
								className={'message-menu-popup-container'}
								sideOffset={5}
								side="left"
								sticky="always"
								style={
									popoverStyles?.applyStyles
										? {
												position: 'fixed',
												top: topPosition,
												left: popoverStyles?.left
													? popoverStyles?.left - 325
													: 0,
										  }
										: {
												opacity: 0,
										  }
								}>
								<Box
									p="20px"
									bg="mainBg"
									boxShadow="0px 4px 8px 0px rgba(28, 41, 89, 0.40)"
									borderRadius="10px">
									<Flex direction="column" rowGap="10px">
										<MessageReactionsPicker
											onReact={onReact}
											userReactedId={userReactedId}
											id={id}
										/>

										<MessageMenuReactionsList
											isMenuOpen={!!isMenuOpened}
											onOpen={onOpenReactionsModal}
											messageId={id}
											chatType={chatType}
											hasReactions={hasReactions}
										/>

										{showButtonGroup ? (
											<VerticalButtonGroup>
												{includeEdit ? (
													<Button
														rightIcon={
															<EditPencilIcon />
														}
														bg="white"
														w="100%"
														color="blue.200"
														fontWeight={500}
														fontSize="16px"
														p="10px"
														iconSpacing="auto"
														borderRadius={0}
														mb="1px"
														onClick={handleEdit}>
														Edit
													</Button>
												) : null}
												{text?.length ? (
													<Button
														rightIcon={<CopyIcon />}
														bg="white"
														w="100%"
														color="blue.200"
														fontWeight={500}
														fontSize="16px"
														p="10px"
														iconSpacing="auto"
														borderRadius={0}
														mb="1px"
														onClick={() =>
															handleCopy(text)
														}>
														Copy text
													</Button>
												) : null}
												{allowFullAccess ? (
													<VStack spacing="2px">
														{includePin &&
														!isSenderDeleted ? (
															<Button
																rightIcon={
																	<PinIcon />
																}
																bg="white"
																w="100%"
																color="blue.200"
																fontWeight={500}
																fontSize="16px"
																p="10px"
																iconSpacing="auto"
																borderRadius={0}
																onClick={onPin}>
																Pin Message
															</Button>
														) : null}
														{!isAdmin ||
														user?.userId ===
															sentById ? (
															<Button
																rightIcon={
																	<TrashIcon />
																}
																bg="white"
																w="100%"
																color="blue.200"
																fontWeight={500}
																fontSize="16px"
																p="10px"
																iconSpacing="auto"
																borderRadius={0}
																onClick={
																	onDelete
																}>
																Delete Message
															</Button>
														) : null}

														{onUnSuspendUser &&
														!isAdmin &&
														includePin ? (
															<Button
																rightIcon={
																	<CheckIcon />
																}
																bg="white"
																w="100%"
																color="success"
																fontWeight={500}
																fontSize="16px"
																p="10px"
																iconSpacing="auto"
																borderRadius={0}
																onClick={
																	handleUserActivate
																}>
																Activate
															</Button>
														) : !isAdmin &&
														  includePin ? (
															<Button
																rightIcon={
																	<BanIcon />
																}
																bg="white"
																w="100%"
																color="dangerButton.500"
																fontWeight={500}
																fontSize="16px"
																p="10px"
																iconSpacing="auto"
																borderRadius={0}
																onClick={
																	handleUserSuspend
																}>
																Suspend
															</Button>
														) : null}
														{!onUnSuspendUser &&
														!isAdmin &&
														includePin ? (
															<Button
																rightIcon={
																	<XCloseIcon />
																}
																bg="white"
																w="100%"
																fontWeight={500}
																fontSize="16px"
																p="10px"
																color="dangerButton.500"
																iconSpacing="auto"
																borderRadius={0}
																onClick={
																	handleUserSuspendWithPurge
																}>
																Suspend and
																delete all
																messages
															</Button>
														) : null}
													</VStack>
												) : null}
											</VerticalButtonGroup>
										) : null}
									</Flex>
								</Box>
							</RadixPopoverContent>
						</RadixPopoverPortal>
					</RadixPopover>
				</Box>
			</HStack>
			<ViewReactionsModal
				isOpen={isReactionsModalOpen}
				onClose={onCloseReactionsModal}
				messageId={id}
				chatType={chatType}
			/>
		</Box>
	);
};
