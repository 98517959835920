/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Box, Button, Flex, IconButton, SimpleGrid } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets';
import { months } from '../helper';
import { range } from 'd3';

interface IPickerMonthYearSelectorProps {
	setSelectedYear: (year: number) => void;
	setSelectedMonth: (month: string) => void;
	setToday: () => void;
	year: number;
	month: string;
	maxYear: number;
	minYear: number;
}
export const PickerMonthYearSelector: React.FC<
	IPickerMonthYearSelectorProps
> = ({ setSelectedYear, setSelectedMonth, year, month, maxYear, minYear }) => {
	const [yearView, setYearView] = useState(false);
	const [firstYearInRange, setFirstYearInRange] = useState(
		new Date().getFullYear() - 8,
	);
	const lastYearInRange = firstYearInRange + 12;

	const yearsRange = range(firstYearInRange, lastYearInRange, 1);

	return (
		<Box p="20px">
			<Flex
				mb="10px"
				mt="8px"
				justifyContent={'space-between'}
				alignItems={'center'}>
				<Button
					onClick={() => setYearView(prevState => !prevState)}
					color="blueText"
					fontSize="14px"
					lineHeight="17px"
					_hover={{ bg: 'white.100' }}
					_active={{ bg: 'white.100' }}
					fontWeight={500}>
					{yearView
						? `${yearsRange[0]} - ${
								yearsRange[yearsRange.length - 1]
						  }`
						: year}
				</Button>
				<Flex>
					<IconButton
						aria-label={'prev year'}
						bg={'inherit'}
						p={0}
						w={'20px'}
						_hover={{ bg: 'inherit' }}
						onClick={() => {
							if (yearView) {
								setFirstYearInRange(
									prevState => prevState - 12,
								);
							} else {
								setSelectedYear(year - 1);
							}
						}}
						icon={<ArrowUpIcon />}
						isDisabled={
							yearView
								? firstYearInRange <= minYear
								: year <= minYear
						}
						size={'sm'}
					/>
					<IconButton
						aria-label={'next year'}
						bg={'inherit'}
						p={0}
						_hover={{ bg: 'inherit' }}
						onClick={() => {
							if (yearView) {
								setFirstYearInRange(
									prevState => prevState + 12,
								);
							} else {
								setSelectedYear(year + 1);
							}
						}}
						icon={<ArrowDownIcon />}
						isDisabled={
							yearView
								? lastYearInRange >= maxYear
								: year >= maxYear
						}
						size={'sm'}
					/>
				</Flex>
			</Flex>
			<SimpleGrid columns={4} spacing={1}>
				{yearView
					? yearsRange.map((item: number, index: number) => {
							const isCurrent = year === item;
							return (
								<Button
									bg={isCurrent ? 'blue.200' : 'transparent'}
									color={isCurrent ? 'white' : 'blue.300'}
									fontSize={'14px'}
									fontWeight={400}
									key={index}
									onClick={() => setSelectedYear(item)}
									w="50px"
									h="50px">
									{item}
								</Button>
							);
					  })
					: months.map((item: string, index) => {
							const isCurrent = month === item;
							return (
								<Button
									bg={isCurrent ? 'blue.200' : 'transparent'}
									color={isCurrent ? 'white' : 'blue.300'}
									fontSize={'14px'}
									fontWeight={400}
									key={index}
									onClick={() => setSelectedMonth(item)}
									w="50px"
									h="50px">
									{item.substring(0, 3)}
								</Button>
							);
					  })}
			</SimpleGrid>
		</Box>
	);
};
