export const PinIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.37756 15.6162L2.7207 21.273M11.6952 6.64169L10.1344 8.20258C10.007 8.3299 9.94337 8.39357 9.87084 8.44415C9.80646 8.48905 9.73702 8.52622 9.66395 8.55488C9.58162 8.58717 9.49334 8.60482 9.31677 8.64014L5.65231 9.37303C4.70001 9.56349 4.22385 9.65872 4.00109 9.90977C3.80703 10.1285 3.71841 10.4212 3.75856 10.7108C3.80465 11.0433 4.14801 11.3866 4.83472 12.0733L11.9205 19.1591C12.6072 19.8458 12.9505 20.1891 13.283 20.2352C13.5726 20.2754 13.8653 20.1868 14.084 19.9927C14.3351 19.7699 14.4303 19.2938 14.6208 18.3415L15.3537 14.677C15.389 14.5005 15.4066 14.4122 15.4389 14.3298C15.4676 14.2568 15.5047 14.1873 15.5496 14.123C15.6002 14.0504 15.6639 13.9868 15.7912 13.8594L17.3521 12.2985C17.4335 12.2171 17.4742 12.1764 17.5189 12.1409C17.5587 12.1093 17.6008 12.0808 17.6449 12.0557C17.6945 12.0273 17.7474 12.0046 17.8532 11.9593L20.3476 10.8903C21.0753 10.5784 21.4391 10.4225 21.6044 10.1705C21.7489 9.95013 21.8006 9.68163 21.7483 9.42335C21.6884 9.12801 21.4085 8.8481 20.8487 8.28827L15.7055 3.14514C15.1457 2.58531 14.8658 2.3054 14.5704 2.24552C14.3122 2.19317 14.0437 2.24488 13.8233 2.38941C13.5713 2.55469 13.4154 2.91854 13.1035 3.64624L12.0345 6.14059C11.9892 6.24641 11.9665 6.29932 11.9381 6.34893C11.913 6.393 11.8845 6.4351 11.8529 6.47484C11.8174 6.51958 11.7767 6.56029 11.6952 6.64169Z"
			stroke="#0F1B49"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
