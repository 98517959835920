import { API_CHAT } from '../../../api/api.base';
import { useMutation } from 'react-query';
import { ApiResponse } from '../../../types';
import { AllFriendsListResponse, SearchWithPaginationPayload } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetFriends = () => {
	return useMutation<
		ApiResponse<AllFriendsListResponse>,
		unknown,
		SearchWithPaginationPayload
	>(
		(data: SearchWithPaginationPayload) =>
			API_CHAT.get('/api/app/friends', data),
		{
			mutationKey: [MessengerQueryKeys.GET_USER_FRIENDS],

			onError(error) {
				console.log(error, 'er');
			},
		},
	);
};
