// /* eslint-disable no-mixed-spaces-and-tabs */
// /* eslint-disable indent */
import React, {
	useEffect,
	useMemo,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';
import { Box } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useChannel } from 'ably/react';
import { MessageWithAddons, DateDivider } from '../../messages-components';
import {
	EditMessageDisplayData,
	ReplyMessage,
	UserByIdData,
	ChatType,
	MessageReactionType,
	MessageReactionActionType,
} from '../../types';
import { DeleteMessageConfirmModal } from '../../modals';
import { StartOfDirectChat } from './StartOfDirectChat';
import { Loader } from '../../../../components';
import { useChatUserStore } from '../../../../store';
import { deletePrivateMessage, updatePrivateMessage } from '../../helpers';
import { DEFAULT_LAST_READ_TIME } from '../../constants';
import {
	useCreateDirectMessageReaction,
	useDeleteDirectMessageReaction,
	useGetDirectMessages,
	useUpdateDirectMessageReaction,
} from '../../queries';
import { IChatUser } from '../../../../types';
import { updateDirectUserSelfReactions } from '../../utils';
import { useUpdateReactionsList } from '../../hooks';
import { MessengerQueryKeys } from '../../queries/query-keys';
import { useHandleDirectMessagesSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry } from '../../../../utils';

interface IDirectMessagesListProps {
	conversationId?: string | null;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	replyMessage: ReplyMessage | null;
	receiver: UserByIdData | null;
	scrollRef: React.RefObject<HTMLDivElement>;
	topScrollRef: React.RefObject<HTMLSpanElement>;
	lastReadTime?: string;
	user: IChatUser | null;
	receiverId?: string;
	updateLastReadTime: (time: string) => void;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	receiverLoading: boolean;
	deleteConversationHandler: VoidFunction;
}

export const DirectMessagesList: React.FC<IDirectMessagesListProps> = ({
	conversationId,
	onSetReplyMessage,
	receiver,
	scrollRef,
	topScrollRef,
	replyMessage,
	receiverId,
	lastReadTime,
	updateLastReadTime,
	updateUnreadCount,
	receiverLoading,
	deleteConversationHandler,
}) => {
	const { user } = useChatUserStore();

	const { mutateAsync: createDirectMessageReaction } =
		useCreateDirectMessageReaction();
	const { mutateAsync: updateDirectMessageReaction } =
		useUpdateDirectMessageReaction();
	const { mutateAsync: removeDirectMessageReaction } =
		useDeleteDirectMessageReaction();
	const { mutate: updateMessageReactionsList } = useUpdateReactionsList(
		MessengerQueryKeys.GET_DIRECT_MESSAGE_REACTIONS,
	);

	const {
		handleDirectMessageSocketEvent,
		newMessages,
		setNewMessages,
		setHistoryMessages,
		historyMessages,
	} = useHandleDirectMessagesSocketEvents({
		conversationId,
		scrollRef,
		updateLastReadTime,
		updateUnreadCount,
		receiverId,
		deleteConversationHandler,
	});
	const [messageToDelete, setMessageToDelete] =
		useState<EditMessageDisplayData | null>(null);

	useChannel(
		{
			channelName: `private-user:${user?.userId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handleDirectMessageSocketEvent(message);
		},
	);

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
		useGetDirectMessages(
			conversationId ? +conversationId : null,
			newMessages.length,
			Boolean(conversationId),
		);

	const onEditMessage = async (messageId: number, newText: string) => {
		try {
			if (!conversationId) {
				return;
			}
			const res = await updatePrivateMessage({
				conversationId: +conversationId,
				privateMessageId: messageId,
				text: newText,
			});
			if (!res?.success) {
				return;
			}
		} catch (error) {
			console.error('Error editing document: ', error);
		}
	};

	const onDeleteMessage = async (messageId: number) => {
		try {
			if (!conversationId) {
				return;
			}
			const res = await deletePrivateMessage({
				conversationId: +conversationId,
				privateMessageId: messageId,
			});
			if (!res?.success) {
				return;
			}
		} catch (error) {
			console.error('Error deleting document: ', error);
		}
	};

	const updateMessagesOnReactionSubmit = (
		messageId: number,
		emojiId: MessageReactionType,
		actionType: MessageReactionActionType,
	) => {
		setNewMessages(prev =>
			prev.map(message => {
				if (message.id !== messageId) {
					return message;
				}
				const reactions = updateDirectUserSelfReactions(
					message.reactions,
					emojiId,
					actionType,
				);
				return {
					...message,
					reactions,
				};
			}),
		);

		setHistoryMessages(prev =>
			prev.map(message => {
				if (message.id !== messageId) {
					return message;
				}
				const reactions = updateDirectUserSelfReactions(
					message.reactions,
					emojiId,
					actionType,
				);
				return {
					...message,
					reactions,
				};
			}),
		);
	};

	const onReact = async (
		conversationId: number,
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => {
		try {
			if (!reactedEmojiId) {
				const res = await createDirectMessageReaction({
					conversationId,
					messageId,
					reactionEmojiId: emojiId,
				});
				if (!res.success) {
					return;
				}

				updateMessagesOnReactionSubmit(
					messageId,
					emojiId,
					MessageReactionActionType.CREATE,
				);
				updateMessageReactionsList(emojiId, messageId, 'add');

				return;
			}
			if (emojiId === reactedEmojiId) {
				const res = await removeDirectMessageReaction({
					conversationId,
					messageId,
				});
				if (!res.success) {
					return;
				}

				updateMessagesOnReactionSubmit(
					messageId,
					emojiId,
					MessageReactionActionType.DELETE,
				);

				updateMessageReactionsList(emojiId, messageId, 'remove');

				return;
			}
			const res = await updateDirectMessageReaction({
				conversationId,
				messageId,
				reactionEmojiId: emojiId,
			});
			if (!res.success) {
				return;
			}

			updateMessagesOnReactionSubmit(
				messageId,
				emojiId,
				MessageReactionActionType.EDIT,
			);
		} catch (error) {
			console.log('onReact error: ', error);
		}
	};

	const combinedMessages = useMemo(
		() => [...newMessages, ...historyMessages],
		[newMessages, historyMessages],
	);

	useEffect(() => {
		if (conversationId && data?.pages.length && !isFetching) {
			const flatMessages =
				data?.pages?.flatMap(page => page?.messages || []) || [];
			setHistoryMessages(flatMessages);
			updateLastReadTime(
				data?.pages?.[data?.pages.length - 1]?.userLastReadDateTime ||
					DEFAULT_LAST_READ_TIME,
			);
			updateUnreadCount(
				data?.pages?.[data?.pages.length - 1]?.unreadCount || 0,
			);
		}
	}, [data, conversationId]);

	const conversationLoading =
		(isFetching && !isFetchingNextPage) || (receiverLoading && !receiver);
	const startOfDirectChatLoading =
		!isFetching && !isFetchingNextPage && !receiverLoading;

	return (
		<>
			<InfiniteScroll
				dataLength={combinedMessages.length}
				pullDownToRefresh={true}
				refreshFunction={fetchNextPage}
				next={fetchNextPage}
				hasMore={!!hasNextPage}
				height={`calc(100vh - ${replyMessage ? 150 : 110}px)`}
				inverse={true}
				scrollThreshold={500}
				loader={null}
				style={{
					paddingTop: '40px',
					display: 'flex',
					flexDirection: 'column-reverse',
				}}>
				{conversationLoading ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<Box
							ref={scrollRef}
							flexShrink={0}
							height="5px"
							w={'100%'}></Box>
						{combinedMessages.map((elem, index, arr) => (
							<MessageWithAddons
								key={elem.id}
								arr={arr}
								data={elem}
								index={index}
								onEditMessage={onEditMessage}
								onSetDeleteMessage={setMessageToDelete}
								onSetReplyMessage={onSetReplyMessage}
								allowFullAccess={user?.userId === elem.senderId}
								includeEdit={user?.userId === elem.senderId}
								receiver={
									user?.userId === elem.senderId
										? null
										: receiver
								}
								includePin={false}
								disableMenu={
									receiver?.isBlockingMe ||
									receiver?.isBlocked
								}
								enableMentions={false}
								messageType={elem.type}
								chatType={ChatType.DIRECT}
								includeUserInfoPopup={true}
								lastReadTime={
									lastReadTime === DEFAULT_LAST_READ_TIME
										? undefined
										: lastReadTime
								}
								onReact={(
									messageId,
									emojiId,
									reactedEmojiId,
								) => {
									if (conversationId) {
										onReact(
											+conversationId,
											messageId,
											emojiId,
											reactedEmojiId,
										);
									}
								}}
							/>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<span ref={topScrollRef}></span>
						{startOfDirectChatLoading ? (
							<StartOfDirectChat
								receiver={receiver}
								receiverId={receiverId}
							/>
						) : null}
					</>
				)}
			</InfiniteScroll>
			<DeleteMessageConfirmModal
				isOpen={!!messageToDelete}
				onClose={() => setMessageToDelete(null)}
				onDeleteMessage={onDeleteMessage}
				messageToDelete={messageToDelete}
				chatType={ChatType.DIRECT}
			/>
		</>
	);
};
