import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Flex,
	HStack,
	IconButton,
	Image,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { imageFileToDataUrl } from '../../utils/imageFileToDataUrl';
import { FileIcon, SearchIcon } from '../../../../assets';
import { colors } from '../../../../theme';
import { CloseIcon } from '@chakra-ui/icons';
import { ViewAttachmentPhotoModal } from '../../modals/ViewAttachmentPhotoModal/ViewAttachmentPhotoModal';
import { CustomPlayer } from '../../../../components';
import { checkFileType } from '../../utils';
import { FileAttachmentType } from '../../constants';
import { PlayerButtonsSize } from '../../../../components/CustomPlayer/types';

interface MessageAttachmentViewerProps {
	file: null | File;
	removeFile: VoidFunction;
	isMessageReplyTo: boolean;
}

export const MessageAttachmentViewer: React.FC<
	MessageAttachmentViewerProps
> = ({ file, removeFile, isMessageReplyTo }) => {
	const [imgSrc, setImgSrc] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const fileType = useMemo(
		() => checkFileType(file?.type || ''),
		[file?.type],
	);

	useEffect(() => {
		setImgSrc('');
		if (fileType !== FileAttachmentType.DOC && file) {
			imageFileToDataUrl(setImgSrc, file);
		}
	}, [file]);

	if (!file) {
		return null;
	}

	return (
		<Flex
			pos={'absolute'}
			p={'10px 0 10px 10px'}
			bottom={isMessageReplyTo ? '110px' : '70px'}
			left={'0px'}
			h={'auto'}
			minH={'80px'}
			w={'100%'}
			borderRadius={'10px'}
			bg={'dropBlue'}
			zIndex={10}>
			{fileType === FileAttachmentType.DOC ? (
				// view file
				<HStack
					pos={'relative'}
					bg={'white'}
					w={'250px'}
					borderRadius={'5px'}
					p={'10px'}>
					<Box minW={'24px'}>
						<FileIcon strokeColor={colors.dropBlue} />
					</Box>
					<Text
						color={'blue.200'}
						isTruncated={true}
						display={'inline'}>
						{file.name}
					</Text>
					<Box
						pos={'absolute'}
						top={'-3px'}
						right={'-8px'}
						bg={'blue.200'}
						borderRadius={'4px'}
						p={'1px'}>
						<IconButton
							aria-label={'remove-file'}
							icon={<CloseIcon color={'white'} />}
							variant={'ghost'}
							_hover={{}}
							onClick={() => {
								removeFile();
							}}
						/>
					</Box>
				</HStack>
			) : null}{' '}
			{fileType === FileAttachmentType.IMAGE ? (
				// view image
				<VStack
					pos={'relative'}
					bg={'white'}
					w={'250px'}
					borderRadius={'5px'}
					p={'10px'}
					spacing={0}>
					<Image
						maxH={'175px'}
						h={'auto'}
						src={imgSrc}
						alt={'uploaded-img'}
					/>
					<Text
						color={'blue.200'}
						textAlign={'start'}
						noOfLines={1}
						textOverflow={'ellipsis'}
						maxW={'230px'}>
						{file.name}
					</Text>
					<Box
						pos={'absolute'}
						top={'0px'}
						right={'-8px'}
						bg={'blue.200'}
						p={'1px'}
						borderRadius={'4px'}>
						<IconButton
							aria-label={'view-image'}
							icon={<SearchIcon color={'white'} />}
							variant={'ghost'}
							_hover={{}}
							onClick={onOpen}
						/>
						<IconButton
							aria-label={'remove-file'}
							icon={<CloseIcon color={'white'} />}
							variant={'ghost'}
							_hover={{}}
							onClick={() => {
								removeFile();
							}}
						/>
					</Box>
				</VStack>
			) : null}
			{fileType === FileAttachmentType.VIDEO ? (
				<VStack
					pos={'relative'}
					bg={'white'}
					w={'250px'}
					borderRadius={'5px'}
					p={'10px'}
					spacing={0}>
					<Box h="180px" w="100%" bg={colors.blue[300]}>
						<CustomPlayer
							videoLink={URL.createObjectURL(file)}
							playerButtonsSize={PlayerButtonsSize.SMALL}
							enableSpaceBarPlayToggle={false}
							wrapperProps={{
								height: '100%',
								w: '100%',
								borderRadius: '0px',
							}}
						/>
					</Box>
					<Box
						pos={'absolute'}
						top={'0px'}
						right={'-8px'}
						bg={'blue.200'}
						p={'1px'}
						borderRadius={'4px'}
						zIndex={10}>
						<IconButton
							aria-label={'remove-file'}
							icon={<CloseIcon color={'white'} />}
							variant={'ghost'}
							_hover={{}}
							onClick={() => {
								removeFile();
							}}
						/>
					</Box>
					<Text
						color={'blue.200'}
						textAlign={'start'}
						noOfLines={1}
						textOverflow={'ellipsis'}
						maxW={'230px'}>
						{file.name}
					</Text>
				</VStack>
			) : null}
			{fileType === FileAttachmentType.IMAGE ? (
				<ViewAttachmentPhotoModal
					isOpen={isOpen}
					onClose={onClose}
					imgPathLarge={imgSrc}
					name={file.name}
					canDownload={false}
				/>
			) : null}
		</Flex>
	);
};
