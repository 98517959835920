import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(switchAnatomy.keys);

const smallOutlined = definePartsStyle({
	track: {
		bg: 'transparent',
		border: '2px solid #4A5991',
	},
	thumb: {
		bg: '#4A5991',
	},
});

const green = definePartsStyle({
	track: {
		bg: 'transparent',
		border: '2px solid #4A5991',
		_checked: {
			borderColor: '#08BB4B',
		},
	},
	thumb: {
		bg: '#4A5991',
		_checked: {
			bg: '#08BB4B',
		},
	},
});

const green2 = definePartsStyle({
	track: {
		bg: '#4A5991',
		_checked: {
			bg: '#08BB4B',
		},
	},
	thumb: {
		bg: 'white',
	},
});

export const Switch = defineMultiStyleConfig({
	variants: { smallOutlined, green, green2 },
});
