export enum CruzQueryKeys {
	PropertiesMoveInReady = 'properties-move-in-ready',
	PropertiesUnderConstruction = 'properties-under-construction',
	PropertyDetails = 'property-details',
	PropertyUnitDetails = 'property-unit-details',
	PropertyExtendInfo = 'property-extend-info',
	UnoccupiedPropertySelector = 'unoccupied-property-selector',
	UnitsSelector = 'units-selector',
	BusinessEntitySelector = 'business-entity-selector',
	BusinessEntity = 'business-entity',
	BusinessEntities = 'business-entities',
	TenantDetails = 'tenant-details',
	CurrentTenants = 'current-tenants',
	ArchivedTenants = 'archived-tenants',
	Mailboxes = 'mailboxes',
	InvoiceOverview = 'invoice-overview',
	Invoices = 'invoices',
	TenantInvoiceOverview = 'tenant-invoice-overview',
	TenantInvoices = 'tenant-invoices',
	Invoice = 'invoice',
	Revenue = 'revenue',
	DashboardOverview = 'dashboard-overview',
	PlannerLists = 'planner-lists',
	DashboardPositions = 'dashboard-positions',
	PlannerCard = 'planner-card',
	CardAttachments = 'card-attachments',
	CardTenants = 'card-tenants',
	AllCardLabels = 'all-card-labels',
	CardLabels = 'card-labels',
	AllCustomFields = 'all-custom-fields',
	CustomFields = 'custom-fields',
	OccupiedPropertySelector = 'occupied-property-selector',
}
