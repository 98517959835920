import { PlayerButtonsSize, PlayerButtonsSizeVariants } from './types';

export const BUTTONS_SIZE_VARIANTS: PlayerButtonsSizeVariants = {
	[`${PlayerButtonsSize.SMALL}`]: {
		mainPlayPauseButtonSize: '32px',
		mainPlayPauseIconSize: '20px',
		bottomPlayPauseButtonSize: '24px',
		bottomPlayPauseIconSize: '16px',
		currentTimeText: '10px',
		volumeButtonSize: '24px',
		volumeIconSize: '16px',
		fullscreenButtonSize: '24px',
		fullscreenIconSize: '16px',
		volumeTrackWidth: '80px',
		volumeTrackPadding: '4px',
		downloadButtonSize: '24px',
		downloadIconSize: '16px',
		bottomBarHeight: '32px',
	},
	[`${PlayerButtonsSize.MEDIUM}`]: {
		mainPlayPauseButtonSize: '48px',
		mainPlayPauseIconSize: '24px',
		bottomPlayPauseButtonSize: '32px',
		bottomPlayPauseIconSize: '20px',
		currentTimeText: '12px',
		volumeButtonSize: '32px',
		volumeIconSize: '20px',
		fullscreenButtonSize: '32px',
		fullscreenIconSize: '20px',
		volumeTrackWidth: '100px',
		volumeTrackPadding: '6px',
		downloadButtonSize: '32px',
		downloadIconSize: '18px',
		bottomBarHeight: '38px',
	},
	[`${PlayerButtonsSize.BIG}`]: {
		mainPlayPauseButtonSize: '64px',
		mainPlayPauseIconSize: '24px',
		bottomPlayPauseButtonSize: '40px',
		bottomPlayPauseIconSize: '24px',
		currentTimeText: '14px',
		volumeButtonSize: '40px',
		volumeIconSize: '24px',
		fullscreenButtonSize: '40px',
		fullscreenIconSize: '24px',
		volumeTrackWidth: '120px',
		volumeTrackPadding: '8px',
		downloadButtonSize: '44px',
		downloadIconSize: '24px',
		bottomBarHeight: '44px',
	},
};
