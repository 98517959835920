import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
// here we have 2 maps packages because first one throws some errors
// in old safari in sentry, and I can't quite figure out how to replace
// street view with the new one, although it is possible
import {
	GoogleMap,
	StreetViewPanorama,
	useJsApiLoader,
} from '@react-google-maps/api';
import {
	Map,
	Marker as NewMarker,
	MapCameraProps,
	MapCameraChangedEvent,
} from '@vis.gl/react-google-maps';
import streetViewPlaceholder from '../../../../../assets/images/empty-state-google-street-view.webp';

const isValidCoordinate = (value: number) => value !== 0 && value !== undefined;

const mapContainerStyle = {
	width: '100%',
	height: '100%',
	borderRadius: '8px',
	overflow: 'hidden',
};

const initialCenter = {
	lat: 34.054908,
	lng: -118.242643,
};

interface IProps {
	lat: number;
	lng: number;
}

const INITIAL_CAMERA = {
	center: initialCenter,
	zoom: 19,
};

export const MapWithStreetView: React.FC<IProps> = ({ lat, lng }) => {
	const [panorama, setPanorama] =
		useState<google.maps.StreetViewPanorama | null>(null);

	const [cameraProps, setCameraProps] =
		useState<MapCameraProps>(INITIAL_CAMERA);

	const handleCameraChange = useCallback(
		(ev: MapCameraChangedEvent) => setCameraProps(ev.detail),
		[],
	);

	const streetViewRef = useRef<StreetViewPanorama | null>(null);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
		id: 'google-map-script',
	});

	const loc = useMemo(
		() =>
			isValidCoordinate(lat) && isValidCoordinate(lng)
				? { lat, lng }
				: null,
		[lat, lng],
	);

	const onLoadPanorama = useCallback(
		(panorama: google.maps.StreetViewPanorama) => {
			setPanorama(panorama);
		},
		[],
	);
	const onUnmountPanorama = useCallback(() => {
		setPanorama(null);
	}, []);

	useEffect(() => {
		if (loc && panorama) {
			setCameraProps({ center: loc, zoom: 19 });
		}
	}, [loc, panorama]);

	return (
		<Flex
			mt={'32px'}
			gap={'16px'}
			flexDirection={{ base: 'column', md: 'row' }}>
			<Box
				position={'relative'}
				w={'100%'}
				h={'271px'}
				overflow={'hidden'}
				borderRadius={'8px'}>
				<Box
					display={!loc ? 'block' : 'none'}
					position={'absolute'}
					top={0}
					left={0}
					width={'100%'}
					height={'100%'}
					backdropFilter={'blur(10px)'}
					zIndex={1}
				/>
				<Map
					gestureHandling={'cooperative'}
					disableDefaultUI={true}
					streetViewControl={false}
					mapTypeControl={false}
					{...cameraProps}
					style={mapContainerStyle}
					onCameraChanged={handleCameraChange}>
					<NewMarker position={loc} />
				</Map>
			</Box>
			<Box
				w={'100%'}
				h={'271px'}
				borderRadius={'8px'}
				position={'relative'}>
				<Image
					display={!loc ? 'block' : 'none'}
					position={'absolute'}
					top={0}
					left={0}
					src={streetViewPlaceholder}
					alt={'street-placeholder'}
					w={'full'}
					h={'full'}
					zIndex={5}
				/>

				{isLoaded && loc ? (
					<GoogleMap
						zoom={7}
						center={loc}
						mapContainerStyle={mapContainerStyle}>
						<StreetViewPanorama
							onLoad={onLoadPanorama}
							onUnmount={onUnmountPanorama}
							ref={streetViewRef}
							options={{
								position: loc,
								visible: true,
								enableCloseButton: false,
								zoom: 1,
								scrollwheel: false,
							}}
						/>
					</GoogleMap>
				) : null}
			</Box>
		</Flex>
	);
};
