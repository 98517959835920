import {
	Box,
	Button,
	Center,
	Flex,
	HStack,
	IconButton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { DownloadIcon, FileIcon, TrashIcon } from '../../../../../assets';
import { UploadFileModal } from '../../UploadFileModal/UploadFileModal';
import { formatFileSize } from '../../../../../utils';

interface IProps {
	setFileToUpload: (value: File) => void;
	file?: File;
	isDisabled: boolean;
	removeFile: VoidFunction;
}

export const UploadLeaseAgreement: React.FC<IProps> = ({
	setFileToUpload,
	file,
	isDisabled,
	removeFile,
}) => {
	const {
		isOpen: isOpenUploadModal,
		onClose: onCloseUploadModal,
		onOpen: onOpenUploadModal,
	} = useDisclosure();
	return (
		<Box alignSelf={'flex-start'}>
			{file ? (
				<Flex
					w={'352px'}
					borderRadius={'8px'}
					border={'1px solid #E4EAF5'}
					px={'16px'}
					py={'8px'}
					justify={'space-between'}
					align={'center'}>
					<HStack spacing={'8px'}>
						<Center
							borderRadius={'full'}
							bg={'lightBlue.100'}
							minW={'40px'}
							minH={'40px'}>
							<FileIcon strokeColor={'#0F1B49'} />
						</Center>
						<Box>
							<Text
								noOfLines={1}
								fontWeight={600}
								color={'blue.50'}
								variant={'bodyMedium'}>
								{file.name}
							</Text>
							<Text variant={'bodySmall'} color={'inactiveText'}>
								{formatFileSize(file.size)}
							</Text>
						</Box>
					</HStack>
					<IconButton
						onClick={removeFile}
						variant={'ghost'}
						size={'sm'}
						aria-label={'remove'}
						icon={
							<TrashIcon
								height={'22'}
								width={'22'}
								strokeColor={'#4A5991'}
							/>
						}
					/>
				</Flex>
			) : (
				<Box>
					<Text variant={'bodySmall'} mb={'16px'} fontWeight={600}>
						Lease Agreement
					</Text>
					<Button
						isDisabled={isDisabled}
						onClick={onOpenUploadModal}
						variant={'ctrl-secondary'}
						leftIcon={<DownloadIcon color={'currentcolor'} />}>
						Upload File
					</Button>
				</Box>
			)}
			<UploadFileModal
				setFileToUpload={setFileToUpload}
				isOpen={isOpenUploadModal}
				onClose={onCloseUploadModal}
			/>
		</Box>
	);
};
