import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { Alerter } from '../../../../utils';

export const getBusinessEntityStripeReconnectLink = async (id: number) => {
	try {
		const res: ApiResponse<string | null> = await API_CTRL.get(
			`/api/app/business-entity/${id}/stripe/onboarding`,
			{},
		);
		if (!res.success) {
			Alerter.error(res?.errors?.[0]?.message);
		}
		return res;
	} catch (err) {
		console.log('@@@getBusinessEntityStripeReconnectLink error: ', err);
	}
};
