import { ReactNode } from 'react';
import {
	CaseIcon,
	DashboardIcon,
	InvoicesIcon,
	PlannerIcon,
	PropertyManagement2,
	UsersIcon,
} from '../../../assets';

export interface IRouteItem {
	icon?: ReactNode;
	name: string;
	to: string;
	id: number;
}

export const BaseRoutes = [
	{
		id: 1,
		icon: <DashboardIcon />,
		name: 'Dashboard',
		to: '/cruz-ctrl/dashboard',
	},
	{
		id: 2,
		icon: <PropertyManagement2 />,
		name: 'Properties',
		to: '/cruz-ctrl/properties?tabIndex=0',
	},
	{
		id: 3,
		icon: <UsersIcon />,
		name: 'Tenants',
		to: '/cruz-ctrl/tenants?tabIndex=0',
	},
	{
		id: 4,
		icon: <InvoicesIcon />,
		name: 'Invoices',
		to: '/cruz-ctrl/invoices',
	},
	{
		id: 5,
		icon: <PlannerIcon />,
		name: 'Planner',
		to: '/cruz-ctrl/planner',
	},
];

export const ConfigurationRoutes = [
	// {
	// 	id: 1,
	// 	icon: <MailIcon />,
	// 	name: 'Mail Accounts',
	// 	to: '/cruz-ctrl/mail-accounts',
	// },
	{
		id: 4,
		icon: <CaseIcon />,
		name: 'Business Entity',
		to: '/cruz-ctrl/business-entity',
	},
	// {
	// 	id: 2,
	// 	icon: <DatabaseIcon />,
	// 	name: 'Data Source',
	// 	to: '/cruz-ctrl/data-source',
	// },
	// {
	// 	id: 3,
	// 	icon: <UserAccountIcon />,
	// 	name: 'Users',
	// 	to: '/cruz-ctrl/users',
	// },
];
