import React from 'react';
import {
	FormControl,
	FormLabel,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	FormErrorMessage,
	NumberInputProps,
	NumberInputStepperProps,
	FormControlProps,
	FormLabelProps,
	chakra,
} from '@chakra-ui/react';

interface IInputNumberProps {
	errorMsg?: string;
	label?: string;
	numberInputProps?: NumberInputProps;
	numberInputStepperProps?: NumberInputStepperProps;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	min?: number;
	max?: number;
	step?: number;
	placeholder?: string;
	handleInputChange?: any;
	rightStepper?: boolean;
	localInputValue?: string | number;
	isRequired?: boolean;
}

export const InputNumber = React.forwardRef<null, IInputNumberProps>(
	(
		{
			numberInputProps,
			numberInputStepperProps,
			errorMsg,
			label,
			formControlProps,
			formLabelProps,
			max,
			min,
			step,
			placeholder,
			handleInputChange,
			rightStepper,
			localInputValue,
			isRequired,
		},
		ref,
	) => {
		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						mb="8px"
						fontSize="12px"
						lineHeight="16px"
						fontWeight={600}
						{...formLabelProps}>
						{label}{' '}
						{isRequired ? (
							<chakra.span color="#FF3636">*</chakra.span>
						) : null}
					</FormLabel>
				) : null}
				<NumberInput
					min={min}
					max={max}
					step={step}
					value={localInputValue}
					onChange={handleInputChange}
					{...numberInputProps}>
					<NumberInputField
						placeholder={placeholder}
						bg="white"
						ref={ref}
						{...numberInputStepperProps}
					/>
					{rightStepper ? null : (
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					)}
				</NumberInput>
				<FormErrorMessage mt={0}>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
InputNumber.displayName = 'InputNumber';
