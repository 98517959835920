import { Box, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
	UseFormWatch,
} from 'react-hook-form';
import { ClearIcon } from '../../../../../assets';
import { DatePicker, InputText } from '../../../../../components';
import { CustomReactSelect, InputPrice } from '../../../components';
import {
	BILLING_CYCLE_OPTIONS,
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
	INVOICE_OPTIONS,
	PAYMENT_METHOD_OPTIONS,
} from '../../../constants';
import { TenantFormType } from '../../../validations';
import React from 'react';
import { BillingCycleEnum, PaymentMethodEnum } from '../../../types';

interface IBillingCycleItemProps {
	index: number;
	errors: FieldErrors<TenantFormType>;
	register: UseFormRegister<TenantFormType>;
	watch: UseFormWatch<TenantFormType>;
	onRemove: VoidFunction;
	control: Control<TenantFormType>;
	isDisabled?: boolean;
	isPaymentMethodDisabled: boolean;
	isStripeConnected: boolean;
}
export const BillingCycleItem: React.FC<IBillingCycleItemProps> = ({
	index,
	register,
	control,
	errors,
	onRemove,
	watch,
	isDisabled,
	isPaymentMethodDisabled,
	isStripeConnected,
}) => {
	const isOneOffBillingCycleOptionSelected =
		watch(`billingCycles.${index}.billingCycle`)?.value ===
		BillingCycleEnum.OneOff;
	return (
		<Box p="16px">
			<input
				style={{ display: 'none' }}
				{...register(`billingCycles.${index}.id`)}
			/>
			<Flex pb="16px" justifyContent="space-between" align={'center'}>
				<Text fontWeight={600} fontSize="14px" color="blue.50">
					Billing Cycle {index + 1}
				</Text>
				<IconButton
					type={'button'}
					onClick={onRemove}
					bg="transparent"
					variant={'ghost'}
					size={'sm'}
					aria-label="remove"
					icon={<ClearIcon width="10" height="10" />}
				/>
			</Flex>
			<Stack
				direction={{
					base: 'column',
					md: 'row',
				}}
				alignItems={'flex-start'}
				spacing="16px">
				<InputText
					{...register(`billingCycles.${index}.invoiceName`)}
					errorMsg={
						errors?.billingCycles?.[index]?.invoiceName?.message
					}
					{...inputStyles}
					placeholder="Enter Name"
					label="Invoice Name"
					errorMessageProps={errorMsgStyles}
					formLabelProps={inputLabelStyles}
					isDisabled={isDisabled}
				/>
				<Controller
					control={control}
					name={`billingCycles.${index}.invoiceType`}
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<CustomReactSelect
							onChange={onChange}
							value={value}
							label="Invoice Type"
							menuPosition={'fixed'}
							placeholder="Select Type"
							options={INVOICE_OPTIONS}
							errMsg={error?.message}
							isDisabled={isDisabled}
							isSearchable={false}
						/>
					)}
				/>
				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<InputPrice
							value={value}
							errorMsg={error?.message}
							onChange={onChange}
							label="Invoice Amount"
							allowNegative={false}
							placeholder={'Enter Amount'}
							disabled={isDisabled}
						/>
					)}
					name={`billingCycles.${index}.amount`}
					control={control}
				/>
			</Stack>
			<Stack
				mt="16px"
				direction={{
					base: 'column',
					md: 'row',
				}}
				alignItems={'flex-start'}
				spacing="16px">
				<Box flex={1} w={{ base: '100%', lg: '' }}>
					<Controller
						control={control}
						name={`billingCycles.${index}.invoiceDate`}
						render={({
							field: { value, onChange },
							fieldState: { error },
						}) => (
							<DatePicker
								minDate={new Date()}
								label="Invoice Date"
								value={value}
								onSetValue={onChange}
								errorMsg={error?.message}
								datesToExclude={
									!isOneOffBillingCycleOptionSelected
										? [29, 30, 31]
										: []
								}
								isDisabled={isDisabled}
							/>
						)}
					/>
				</Box>
				<Box flex={1} w={{ base: '100%', lg: '' }}>
					<InputText
						{...register(`billingCycles.${index}.dueDays`)}
						errorMsg={
							errors?.billingCycles?.[index]?.dueDays?.message
						}
						{...inputStyles}
						type={'number'}
						placeholder="Enter number of days"
						label="Due Within"
						errorMessageProps={errorMsgStyles}
						formLabelProps={inputLabelStyles}
						isDisabled={isDisabled}
					/>
				</Box>
				<Box flex={1} w={{ base: '100%', lg: '' }}>
					<Controller
						control={control}
						name={`billingCycles.${index}.paymentMethod`}
						render={({
							field: { value, onChange },
							fieldState: { error },
						}) => (
							<CustomReactSelect
								options={PAYMENT_METHOD_OPTIONS}
								onChange={onChange}
								value={value}
								label="Payment Method"
								menuPosition={'fixed'}
								placeholder="Select Method"
								errMsg={error?.message}
								isDisabled={
									isDisabled || isPaymentMethodDisabled
								}
								isOptionDisabled={option =>
									option.value === PaymentMethodEnum.Stripe &&
									!isStripeConnected
								}
								isSearchable={false}
							/>
						)}
					/>
				</Box>
				<Box flex={1} w={{ base: '100%', lg: '' }}>
					<Controller
						control={control}
						name={`billingCycles.${index}.billingCycle`}
						render={({
							field: { value, onChange },
							fieldState: { error },
						}) => (
							<CustomReactSelect
								options={BILLING_CYCLE_OPTIONS}
								onChange={onChange}
								value={value}
								menuPosition={'fixed'}
								label="Billing Cycle"
								placeholder="Select Cycle"
								errMsg={error?.message}
								isDisabled={isDisabled}
								isSearchable={false}
							/>
						)}
					/>
				</Box>
			</Stack>
		</Box>
	);
};
