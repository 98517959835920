import React from 'react';
import {
	Flex,
	Text,
	Avatar,
	HStack,
	AvatarBadge,
	VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HREFs } from '../../../constants';
import { AdminVerificationSmall } from '../../../../../assets';
import { UserPresenceStatus } from '../../../types';
import { useChatUserStore } from '../../../../../store';

interface IDirectFriendBaseProps {
	controls?: JSX.Element;
	bg?: string;
	name?: string;
	photoURL?: string | null;
	id: number;
	userStatus?: UserPresenceStatus;
	isAdmin?: boolean;
	email?: string | null;
}

export const DirectFriendBase: React.FC<IDirectFriendBaseProps> = ({
	controls,
	bg = 'white',
	name,
	photoURL,
	id,
	userStatus,
	isAdmin,
	email,
}) => {
	const { user } = useChatUserStore();

	const isOnline = userStatus === UserPresenceStatus.ONLINE;
	return (
		<Flex
			p="10px 20px"
			align="center"
			justify="space-between"
			borderRadius="4px"
			bg={bg}
			mb="10px"
			transition="all .3s"
			_hover={{
				bg: 'dropBlue',
			}}>
			<HStack flex={1} as={Link} to={HREFs.DIRECT + '/' + id}>
				<Avatar boxSize="40px" src={photoURL || undefined}>
					<AvatarBadge
						display={isOnline ? 'flex' : 'none'}
						bottom={0}
						right={'6px'}
						border={'none'}
						boxSize="10px"
						bg="success"
					/>
				</Avatar>

				<VStack
					justify="start"
					align="start"
					spacing={0}
					isTruncated={true}>
					<HStack>
						{isAdmin ? <AdminVerificationSmall /> : null}
						<Text fontSize="16px" color="blue.200">
							{name}
						</Text>
					</HStack>
					{user?.isAdmin && email ? (
						<Text
							w="100%"
							fontSize="14px"
							lineHeight="16px"
							color="inactiveText"
							isTruncated={true}>
							{email}
						</Text>
					) : null}
				</VStack>
			</HStack>
			{controls}
		</Flex>
	);
};
