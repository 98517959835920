import { useQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ReactionsModalListResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

const PAGE_SIZE = 2;

export const useGetDirectMessageReactions = (
	messageId: number | null,
	enableFetch: boolean,
) => {
	return useQuery<ApiResponse<ReactionsModalListResponse>>({
		queryKey: [MessengerQueryKeys.GET_DIRECT_MESSAGE_REACTIONS, messageId],
		queryFn: () =>
			API_CHAT.get('/api/app/conversation/messages/reaction', {
				messageId,
				currentPage: 1,
				pageSize: PAGE_SIZE,
			}),
		enabled: enableFetch && !!messageId,
		refetchOnWindowFocus: false,
	});
};
