import { API_CHAT } from '../../api/api.base';
import { ApiResponse, ApiResponseEmpty } from '../../types';
import {
	ConversationsListResponse,
	DeletePrivateMessagePayload,
	EditChannelMessagePayload,
	SearchUsersByNameData,
	SearchWithPaginationPayload,
	UpdatePrivateMessagePayload,
} from './types';

export const getConversationsList = async (
	data: SearchWithPaginationPayload,
) => {
	try {
		const res: ApiResponse<ConversationsListResponse> = await API_CHAT.get(
			'/api/app/conversation',
			data,
		);
		return res;
	} catch (err) {
		console.log('conversations list err: ', err);
	}
};

export const getUsersByName = async (name: string) => {
	try {
		const res: ApiResponse<{ users: SearchUsersByNameData[] }> =
			await API_CHAT.get('/api/app/user/name', { name });
		return res;
	} catch (err) {
		console.log('search for users err: ', err);
	}
};

export const getNonFriendUsersByName = async (name: string) => {
	try {
		const res: ApiResponse<{ users: SearchUsersByNameData[] }> =
			await API_CHAT.get('/api/app/friends/new', { name });
		return res;
	} catch (err) {
		console.log('search for users err: ', err);
	}
};

export const updatePrivateMessage = async (
	data: UpdatePrivateMessagePayload,
) => {
	try {
		const res: ApiResponseEmpty = await API_CHAT.put(
			'/api/app/conversation/messages/private/update',
			data,
		);
		return res;
	} catch (err) {
		console.log('update private message err: ', err);
	}
};

export const deletePrivateMessage = async (
	data: DeletePrivateMessagePayload,
) => {
	try {
		const res: ApiResponseEmpty = await API_CHAT.delete(
			'/api/app/conversation/messages/private/delete',
			data,
		);
		return res;
	} catch (err) {
		console.log('delete private message err: ', err);
	}
};

export const deleteAllConversationMessages = async (
	messageId: number,
): Promise<ApiResponseEmpty | undefined> => {
	try {
		const res: ApiResponseEmpty = await API_CHAT.delete(
			`/api/app/conversation/${messageId}/delete`,
			{},
		);
		return res;
	} catch (err) {
		console.log('delete conversation err: ', err);
	}
};

export const editChannelMessage = async (data: EditChannelMessagePayload) => {
	try {
		const res: ApiResponseEmpty = await API_CHAT.put(
			'/api/app/channel/messages/update',
			data,
		);
		return res;
	} catch (err) {
		console.log('edit channel message err: ', err);
	}
};

export const deleteChannelMessage = async (messageId: number) => {
	try {
		const res: ApiResponseEmpty = await API_CHAT.delete(
			`/api/app/channel/messages/${messageId}/delete`,
			{},
		);
		return res;
	} catch (err) {
		console.log('delete channel message err: ', err);
	}
};
