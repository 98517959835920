export const DealFinder = () => {
	return (
		<svg
			width="25"
			style={{ minWidth: '25px' }}
			height="25"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19 20L16.3334 17.3333M17.2222 15.1111C17.2222 16.8293 15.8293 18.2222 14.1111 18.2222C12.3929 18.2222 11 16.8293 11 15.1111C11 13.3929 12.3929 12 14.1111 12C15.8293 12 17.2222 13.3929 17.2222 15.1111Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.2957 15.255V11.3821C20.2957 10.8527 20.2957 10.588 20.2275 10.3442C20.167 10.1283 20.0677 9.9252 19.9344 9.74492C19.7838 9.5414 19.5749 9.37889 19.157 9.05387L12.9056 4.19164C12.5817 3.93977 12.4198 3.81384 12.241 3.76543C12.0833 3.72272 11.917 3.72272 11.7593 3.76543C11.5805 3.81384 11.4186 3.93977 11.0947 4.19164L4.84328 9.05387C4.4254 9.37889 4.21646 9.5414 4.06593 9.74492C3.93259 9.9252 3.83326 10.1283 3.77282 10.3442C3.70459 10.588 3.70459 10.8527 3.70459 11.3821V18.0507C3.70459 19.0832 3.70459 19.5994 3.90551 19.9937C4.08225 20.3406 4.36427 20.6226 4.71113 20.7993C5.10547 21.0003 5.62169 21.0003 6.65412 21.0003H14.7653"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
