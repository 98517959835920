import React from 'react';
import { BaseIconType } from '../../types';

export const CtrlSmallLogo: React.FC<BaseIconType> = ({
	width = 72,
	height = 32,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 72 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.789246" width="32" height="32" rx="8" fill="#08BB4B" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1705 8.60306L23.9844 15.419C24.3127 15.6729 24.7836 15.6112 25.0362 15.2811C25.2887 14.9511 25.2273 14.4777 24.899 14.2238L15.8609 7.23454C15.4629 6.92674 14.9102 6.9214 14.5063 7.22145L5.09352 14.2151C4.76041 14.4626 4.68995 14.9347 4.93615 15.2696C5.18235 15.6044 5.65196 15.6753 5.98507 15.4278L15.1705 8.60306Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.0926 24.9999C28.0926 23.9589 27.9003 22.5511 27.5269 21.5893C27.1534 20.6275 26.606 19.7536 25.916 19.0175C25.2259 18.2813 24.4067 17.6974 23.5051 17.299C22.6035 16.9007 21.6371 16.6956 20.6612 16.6956C19.6853 16.6956 18.719 16.9007 17.8174 17.299C16.9158 17.6974 16.0966 18.2813 15.4065 19.0175C14.7164 19.7536 14.169 20.6275 13.7956 21.5893C13.4221 22.5511 13.2299 23.9589 13.2299 24.9999H12.9103C12.702 24.9999 12.5324 24.831 12.5409 24.6229C12.5821 23.613 12.7886 22.2406 13.1519 21.3049C13.5604 20.2529 14.1591 19.2971 14.9138 18.4919C15.6686 17.6868 16.5646 17.0481 17.5508 16.6124C18.5369 16.1767 19.5938 15.9524 20.6612 15.9524C21.7286 15.9524 22.7856 16.1767 23.7717 16.6124C24.7578 17.0481 25.6539 17.6868 26.4086 18.4919C27.1634 19.2971 27.7621 20.2529 28.1706 21.3049C28.5339 22.2406 28.7404 23.613 28.7816 24.6229C28.7901 24.831 28.6204 24.9999 28.4122 24.9999H28.0926Z"
				fill="white"
			/>
			<path
				d="M6.86248 9.92074V12.9366H8.75577C8.96288 12.9366 9.13077 12.7678 9.13077 12.5596V9.92074H9.51187C9.71897 9.92074 9.88686 9.75196 9.88686 9.54376V9.16678C9.88686 8.95857 9.71897 8.78979 9.51187 8.78979H6.48138C6.27428 8.78979 6.10638 8.95857 6.10638 9.16677V9.54376C6.10638 9.75196 6.27428 9.92074 6.48138 9.92074H6.86248Z"
				fill="white"
			/>
			<path
				d="M21.6064 23.4921C21.6064 24.1167 21.0986 24.6231 20.4722 24.6231C19.8458 24.6231 19.3381 24.1167 19.3381 23.4921C19.3381 22.8675 19.8458 22.3612 20.4722 22.3612C21.0986 22.3612 21.6064 22.8675 21.6064 23.4921Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1795 24.623C15.1795 24.8312 15.0108 24.9999 14.8026 24.9999H13.2882C13.08 24.9999 12.9113 24.8312 12.9113 24.623C12.9113 24.4148 13.08 24.246 13.2882 24.246H14.8026C15.0108 24.246 15.1795 24.4148 15.1795 24.623Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.7892 24.623C28.7892 24.8312 28.6204 24.9999 28.4122 24.9999H26.8979C26.6897 24.9999 26.5209 24.8312 26.5209 24.623C26.5209 24.4148 26.6897 24.246 26.8979 24.246H28.4122C28.6204 24.246 28.7892 24.4148 28.7892 24.623Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.4722 16.3294C20.681 16.3294 20.8503 16.4987 20.8503 16.7075V18.2133C20.8503 18.4221 20.681 18.5913 20.4722 18.5913C20.2634 18.5913 20.0942 18.4221 20.0942 18.2133V16.7075C20.0942 16.4987 20.2634 16.3294 20.4722 16.3294Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9135 17.5789C25.0766 17.7089 25.103 17.9463 24.9724 18.1089L24.0281 19.2861C23.8976 19.4487 23.6596 19.4752 23.4966 19.3451C23.3335 19.2151 23.3072 18.9777 23.4377 18.8151L24.382 17.6379C24.5125 17.4753 24.7505 17.4488 24.9135 17.5789Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.1595 23.7039L16.2141 17.9114L16.8396 17.4878L20.7849 23.2803L20.1595 23.7039Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.5128 20.6816C27.614 20.8637 27.5484 21.093 27.366 21.1938L26.0427 21.9258C25.8604 22.0267 25.6305 21.9608 25.5292 21.7788C25.4279 21.5967 25.4936 21.3673 25.6759 21.2665L26.9993 20.5345C27.1816 20.4337 27.4115 20.4995 27.5128 20.6816Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.7932 21.7787C15.6919 21.9608 15.462 22.0266 15.2797 21.9258L13.9564 21.1937C13.7741 21.0929 13.7084 20.8635 13.8097 20.6815C13.911 20.4994 14.1409 20.4336 14.3232 20.5344L15.6465 21.2665C15.8288 21.3673 15.8945 21.5967 15.7932 21.7787Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.05151 25.0001C6.84272 25.0001 6.67346 24.8313 6.67346 24.6231V16.1436C6.67346 16.0235 6.73084 15.9106 6.82795 15.8396L14.9508 9.89964C15.0864 9.80053 15.2712 9.80255 15.4046 9.90458L21.2186 14.3539C21.5449 14.6036 21.2708 15.2245 20.8599 15.2069C20.7313 15.2014 20.6021 15.1986 20.4722 15.1986C15.5657 15.1986 11.5881 19.1649 11.5881 24.0576C11.5881 24.2339 11.5932 24.4089 11.6034 24.5826C11.6165 24.8061 11.4422 25.0001 11.2176 25.0001H7.05151Z"
				fill="white"
			/>
			<path
				d="M65.5002 28.7273V19.2727H66.9267V27.4993H71.2108V28.7273H65.5002Z"
				fill="white"
			/>
			<path
				d="M57.1213 28.7273V19.2727H60.4913C61.2238 19.2727 61.8316 19.3989 62.3148 19.6513C62.8011 19.9036 63.1642 20.2529 63.4043 20.6992C63.6444 21.1424 63.7644 21.6548 63.7644 22.2365C63.7644 22.8151 63.6428 23.3244 63.3997 23.7645C63.1596 24.2016 62.7965 24.5416 62.3102 24.7848C61.827 25.0279 61.2192 25.1495 60.4867 25.1495H57.9338V23.9215H60.3574C60.8191 23.9215 61.1945 23.8553 61.4838 23.723C61.7762 23.5907 61.9901 23.3983 62.1255 23.1459C62.261 22.8936 62.3287 22.5904 62.3287 22.2365C62.3287 21.8795 62.2594 21.5702 62.1209 21.3086C61.9855 21.047 61.7716 20.8469 61.4792 20.7084C61.1899 20.5669 60.8098 20.4961 60.339 20.4961H58.5478V28.7273H57.1213ZM61.7885 24.4616L64.1245 28.7273H62.4995L60.2097 24.4616H61.7885Z"
				fill="white"
			/>
			<path
				d="M48.1873 20.5007V19.2727H55.5044V20.5007H52.5544V28.7273H51.1326V20.5007H48.1873Z"
				fill="white"
			/>
			<path
				d="M46.9466 22.3473H45.5062C45.4508 22.0395 45.3477 21.7687 45.1969 21.5348C45.0461 21.3009 44.8615 21.1024 44.6429 20.9392C44.4244 20.7761 44.1798 20.653 43.9089 20.5699C43.6412 20.4868 43.3565 20.4453 43.0549 20.4453C42.5101 20.4453 42.0223 20.5822 41.5914 20.8561C41.1637 21.1301 40.8251 21.5317 40.5758 22.061C40.3296 22.5904 40.2065 23.2367 40.2065 24C40.2065 24.7694 40.3296 25.4188 40.5758 25.9481C40.8251 26.4775 41.1652 26.8776 41.5961 27.1484C42.0269 27.4192 42.5117 27.5547 43.0503 27.5547C43.3488 27.5547 43.6319 27.5146 43.8997 27.4346C44.1705 27.3515 44.4152 27.23 44.6337 27.0699C44.8522 26.9099 45.0369 26.7145 45.1877 26.4836C45.3416 26.2497 45.4477 25.982 45.5062 25.6804L46.9466 25.685C46.8696 26.1497 46.7204 26.5775 46.4988 26.9684C46.2803 27.3561 45.9986 27.6916 45.6539 27.9748C45.3123 28.2548 44.9215 28.4718 44.4814 28.6257C44.0413 28.7796 43.5611 28.8565 43.041 28.8565C42.2224 28.8565 41.493 28.6626 40.8528 28.2748C40.2127 27.884 39.7079 27.3254 39.3386 26.599C38.9724 25.8727 38.7892 25.0064 38.7892 24C38.7892 22.9905 38.9739 22.1241 39.3432 21.4009C39.7125 20.6746 40.2173 20.1175 40.8574 19.7297C41.4976 19.3389 42.2254 19.1434 43.041 19.1434C43.5427 19.1434 44.0105 19.2158 44.4444 19.3604C44.8815 19.502 45.2739 19.7113 45.6216 19.9882C45.9694 20.2622 46.2572 20.5976 46.4849 20.9946C46.7127 21.3886 46.8665 21.8395 46.9466 22.3473Z"
				fill="white"
			/>
			<path
				d="M61.1157 12.7273V11.7809L64.9566 6.90127V6.83664H61.2403V5.63635H66.6785V6.64275L62.9853 11.4623V11.527H66.8078V12.7273H61.1157Z"
				fill="white"
			/>
			<path
				d="M58.0238 9.78657V5.63635H59.4088V12.7273H58.0515V11.4993H57.9776C57.8145 11.8778 57.5529 12.1933 57.1929 12.4457C56.8358 12.6949 56.3911 12.8196 55.8587 12.8196C55.4032 12.8196 55 12.7196 54.6492 12.5195C54.3014 12.3164 54.0275 12.0163 53.8274 11.6193C53.6305 11.2223 53.532 10.7314 53.532 10.1467V5.63635H54.9123V9.98046C54.9123 10.4636 55.0462 10.8484 55.3139 11.1346C55.5817 11.4208 55.9295 11.5639 56.3573 11.5639C56.6158 11.5639 56.8728 11.4993 57.1282 11.37C57.3867 11.2408 57.6006 11.0453 57.7699 10.7837C57.9423 10.5221 58.0269 10.1897 58.0238 9.78657Z"
				fill="white"
			/>
			<path
				d="M48.5427 12.7272V5.63633H49.8769V6.76275H49.9508C50.08 6.38112 50.3078 6.08105 50.634 5.86254C50.9633 5.64095 51.3357 5.53015 51.7512 5.53015C51.8374 5.53015 51.9389 5.53323 52.0559 5.53938C52.1759 5.54554 52.2698 5.55323 52.3375 5.56247V6.88278C52.2821 6.86739 52.1836 6.85046 52.042 6.832C51.9004 6.81045 51.7589 6.79968 51.6173 6.79968C51.2911 6.79968 51.0002 6.86893 50.7448 7.00742C50.4924 7.14284 50.2924 7.33212 50.1446 7.57525C49.9969 7.81531 49.9231 8.08922 49.9231 8.39698V12.7272H48.5427Z"
				fill="white"
			/>
			<path
				d="M46.9466 6.34727H45.5062C45.4508 6.0395 45.3477 5.76867 45.1969 5.53477C45.0461 5.30087 44.8615 5.10236 44.6429 4.93924C44.4244 4.77613 44.1798 4.65302 43.9089 4.56992C43.6412 4.48683 43.3565 4.44528 43.0549 4.44528C42.5101 4.44528 42.0223 4.58223 41.5914 4.85615C41.1637 5.13006 40.8251 5.53169 40.5758 6.06105C40.3296 6.5904 40.2065 7.23671 40.2065 7.99997C40.2065 8.76938 40.3296 9.41876 40.5758 9.94812C40.8251 10.4775 41.1652 10.8776 41.5961 11.1484C42.0269 11.4192 42.5117 11.5547 43.0503 11.5547C43.3488 11.5547 43.6319 11.5146 43.8997 11.4346C44.1705 11.3515 44.4152 11.23 44.6337 11.0699C44.8522 10.9099 45.0369 10.7145 45.1877 10.4836C45.3416 10.2497 45.4477 9.98197 45.5062 9.68036L46.9466 9.68498C46.8696 10.1497 46.7204 10.5775 46.4988 10.9684C46.2803 11.3561 45.9986 11.6916 45.6539 11.9748C45.3123 12.2548 44.9215 12.4718 44.4814 12.6257C44.0413 12.7796 43.5611 12.8565 43.041 12.8565C42.2224 12.8565 41.493 12.6626 40.8528 12.2748C40.2127 11.884 39.7079 11.3254 39.3386 10.599C38.9724 9.87272 38.7892 9.00636 38.7892 7.99997C38.7892 6.9905 38.9739 6.12414 39.3432 5.40089C39.7125 4.67456 40.2173 4.11751 40.8574 3.72973C41.4976 3.33886 42.2254 3.14343 43.041 3.14343C43.5427 3.14343 44.0105 3.21576 44.4444 3.36041C44.8815 3.50198 45.2739 3.71126 45.6216 3.98825C45.9694 4.26216 46.2572 4.59762 46.4849 4.99464C46.7127 5.38858 46.8665 5.83946 46.9466 6.34727Z"
				fill="white"
			/>
		</svg>
	);
};
