import React from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { SideBar } from './components';
import { useSidebarOpenStore } from '../../store';

export const DashboardLayout: React.FC = () => {
	const { isOpen, onToggle } = useSidebarOpenStore();

	return (
		<Box minH="100vh" background={'background'}>
			<SideBar menuExpanded={isOpen} toggleMenu={onToggle} />
			<Box
				ml={{ base: 0, md: isOpen ? '250px' : '120px' }}
				transition=".3s"
				bg="mainBg"
				minH="100vh">
				<Outlet />
			</Box>
		</Box>
	);
};
