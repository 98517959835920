import {
	Avatar,
	Box,
	Center,
	Flex,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	DropdownMenu,
	DropdownMenuTrigger,
} from '../../../pages/Messenger/common-components/Menu/Menu';
import { UserMenu } from './UserMenu';
import React from 'react';
import { ICurrentUser } from '../../../types';

interface ISideBarFooter {
	isLoading: boolean;
	menuExpanded: boolean;
	fullName: string;
	data?: ICurrentUser;
	onSignOut: VoidFunction;
}

export const SideBarFooter: React.FC<ISideBarFooter> = ({
	isLoading,
	menuExpanded,
	fullName,
	data,
	onSignOut,
}) => {
	return (
		<Box>
			<DropdownMenu>
				{isLoading ? (
					<Center>
						<Spinner size="lg" color="white" />
					</Center>
				) : (
					<Box>
						{menuExpanded ? (
							<DropdownMenuTrigger asChild={true}>
								<Flex
									px={'11px'}
									cursor={'pointer'}
									align="center"
									justify="space-between">
									<Avatar
										name={fullName}
										src={
											data?.imageUrl ? data.imageUrl : ''
										}
										boxSize={'40px'}
									/>
									<Flex
										direction="column"
										ml={'15px'}
										minWidth="100px"
										flex={1}>
										<Text color="white" fontSize="16px">
											{fullName}
										</Text>
										<Text
											color="gray.400"
											fontSize="12px"
											isTruncated={true}>
											{data?.email}
										</Text>
									</Flex>
								</Flex>
							</DropdownMenuTrigger>
						) : (
							<VStack spacing={'17px'} cursor={'pointer'}>
								<DropdownMenuTrigger asChild={true}>
									<Avatar
										name={fullName}
										src={
											data?.imageUrl ? data.imageUrl : ''
										}
										boxSize={'40px'}
									/>
								</DropdownMenuTrigger>
							</VStack>
						)}
					</Box>
				)}
				<UserMenu onSignOut={onSignOut} />
			</DropdownMenu>
		</Box>
	);
};
