import React from 'react';
import { Flex, HStack, Button, Badge } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { PlusIcon } from '../../../../assets';
import { usePendingFriendRequestsStore } from '../../../../store';

export const DirectChatHeader: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { amount } = usePendingFriendRequestsStore();

	const friendsStatusFilters = [
		{ name: 'Online', value: '1' },
		{ name: 'All', value: '2' },
		{ name: 'Pending', value: '3', newValue: amount },
		{ name: 'Blocked', value: '4' },
	];

	const selectedFriendsFilter = searchParams.get('friendsStatus') || '1';

	return (
		<Flex width="100%" alignItems="center" justify="space-between">
			<HStack>
				{friendsStatusFilters.map(elem => (
					<Button
						key={elem.value}
						color={
							selectedFriendsFilter === elem.value
								? 'blue.200'
								: 'blue.50'
						}
						fontWeight={500}
						bg={
							selectedFriendsFilter === elem.value
								? 'white'
								: 'transparent'
						}
						border="1px solid"
						borderColor={
							selectedFriendsFilter === elem.value
								? 'dropBlue'
								: 'transparent'
						}
						onClick={() =>
							setSearchParams({ friendsStatus: elem.value })
						}
						rightIcon={
							elem.newValue ? (
								<Badge variant="outline" colorScheme="green">
									{elem.newValue}
								</Badge>
							) : undefined
						}>
						{elem.name}
					</Button>
				))}
				<Button
					rightIcon={<PlusIcon />}
					bg="blue.100"
					color="white"
					_hover={{ bg: 'blue.50' }}
					fontWeight={500}
					onClick={() => setSearchParams({ friendsStatus: '5' })}>
					Add Friends
				</Button>
			</HStack>
			{/* <IconButton aria-label="Notifications" bg="white">
				<BellIcon />
			</IconButton> */}
		</Flex>
	);
};
