import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const duplicateChannelSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
});

export type DuplicateChannelForm = yup.InferType<typeof duplicateChannelSchema>;
export const duplicateChannelResolver = yupResolver(duplicateChannelSchema);
