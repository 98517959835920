export const SUPPORTED_FILE_TYPES_LIST = {
	image: ['image/jpeg', 'image/jpg', 'image/png'],
	doc: ['application/pdf'],
	video: ['video/mp4', 'video/quicktime'],
};

export enum FileAttachmentType {
	IMAGE = 'Images',
	DOC = 'Files',
	VIDEO = 'Videos',
}
