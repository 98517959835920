import React from 'react';
import { HStack, Button } from '@chakra-ui/react';
import { MinusIcon, CheckCircleIcon } from '../../../../../../assets';
import {
	useAcceptFriendRequest,
	useCancelFriendRequest,
	useIgnoreFriendRequest,
} from '../../../../queries';

interface IPendingControlsProps {
	variant: 'incoming' | 'outgoing';
	requestId: number;
}

export const PendingControls: React.FC<IPendingControlsProps> = ({
	variant,
	requestId,
}) => {
	const {
		mutateAsync: dismissFriendship,
		isLoading: dismissFriendshipLoading,
	} = useIgnoreFriendRequest();
	const {
		mutateAsync: acceptFriendship,
		isLoading: acceptFriendshipLoading,
	} = useAcceptFriendRequest();
	const {
		mutateAsync: cancelFriendship,
		isLoading: cancelFriendshipLoading,
	} = useCancelFriendRequest();

	// TODO rework (split maybe)
	const dismissRequest = async () => {
		try {
			await dismissFriendship(requestId);
		} catch (error) {
			console.log(error, 'dismiss error');
		}
	};

	const approveRequest = async () => {
		try {
			await acceptFriendship(requestId);
		} catch (error) {
			console.log(error, 'approve error');
		}
	};

	const cancelRequest = async () => {
		try {
			await cancelFriendship(requestId);
		} catch (error) {
			console.log(error, 'cancel error');
		}
	};

	if (variant === 'incoming') {
		return (
			<HStack>
				<Button
					rightIcon={<CheckCircleIcon />}
					colorScheme="green"
					bg="success"
					isLoading={acceptFriendshipLoading}
					onClick={approveRequest}>
					Approve
				</Button>
				<Button
					variant="outline"
					isLoading={dismissFriendshipLoading}
					onClick={dismissRequest}>
					Dismiss
				</Button>
			</HStack>
		);
	} else {
		return (
			<Button
				rightIcon={<MinusIcon />}
				variant="outline"
				isLoading={cancelFriendshipLoading}
				onClick={cancelRequest}>
				Cancel
			</Button>
		);
	}
};
