import React from 'react';
import { Flex, Center, Text } from '@chakra-ui/react';
import {
	EditMessageDisplayData,
	ConversationHistoryItem,
} from '../../../types';
import { PinnedMessageItem } from './PinnedMessageItem';
import { Loader } from '../../../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IPinnedMessagesListProps {
	onSetMessageToUnPin: (value: EditMessageDisplayData) => void;
	pinnedMessages?: ConversationHistoryItem[];
	isAdmin: boolean;
	hasNextPage?: boolean;
	infiniteLoading?: boolean;
	fetchNextPage: VoidFunction;
}

export const PinnedMessagesList: React.FC<IPinnedMessagesListProps> = ({
	onSetMessageToUnPin,
	pinnedMessages,
	isAdmin,
	fetchNextPage,
	hasNextPage,
	infiniteLoading,
}) => {
	return (
		<Flex direction="column" minH="300px" maxH="400px" overflowY="auto">
			{infiniteLoading ? (
				<Loader
					centerHeight="100%"
					spinnerSize="md"
					centerProps={{
						minH: '284px',
						maxH: '384px',
					}}
				/>
			) : (
				<>
					{pinnedMessages?.length ? (
						<InfiniteScroll
							dataLength={pinnedMessages.length}
							next={fetchNextPage}
							hasMore={!!hasNextPage}
							height="300px"
							style={{ padding: '0px' }}
							// style={{ rowGap: '10px' }}
							loader={<Loader spinnerSize="md" />}>
							{pinnedMessages.map((elem, index) => (
								<PinnedMessageItem
									key={elem.id}
									{...elem}
									onSetMessageToUnPin={onSetMessageToUnPin}
									isCurrentUserAdmin={isAdmin}
									wrapperProps={{
										mb:
											pinnedMessages?.length - 1 === index
												? '0px'
												: '10px',
									}}
								/>
							))}
						</InfiniteScroll>
					) : (
						<Center height="100%">
							<Text color="blue.50">No pinned messages</Text>
						</Center>
					)}
				</>
			)}

			{/* {loading ? (
				<Loader
					centerHeight="100%"
					spinnerSize="md"
					centerProps={{
						minH: '284px',
						maxH: '384px',
					}}
				/>
			) : pinnedMessages?.length ? (
				<>
					{pinnedMessages.map(elem => (
						<PinnedMessageItem
							key={elem.id}
							{...elem}
							onSetMessageToUnPin={onSetMessageToUnPin}
							isCurrentUserAdmin={isAdmin}
						/>
					))}
				</>
			) : (
				<Center height="100%">
					<Text color="blue.50">No pinned messages</Text>
				</Center>
			)} */}
		</Flex>
	);
};
