import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { AlertDialog } from '../../common-components';
import { ChatType, EditMessageDisplayData } from '../../types';
import { MessageWithAvatar } from '../../messages-components';

interface IPinMessageConfirmModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	message: EditMessageDisplayData | null;
	action: (value: number) => void;
	variant: 'add' | 'remove';
	chatType: ChatType;
}

export const PinMessageConfirmModal: React.FC<IPinMessageConfirmModalProps> = ({
	isOpen,
	onClose,
	message,
	action,
	variant,
	chatType,
}) => {
	return (
		<AlertDialog
			isOpen={isOpen}
			acceptCallback={() => {
				if (message) {
					action(message.id);
				}
			}}
			acceptButtonText="Yes"
			cancelButtonText="No"
			onClose={onClose}
			title={variant === 'remove' ? 'Unpin Message' : 'Pin It'}>
			<Text
				color={variant === 'remove' ? 'red.500' : 'blue.200'}
				textAlign="center"
				my="20px">
				Hey, are you sure you want to{' '}
				{variant === 'remove' ? 'unpin' : 'pin'} this message?
			</Text>
			{message ? (
				<Box mt="10px" borderRadius="10px" bg="white" p="5px 20px">
					<MessageWithAvatar
						isEdit={false}
						avatarImage={message.avatarImage}
						sentByDisplayName={message.sentByDisplayName}
						text={message.text}
						date={message.date}
						isEdited={message.isEdited}
						isSameSenderNext={true}
						showMenu={false}
						type={message.type}
						replyToId={message.replyToId}
						id={message.id}
						isAdmin={message.isAdmin}
						chatType={chatType}
						sentById={message.sentById}
						messageAttachmentImages={message.attachmentImages}
						messageAttachmentFiles={message.attachmentFiles}
						messageAttachmentVideos={message.attachmentVideos}
						disabledAttachmentView={true}
						reactions={message.reactions}
					/>
				</Box>
			) : null}
		</AlertDialog>
	);
};
