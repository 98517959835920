import React, { PropsWithChildren } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface IFieldWrapper {
	title: string;
	subTitle?: string;
	isReadOnly?: boolean;
}

export const FieldWrapper: React.FC<PropsWithChildren<IFieldWrapper>> = ({
	title,
	subTitle,
	children,
	isReadOnly = false,
}) => (
	<Box p={'10px'} borderRadius={'4px'} bg={'blue.100'}>
		<Text
			color={isReadOnly ? 'blue.50' : 'white'}
			textTransform="uppercase"
			fontWeight={'bold'}>
			{title}
		</Text>
		{subTitle ? (
			<Text fontWeight={'bold'} color={'blue.50'}>
				{subTitle}
			</Text>
		) : null}
		<Box mt={'5px'}>{children}</Box>
	</Box>
);
