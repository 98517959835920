import { MessageType, ConversationHistoryItem } from '../types';
import { getTimeDifference } from './date-utils';

export function checkForSameSender(
	message1: ConversationHistoryItem,
	message2: ConversationHistoryItem,
) {
	if (message1.senderId !== message2.senderId) {
		return false;
	}
	if (message1.sentAt === message2.sentAt) {
		return false;
	}

	if (message1.type === MessageType.Reply) {
		return false;
	}

	if (
		message1.type === MessageType.Pin ||
		message2.type === MessageType.Pin
	) {
		return false;
	}

	return (
		Math.abs(
			getTimeDifference(
				new Date(message1.sentAt),
				new Date(message2.sentAt),
			),
		) < 5
	);
}
