import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../types';
// import { store } from '../../redux';
// import { setAuthHeaderToken } from '../utils';
import intercept from './interceptor';

type KeyValue<U> = {
	[key: string]: U;
};
//USING AXIOS INSTANCE INSTEAD DEFAULT
const axiosDefaultInstance: AxiosInstance = intercept(
	import.meta.env.VITE_BASE_URL || 'http://localhost:3000',
);
const axiosChatInstance: AxiosInstance = intercept(
	import.meta.env.VITE_CHAT_BASE_URL || 'http://localhost:3000',
);

const axiosIdentityInstance: AxiosInstance = intercept(
	import.meta.env.VITE_IDENTITY_BASE_URL,
);

const axiosCruzControlInstance: AxiosInstance = intercept(
	import.meta.env.VITE_CTRL_BASE_URL,
);

const handleError = (err: any) => {
	if (err && err.response && err?.response?.data) {
		return { data: err.response.data };
	} else {
		return {
			data: {
				success: false,
				errors: [
					{
						key: 'Request error',
						message: 'Request error',
					},
				],
			},
		};
	}
};

class Api<T> {
	constructor(private axiosInstance: AxiosInstance) {}

	async get(url: string, params: any, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.get(url, {
				params,
				headers: {
					...headers,
				},
				paramsSerializer: {
					indexes: null,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async post(
		url: string,
		body: T,
		config: AxiosRequestConfig = {},
	): Promise<ApiResponse<any>> {
		// console.log(body)
		const response = await this.axiosInstance
			.post(url, body, config)
			.catch(err => handleError(err));
		//console.log(response)
		return response.data;
	}

	async put(url: string, body: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.put(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async patch(url: string, body: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.patch(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleError(err));
		return response.data;
	}

	async delete(url: string, body?: T, headers: KeyValue<string> = {}) {
		const response = await this.axiosInstance
			.delete(url, {
				data: body,
				headers,
			})
			.catch(err => handleError(err));
		return response.data;
	}
}

// api instance for chat
export const API_CHAT = new Api(axiosChatInstance);
// api instance for identity
export const API_IDENTITY = new Api(axiosIdentityInstance);
// api instance for cruz control
export const API_CTRL = new Api(axiosCruzControlInstance);

export default new Api(axiosDefaultInstance);
