import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
} from '@chakra-ui/react';
import { colors } from '../../../../theme';

interface IPriceInput extends NumericFormatProps {
	errorMsg?: string;
	label?: string;
	isDisabled?: boolean;
}

export const InputPrice: React.FC<IPriceInput> = ({
	errorMsg,
	label,
	isDisabled,
	...rest
}) => (
	<FormControl isInvalid={!!errorMsg}>
		{label ? (
			<FormLabel
				mb="8px"
				fontSize="12px"
				lineHeight="18px"
				color={'inactiveText'}
				fontWeight={500}>
				{label}
			</FormLabel>
		) : null}
		<NumericFormat
			{...rest}
			prefix={'$'}
			thousandSeparator={','}
			decimalSeparator={'.'}
			decimalScale={2}
			w={'full'}
			_invalid={{
				boxShadow: '0px 0px 0px 1px #FF3636',
				borderColor: '#FF3636',
			}}
			_placeholder={{
				color: '#7E788F',
			}}
			height="40px"
			fontSize={'14px'}
			lineHeight={'21px'}
			fontWeight={500}
			bg={'white'}
			color={'blue.300'}
			borderRadius={'10px'}
			borderColor={colors.dropBlue}
			_hover={{
				background: '#EBF0F9',
			}}
			_focus={{
				borderColor: colors.blue['200'],
				boxShadow: 'none',
			}}
			_disabled={{
				background: '#F0F0F0',
				border: '1px solid #8E8E8E',
				color: '#8E8E8E',
			}}
			customInput={Input}
			isDisabled={isDisabled}
			onChange={event => {
				event.target.value = event.target.value.replace(/[$,]/g, '');
				rest.onChange?.(event);
			}}
		/>

		<FormErrorMessage
			fontSize={'10px'}
			lineHeight={'12px'}
			color={'#DC2D2D'}
			mt={'4px'}>
			{errorMsg}
		</FormErrorMessage>
	</FormControl>
);
