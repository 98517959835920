import { useQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ChannelMessageByIdResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetChannelMessageById = (id?: number | null) => {
	return useQuery<ApiResponse<ChannelMessageByIdResponse | null>>({
		queryKey: [MessengerQueryKeys.GET_CHANNEL_MESSAGE_BY_ID, id],
		queryFn: () => API_CHAT.get(`/api/app/channel/messages/${id}/info`, {}),
		enabled: Boolean(id),
		refetchOnWindowFocus: false,
	});
};
