import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailValidationPattern, ErrorMessages } from '../constants';

const StripeConnectionSchema = yup.object().shape({
	email: yup
		.string()
		.required(ErrorMessages.EMAIL_INVALID)
		.matches(EmailValidationPattern, ErrorMessages.EMAIL_INVALID),
});

export const stripeConnectionResolver = yupResolver(StripeConnectionSchema);
