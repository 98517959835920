import React from 'react';
import {
	Text,
	HStack,
	Avatar,
	AvatarBadge,
	Box,
	VStack,
} from '@chakra-ui/react';
import { ChatType, UserChannelData, UserPresenceStatus } from '../../../types';
import { RoleInfoPopup } from './RoleInfoPopup';
import { AdminVerificationSmall } from '../../../../../assets';

interface IRoleItem extends UserChannelData {
	isCurrentUser: boolean;
	isUserBlocked?: boolean;
	isCurrentUserAdmin?: boolean;
	presenceStatus: UserPresenceStatus;
	isForceOffline: boolean;
}

export const RoleItem: React.FC<IRoleItem> = ({
	about,
	displayName,
	userId,
	isCurrentUser,
	isAdmin,
	avatarStoragePath,
	isBannedInChannel,
	isCurrentUserAdmin = false,
	presenceStatus,
	isForceOffline,
	...elem
}) => {
	const isOnline = presenceStatus === UserPresenceStatus.ONLINE;
	return (
		<RoleInfoPopup
			isCurrentUser={isCurrentUser}
			userId={userId}
			about={about || ''}
			displayName={displayName}
			avatarStoragePath={avatarStoragePath}
			isAdmin={isAdmin}
			isBannedInChannel={isBannedInChannel}
			isCurrentUserAdmin={isCurrentUserAdmin}
			isLoaded={true}
			chatType={ChatType.CHANNEL}
			isForceOffline={isForceOffline}
			{...elem}>
			<HStack
				w={'full'}
				as={'button'}
				p="10px 20px"
				borderRadius="4px"
				_hover={{ bg: 'blue.50' }}
				transition="all .3s">
				<Avatar boxSize="40px" src={avatarStoragePath || ''}>
					<AvatarBadge
						display={isOnline ? 'flex' : 'none'}
						bg="success"
						borderColor="success"
						boxSize="12px"
						right="3px"
					/>
				</Avatar>
				<VStack spacing="0px" align="flex-start" isTruncated={true}>

					<HStack>
						{isAdmin ? (
							<Box>
								<AdminVerificationSmall />
							</Box>
						) : null}
						<Text
							color="dropBlue"
							fontSize="16px"
							textAlign="left"
							maxW={
								isCurrentUserAdmin && isBannedInChannel
									? '110px'
									: '100%'
							}
							isTruncated={Boolean(
								isCurrentUserAdmin && isBannedInChannel,
							)}>
							{displayName}
						</Text>

						{/* </VStack> */}
						{isCurrentUserAdmin && isBannedInChannel ? (
							<Text
								w="20%"
								color="valueRed"
								fontSize="14px"
								fontWeight={600}
								textAlign="left">
								Suspend
							</Text>
						) : null}
					</HStack>
					{isCurrentUserAdmin && elem.email ? (
						<Text
							w="100%"
							fontSize="14px"
							lineHeight="16px"
							color="inactiveText"
							textAlign="start"
							isTruncated={true}>
							{elem.email}
						</Text>
					) : null}
				</VStack>
			</HStack>
		</RoleInfoPopup>
	);
};
