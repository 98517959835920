export const CoachingIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13 21.5C13 20.6472 13 19.7207 12.8924 19.3737C12.65 18.5925 12.0247 17.9811 11.2257 17.7441C10.8708 17.6389 10.4347 17.6389 9.5625 17.6389H6.4375C5.56527 17.6389 5.12916 17.6389 4.77429 17.7441C3.97528 17.9811 3.35003 18.5925 3.10765 19.3737C3 19.7207 3 20.6472 3 21.5M10.8125 12.75C10.8125 14.2688 9.5533 15.5 8 15.5C6.4467 15.5 5.1875 14.2688 5.1875 12.75C5.1875 11.2312 6.4467 10 8 10C9.5533 10 10.8125 11.2312 10.8125 12.75Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.0001 21.5C21.0001 20.8797 21.0001 20.0696 20.9248 19.8173C20.7551 19.2491 20.3174 18.8045 19.7581 18.6321C19.5097 18.5556 19.2044 18.5556 18.5939 18.5556H16.4064C15.7958 18.5556 15.4905 18.5556 15.2421 18.6321C14.6828 18.8045 14.2451 19.2491 14.0755 19.8173C14.0001 20.0696 14 20.8797 14 21.5M19.4689 15C19.4689 16.1046 18.5874 17 17.5001 17C16.4128 17 15.5314 16.1046 15.5314 15C15.5314 13.8954 16.4128 13 17.5001 13C18.5874 13 19.4689 13.8954 19.4689 15Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 3.1727C11 2.45594 11 2.09756 11.1635 1.82379C11.3073 1.58298 11.5368 1.38719 11.819 1.26449C12.1399 1.125 12.5599 1.125 13.4 1.125H17.6C18.4401 1.125 18.8601 1.125 19.181 1.26449C19.4632 1.38719 19.6927 1.58298 19.8365 1.82379C20 2.09756 20 2.45594 20 3.17271V5.47637C20 6.19314 20 6.55152 19.8365 6.82529C19.6927 7.0661 19.4632 7.26189 19.181 7.38459C18.8601 7.52408 18.4401 7.52408 17.6 7.52408H16.3419C16.0298 7.52408 15.8738 7.52408 15.7246 7.55021C15.5922 7.57339 15.4641 7.61174 15.3437 7.6642C15.2081 7.72333 15.0863 7.80649 14.8426 7.97279L13.6499 8.78691C13.4418 8.92892 13.3378 8.99992 13.2503 9C13.1741 9.00007 13.1021 8.97054 13.0546 8.91977C13 8.86139 13 8.74773 13 8.52042V7.52408C12.535 7.52408 12.3025 7.52408 12.1118 7.48047C11.5941 7.36213 11.1898 7.01716 11.0511 6.5755C11 6.41275 11 6.21439 11 5.81766V3.1727Z"
				stroke="currentColor"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
