import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ProfileFormNavBtn } from '../ProfileFormNavBtn';
import { useGetCurrentUser } from '../../../../hooks';
import { MyProfileRoutes } from '../../helper';
import MyProfile from '../../MyProfile';
import { Loader } from '../../../../components';

export const MainForm: React.FC = () => {
	const { data, isLoading } = useGetCurrentUser();
	// const {
	// 	isOpen: isOpenImageModal,
	// 	onClose: onCloseImageModal,
	// 	onOpen: onOpenImageModal,
	// } = useDisclosure();

	// const [value, setValue] = useState<File>();

	// const [imageToDisplay, setImageToDisplay] = useState(data?.imageUrl);

	// // setting avatar on GET user request
	// useEffect(() => {
	// 	setImageToDisplay(data?.imageUrl);
	// }, [data]);

	// const onSetFile = (value: File) => {
	// 	setValue(value);
	// };

	// const { mutate } = useMutation<ApiResponseEmpty, any, FormData>(
	// 	data => API.post('/api/user/current/edit/image', data),
	// 	{
	// 		onSuccess(e) {
	// 			queryClient.invalidateQueries(
	// 				CurrentUserQueryKeys.CURRENT_USER,
	// 			);
	// 			Alerter.success(e.messages[0]?.message);
	// 			setValue(undefined);
	// 		},
	// 		onError(e) {
	// 			Alerter.error(e);
	// 		},
	// 	},
	// );

	// updating image
	// useEffect(() => {
	// 	if (value) {
	// 		const formData = new FormData();
	// 		formData.append('image', value);
	// 		// mutate(formData);
	// 	}
	// }, [value]);

	if (isLoading) {
		return <Loader centerHeight={'100vh'} />;
	}

	return (
		<MyProfile>
			<Flex flexDirection={'column'} gap={'15px'}>
				{/* <ImageModalPicker
					onSetFile={onSetFile}
					setImageToDisplay={setImageToDisplay}
					isOpen={isOpenImageModal}
					onClose={onCloseImageModal}
				/> */}
				{/* <Center> */}
				{/* <Avatar
						onClick={onOpenImageModal}
						cursor={'pointer'}
						name={
							imageToDisplay
								? ''
								: `${data?.firstName} ${data?.lastName}`
						}
						src={imageToDisplay ? imageToDisplay : ''}
						boxSize={'130px'}
					/> */}
				{/*<Popover*/}
				{/*	isOpen={isOpen}*/}
				{/*	onOpen={onOpen}*/}
				{/*	onClose={onClose}*/}
				{/*	placement={'right'}*/}
				{/*	closeOnBlur={true}>*/}
				{/*	<PopoverTrigger>*/}
				{/*		<Avatar*/}
				{/*			onClick={onOpen}*/}
				{/*			cursor={'pointer'}*/}
				{/*			name={*/}
				{/*				imageToDisplay*/}
				{/*					? ''*/}
				{/*					: `${data?.firstName} ${data?.lastName}`*/}
				{/*			}*/}
				{/*			src={imageToDisplay ? imageToDisplay : ''}*/}
				{/*			boxSize={'130px'}*/}
				{/*		/>*/}
				{/*	</PopoverTrigger>*/}
				{/*	<PopoverContent p={5}>*/}
				{/*		<Button*/}
				{/*			onClick={onOpenImageModal}*/}
				{/*			type={'submit'}*/}
				{/*			h={'40px'}*/}
				{/*			bg={'successButton.500'}*/}
				{/*			mb={'15px'}*/}
				{/*			color={'white'}>*/}
				{/*			Change Image*/}
				{/*		</Button>*/}
				{/*		<Button*/}
				{/*			onClick={onRemoveImg}*/}
				{/*			type={'submit'}*/}
				{/*			h={'40px'}*/}
				{/*			bg={'successButton.500'}*/}
				{/*			color={'white'}>*/}
				{/*			Delete Image*/}
				{/*		</Button>*/}
				{/*	</PopoverContent>*/}
				{/*</Popover>*/}
				{/* </Center> */}
				<ProfileFormNavBtn
					name={'Name'}
					value={`${data?.firstName} ${data?.lastName}`}
					nextRoute={
						'/' +
						MyProfileRoutes.MY_PROFILE +
						'/' +
						MyProfileRoutes.NAME
					}
				/>
				<ProfileFormNavBtn name={'Email'} value={data?.email} />
				<ProfileFormNavBtn
					name={'Phone'}
					nextRoute={
						'/' +
						MyProfileRoutes.MY_PROFILE +
						'/' +
						MyProfileRoutes.PHONE
					}
					value={`+1 ${data?.phoneNumber || ''}`}
				/>
				<ProfileFormNavBtn
					name={'Password'}
					nextRoute={
						'/' +
						MyProfileRoutes.MY_PROFILE +
						'/' +
						MyProfileRoutes.PASSWORD
					}
					value={'******'}
				/>
			</Flex>
		</MyProfile>
	);
};
