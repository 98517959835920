import React from 'react';
import classNames from 'classnames';
import * as Popover from '@radix-ui/react-popover';
import styles from './RadixPopover.module.css';

const RadixPopover = Popover.Root;
const RadixPopoverTrigger = Popover.Trigger;
const RadixPopoverPortal = Popover.Portal;

const RadixPopoverContent = React.forwardRef<
	React.ElementRef<typeof Popover.Content>,
	React.ComponentPropsWithoutRef<typeof Popover.Content>
>(({ className, children, ...props }, ref) => (
	<Popover.Content
		ref={ref}
		className={classNames(styles.PopoverContent, className)}
		{...props}>
		{children}
	</Popover.Content>
));

RadixPopoverContent.displayName = 'RadixPopoverContent';

export {
	RadixPopover,
	RadixPopoverTrigger,
	RadixPopoverPortal,
	RadixPopoverContent,
};
