import React from 'react';

export const CheckboxInput: React.FC<{ isChecked?: boolean }> = ({
	isChecked,
}) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		{isChecked ? (
			<path
				d="M6.66699 10L9.16699 12.5L13.3337 7.5"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		) : null}
		<path
			d="M15.833 3.33301H4.16634C3.7061 3.33301 3.33301 3.7061 3.33301 4.16634V15.833C3.33301 16.2932 3.7061 16.6663 4.16634 16.6663H15.833C16.2932 16.6663 16.6663 16.2932 16.6663 15.833V4.16634C16.6663 3.7061 16.2932 3.33301 15.833 3.33301Z"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
