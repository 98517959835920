export enum CardFieldEnum {
	Checkbox = 1,
	Date,
	Dropdown,
	Text,
}

export type TestFieldItemType = {
	id: number;
	type: number;
	isChecked: boolean;
	name: string;
};

export type CustomDropdownOptionType = {
	name: string;
	colour: string;
	id: number;
	isSelected: boolean;
};

export type CardCustomFieldItemType = {
	id: number;
	type: number;
	name: string;
	isAddToCard: boolean;
	customIsChecked: boolean;
	customDate: string | null;
	customDropdownOptions: CustomDropdownOptionType[];
	customText: string | null;
};

export type CardTenantItemType = {
	cardTenantId: number;
	tenantId: number;
	firstName: string;
	lastName: string;
};

export type CardLabelItemType = {
	id: number;
	name: string;
	colour: string;
	isAddToCard: boolean;
};

export type CardItemType = {
	id: number;
	name: string;
	description: string | null;
	position: number;
	dueDate: string | null;
	attachmentCount: number;
	labels: CardLabelItemType[];
	cardTenants: CardTenantItemType[];
	customFields: CardCustomFieldItemType[];
};

export type PlannerListItemType = {
	id: number;
	name: string;
	position: number;
	cards: CardItemType[];
};

export enum DroppableTypeEnum {
	LIST = 'LIST',
	CARD = 'CARD',
}

export type CardModalData = {
	cardId: number;
	listId: number;
	listName: string;
};

export type CardAttachmentItemType = {
	id: number;
	baseName: string;
	fileName: string;
	extension: string;
	fileUrl: string;
	thumbnailUrl: string | null;
	createdAt: string;
};

export type CustomFieldPayload = {
	name: string;
	type: number;
	options: Array<{
		name: string;
		colour: string;
	}>;
};

export type CustomFieldShortItemType = {
	id: number;
	type: number;
	name: string;
	isAddToCard: boolean;
	options: CustomDropdownOptionType[];
};

export type UpdateCustomNamePayload = {
	customId: number;
	isAddToCard: boolean;
	name: string;
};

export type UpdateCustomDropdownContent = {
	name: string;
	optionsToCreate: Array<{
		name: string;
		colour: string;
	}>;
	optionsToUpdate: CustomDropdownOptionType[];
};
