import React, { useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Text,
	VStack,
} from '@chakra-ui/react';
import { ErrorIcon } from '../../../../../assets';

interface IWarningDialog {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const WarningDialog: React.FC<IWarningDialog> = ({
	isOpen,
	onClose,
}) => {
	const cancelRef = useRef<HTMLButtonElement | null>(null);
	return (
		<AlertDialog
			isCentered={true}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent bg="white" maxW={'324px'}>
					<AlertDialogBody p="20px">
						<VStack spacing={'16px'}>
							<ErrorIcon />
							<Text textAlign={'center'} fontWeight={600}>
								A tenant is associated with this unit. Remove
								tenant to delete this unit.
							</Text>
							<Button
								ref={cancelRef}
								onClick={onClose}
								maxW={'120px'}
								w={'full'}
								variant={'cruzctrl-solid'}
								h={'48px'}>
								Ok
							</Button>
						</VStack>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
