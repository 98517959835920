import React from 'react';
import { Box, Tabs, TabPanel, TabPanels } from '@chakra-ui/react';
import { useOutlet, useSearchParams } from 'react-router-dom';
import { DirectChatHeader } from './components';
import { DeleteAllMessagesConfirmModal } from '../modals';
import {
	AddFriendTab,
	PendingTab,
	OnlineTab,
	BlockedTab,
	AllTab,
} from './tabs';
import { useChatUserStore } from '../../../store';

export const DirectPage: React.FC = () => {
	const outlet = useOutlet();

	const { user } = useChatUserStore();
	const [searchParams] = useSearchParams();
	const selectedFriendsFilter = searchParams.get('friendsStatus') || '1';

	const tabIndex = +selectedFriendsFilter - 1;

	return (
		<>
			{user?.userId ? (
				<>
					{outlet || (
						<Box w="100%" p="2" bg="mainBg">
							<DirectChatHeader />
							<Tabs index={tabIndex}>
								<TabPanels>
									<TabPanel p="0">
										<OnlineTab index={tabIndex} />
									</TabPanel>
									<TabPanel p="0">
										<AllTab index={tabIndex} />
									</TabPanel>
									<TabPanel
										p="0"
										maxH="95vh"
										overflowX="auto">
										<PendingTab index={tabIndex} />
									</TabPanel>
									<TabPanel p="0">
										<BlockedTab index={tabIndex} />
									</TabPanel>
									<TabPanel p="0">
										<AddFriendTab index={tabIndex} />
									</TabPanel>
								</TabPanels>
							</Tabs>
						</Box>
					)}
				</>
			) : null}
			<DeleteAllMessagesConfirmModal />
		</>
	);
};
