export const extractMentionsIds = (message: string) => {
	const pattern = /@\[([^\]]+)\]\((\d+)\)/g;

	const matches = Array.from(message.matchAll(pattern), match => ({
		name: match[1],
		number: match[2],
	}));

	const uniqueSet = new Set(matches.map(match => Number(match.number)));

	const uniqueIds = Array.from(uniqueSet);

	return uniqueIds.length ? uniqueIds : null;
};
