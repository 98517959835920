import { useQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { MessengerQueryKeys } from './query-keys';
import { ApiResponse } from '../../../types';
import { ChannelUsersResponse } from '../types';

export const useGetChannelOnlineUsers = (
	debouncedSearchQuery: string,
	channelId?: number,
	count?: number,
) => {
	return useQuery<ApiResponse<ChannelUsersResponse>>({
		queryKey: [
			MessengerQueryKeys.GET_CHANNEL_ONLINE_USERS,
			debouncedSearchQuery,
			// channelId,
		],
		queryFn: () =>
			API_CHAT.get('/api/app/channel/users', {
				PageSize: count,
				Search: debouncedSearchQuery || undefined,
			}),
		enabled: !!channelId,
		refetchOnWindowFocus: false,
	});
};
