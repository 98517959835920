/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useQueryClient } from 'react-query';
import { useChatUserStore } from '../../../store';
import { ApiResponse } from '../../../types';
import { MessengerQueryKeys } from '../queries/query-keys';
import { ReactionsModalListResponse } from '../types';

export const useUpdateReactionsList = (
	key:
		| MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW
		| MessengerQueryKeys.GET_DIRECT_MESSAGE_REACTIONS,
) => {
	const queryClient = useQueryClient();
	const { user } = useChatUserStore();
	const mutate = (
		emojiId: number,
		messageId: number,
		operation: 'add' | 'remove',
	) =>
		queryClient.setQueryData<
			ApiResponse<ReactionsModalListResponse> | undefined
		>([key, messageId], oldData => {
			if (oldData?.value) {
				return {
					...oldData,
					value: {
						reactionItemsInfo:
							operation === 'add'
								? [
										{
											emojiId,
											reactedAt:
												new Date().toDateString(),
											userId: user?.userId || 1,
											displayName:
												user?.displayName || '',
											isAdmin: user?.isAdmin || false,
											avatarUrl: user?.avatarUrl || '',
										},
										...oldData.value.reactionItemsInfo,
								  ]
								: oldData.value.reactionItemsInfo?.filter(
										reaction =>
											reaction.userId !== user?.userId,
								  ),
						count:
							operation === 'add'
								? ++oldData.value.count
								: --oldData.value.count,
					},
				};
			}
			return oldData;
		});

	return { mutate };
};
