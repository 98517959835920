import { Box, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import React from 'react';
import { Alerter, formatFileSize } from '../../../../../../utils';

interface IFilePickerButton {
	onSetValue: (value: string) => void;
	onSetFileToUpload: (value: File) => void;
}

const MAX_IMAGE_SIZE = 25 * 1024 * 1024;

export const FilePickerButton: React.FC<IFilePickerButton> = ({
	onSetValue,
	onSetFileToUpload,
}) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			if (file.size > MAX_IMAGE_SIZE) {
				Alerter.error(
					`The image size must not exceed 25 MB, but you have ${formatFileSize(
						file.size,
					)}`,
				);
				return;
			}
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				if (reader.result) {
					onSetValue(reader.result.toString());
					event.target.value = '';
				}
			});
			reader.readAsDataURL(file);
			onSetFileToUpload(file);
		}
	};

	const handleClick = () => {
		inputRef.current?.click();
	};

	return (
		<Box>
			<Button
				onClick={handleClick}
				variant={'link'}
				color={'secondaryButton.500'}
				leftIcon={<AddIcon />}>
				Add Image
			</Button>
			<input
				type="file"
				accept="image/jpeg, image/png, image/jpg"
				ref={inputRef}
				onChange={handleChange}
				style={{ display: 'none' }}
			/>
		</Box>
	);
};
