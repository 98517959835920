import React from 'react';
import {
	Box,
	Divider,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	Tabs,
	TabList,
	TabPanels,
	TabPanel,
	Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SettingsModalCustomTab } from '../../common-components';
import {
	AnnouncementIcon,
	TrashIcon,
	Typography2Icon,
} from '../../../../assets';
import { ChannelType, ServerChannelData } from '../../types';
import { HREFs } from '../../constants';
import { OverviewTab } from './tabs';
import { useDeleteChannel } from '../../queries';

interface ManageChannelModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	channel: ServerChannelData;
	categoryName: string | null;
	categoryId: number | null;
}

export const ManageChannelModal: React.FC<ManageChannelModalProps> = ({
	onClose,
	isOpen,
	channel,
	categoryName,
}) => {
	const navigate = useNavigate();

	const clearUrl = () => {
		navigate(HREFs.SERVER, { replace: true });
	};

	const { mutateAsync: deleteChannel, isLoading: deleteChannelLoading } =
		useDeleteChannel();

	const handleDeleteChannel = async () => {
		try {
			await deleteChannel(channel.id);
			onClose();
			clearUrl();
		} catch (error) {
			console.log(error, 'handleDeleteChannel error');
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} trapFocus={false} size="5xl">
			<ModalOverlay
				bg={'rgba(74, 89, 145, 0.60)'}
				backdropFilter={'blur(10px)'}
			/>
			<ModalContent bg={'blue.200'} borderRadius={'10px'}>
				<ModalHeader pb="7">
					<ModalCloseButton
						bg="blue.100"
						borderRadius="10px"
						color="white"
						top="20px"
						right="20px"
					/>
				</ModalHeader>
				<ModalBody p={'20px'}>
					<Tabs>
						<Flex flexDirection={'row'} gap={'20px'}>
							<Box
								borderRadius={'10px'}
								bg={'blue.100'}
								minH={'650px'}
								minW={'320px'}
								p={'20px'}>
								<Flex
									flexDirection={'row'}
									alignItems={'center'}
									p={'10px'}
									borderRadius={'4px'}
									bg={'blue.300'}
									w={'100%'}
									color="white"
									mb="10px">
									{channel.type ===
									ChannelType.ANNOUNCEMENT ? (
										<AnnouncementIcon />
									) : (
										<Typography2Icon />
									)}
									<Text
										ml={'5px'}
										fontSize={'16px'}
										color={'white'}>
										{channel.name}
									</Text>
									<Text
										fontWeight={'700'}
										fontSize={'16px'}
										color={'blue.50'}
										ml={'auto'}>
										{categoryName}
									</Text>
								</Flex>
								<TabList
									border="none"
									flexDirection="column"
									mb="10px">
									<SettingsModalCustomTab>
										Overview
									</SettingsModalCustomTab>
									{/* <SettingsModalCustomTab>
										Permission
									</SettingsModalCustomTab> */}
								</TabList>

								<Divider />
								<Button
									_hover={{ bg: 'blue.200' }}
									_loading={{
										justifyContent: 'center',
										color: 'white',
										backgroundColor: 'blue.200',
									}}
									_active={{}}
									p={'16px 10px'}
									mt="10px"
									borderRadius={'6px'}
									bg="transparent"
									justifyContent={'space-between'}
									w={'100%'}
									isLoading={deleteChannelLoading}
									onClick={handleDeleteChannel}>
									<Text color={'dangerButton.500'}>
										Delete Channel
									</Text>
									<TrashIcon strokeColor={'#C14B4B'} />
								</Button>
							</Box>

							<TabPanels>
								<TabPanel p={0}>
									<OverviewTab
										channel={channel}
										onClose={onClose}
									/>
								</TabPanel>
								{/* <TabPanel p={0}>
									<PermissionTab />
								</TabPanel> */}
							</TabPanels>
						</Flex>
					</Tabs>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
