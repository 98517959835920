import { Box, Flex, RadioProps, Text, useRadio } from '@chakra-ui/react';
import { colors } from '../../../../theme';

type CustomRadioButtonProps = RadioProps;

export const CustomRadioButton = (props: CustomRadioButtonProps) => {
	const { getInputProps, getCheckboxProps } = useRadio(props);

	const input = getInputProps();
	const checkbox = getCheckboxProps();

	return (
		<Flex as="label" cursor="pointer" align={'center'} gap={'8px'}>
			<input {...input} />
			<Flex
				border={`2px solid ${colors.success}`}
				borderRadius={'50%'}
				cursor="pointer"
				w={'20px'}
				h={'20px'}
				justify={'center'}
				align={'center'}>
				<Box
					{...checkbox}
					transition={'all .1s ease-in'}
					bg={'transparent'}
					borderRadius={'50%'}
					_checked={{ bg: 'success' }}
					w={'12px'}
					h={'12px'}></Box>
			</Flex>
			<Text color={'blue.100'} fontSize={'14px'}>
				{props.children}
			</Text>
		</Flex>
	);
};
