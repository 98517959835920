import { ConversationItemData, UserByIdData } from '../types';

export function updateConversationInList(
	conversationObj: ConversationItemData,
	userObj: UserByIdData | null,
) {
	if (!userObj) {
		return conversationObj;
	}

	if (conversationObj.displayName !== userObj.displayName) {
		conversationObj.displayName = userObj.displayName;
	}

	if (conversationObj.avatarStoragePath !== userObj.avatarUrl) {
		conversationObj.avatarStoragePath = userObj.avatarUrl;
	}

	return conversationObj;
}
