import React, { useMemo, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { getTime } from '../../utils';
import { EditAndTextBlock, MessageMenu, MessageReply } from './components';
import { MessageWithAvatar } from './MessageWithAvatar';
import {
	ReplyMessage,
	EditMessageDisplayData,
	MessageType,
	ChatType,
	MessageReactionType,
	MessageAttachmentFile,
	MessageAttachmentImage,
	MessageReactionItem,
	MessageAttachmentVideo,
	UserByIdData,
} from '../../types';
import { PinnedMessageNotify } from '../PinnedMessageNotify';
import { ReactionsList } from './components/ReactionsList';
import { MessageAttachments } from './components/MessageAttachments';

interface IMessageProps {
	text: string | null;
	sentById: number;
	sentByDisplayName: string;
	date?: Date;
	isSameSenderNext?: boolean;
	isSameSenderPrev?: boolean;
	id: number;
	onEditMessage: (messageId: number, newText: string) => void;
	onSetDeleteMessage: (message: EditMessageDisplayData) => void;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	onSetPinMessage?: (message: EditMessageDisplayData) => void;
	isEdited?: boolean;
	type?: MessageType;
	replyToId: number | null;
	allowFullAccess?: boolean;
	pinnedMessageId?: number;
	sentByAvatarUrl?: string | null;
	includePin?: boolean;
	includeEdit?: boolean;
	disableMenu?: boolean;
	enableMentions?: boolean;
	onSuspendUser?: VoidFunction;
	onSuspendAndPurgeMessagesUser?: VoidFunction;
	onUnSuspendUser?: VoidFunction;
	chatType: ChatType;
	sentByAdmin: boolean;
	isSuspended?: boolean;
	isSenderDeleted?: boolean;
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	hideReactions?: boolean;
	messageAttachmentFiles: MessageAttachmentFile[];
	messageAttachmentImages: MessageAttachmentImage[];
	messageAttachmentVideos: MessageAttachmentVideo[];
	disabledAttachmentView?: boolean;
	reactions?: MessageReactionItem[];
	receiver?: UserByIdData | null;
	includeUserInfoPopup?: boolean;
}

export const Message: React.FC<IMessageProps> = ({
	sentByDisplayName,
	sentById,
	text,
	date,
	isSameSenderNext = false,
	isSameSenderPrev = false,
	id,
	onEditMessage,
	onSetDeleteMessage,
	isEdited,
	onSetReplyMessage,
	onSetPinMessage,
	type,
	sentByAvatarUrl,
	replyToId,
	allowFullAccess,
	pinnedMessageId,
	includePin,
	includeEdit,
	disableMenu,
	enableMentions,
	onSuspendAndPurgeMessagesUser,
	onSuspendUser,
	onUnSuspendUser,
	sentByAdmin,
	chatType,
	isSuspended,
	isSenderDeleted,
	onReact,
	hideReactions,
	messageAttachmentFiles,
	messageAttachmentImages,
	disabledAttachmentView,
	messageAttachmentVideos,
	reactions,
	receiver,
	includeUserInfoPopup,
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [isMessageHovered, setIsMessageHovered] = useState(false);
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	const onOpenEdit = () => {
		setIsEdit(true);
	};

	const onCloseEdit = () => {
		setIsEdit(false);
	};

	const onCloseMessageActions = () => {
		setIsMessageHovered(false);
		setIsMenuOpened(false);
	};

	const onDelete = () => {
		onSetDeleteMessage({
			avatarImage: sentByAvatarUrl || '',
			sentByDisplayName,
			text: text!,
			isEdited,
			type,
			replyToId,
			id,
			date: date ? getTime(date) : undefined,
			isAdmin: sentByAdmin,
			sentById,
			attachmentFiles: messageAttachmentFiles,
			attachmentImages: messageAttachmentImages,
			attachmentVideos: messageAttachmentVideos,
			reactions,
		});
		onCloseMessageActions();
	};

	const onReply = () => {
		onSetReplyMessage({
			messageId: id,
			sentBy: sentByDisplayName,
			sentById,
		});
		onCloseMessageActions();
	};

	const onPin = () => {
		onSetPinMessage?.({
			avatarImage: sentByAvatarUrl || '',
			sentByDisplayName,
			text: text!,
			isEdited,
			type,
			replyToId,
			id,
			date: date ? getTime(date) : undefined,
			isAdmin: sentByAdmin,
			sentById,
			attachmentFiles: messageAttachmentFiles,
			attachmentImages: messageAttachmentImages,
			attachmentVideos: messageAttachmentVideos,
			reactions,
		});
		onCloseMessageActions();
	};

	const reactedObj = useMemo(
		() => reactions?.find(reaction => reaction.userReacted === true),
		[reactions],
	);
	const userReactedId = reactedObj ? reactedObj.emojiId : null;

	if (type === MessageType.Pin) {
		return (
			<PinnedMessageNotify
				sentByDisplayName={sentByDisplayName}
				pinnedMessageId={pinnedMessageId}
				date={date}
			/>
		);
	}

	return (
		<Box
			bg={isEdit ? 'white' : 'transparent'}
			p="5px 20px"
			className={`message-${id}`}
			onMouseLeave={() => {
				if (!isMenuOpened) {
					setIsMessageHovered(false);
				}
			}}
			onMouseOver={() => setIsMessageHovered(true)}>
			{type === MessageType.Reply ? (
				<MessageReply
					replyToId={replyToId}
					chatType={chatType}
					receiver={receiver}
					includeUserInfoPopup={includeUserInfoPopup}
				/>
			) : null}
			{isSameSenderPrev ? (
				<Flex gap="10px" mb={isSameSenderNext ? 0 : '20px'}>
					<Box w="50px" flexShrink={0}>
						<Text
							color="blue.50"
							fontSize="10px"
							lineHeight="24px"
							display={isMessageHovered ? 'flex' : 'none'}>
							{date ? getTime(date) : null}
						</Text>
					</Box>
					<Flex w="100%" position="relative">
						<Flex
							alignItems={'start'}
							flexDirection={'column'}
							rowGap="4px"
							w="100%">
							<EditAndTextBlock
								isEdit={isEdit}
								onCancelEdit={onCloseEdit}
								text={text!}
								onSubmitEdit={text => onEditMessage(id, text)}
								isEdited={isEdited}
								enableMentions={enableMentions}
							/>
							<MessageAttachments
								messageAttachmentImages={
									messageAttachmentImages
								}
								messageAttachmentFiles={messageAttachmentFiles}
								disabledAttachmentView={disabledAttachmentView}
								messageAttachmentVideos={
									messageAttachmentVideos
								}
							/>
							{hideReactions ? null : (
								<ReactionsList
									isSuspended={isSuspended}
									onReact={onReact}
									messageId={id}
									reactions={reactions}
									userReactedId={userReactedId}
								/>
							)}
						</Flex>
						{disableMenu ? null : (
							<MessageMenu
								onEdit={onOpenEdit}
								onDelete={onDelete}
								onReply={onReply}
								onPin={onPin}
								text={text}
								allowFullAccess={allowFullAccess}
								includePin={includePin}
								includeEdit={includeEdit}
								onSuspendAndPurgeMessagesUser={
									onSuspendAndPurgeMessagesUser
								}
								onSuspendUser={onSuspendUser}
								onUnSuspendUser={
									isSuspended ? onUnSuspendUser : undefined
								}
								isAdmin={sentByAdmin}
								sentById={sentById}
								isSenderDeleted={isSenderDeleted}
								id={id}
								chatType={chatType}
								hasReactions={!!reactions?.length}
								onReact={onReact}
								userReactedId={userReactedId}
								isMessageHovered={isMessageHovered}
								onInteractWithMenu={(bool: boolean) => {
									setIsMenuOpened(bool);
								}}
								isMenuOpened={isMenuOpened}
								onRemoveMessageHover={() =>
									setIsMessageHovered(false)
								}
							/>
						)}
					</Flex>
				</Flex>
			) : (
				<MessageWithAvatar
					text={text!}
					date={date ? getTime(date) : undefined}
					sentByDisplayName={sentByDisplayName}
					avatarImage={sentByAvatarUrl || ''}
					isSameSenderNext={isSameSenderNext}
					isEdit={isEdit}
					onCloseEdit={onCloseEdit}
					onEditMessage={onEditMessage}
					isEdited={isEdited}
					onOpenEdit={onOpenEdit}
					onDelete={onDelete}
					onReply={onReply}
					onPin={onPin}
					allowFullAccess={allowFullAccess}
					id={id}
					includePin={includePin}
					includeEdit={includeEdit}
					showMenu={!disableMenu}
					isAdmin={sentByAdmin}
					enableMentions={enableMentions}
					onSuspendAndPurgeMessagesUser={
						onSuspendAndPurgeMessagesUser
					}
					onSuspendUser={onSuspendUser}
					onUnSuspendUser={isSuspended ? onUnSuspendUser : undefined}
					replyToId={replyToId}
					chatType={chatType}
					sentById={sentById}
					isSenderDeleted={isSenderDeleted}
					isSuspended={isSuspended}
					onReact={onReact}
					hideReactions={hideReactions}
					messageAttachmentFiles={messageAttachmentFiles}
					messageAttachmentImages={messageAttachmentImages}
					disabledAttachmentView={disabledAttachmentView}
					reactions={reactions}
					messageAttachmentVideos={messageAttachmentVideos}
					isMessageHovered={isMessageHovered}
					isMenuOpened={isMenuOpened}
					onInteractWithMenu={(bool: boolean) =>
						setIsMenuOpened(bool)
					}
					onRemoveMessageHover={() => setIsMessageHovered(false)}
					receiver={receiver}
					includeUserInfoPopup={includeUserInfoPopup}
				/>
			)}
		</Box>
	);
};
