/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { create } from 'zustand';
import {
	ConversationItemData,
	ConversationLastMessage,
} from '../pages/Messenger/types';

interface IState {
	conversationsList: ConversationItemData[];
	setConversationsList: (
		updater: (prev: ConversationItemData[]) => ConversationItemData[],
	) => void;
	updateConversationsList: (
		receiverId: number,
		updates: Partial<ConversationItemData>,
	) => void;
	updateConversationLastMessage: (
		conversationId: number,
		updatedLastMessage: Partial<ConversationLastMessage>,
		removeMessageId?: boolean,
	) => void;
}

export const useConversationsList = create<IState>(set => ({
	conversationsList: [],
	setConversationsList: (
		updater: (prev: ConversationItemData[]) => ConversationItemData[],
	) => set(prev => ({ conversationsList: updater(prev.conversationsList) })),
	updateConversationsList: (receiverId, updatedData) =>
		set(state => ({
			conversationsList: state.conversationsList.map(conversation =>
				conversation.receiverId === receiverId
					? { ...conversation, ...updatedData }
					: conversation,
			),
		})),
	updateConversationLastMessage: (
		conversationId: number,
		updatedLastMessage: Partial<ConversationLastMessage>,
		removeMessageId?: boolean,
	) =>
		set(state => ({
			conversationsList: state.conversationsList.map(conversation =>
				conversation.id === conversationId &&
				conversation.lastMessage &&
				conversation.lastMessage.id === updatedLastMessage.id
					? {
							...conversation,
							lastMessage: {
								id: removeMessageId
									? null
									: updatedLastMessage.id,
								text:
									updatedLastMessage.text ||
									conversation.lastMessage.text,
								senderId: updatedLastMessage.senderId
									? updatedLastMessage.senderId
									: conversation.lastMessage.senderId,
							},
					  }
					: conversation,
			),
		})),
}));
