import React, { ReactNode, useRef } from 'react';
import {
	AlertDialog as ChakraAlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	forwardRef,
	ButtonProps,
} from '@chakra-ui/react';

type Props = {
	acceptButtonText?: string;
	acceptCallback: VoidFunction;
	cancelButtonText?: string;
	cancelCallback?: VoidFunction;
	children: ReactNode;
	title: string;
	// triggerComponent: ReactElement;
	acceptButtonProps?: ButtonProps;
	isOpen: boolean;
	onClose: VoidFunction;
};

export const AlertDialog = forwardRef(
	(
		{
			acceptButtonText = 'Accept',
			acceptCallback,
			cancelButtonText = 'Cancel',
			cancelCallback,
			children,
			title,
			// triggerComponent,
			acceptButtonProps,
			isOpen,
			onClose,
		}: Props,
		ref,
	) => {
		const cancelRef = useRef<HTMLButtonElement | null>(null);

		const handleAccept = () => {
			acceptCallback();
			onClose();
		};

		const handleCancel = () => {
			cancelCallback && cancelCallback();
			onClose();
		};

		return (
			<>
				{/* {cloneElement(triggerComponent, {
					onClick: onOpen,
					ref: ref,
				})} */}

				<ChakraAlertDialog
					isOpen={isOpen}
					leastDestructiveRef={cancelRef}
					onClose={onClose}
					size="xl">
					<AlertDialogOverlay zIndex={1999}>
						<AlertDialogContent bg="mainBg">
							<AlertDialogHeader
								fontSize="20px"
								fontWeight="700"
								color="blue.200"
								pb="0"
								textAlign="center">
								{title}
							</AlertDialogHeader>

							<AlertDialogBody py="0" px="20px">
								{children}
							</AlertDialogBody>

							<AlertDialogFooter justifyContent="center">
								<Button
									onClick={handleAccept}
									variant="outline"
									colorScheme="blackAlpha"
									borderColor="blue.100"
									color="blue.100"
									height="50px"
									px="20px"
									{...acceptButtonProps}>
									{acceptButtonText}
								</Button>
								<Button
									ref={cancelRef}
									colorScheme="blue"
									bg="blue.100"
									height="50px"
									px="20px"
									onClick={handleCancel}
									ml={3}>
									{cancelButtonText}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</ChakraAlertDialog>
			</>
		);
	},
);
