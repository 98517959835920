import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { useQuery } from 'react-query';
import { IdNameType } from '../../types';

interface IPayload {
	propertyId?: number;
	isOccupied?: boolean;
}

export const useGetUnitsSelector = (payload: IPayload) => {
	return useQuery({
		queryKey: [CruzQueryKeys.UnitsSelector, payload.propertyId],
		queryFn: (): Promise<
			ApiResponse<{
				units: IdNameType[];
			}>
		> =>
			API_CTRL.get(
				`/api/app/selector/properties/${payload.propertyId}/units`,
				{
					isOccupied:
						typeof payload.isOccupied === 'boolean'
							? payload.isOccupied
							: undefined,
				},
			),
		enabled: Boolean(payload.propertyId),
	});
};
