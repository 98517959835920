import {
	Avatar,
	Box,
	Flex,
	Img,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { colors } from '../../../../theme';
import { LikeIcon } from '../../../../assets/messenger-icons/LikeIcon';
import { Link } from 'react-router-dom';
import {
	useGetChannelMessageReactions,
	useGetDirectMessageReactions,
} from '../../queries';
import { ChatType } from '../../types';
import { HREFs, REACTION_LIST_BIG } from '../../constants';
import { Loader } from '../../../../components';
import { getTimeAndDate, getTime, isNewDay } from '../../utils';
import { AdminVerificationSmall } from '../../../../assets';
import { useChatUserStore } from '../../../../store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VerticalScrollbarStyles } from '../../../../constants';

interface ViewReactionsModal {
	isOpen: boolean;
	onClose: VoidFunction;
	messageId?: number;
	chatType?: ChatType;
}

export const ViewReactionsModal: FC<ViewReactionsModal> = ({
	onClose,
	isOpen,
	messageId,
	chatType,
}) => {
	const { user } = useChatUserStore();
	const isDirect = chatType === ChatType.DIRECT;
	const isChannel = chatType === ChatType.CHANNEL;
	const {
		data: channelMessageReactionsList,
		isFetching: isChannelMessageReactionsFetching,
		isFetchingNextPage: isChannelMessageReactionsFetchingNextPage,
		hasNextPage: isChannelMessageReactionsHasNextPage,
		fetchNextPage: fetchNextChannelMessageReactionsPage,
	} = useGetChannelMessageReactions(messageId || null, isChannel && isOpen);

	const {
		data: directMessageReactionsList,
		isFetching: isDirectMessageReactionsFetching,
	} = useGetDirectMessageReactions(messageId || null, isDirect && isOpen);

	const channelMessageReactions = useMemo(
		() => channelMessageReactionsList?.pages?.flat(),
		[channelMessageReactionsList],
	);

	const reactionsData =
		channelMessageReactions ||
		directMessageReactionsList?.value?.reactionItemsInfo;

	const isFetchingNextPage = isChannelMessageReactionsFetchingNextPage;
	const fetchNextPage = fetchNextChannelMessageReactionsPage;
	const hasNextPage = isChannel
		? isChannelMessageReactionsHasNextPage
		: false;

	const reactionsDataFetching =
		isChannelMessageReactionsFetching || isDirectMessageReactionsFetching;

	const isViewReady = reactionsData?.length && isOpen;

	if (!isViewReady) {
		return null;
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
			<ModalOverlay
				bg={'rgba(74, 89, 145, 0.60)'}
				backdropFilter={'blur(10px)'}
			/>
			<ModalContent>
				<ModalHeader>Reactions</ModalHeader>
				<ModalCloseButton top="16px" right="20px" />
				<ModalBody
					paddingBottom={'20px'}
					minH="345"
					maxH="345"
					overflowY="auto"
					pl="24px"
					pr="16px">
					{reactionsDataFetching && !isFetchingNextPage ? (
						<Loader spinnerSize="md" centerHeight="315px" />
					) : (
						<InfiniteScroll
							dataLength={reactionsData.length}
							next={() => {
								if (isChannel) {
									fetchNextPage();
								}
							}}
							hasMore={!!hasNextPage}
							height="315px"
							loader={
								<Loader spinnerSize="sm" centerHeight="20px" />
							}
							style={{
								...VerticalScrollbarStyles,
								scrollbarWidth: 'thin',
								paddingRight: '8px',
							}}>
							{reactionsData.map((item, index) => {
								const renderEmoji =
									REACTION_LIST_BIG[`${item.emojiId}`];
								const isToday = !isNewDay(
									new Date(item.reactedAt),
									new Date(),
								);
								const reactionTime = isToday
									? `Today, ${getTime(
											new Date(item.reactedAt),
									  )}`
									: getTimeAndDate(new Date(item.reactedAt));
								const isCurrentUser =
									user?.userId === item.userId;
								return (
									<Flex
										key={index}
										p={'10px 0'}
										borderBottom={`1px solid ${colors.dropBlue}`}
										alignItems={'center'}>
										<Box position="relative">
											<Avatar
												src={item.avatarUrl || ''}
												name={item.displayName || ''}
												boxSize={'30px'}
												bg={colors.blue[50]}
												color="white"
												sx={{
													'> div': {
														fontSize: '12px',
													},
												}}
											/>
											{item.isAdmin ? (
												<Box
													position="absolute"
													top="-2px"
													right="-2px">
													<AdminVerificationSmall
														width="14px"
														height="13px"
													/>
												</Box>
											) : null}
										</Box>

										<Flex
											flexDirection={'column'}
											gap={'0'}
											ml={'10px'}>
											<Text
												as={Link}
												to={
													isCurrentUser
														? ''
														: HREFs.DIRECT +
														  '/' +
														  item.userId
												}
												fontWeight={'700'}
												color={'blue.200'}
												cursor={
													isCurrentUser
														? 'default'
														: 'pointer'
												}
												_hover={{
													textDecoration:
														isCurrentUser
															? 'none'
															: 'underline',
												}}>
												{isCurrentUser
													? 'Me'
													: item.displayName || ''}
											</Text>
											<Flex
												alignItems={'center'}
												gap={'5px'}>
												<LikeIcon
													width={'14'}
													height={'12'}
												/>
												<Text
													fontSize={'12px'}
													fontWeight={'500'}
													color={
														'secondaryButton.500'
													}>
													{reactionTime}
												</Text>
											</Flex>
										</Flex>
										<Box boxSize="24px" ml="auto">
											<Img
												src={renderEmoji}
												alt={`emoji ${item.emojiId}`}
												width="24px"
												height="24px"
												draggable={false}
											/>
										</Box>
									</Flex>
								);
							})}
						</InfiniteScroll>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
