export enum SOCKET_EVENTS {
	FRIEND_REQUEST_DISMISS_EVENT = 'friend-request-dismissed',
	PRIVATE_MESSAGE_EVENT = 'private-message',
	DELETE_MESSAGE_EVENT = 'delete-message',
	EDIT_MESSAGE_EVENT = 'edit-message',
	NEW_FRIEND_ADDED_EVENT = 'new-friend-added',
	FRIEND_DELETED_EVENT = 'friend-deleted',
	USER_BLOCKED_EVENT = 'user-blocked',
	USER_UNBLOCKED_EVENT = 'user-unblocked',
	NEW_FRIEND_REQUEST_IN_EVENT = 'new-friend-request-in',
	// NEW_FRIEND_REQUEST_OUT_EVENT = 'new-friend-request-out',
	FRIEND_REQUEST_DELETED_EVENT = 'friend-request-deleted',
	DELETE_CONVERSATION_EVENT = 'delete-conversation',
	CHANNEL_MESSAGE_EVENT = 'channel-message',
	CHANNEL_MESSAGE_EDIT_EVENT = 'channel-message-edit',
	CHANNEL_MESSAGE_DELETE_EVENT = 'channel-message-delete',
	CHANNEL_MESSAGE_PIN_ADDED_EVENT = 'channel-message-pin',
	CHANNEL_MESSAGE_UNPIN_EVENT = 'channel-message-unpin',
	CHANNEL_MESSAGE_UNPIN_ALL_EVENT = 'channel-message-unpin-all',
	CHANNEL_BLOCK_USER_EVENT = 'channel-block-user',
	CHANNEL_BLOCK_FULL_USER = 'channel-block-full-user',
	CREATE_CONVERSATION_EVENT = 'create-conversation',
	CHANNEL_UPDATE_EVENT = 'channel-update',
	CHANNEL_DELETE_EVENT = 'channel-delete',

	CHANNEL_MESSAGE_REACTION_CREATE = 'channel-message-reaction-create',
	CHANNEL_MESSAGE_REACTION_UPDATE = 'channel-message-reaction-update',
	CHANNEL_MESSAGE_REACTION_DELETE = 'channel-message-reaction-delete',

	PRIVATE_MESSAGE_REACTION_CREATE = 'private-message-reaction-create',
	PRIVATE_MESSAGE_REACTION_UPDATE = 'private-message-reaction-update',
	PRIVATE_MESSAGE_REACTION_DELETE = 'private-message-reaction-delete',

	SERVER_CATEGORY_CREATE = 'server-category-create',
	SERVER_CATEGORY_UPDATE = 'server-category-update',
	SERVER_CATEGORY_DELETE = 'server-category-delete',
}
