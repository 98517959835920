import { useMutation } from 'react-query';
import { Alerter } from '../../../utils';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useAddFriendById = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (userId: number) =>
			API_CHAT.post('/api/app/friend/new', { userId }),
		onSuccess(data) {
			if (data.success) {
				Alerter.success('Friend request was successfully sended!');
			}
		},

		onError(error) {
			console.log('useAddFriendById', error);
			Alerter.error('Something went wrong when sending friend request');
		},
	});
};
