import { create } from 'zustand';
import { ICurrentUser } from '../types';

export const isAdmin: { [key: number]: boolean } = {
	1: true, //is admin
	2: false, // user
	0: false,
} as const;

interface IState {
	user: ICurrentUser | null;
	isAdmin: boolean | null;
	setUser: (user: ICurrentUser) => void;
}

export const useDefaultUserStore = create<IState>(set => ({
	user: null,
	isAdmin: null,
	setUser: user =>
		set(() => ({
			user: user,
			isAdmin: isAdmin[user.roleId],
		})),
}));
