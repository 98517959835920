import React from 'react';
import { Flex, Box, Center, Text, chakra } from '@chakra-ui/react';
import { PinIcon } from '../../../assets';
import { usePinnedMessagesPopupOpenStore } from '../../../store';
import { getTime } from '../utils';

interface IPinnedMessageNotifyProps {
	sentByDisplayName: string;
	pinnedMessageId?: number;
	date?: Date;
}

export const PinnedMessageNotify: React.FC<IPinnedMessageNotifyProps> = ({
	sentByDisplayName,
	pinnedMessageId,
	date,
}) => {
	const { onOpen } = usePinnedMessagesPopupOpenStore();

	const triggerHighlight = () => {
		const element = document.querySelector(
			`.message-${pinnedMessageId}`,
		) as HTMLElement;
		if (element) {
			element.style.animation = 'none';
			void element.offsetWidth;
			element.style.animation = 'flashAndFade 1s ease-in-out';
		}
	};

	return (
		<Flex gap="20px" p="8px 20px" bg="white" borderRadius="4px" mb="20px">
			<Center p="10px" bg="mainBg" borderRadius="4px">
				<PinIcon />
			</Center>
			<Box>
				<Text color="blue.200" fontSize="16px">
					<chakra.span fontWeight={700}>
						{sentByDisplayName}
					</chakra.span>{' '}
					pins the{' '}
					<chakra.span
						fontWeight={700}
						as="button"
						onClick={triggerHighlight}>
						message
					</chakra.span>{' '}
					to this channel.
				</Text>
				<Text color="blue.200" fontSize="16px">
					View{' '}
					<chakra.span as="button" onClick={onOpen} fontWeight={700}>
						all pinned
					</chakra.span>{' '}
					messages{' '}
					{date ? (
						<chakra.span color="blue.50" fontSize="12px">
							{getTime(date)}
						</chakra.span>
					) : null}
				</Text>
			</Box>
		</Flex>
	);
};
