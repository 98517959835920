import { Button, FormLabel, HStack, Text } from '@chakra-ui/react';
import { CalendarIcon } from '../../../assets';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import React from 'react';

interface IProps {
	isDisabled?: boolean;
	label?: string;
	errorMsg?: string;
	value?: string | null;
}

export const BaseButtonTrigger = React.forwardRef<null, IProps>(
	({ isDisabled, label, errorMsg, value, ...rest }, ref) => {
		const textColor =
			value && !isDisabled
				? 'blue.300'
				: isDisabled
				? 'textGray.200'
				: 'inactiveText';

		return (
			<Button
				ref={ref}
				{...rest}
				_disabled={{
					cursor: 'not-allowed',
				}}
				isDisabled={isDisabled}
				h={'full'}
				variant="unstyled"
				w="100%">
				{label ? (
					<FormLabel
						mr={0}
						noOfLines={1}
						mb="8px"
						fontSize="12px"
						lineHeight="18px"
						color={isDisabled ? 'textGray.200' : 'inactiveText'}
						fontWeight={500}>
						{label}
					</FormLabel>
				) : null}
				<HStack
					h="40px"
					px="16px"
					bg={isDisabled ? '#F0F0F0' : 'transparent'}
					borderRadius="10px"
					borderWidth={errorMsg ? 2 : 1}
					borderColor={
						errorMsg
							? '#FF3636'
							: isDisabled
							? colors.textGray['200']
							: '#BDCDE9'
					}
					_hover={{
						background: isDisabled ? '#F0F0F0' : '#EBF0F9',
					}}>
					<CalendarIcon
						fill={
							isDisabled
								? colors.textGray['200']
								: colors.blue['50']
						}
					/>
					<Text variant={'bodyMedium'} color={textColor}>
						{value
							? dayjs(value).format('MM/DD/YYYY')
							: 'MM/DD/YYYY'}
					</Text>
				</HStack>
				{errorMsg ? (
					<Text
						textAlign={'left'}
						fontSize={'10px'}
						lineHeight={'12px'}
						color={'#DC2D2D'}
						mt={'4px'}>
						{errorMsg}
					</Text>
				) : null}
			</Button>
		);
	},
);

BaseButtonTrigger.displayName = 'BaseButtonTrigger';
