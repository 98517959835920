import React from 'react';

export const DashboardIcon: React.FC = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect
				x="2"
				y="3.05005"
				width="5.91991"
				height="6.17817"
				rx="1"
				stroke="white"
				strokeWidth="2"
			/>
			<rect
				x="11.6797"
				y="3.05005"
				width="10.3199"
				height="6.17817"
				rx="1"
				stroke="white"
				strokeWidth="2"
			/>
			<rect
				x="2"
				y="13.0458"
				width="10.3199"
				height="8.90423"
				rx="1"
				stroke="white"
				strokeWidth="2"
			/>
			<rect
				x="16.0798"
				y="13.0458"
				width="5.91991"
				height="6.17817"
				rx="1"
				stroke="white"
				strokeWidth="2"
			/>
		</svg>
	);
};
