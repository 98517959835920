import { create } from 'zustand';

interface IState {
	markConversationId: number | null;
	setMarkConversationId: (user: number | null) => void;
	markChannelId: number | null;
	setMarkChannelId: (user: number | null) => void;
}

export const useCheckMarkAsRead = create<IState>(set => ({
	markConversationId: null,
	markChannelId: null,
	setMarkConversationId: markConversationId =>
		set(() => ({
			markConversationId,
		})),
	setMarkChannelId: markChannelId =>
		set(() => ({
			markChannelId,
		})),
}));
