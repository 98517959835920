import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ChannelProvider } from 'ably/react';
import { Roles, ChannelHeader } from './components';
import { useChatUserStore, useMembersSidebarOpenStore } from '../../../store';
import { ServerMessagesCore } from './ServerMessagesCore';
import { useDebounce } from '../../../hooks';
import { useGetChannelById } from '../queries';

export const ServerMessages: React.FC = () => {
	const { id } = useParams();
	const [search, setSearch] = useState('');

	const { user } = useChatUserStore();
	const debouncedSearch = useDebounce(search);
	const { isOpen } = useMembersSidebarOpenStore();

	const { data: channelInfo, isLoading: loadingChannelInfo } =
		useGetChannelById(Number(id), !!id);

	return (
		<ChannelProvider channelName={`private-channel:${id}`}>
			<Flex direction="column" width="100%">
				<ChannelHeader
					search={search}
					setSearch={setSearch}
					// isAdmin={Boolean(user?.isAdmin)}
				/>
				<Flex flex={1}>
					<ServerMessagesCore
						channelData={channelInfo?.value}
						id={id}
						loadingChannelInfo={loadingChannelInfo}
					/>

					<Box
						width={isOpen ? '300px' : 0}
						bg="blue.200"
						height="calc(100vh - 60px)">
						<Roles
							channelId={id}
							isPrivate={false}
							isAdmin={Boolean(user?.isAdmin)}
							debouncedSearch={debouncedSearch}
						/>
					</Box>
				</Flex>
			</Flex>
		</ChannelProvider>
	);
};
