import React, { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loader } from '../Loader/Loader';

interface IProtectedRoleAreaProps {
	isAllowed: boolean;
	redirectPath?: string;
	children?: JSX.Element;
}

const SuspenseLoader = (
	<div>
		<Loader centerProps={{ minH: '100vh' }} />
	</div>
);

export const ProtectedRoleArea: React.FC<IProtectedRoleAreaProps> = ({
	isAllowed,
	redirectPath = '/',
	children,
}) => {
	if (!isAllowed) {
		return <Navigate to={redirectPath} replace={true} />;
	}

	return children ? (
		<Suspense fallback={SuspenseLoader}>{children}</Suspense>
	) : (
		<Outlet />
	);
};
