import React from 'react';
import {
	Flex,
	FlexProps,
	IconButton,
	useColorModeValue,
} from '@chakra-ui/react';
import { CtrlSmallLogo, MenuIcon, S8SmallLogo } from '../../../assets';

interface MobileProps extends FlexProps {
	onOpen: VoidFunction;
}
export const MobileNav: React.FC<MobileProps> = ({ onOpen, ...rest }) => {
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue('blue.100', 'blue.100')}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			justifyContent="space-between"
			{...rest}>
			<Flex alignItems={'center'}>
				{window.location.href.includes('cruz-ctrl') ? (
					<CtrlSmallLogo />
				) : (
					<S8SmallLogo />
				)}
			</Flex>
			<IconButton
				onClick={onOpen}
				aria-label="Menu toggle"
				color="white"
				bg="transparent">
				<MenuIcon />
			</IconButton>
		</Flex>
	);
};
