export const months: ReadonlyArray<string> = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const generateExcludedDates = (
	datesToExclude: Array<number>,
): Array<Date> => {
	if (!datesToExclude.length) {
		return [];
	}
	const excludedDates: Array<Date> = [];
	const today = new Date();
	const year = today.getFullYear();

	// Loop through each month
	for (let month = 0; month < 12; month++) {
		// datesToExclude.forEach(date =>
		// 	excludedDates.push(new Date(year, month, date)),
		// );
		excludedDates.push(new Date(year, month, 29));
		excludedDates.push(new Date(year, month, 30));
		if (
			month === 0 ||
			month === 2 ||
			month === 4 ||
			month === 6 ||
			month === 7 ||
			month === 9 ||
			month === 11
		) {
			excludedDates.push(new Date(year, month, 31));
		}
	}

	return excludedDates;
};
