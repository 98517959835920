import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { deleteToken, getMessaging } from 'firebase/messaging';
import { userManager } from '../utils';
import { byPassInterceptionRegExp } from './by-pass-interception-routes';

export default function interceptor(baseURL: string) {
	const axiosInstance = axios.create();

	axiosInstance.defaults.baseURL = baseURL;

	axiosInstance.interceptors.request.use(async req => {
		const user = await userManager.getUser();

		const token = user?.access_token;

		if (!user) {
			const settings = userManager.settings;
			try {
				await userManager.signinRedirect(settings);
			} catch (error) {
				Sentry.captureException(error, {
					level: 'warning',
				});

				console.log('sign in redirect error: ', error);
			}
		}

		if (token) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${token}`;
			} else {
				delete req.headers['Authorization'];
			}
		}

		return req;
	});

	axiosInstance.interceptors.response.use(
		undefined,
		async (err: AxiosError) => {
			// console.log(err, "intercept err");
			const { response, config } = err;
			// if (status === 401) {
			// 	const settings = userManager.settings;
			// 	await userManager.signinRedirect(settings);
			//
			// 	const token = user?.access_token;
			//
			// 	if (token) {
			// 		axios.defaults.headers.common[
			// 			'Authorization'
			// 		] = `Bearer ${token}`;
			// 	} else {
			// 		delete axios.defaults.headers.common['Authorization'];
			// 	}
			// 	return axios(originalRequest); response.status === 403 ||
			// }
			if (response && response.status === 403) {
				window.location.replace('/forbidden');
			}
			if (response && response.status === 401) {
				if (
					config?.url &&
					!byPassInterceptionRegExp.test(config?.url)
				) {
					try {
						await deleteToken(getMessaging());
					} catch (error) {
						console.log(
							error,
							'deleteToken(getMessaging()) interceptor',
						);
					}
					// localStorage.removeItem('notificationPermissionShown');
					await userManager.signoutRedirect({
						extraQueryParams: {
							returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
						},
					});
				}
			}
			return Promise.reject(err);
		},
	);
	return axiosInstance;
}
