/* eslint-disable indent */

import { StylesConfig } from 'react-select';
import { OptionType } from '../../types';

export const stylesConfig = (size: 'md' | 'lg'): StylesConfig<OptionType> => ({
	control: (provided, { selectProps, isFocused, isDisabled }) => {
		const isInvalid = Boolean(selectProps['aria-invalid']);

		const border = selectProps.isDisabled
			? '1px solid #8E8E8E'
			: isFocused
			? '1px solid #0F1B49'
			: isInvalid
			? '1px solid #FF3636'
			: '1px solid #BDCDE9';

		const colour = (selectProps.value as OptionType)?.colour;

		const bg = colour ? colour : isDisabled ? '#F0F0F0' : 'white';

		return {
			...provided,
			border: border,
			borderRadius: '10px',
			fontSize: '14px',
			background: bg,
			fontFamily: 'Inter',
			height: size === 'md' ? '40px' : '48px',
			fontWeight: 500,
			lineHeight: '21px',
			transition: 'all 0.1s ease-in',
			width: '100%',
			boxShadow:
				isInvalid && !isFocused ? '0px 0px 0px 1px #FF3636' : 'none',
			'&:hover': {
				border: `1px solid ${isFocused ? '#0F1B49' : '#BDCDE9'}`,
				cursor: 'pointer',
				background: colour ? colour : '#EBF0F9',
			},
		};
	},
	placeholder: (defaultStyles, state) => ({
		...defaultStyles,
		fontWeight: 500,
		color: state.isDisabled ? '#8E8E8E' : '#7E788F',
	}),
	option: (styles, state) => {
		const backgroundColor = state.isDisabled
			? '#F0F0F0'
			: state.isSelected
			? '#BDCDE9'
			: 'white';

		return {
			...styles,
			padding: '10px',
			borderRadius: '5px',
			lineHeight: '21px',
			fontSize: '14px',
			color: state.isDisabled ? '#8E8E8E' : '#10162C',
			fontWeight: '500',
			fontFamily: 'inherit',
			backgroundColor,
			cursor: state.isDisabled ? 'not-allowed' : 'pointer',
			'&:hover': {
				backgroundColor: state.isDisabled ? '#F0F0F0' : '#1C2959',
				color: state.isDisabled ? '#8E8E8E' : 'white',
			},
		};
	},
	clearIndicator: base => {
		return {
			...base,
			'&:hover': {
				color: '#0F1B49',
			},
			svg: {
				height: '23px',
				width: '23px',
			},
		};
	},
	menuList: base => {
		return {
			...base,
			paddingLeft: '5px',
			paddingRight: '5px',
			'::-webkit-scrollbar': {
				width: '5px',
			},
			'::-webkit-scrollbar-track': {
				background: 'white',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#4A5991CC',
				opacity: 0.1,
				borderRadius: '10px',
				minHeight: '60px',
			},
		};
	},
	indicatorSeparator: props => {
		return {
			display: 'none',
			...props,
		};
	},
	singleValue: (props, { isDisabled, selectProps }) => {
		const colour = (selectProps.value as OptionType)?.colour;

		return {
			...props,
			color: colour ? 'white' : isDisabled ? '#8E8E8E' : '#10162C',
			fontWeight: '500',
		};
	},
	noOptionsMessage: props => ({
		...props,
		color: '#7E788F',
		fontSize: '14px',
		fontWeight: '500',
	}),
	menuPortal: props => ({
		...props,
		zIndex: 999,
	}),
	dropdownIndicator(base, { isDisabled, selectProps }) {
		const colour = (selectProps.value as OptionType)?.colour;
		return {
			...base,
			color: colour ? 'white' : isDisabled ? '#8E8E8E' : '#0F1B49',
			'&:hover': {
				color: colour ? 'white' : '#0F1B49',
			},
		};
	},
	valueContainer: base => ({
		...base,
		padding: '2px 0px 2px 16px',
	}),
	menu: props => ({
		...props,
		zIndex: 999,
	}),
});
