import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const CreateUserSchema = yup.object().shape({
	nickName: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.trim()
		.matches(/^\S*$/, 'Nickname should not contain spaces'),
	displayName: yup.string().required(ErrorMessages.REQUIRED),
	aboutMe: yup.string(),
	avatar: yup.mixed<File>(),
});

export type CreateUserValuesType = yup.InferType<typeof CreateUserSchema>;
export const createUserResolver = yupResolver(CreateUserSchema);
