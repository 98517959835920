import React from 'react';
import { InputMask } from '@react-input/mask';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputProps,
} from '@chakra-ui/react';
import { colors } from '../../../../theme';

interface IInputPhone extends InputProps {
	label: string;
	errorMsg?: string;
}

export const InputPhone = React.forwardRef<null, IInputPhone>(
	({ label, errorMsg, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg}>
				<FormLabel
					mb="8px"
					fontSize="12px"
					lineHeight="18px"
					color={'inactiveText'}
					fontWeight={500}>
					{label}
				</FormLabel>
				<Input
					as={InputMask}
					mask="___-___-____"
					replacement={{ _: /\d/ }}
					ref={ref}
					w={'full'}
					_invalid={{
						boxShadow: '0px 0px 0px 1px #FF3636',
						borderColor: '#FF3636',
					}}
					_placeholder={{
						color: '#7E788F',
					}}
					height="40px"
					fontSize={'14px'}
					lineHeight={'21px'}
					fontWeight={500}
					bg={'white'}
					color={'blue.300'}
					borderRadius={'10px'}
					borderColor={colors.dropBlue}
					_hover={{
						background: '#EBF0F9',
					}}
					_focus={{
						borderColor: colors.blue['200'],
						boxShadow: 'none',
					}}
					{...rest}
				/>
				<FormErrorMessage
					fontSize={'10px'}
					lineHeight={'12px'}
					color={'#DC2D2D'}
					mt={'4px'}>
					{errorMsg}
				</FormErrorMessage>
			</FormControl>
		);
	},
);

InputPhone.displayName = 'InputPhone';
