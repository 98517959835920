export const Announcement2Icon = () => (
	<svg
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.5 16.5C20.433 16.5 22 13.5899 22 10C22 6.41015 20.433 3.5 18.5 3.5M18.5 16.5C16.567 16.5 15 13.5899 15 10C15 6.41015 16.567 3.5 18.5 3.5M18.5 16.5L5.44354 14.1261C4.51605 13.9575 4.05231 13.8731 3.67733 13.689C2.91447 13.3142 2.34636 12.6335 2.11414 11.8159C2 11.414 2 10.9427 2 10C2 9.0573 2 8.58595 2.11414 8.18407C2.34636 7.36649 2.91447 6.68577 3.67733 6.31105C4.05231 6.12685 4.51605 6.04254 5.44354 5.8739L18.5 3.5M5 14.5L5.39386 20.014C5.43126 20.5376 5.44996 20.7995 5.56387 20.9979C5.66417 21.1726 5.81489 21.3129 5.99629 21.4005C6.20232 21.5 6.46481 21.5 6.98979 21.5H8.7722C9.37234 21.5 9.67242 21.5 9.89451 21.3803C10.0897 21.2751 10.2443 21.1081 10.3342 20.9055C10.4365 20.6749 10.4135 20.3757 10.3675 19.7773L10 15"
			stroke="#4A5991"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
