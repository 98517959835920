import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const onboardingSchema = yup.object().shape({
	soonLookingId: yup.number().required(ErrorMessages.REQUIRED),
	realEstateExperienceId: yup.number().required(ErrorMessages.REQUIRED),
	cashAvailableToInvestId: yup.number().required(ErrorMessages.REQUIRED),
});

export type OnboardingForm = yup.InferType<typeof onboardingSchema>;
export const onboardingResolver = yupResolver(onboardingSchema);
