import React, { useCallback, useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CustomAsyncSelect, CustomReactSelect } from '../../components';
import { InputText, Loader } from '../../../../components';
import {
	defaultPropertyValues,
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
	PropertyStatusOptions,
	PropertyTypeOptions,
} from '../../constants';
import { MangeImages } from './components/ManageImages';
import { ManageUnit } from './components/ManageUnit';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { PropertyFormType, propertyResolver } from '../../validations';
import { SingleValue } from 'react-select';
import { LoadOptionsType } from '../../types';
import debounce from 'lodash.debounce';
import {
	getPlaceById,
	getPlaces,
	useCreateProperty,
	useGetBusinessEntitySelector,
	useGetPropertyExtendInfo,
	useUpdateProperty,
} from '../../queries';
import {
	transformAmount,
	transformEntityToOptions,
	transformEntityValueToOption,
	transformGeocodeValueToOption,
} from '../../utils';
import { Alerter } from '../../../../utils';
import { MapWithStreetView } from './components/MapWithStreetView';
import { usePropertyModalStore } from '../../store';
import { getPropertyValues } from './utils';

interface IPropertyModal {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const PropertyModal: React.FC<IPropertyModal> = ({
	isOpen,
	onClose,
}) => {
	const { propertyId } = usePropertyModalStore();

	const propertyInfo = useGetPropertyExtendInfo(Number(propertyId));

	const methods = useForm<PropertyFormType>({
		resolver: propertyResolver,
		defaultValues: defaultPropertyValues,
		values:
			propertyInfo.data?.value && propertyId
				? getPropertyValues(propertyInfo.data.value)
				: defaultPropertyValues,
	});

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		control,
		watch,
		reset,
	} = methods;

	const [typeWatch, location, units] = watch(['type', 'location', 'units']);

	const modalTitle = propertyId ? 'Update Property' : 'Add New Property';

	const { data: entityData, isLoading: entityLoading } =
		useGetBusinessEntitySelector();

	const createProperty = useCreateProperty();

	const updateProperty = useUpdateProperty();

	const onReset = useCallback(() => {
		reset(defaultPropertyValues);
		onClose();
	}, []);

	const onSubmit = (values: PropertyFormType) => {
		const formData = new FormData();
		formData.append('Name', values.name);
		formData.append('Type', String(values.type));
		formData.append('Label', String(values.label));
		formData.append('OwnerId', String(values.ownerId));
		formData.append('Location.Address', values.location.address);
		formData.append(
			'Location.Longitude',
			String(values.location.longitude),
		);
		formData.append('Location.Latitude', String(values.location.latitude));
		values.units?.forEach((it, index) => {
			if (it.id) {
				formData.append(`Units[${index}].id`, String(it.id));
			}
			formData.append(`Units[${index}].name`, it.name);
			formData.append(
				`Units[${index}].squareFeet`,
				String(it.squareFeet),
			);
			formData.append(`Units[${index}].bedrooms`, String(it.bedrooms));
			formData.append(`Units[${index}].bathrooms`, String(it.bathrooms));
			formData.append(
				`Units[${index}].deposit`,
				String(transformAmount(Number(it.deposit))),
			);
			formData.append(
				`Units[${index}].monthlyRent`,
				String(transformAmount(Number(it.monthlyRent))),
			);
		});
		if (values.images?.length !== 0) {
			values.images?.forEach((it, index) => {
				formData.append(`Images[${index}].image`, it.image);
				formData.append(
					`Images[${index}].isPinned`,
					String(it.isPinned),
				);
			});
		}
		if (propertyId) {
			if (values.imageIdsToDelete.length) {
				values.imageIdsToDelete.forEach(it => {
					formData.append('ImageIdsToDelete', String(it));
				});
			}
			if (values.pinnedImageId) {
				if (!values.images?.some(it => it.isPinned)) {
					formData.append(
						'PinnedImageId',
						String(values.pinnedImageId),
					);
				}
			} else {
				if (
					!values.images?.length ||
					!values.images?.some(it => it.isPinned)
				) {
					formData.append('PinnedImageId', String(1));
				}
			}

			updateProperty.mutate(
				{
					data: formData,
					propertyId,
				},
				{
					onSuccess: data => {
						if (data.success) {
							onReset();
						}
					},
				},
			);
		} else {
			createProperty.mutate(formData, {
				onSuccess: data => {
					if (data.success) {
						onReset();
					}
				},
			});
		}
	};

	const loadOptions: LoadOptionsType = useCallback(
		debounce((inputValue, callback) => {
			getPlaces(inputValue).then(resp => callback(resp || []));
		}, 500),
		[],
	);

	useEffect(() => {
		if (!units?.length && typeWatch && isSubmitting) {
			Alerter.error('Min one unit required');
		}
	}, [units, isSubmitting, typeWatch]);

	return (
		<Modal
			returnFocusOnClose={false}
			closeOnOverlayClick={false}
			scrollBehavior={'inside'}
			isCentered={true}
			isOpen={isOpen}
			onClose={onReset}>
			<ModalOverlay />
			<ModalContent
				bg={'mainBg'}
				maxW={'1050px'}
				w={{ base: '90vw', lg: '100%' }}>
				<ModalHeader
					px={'16px'}
					fontSize={'20px'}
					fontWeight={'700'}
					color={'blue.200'}
					lineHeight={'24px'}>
					{modalTitle}
				</ModalHeader>
				<ModalCloseButton color={'blue.200'} />
				<ModalBody p={'16px'}>
					{propertyInfo.isLoading ? (
						<Loader centerHeight={'400px'} />
					) : (
						<FormProvider {...methods}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<VStack spacing={'16px'} align={'stretch'}>
									<Box bg={'white'} borderRadius={'8px'}>
										{/*	HEADER*/}
										<Box
											borderBottom={'1px solid #F1F4F9'}
											p={'16px'}>
											<Text
												fontWeight={600}
												color={'blue.100'}>
												Property Details
											</Text>
										</Box>
										{/*	CONTENT*/}
										<Box p={'16px'}>
											<Flex
												align={{
													base: '',
													lg: 'center',
												}}
												gap={'16px'}
												flexDirection={{
													base: 'column',
													lg: 'row',
												}}>
												<MangeImages
													data={
														propertyInfo.data?.value
															.images || []
													}
												/>
												<VStack
													spacing={'24px'}
													align={'stretch'}
													flex={1}>
													<Flex
														flexDirection={{
															base: 'column',
															md: 'row',
														}}
														gap={'16px'}
														align={'stretch'}>
														<Controller
															render={({
																field: {
																	value,
																	onChange,
																},
																fieldState: {
																	error,
																},
															}) => (
																<CustomAsyncSelect
																	onChange={async (
																		newValue: SingleValue<any>,
																	) => {
																		if (
																			newValue?.value
																		) {
																			const response =
																				await getPlaceById(
																					newValue.value,
																				);
																			onChange(
																				{
																					address:
																						newValue.label,
																					...response,
																				},
																			);
																		}
																	}}
																	value={transformGeocodeValueToOption(
																		value,
																	)}
																	loadOptions={
																		loadOptions
																	}
																	label={
																		'Address'
																	}
																	placeholder={
																		'Search Address'
																	}
																	errMsg={
																		error?.message
																	}
																/>
															)}
															name={'location'}
															control={control}
														/>
														<InputText
															{...register(
																'name',
															)}
															errorMsg={
																errors.name
																	?.message
															}
															formLabelProps={
																inputLabelStyles
															}
															{...inputStyles}
															errorMessageProps={
																errorMsgStyles
															}
															placeholder={
																'Enter Property Name'
															}
															label={'Name'}
														/>
													</Flex>
													<Flex
														flexDirection={{
															base: 'column',
															md: 'row',
														}}
														gap={'16px'}
														align={'stretch'}>
														<Controller
															render={({
																fieldState: {
																	error,
																},
																field: {
																	onChange,
																	value,
																},
															}) => (
																<CustomReactSelect
																	errMsg={
																		error?.message
																	}
																	onChange={(
																		newValue: SingleValue<any>,
																	) => {
																		if (
																			newValue
																		) {
																			onChange(
																				newValue?.value,
																			);
																		}
																	}}
																	value={PropertyTypeOptions.find(
																		it =>
																			Number(
																				it.value,
																			) ===
																			value,
																	)}
																	label={
																		'Property Type'
																	}
																	placeholder={
																		'Select Property Type'
																	}
																	options={
																		PropertyTypeOptions
																	}
																	isSearchable={
																		false
																	}
																/>
															)}
															name={'type'}
															control={control}
														/>
														<Controller
															render={({
																fieldState: {
																	error,
																},
																field: {
																	onChange,
																	value,
																},
															}) => (
																<CustomReactSelect
																	isDisabled={
																		entityLoading
																	}
																	onChange={(
																		newValue: SingleValue<any>,
																	) => {
																		if (
																			newValue
																		) {
																			onChange(
																				newValue?.value,
																			);
																		}
																	}}
																	errMsg={
																		error?.message
																	}
																	value={transformEntityValueToOption(
																		value,
																		entityData
																			?.value
																			.items,
																	)}
																	label={
																		'Business Entity'
																	}
																	placeholder={
																		'Select Business Entity'
																	}
																	options={transformEntityToOptions(
																		entityData
																			?.value
																			.items,
																	)}
																/>
															)}
															name={'ownerId'}
															control={control}
														/>
														<Controller
															render={({
																fieldState: {
																	error,
																},
																field: {
																	onChange,
																	value,
																},
															}) => (
																<CustomReactSelect
																	errMsg={
																		error?.message
																	}
																	onChange={(
																		newValue: SingleValue<any>,
																	) => {
																		if (
																			newValue
																		) {
																			onChange(
																				newValue?.value,
																			);
																		}
																	}}
																	value={PropertyStatusOptions.find(
																		it =>
																			Number(
																				it.value,
																			) ===
																			value,
																	)}
																	label={
																		'Property Status'
																	}
																	placeholder={
																		'Select Status'
																	}
																	options={
																		PropertyStatusOptions
																	}
																	isSearchable={
																		false
																	}
																/>
															)}
															name={'label'}
															control={control}
														/>
													</Flex>
												</VStack>
											</Flex>
											{/*GOOGLE MAP*/}
											<MapWithStreetView
												lat={location?.latitude}
												lng={location?.longitude}
											/>
										</Box>
									</Box>
									{/*	UNITS*/}
									{typeWatch ? <ManageUnit /> : null}
									<Button
										isLoading={
											createProperty.isLoading ||
											updateProperty.isLoading
										}
										isDisabled={
											createProperty.isLoading ||
											updateProperty.isLoading
										}
										type={'submit'}
										maxW={'200px'}
										w={'full'}
										variant={'cruzctrl-solid'}
										alignSelf={'flex-end'}>
										Submit
									</Button>
								</VStack>
							</form>
						</FormProvider>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
