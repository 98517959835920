import React, { PropsWithChildren } from 'react';
import { InvoiceModal, PropertyModal, TenantModal } from '../../modals';
import {
	useInvoiceModalStore,
	usePropertyModalStore,
	useTenantModalStore,
} from '../../store';

export const LayoutWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	const { isOpen, onClose } = usePropertyModalStore();
	const { isOpen: isOpenTenant, onClose: onCloseTenant } =
		useTenantModalStore();

	const { isOpen: isOpenInvoiceModal, onClose: onCloseInvoiceModal } =
		useInvoiceModalStore();

	return (
		<>
			{children}
			<PropertyModal isOpen={isOpen} onClose={onClose} />
			<TenantModal isOpen={isOpenTenant} onClose={onCloseTenant} />
			<InvoiceModal
				isOpen={isOpenInvoiceModal}
				onClose={onCloseInvoiceModal}
			/>
		</>
	);
};
