import React from 'react';
import { BaseIconType } from '../../types';

export const CtrlLogo: React.FC<BaseIconType> = ({
	width = 134,
	height = 32,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 134 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.789307" width="32" height="32" rx="8" fill="#08BB4B" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1705 8.60306L23.9845 15.419C24.3128 15.6729 24.7837 15.6112 25.0362 15.2811C25.2888 14.9511 25.2274 14.4777 24.899 14.2238L15.861 7.23454C15.463 6.92674 14.9102 6.9214 14.5064 7.22145L5.09358 14.2151C4.76047 14.4626 4.69002 14.9347 4.93621 15.2696C5.18241 15.6044 5.65202 15.6753 5.98513 15.4278L15.1705 8.60306Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.0926 24.9999C28.0926 23.9589 27.9003 22.5511 27.5269 21.5893C27.1534 20.6275 26.606 19.7536 25.916 19.0175C25.2259 18.2813 24.4067 17.6974 23.5051 17.299C22.6035 16.9007 21.6371 16.6956 20.6612 16.6956C19.6853 16.6956 18.719 16.9007 17.8174 17.299C16.9158 17.6974 16.0966 18.2813 15.4065 19.0175C14.7164 19.7536 14.169 20.6275 13.7956 21.5893C13.4221 22.5511 13.2299 23.9589 13.2299 24.9999H12.9103C12.702 24.9999 12.5324 24.831 12.5409 24.6229C12.5821 23.613 12.7886 22.2406 13.1519 21.3049C13.5604 20.2529 14.1591 19.2971 14.9138 18.4919C15.6686 17.6868 16.5646 17.0481 17.5508 16.6124C18.5369 16.1767 19.5938 15.9524 20.6612 15.9524C21.7286 15.9524 22.7856 16.1767 23.7717 16.6124C24.7578 17.0481 25.6539 17.6868 26.4086 18.4919C27.1634 19.2971 27.7621 20.2529 28.1706 21.3049C28.5339 22.2406 28.7404 23.613 28.7816 24.6229C28.7901 24.831 28.6204 24.9999 28.4122 24.9999H28.0926Z"
				fill="white"
			/>
			<path
				d="M6.86254 9.92074V12.9366H8.75583C8.96294 12.9366 9.13083 12.7678 9.13083 12.5596V9.92074H9.51193C9.71903 9.92074 9.88693 9.75196 9.88693 9.54376V9.16678C9.88693 8.95857 9.71903 8.78979 9.51193 8.78979H6.48144C6.27434 8.78979 6.10645 8.95857 6.10645 9.16677V9.54376C6.10645 9.75196 6.27434 9.92074 6.48144 9.92074H6.86254Z"
				fill="white"
			/>
			<path
				d="M21.6064 23.4923C21.6064 24.1169 21.0986 24.6232 20.4723 24.6232C19.8459 24.6232 19.3381 24.1169 19.3381 23.4923C19.3381 22.8677 19.8459 22.3613 20.4723 22.3613C21.0986 22.3613 21.6064 22.8677 21.6064 23.4923Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.1797 24.6231C15.1797 24.8313 15.0109 25.0001 14.8027 25.0001H13.2884C13.0802 25.0001 12.9114 24.8313 12.9114 24.6231C12.9114 24.4149 13.0802 24.2461 13.2884 24.2461H14.8027C15.0109 24.2461 15.1797 24.4149 15.1797 24.6231Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.7893 24.6231C28.7893 24.8313 28.6205 25.0001 28.4123 25.0001H26.898C26.6898 25.0001 26.521 24.8313 26.521 24.6231C26.521 24.4149 26.6898 24.2461 26.898 24.2461H28.4123C28.6205 24.2461 28.7893 24.4149 28.7893 24.6231Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.4723 16.3294C20.6811 16.3294 20.8503 16.4987 20.8503 16.7075V18.2133C20.8503 18.4221 20.6811 18.5913 20.4723 18.5913C20.2635 18.5913 20.0942 18.4221 20.0942 18.2133V16.7075C20.0942 16.4987 20.2635 16.3294 20.4723 16.3294Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9136 17.5789C25.0766 17.7089 25.103 17.9463 24.9725 18.1089L24.0281 19.2861C23.8976 19.4487 23.6597 19.4752 23.4966 19.3451C23.3336 19.2151 23.3072 18.9777 23.4377 18.8151L24.3821 17.6379C24.5126 17.4753 24.7506 17.4488 24.9136 17.5789Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.1595 23.7039L16.2141 17.9114L16.8396 17.4878L20.7849 23.2803L20.1595 23.7039Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.5129 20.6817C27.6142 20.8638 27.5485 21.0931 27.3662 21.194L26.0428 21.926C25.8605 22.0268 25.6306 21.9609 25.5293 21.7789C25.428 21.5968 25.4937 21.3675 25.676 21.2666L26.9994 20.5346C27.1817 20.4338 27.4116 20.4996 27.5129 20.6817Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.7933 21.7787C15.692 21.9608 15.4621 22.0266 15.2798 21.9258L13.9565 21.1937C13.7742 21.0929 13.7085 20.8635 13.8098 20.6815C13.9111 20.4994 14.1409 20.4336 14.3233 20.5344L15.6466 21.2665C15.8289 21.3673 15.8946 21.5967 15.7933 21.7787Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.05163 25.0001C6.84284 25.0001 6.67358 24.8313 6.67358 24.6231V16.1436C6.67358 16.0235 6.73096 15.9106 6.82807 15.8396L14.9509 9.89964C15.0865 9.80053 15.2713 9.80255 15.4047 9.90458L21.2187 14.3539C21.545 14.6036 21.2709 15.2245 20.86 15.2069C20.7315 15.2014 20.6022 15.1986 20.4723 15.1986C15.5658 15.1986 11.5882 19.1649 11.5882 24.0576C11.5882 24.2339 11.5934 24.4089 11.6035 24.5826C11.6166 24.8061 11.4423 25.0001 11.2178 25.0001H7.05163Z"
				fill="white"
			/>
			<path
				d="M124.81 23.273V8.72754H127.005V21.3838H133.596V23.273H124.81Z"
				fill="white"
			/>
			<path
				d="M111.92 23.273V8.72754H117.104C118.231 8.72754 119.166 8.92167 119.91 9.30993C120.658 9.69818 121.217 10.2356 121.586 10.9221C121.955 11.604 122.14 12.3923 122.14 13.2872C122.14 14.1773 121.953 14.961 121.579 15.6381C121.21 16.3104 120.651 16.8336 119.903 17.2077C119.159 17.5817 118.224 17.7687 117.097 17.7687H113.17V15.8795H116.898C117.609 15.8795 118.186 15.7777 118.631 15.5741C119.081 15.3705 119.41 15.0746 119.619 14.6863C119.827 14.2981 119.931 13.8317 119.931 13.2872C119.931 12.738 119.825 12.2621 119.612 11.8596C119.403 11.4572 119.074 11.1494 118.624 10.9363C118.179 10.7185 117.595 10.6096 116.87 10.6096H114.114V23.273H111.92ZM119.1 16.7105L122.694 23.273H120.194L116.671 16.7105H119.1Z"
				fill="white"
			/>
			<path
				d="M98.1752 10.6167V8.72754H109.432V10.6167H104.894V23.273H102.706V10.6167H98.1752Z"
				fill="white"
			/>
			<path
				d="M96.2664 13.4573H94.0505C93.9653 12.9838 93.8066 12.5671 93.5746 12.2073C93.3426 11.8475 93.0585 11.5421 92.7224 11.2911C92.3862 11.0402 92.0098 10.8508 91.5931 10.7229C91.1812 10.5951 90.7432 10.5312 90.2792 10.5312C89.4411 10.5312 88.6906 10.7419 88.0278 11.1633C87.3696 11.5847 86.8488 12.2026 86.4653 13.017C86.0865 13.8314 85.8971 14.8257 85.8971 15.9999C85.8971 17.1836 86.0865 18.1827 86.4653 18.9971C86.8488 19.8115 87.372 20.427 88.0349 20.8437C88.6977 21.2603 89.4435 21.4687 90.2721 21.4687C90.7314 21.4687 91.167 21.4071 91.5789 21.284C91.9956 21.1562 92.372 20.9691 92.7082 20.7229C93.0443 20.4767 93.3284 20.176 93.5604 19.8209C93.7972 19.4611 93.9605 19.0492 94.0505 18.5851L96.2664 18.5922C96.148 19.3072 95.9184 19.9653 95.5775 20.5667C95.2413 21.1633 94.8081 21.6794 94.2778 22.115C93.7522 22.5458 93.1509 22.8796 92.4738 23.1164C91.7967 23.3531 91.0581 23.4715 90.2579 23.4715C88.9984 23.4715 87.8762 23.1732 86.8914 22.5766C85.9065 21.9753 85.13 21.1159 84.5618 19.9985C83.9984 18.8811 83.7167 17.5482 83.7167 15.9999C83.7167 14.4469 84.0008 13.114 84.5689 12.0013C85.1371 10.8839 85.9136 10.0269 86.8985 9.43031C87.8833 8.82898 89.0031 8.52832 90.2579 8.52832C91.0296 8.52832 91.7493 8.63959 92.417 8.86213C93.0893 9.07993 93.693 9.4019 94.228 9.82804C94.7631 10.2494 95.2058 10.7655 95.5562 11.3763C95.9065 11.9824 96.1433 12.676 96.2664 13.4573Z"
				fill="white"
			/>
			<path
				d="M72.7526 23.2724V21.8164L78.6617 14.3093V14.2099H72.9443V12.3633H81.3108V13.9116L75.629 21.3264V21.4258H81.5097V23.2724H72.7526Z"
				fill="white"
			/>
			<path
				d="M67.9958 18.7482V12.3633H70.1265V23.2724H68.0385V21.3832H67.9248C67.6739 21.9656 67.2714 22.4509 66.7174 22.8391C66.1682 23.2227 65.484 23.4144 64.6649 23.4144C63.9641 23.4144 63.3438 23.2605 62.8041 22.9528C62.269 22.6403 61.8476 22.1786 61.5399 21.5678C61.2368 20.957 61.0853 20.2018 61.0853 19.3022V12.3633H63.2089V19.0465C63.2089 19.7899 63.4149 20.3817 63.8268 20.8221C64.2387 21.2624 64.7738 21.4826 65.4319 21.4826C65.8296 21.4826 66.225 21.3832 66.618 21.1843C67.0157 20.9854 67.3448 20.6848 67.6052 20.2823C67.8704 19.8799 68.0006 19.3685 67.9958 18.7482Z"
				fill="white"
			/>
			<path
				d="M53.4095 23.2726V12.3635H55.4621V14.0965H55.5757C55.7746 13.5094 56.125 13.0477 56.6269 12.7116C57.1335 12.3707 57.7064 12.2002 58.3456 12.2002C58.4782 12.2002 58.6345 12.2049 58.8144 12.2144C58.999 12.2239 59.1434 12.2357 59.2476 12.2499V14.2812C59.1624 14.2575 59.0109 14.2314 58.7931 14.203C58.5753 14.1699 58.3575 14.1533 58.1397 14.1533C57.6378 14.1533 57.1903 14.2599 56.7973 14.4729C56.4091 14.6813 56.1013 14.9724 55.874 15.3465C55.6468 15.7158 55.5331 16.1372 55.5331 16.6107V23.2726H53.4095Z"
				fill="white"
			/>
			<path
				d="M50.9539 13.4573H48.738C48.6528 12.9838 48.4941 12.5671 48.2621 12.2073C48.0301 11.8475 47.746 11.5421 47.4099 11.2911C47.0737 11.0402 46.6973 10.8508 46.2806 10.7229C45.8687 10.5951 45.4307 10.5312 44.9667 10.5312C44.1286 10.5312 43.3781 10.7419 42.7153 11.1633C42.0571 11.5847 41.5363 12.2026 41.1528 13.017C40.774 13.8314 40.5846 14.8257 40.5846 15.9999C40.5846 17.1836 40.774 18.1827 41.1528 18.9971C41.5363 19.8115 42.0595 20.427 42.7224 20.8437C43.3852 21.2603 44.131 21.4687 44.9596 21.4687C45.4189 21.4687 45.8545 21.4071 46.2664 21.284C46.6831 21.1562 47.0595 20.9691 47.3957 20.7229C47.7318 20.4767 48.0159 20.176 48.2479 19.8209C48.4847 19.4611 48.648 19.0492 48.738 18.5851L50.9539 18.5922C50.8355 19.3072 50.6059 19.9653 50.265 20.5667C49.9288 21.1633 49.4956 21.6794 48.9653 22.115C48.4397 22.5458 47.8384 22.8796 47.1613 23.1164C46.4842 23.3531 45.7456 23.4715 44.9454 23.4715C43.6859 23.4715 42.5637 23.1732 41.5789 22.5766C40.594 21.9753 39.8175 21.1159 39.2493 19.9985C38.6859 18.8811 38.4042 17.5482 38.4042 15.9999C38.4042 14.4469 38.6883 13.114 39.2564 12.0013C39.8246 10.8839 40.6011 10.0269 41.586 9.43031C42.5708 8.82898 43.6906 8.52832 44.9454 8.52832C45.7171 8.52832 46.4368 8.63959 47.1045 8.86213C47.7768 9.07993 48.3805 9.4019 48.9155 9.82804C49.4506 10.2494 49.8933 10.7655 50.2437 11.3763C50.594 11.9824 50.8308 12.676 50.9539 13.4573Z"
				fill="white"
			/>
		</svg>
	);
};
