interface IData {
	invoiceType: string;
	propertyName: string;
	unitName: string;
	invoiceDate: string;
	invoiceDueDate: string;
}

export const generateDescription = (data: IData) => {
	const { invoiceType, invoiceDate, invoiceDueDate, unitName, propertyName } =
		data;
	return `${invoiceType} - ${propertyName} - ${unitName} - (${invoiceDate}-${invoiceDueDate})`;
};
