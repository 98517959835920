import { CardFieldEnum } from '../types';

export const CARD_COLORS = [
	'#5FBD58',
	'#ECCE2E',
	'#FF9F2C',
	'#F35050',
	'#9F8FEC',
	'#31479B',
	'#559DFC',
	'#47CE9A',
	'#E874B9',
	'#344562',
	'#B3BAC5',
	'#991965',
];

export const FIELD_TYPE_OPTIONS = [
	{
		value: CardFieldEnum.Checkbox,
		label: 'Checkbox',
	},
	{
		value: CardFieldEnum.Date,
		label: 'Date',
	},
	{
		value: CardFieldEnum.Dropdown,
		label: 'Dropdown',
	},
	{
		value: CardFieldEnum.Text,
		label: 'Text',
	},
];

export const defaultDropdownForm = {
	name: '',
	options: [
		{
			id: 0,
			colour: '#B3BAC5',
			name: '',
		},
	],
};
