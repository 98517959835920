import React from 'react';
import {
	Box,
	Flex,
	IconButton,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
} from '@chakra-ui/react';
import {
	MaximizeWindowIcon,
	PauseIcon,
	PlayIcon,
	VolumeIcon,
} from '../../assets';
import { formatVideDuration } from '../../utils';
import { PlayerButtonsSizeItem } from './types';
import { DownloadIcon } from '@chakra-ui/icons';

interface IControlsProps {
	controlsHided: boolean;
	playing: boolean;
	volumeIsOpen: boolean;
	currentTime: number;
	duration: number;
	volume: number;
	handleSeek: (seconds: number) => void;
	togglePlay: VoidFunction;
	volumeOnClose: VoidFunction;
	volumeOnOpen: VoidFunction;
	toggleFullscreen: VoidFunction;
	setVolume: (volume: number) => void;
	onDownload?: VoidFunction;
	downloadLoading?: boolean;
	playerButtonsSizeProps: PlayerButtonsSizeItem;
}

export const Controls: React.FC<IControlsProps> = ({
	controlsHided,
	togglePlay,
	playing,
	currentTime,
	duration,
	handleSeek,
	volumeIsOpen,
	volumeOnClose,
	volumeOnOpen,
	volume,
	setVolume,
	toggleFullscreen,
	onDownload,
	downloadLoading,
	playerButtonsSizeProps,
}) => {
	const {
		bottomPlayPauseButtonSize,
		volumeIconSize,
		mainPlayPauseIconSize,
		fullscreenIconSize,
		downloadIconSize,
		bottomPlayPauseIconSize,
		currentTimeText,
		volumeTrackWidth,
		volumeTrackPadding,
		volumeButtonSize,
		mainPlayPauseButtonSize,
		fullscreenButtonSize,
		downloadButtonSize,
		bottomBarHeight,
	} = playerButtonsSizeProps;
	return (
		<>
			<Flex
				position="absolute"
				zIndex={15}
				h="100%"
				w="100%"
				bg="transparent"
				top={0}
				bottom={0}
				right={0}
				left={0}
				justifyContent="center"
				alignItems="center"
				opacity={controlsHided ? 0 : 1}
				transition=".7s"
				onClick={togglePlay}>
				<IconButton
					w={mainPlayPauseButtonSize}
					h={mainPlayPauseButtonSize}
					minW={mainPlayPauseButtonSize}
					icon={
						playing ? (
							<PauseIcon
								width={mainPlayPauseIconSize}
								height={mainPlayPauseIconSize}
							/>
						) : (
							<PlayIcon
								isFilled={true}
								fillColor="black"
								color="black"
								width={mainPlayPauseIconSize}
								height={mainPlayPauseIconSize}
							/>
						)
					}
					aria-label={''}
				/>
			</Flex>
			{onDownload ? (
				<Box
					position="absolute"
					top="10px"
					right="10px"
					zIndex={16}
					opacity={controlsHided ? 0 : 1}>
					<IconButton
						aria-label="download"
						w={downloadButtonSize}
						minW={downloadButtonSize}
						h={downloadButtonSize}
						borderRadius="4px"
						isLoading={downloadLoading}
						onClick={onDownload}
						icon={
							<DownloadIcon
								width={downloadIconSize}
								height={downloadIconSize}
							/>
						}
					/>
				</Box>
			) : null}
			<Flex
				h="70px"
				w="100%"
				position="absolute"
				bottom={0}
				justifyContent="center"
				alignItems="center"
				zIndex={16}
				opacity={controlsHided ? 0 : 1}>
				<Flex
					w="90%"
					h={bottomBarHeight}
					bg="white"
					borderRadius="10px"
					justifyContent="space-around"
					alignItems="center">
					<Flex alignItems="center">
						<IconButton
							w={bottomPlayPauseButtonSize}
							h={bottomPlayPauseButtonSize}
							minW={bottomPlayPauseButtonSize}
							bg="transparent"
							_hover={{}}
							icon={
								playing ? (
									<PauseIcon
										width={bottomPlayPauseIconSize}
										height={bottomPlayPauseIconSize}
									/>
								) : (
									<PlayIcon
										isFilled={true}
										fillColor="black"
										color="black"
										width={bottomPlayPauseIconSize}
										height={bottomPlayPauseIconSize}
									/>
								)
							}
							onClick={togglePlay}
							aria-label={''}
						/>
						<Text
							color="blue.300"
							fontSize={currentTimeText}
							mr="10px">
							{formatVideDuration(Number(currentTime.toFixed(0)))}
						</Text>
					</Flex>
					<Slider
						flex={1}
						value={currentTime}
						max={duration || 0}
						onChange={handleSeek}
						focusThumbOnChange={false}
						aria-label="video-seek">
						<SliderTrack bg="mainBg">
							<SliderFilledTrack bg="blue.300" />
						</SliderTrack>
						<SliderThumb />
					</Slider>
					<Flex>
						<Popover isOpen={volumeIsOpen}>
							<PopoverTrigger>
								<IconButton
									w={volumeButtonSize}
									h={volumeButtonSize}
									minW={volumeButtonSize}
									bg="transparent"
									onClick={
										volumeIsOpen
											? volumeOnClose
											: volumeOnOpen
									}
									_hover={{}}
									icon={
										<VolumeIcon
											width={volumeIconSize}
											height={volumeIconSize}
										/>
									}
									aria-label={''}
								/>
							</PopoverTrigger>
							<PopoverContent
								p={volumeTrackPadding}
								width={volumeTrackWidth}>
								<Slider
									value={volume}
									onChange={setVolume}
									step={0.01}
									max={1}>
									<SliderTrack bg="mainBg">
										<SliderFilledTrack bg="blue.300" />
									</SliderTrack>
									<SliderThumb />
								</Slider>
							</PopoverContent>
						</Popover>
						{/* <IconButton
									w="24px"
									h="24px"
									bg="transparent"
									_hover={{}}
									onClick={togglePlay}
									aria-label={''}
								/> */}
						<IconButton
							w={fullscreenButtonSize}
							h={fullscreenButtonSize}
							minW={fullscreenButtonSize}
							bg="transparent"
							_hover={{}}
							icon={
								<MaximizeWindowIcon
									width={fullscreenIconSize}
									height={fullscreenIconSize}
								/>
							}
							onClick={toggleFullscreen}
							aria-label={''}
						/>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
