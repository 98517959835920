import React from 'react';

export const CameraIcon: React.FC = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.33317 4.16667H5.83317L7.49984 2.5H12.4998L14.1665 4.16667H16.6665C17.1085 4.16667 17.5325 4.34226 17.845 4.65482C18.1576 4.96738 18.3332 5.39131 18.3332 5.83333V15.8333C18.3332 16.2754 18.1576 16.6993 17.845 17.0118C17.5325 17.3244 17.1085 17.5 16.6665 17.5H3.33317C2.89114 17.5 2.46722 17.3244 2.15466 17.0118C1.8421 16.6993 1.6665 16.2754 1.6665 15.8333V5.83333C1.6665 5.39131 1.8421 4.96738 2.15466 4.65482C2.46722 4.34226 2.89114 4.16667 3.33317 4.16667ZM9.99984 6.66667C8.89477 6.66667 7.83496 7.10565 7.05356 7.88705C6.27216 8.66846 5.83317 9.72826 5.83317 10.8333C5.83317 11.9384 6.27216 12.9982 7.05356 13.7796C7.83496 14.561 8.89477 15 9.99984 15C11.1049 15 12.1647 14.561 12.9461 13.7796C13.7275 12.9982 14.1665 11.9384 14.1665 10.8333C14.1665 9.72826 13.7275 8.66846 12.9461 7.88705C12.1647 7.10565 11.1049 6.66667 9.99984 6.66667ZM9.99984 8.33333C10.6629 8.33333 11.2988 8.59672 11.7676 9.06557C12.2364 9.53441 12.4998 10.1703 12.4998 10.8333C12.4998 11.4964 12.2364 12.1323 11.7676 12.6011C11.2988 13.0699 10.6629 13.3333 9.99984 13.3333C9.3368 13.3333 8.70091 13.0699 8.23207 12.6011C7.76323 12.1323 7.49984 11.4964 7.49984 10.8333C7.49984 10.1703 7.76323 9.53441 8.23207 9.06557C8.70091 8.59672 9.3368 8.33333 9.99984 8.33333Z"
				fill="#0F1B49"
			/>
		</svg>
	);
};
