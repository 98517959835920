import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';
import { InvoiceStatusEnum } from '../types';

const selectSchema = yup
	.object()
	.shape({
		value: yup.number().required(ErrorMessages.REQUIRED),
		label: yup.string().required(ErrorMessages.REQUIRED),
	})
	.nullable()
	.test('custom', ErrorMessages.REQUIRED, function (value) {
		return value !== null && !isNaN(value?.value);
	});

const tenantSchema = yup
	.object()
	.shape({
		value: yup.number().required(ErrorMessages.REQUIRED),
		label: yup.string().required(ErrorMessages.REQUIRED),
		propertyName: yup.string().required(ErrorMessages.REQUIRED),
		unitName: yup.string().required(ErrorMessages.REQUIRED),
		isStripeConnected: yup.boolean(),
	})
	.nullable()
	.test('custom', ErrorMessages.REQUIRED, function (value) {
		return value !== null && !isNaN(value?.value);
	});

const InvoiceSchema = yup.object().shape({
	tenant: tenantSchema,
	invoiceDate: yup.string().required(ErrorMessages.REQUIRED),
	invoiceDueDate: yup.string().required(ErrorMessages.REQUIRED),
	invoiceType: selectSchema,
	invoiceStatus: selectSchema,
	paymentMethod: selectSchema,
	paidDate: yup.string().when('invoiceStatus', {
		is: (invoiceStatus: null | { value: number; label: string }) =>
			invoiceStatus !== null &&
			invoiceStatus.value === InvoiceStatusEnum.Paid,
		then: schema => schema.required(ErrorMessages.REQUIRED),
		otherwise: schema => schema,
	}),
	invoiceItems: yup.array().of(
		yup.object().shape({
			id: yup.number().optional(),
			description: yup.string().required(ErrorMessages.REQUIRED),
			amount: yup
				.number()
				.nullable()
				.test('custom', ErrorMessages.REQUIRED, function (value) {
					return !!value;
				})
				.typeError(ErrorMessages.REQUIRED),
		}),
	),
	isSendToEmail: yup.boolean(),
});

export type InvoiceFormType = yup.InferType<typeof InvoiceSchema>;

export const invoiceResolver = yupResolver(InvoiceSchema);
