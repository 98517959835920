import { useQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ServerChannelData } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetChannelById = (id?: number, enabled?: boolean) => {
	return useQuery<ApiResponse<ServerChannelData | null>>({
		queryKey: [MessengerQueryKeys.GET_CHANNEL_BY_ID, id],
		queryFn: () => API_CHAT.get(`/api/app/channel/${id}/info`, {}),
		refetchOnWindowFocus: false,
		enabled,
	});
};
