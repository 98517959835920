import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';

interface IProps {
	unBlock: VoidFunction;
	isLoading: boolean;
	meBlocked?: boolean;
}

export const BlockMessageComponent: React.FC<IProps> = ({
	unBlock,
	isLoading,
	meBlocked,
}) => {
	return (
		<Flex
			bg={'blue.50'}
			mt={'auto'}
			h={'52px'}
			px={'16px'}
			align={'center'}
			justify={'space-between'}
			borderRadius={'4px'}>
			<Text color={'white'}>
				{meBlocked
					? 'You cannot send messages because you have been blocked'
					: 'You cannot send messages to a user you have blocked.'}
			</Text>
			{!meBlocked ? (
				<Button
					isLoading={isLoading}
					colorScheme={'primaryButton'}
					onClick={unBlock}
					size={'sm'}>
					Unblock
				</Button>
			) : null}
		</Flex>
	);
};
