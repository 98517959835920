// /* eslint-disable indent */
// /* eslint-disable no-mixed-spaces-and-tabs */
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useChannel } from 'ably/react';
import { Loader } from '../../../../components';
import { MessageWithAddons, DateDivider } from '../../messages-components';
import {
	DeleteMessageConfirmModal,
	PinMessageConfirmModal,
} from '../../modals';
import {
	EditMessageDisplayData,
	ReplyMessage,
	ServerChannelData,
	ChatType,
	MessageReactionType,
} from '../../types';
import { StartOfServerChat } from './StartOfServerChat';
import { useChatUserStore, useSuspendModalStore } from '../../../../store';
import { extractMentionsIds } from '../../utils';
import {
	useCreateChannelMessageReaction,
	useDeleteChannelMessageReaction,
	useDeleteUserChannelMessageByAdmin,
	useGetChannelMessages,
	useUpdateChannelMessageReaction,
	useUpdateChannelsBanStatus,
	usePinMessage,
} from '../../queries';
import { deleteChannelMessage, editChannelMessage } from '../../helpers';
import { DEFAULT_LAST_READ_TIME } from '../../constants';
import { useUpdateReactionsList } from '../../hooks';
import { MessengerQueryKeys } from '../../queries/query-keys';
import { useHandleServerMessagesSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry } from '../../../../utils';

interface IServerMessagesListProps {
	id?: string;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	replyMessage: ReplyMessage | null;
	scrollRef: React.RefObject<HTMLSpanElement>;
	topScrollRef: React.RefObject<HTMLSpanElement>;
	channelData?: ServerChannelData | null;
	lastReadTime?: string;
	disableMenu?: boolean;
	updateLastReadTime: (time: string) => void;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	onMarkAsRead: (id: number) => Promise<void>;
}

export const ServerMessagesList: React.FC<IServerMessagesListProps> = ({
	id,
	onSetReplyMessage,
	scrollRef,
	topScrollRef,
	channelData,
	lastReadTime,
	replyMessage,
	disableMenu,
	updateLastReadTime,
	updateUnreadCount,
	onMarkAsRead,
}) => {
	const [messageToDelete, setMessageToDelete] =
		useState<EditMessageDisplayData | null>(null);
	const [messageToPin, setMessageToPin] =
		useState<EditMessageDisplayData | null>(null);
	const { onOpen } = useSuspendModalStore();
	const { user } = useChatUserStore();
	const { mutate } = useUpdateChannelsBanStatus();
	const { pinMessage } = usePinMessage();
	const { mutateAsync: createChannelMessageReaction } =
		useCreateChannelMessageReaction();
	const { mutateAsync: updateChannelMessageReaction } =
		useUpdateChannelMessageReaction();
	const { mutateAsync: removeChannelMessageReaction } =
		useDeleteChannelMessageReaction();
	const { mutateAsync: deleteMessageByAdmin } =
		useDeleteUserChannelMessageByAdmin();
	const { mutate: updateMessageReactionsList } = useUpdateReactionsList(
		MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW,
	);

	const shouldCallMarkAsReadRef = useRef(true);

	const {
		newMessages,
		handleDirectMessageSocketEvent,
		setHistoryMessages,
		historyMessages,
	} = useHandleServerMessagesSocketEvents({
		id,
		updateUnreadCount,
		updateLastReadTime,
		scrollRef,
		shouldCallMarkAsReadRef,
	});

	useChannel(
		{
			channelName: `private-channel:${id}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				console.log(err, 'private channel connection err');
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handleDirectMessageSocketEvent(message);
		},
	);

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
		useGetChannelMessages(id ? +id : null, newMessages.length);

	const combinedMessages = useMemo(
		() => [...newMessages, ...historyMessages],
		[newMessages, historyMessages],
	);

	useEffect(() => {
		if (id && data?.pages.length && !isFetching) {
			const flatMessages =
				data?.pages?.flatMap(page => page?.messages || []) || [];
			setHistoryMessages(flatMessages);
			updateLastReadTime(
				data?.pages?.[data?.pages.length - 1].userLastReadDateTime ||
					DEFAULT_LAST_READ_TIME,
			);
			updateUnreadCount(
				data?.pages?.[data?.pages.length - 1].unreadCount || 0,
			);
		}
	}, [data, id]);

	useEffect(() => {
		return () => {
			if (user?.userId && id && shouldCallMarkAsReadRef.current) {
				onMarkAsRead(+id);
			}
		};
	}, [id, user]);

	const onEditMessage = async (messageId: number, newText: string) => {
		try {
			if (!channelData) {
				return;
			}
			const mentionsIds = extractMentionsIds(newText);

			await editChannelMessage({
				text: newText,
				messageId,
				mentionId: mentionsIds || [],
				channelId: channelData.id,
			});
		} catch (error) {
			console.error('Error editing message: ', error);
		}
	};

	const onDeleteMessage = async (messageId: number, byAdmin?: boolean) => {
		try {
			if (!channelData) {
				return;
			}
			if (byAdmin) {
				await deleteMessageByAdmin(messageId);
				return;
			}
			await deleteChannelMessage(messageId);
		} catch (error) {
			console.error('Error deleting message: ', error);
		}
	};

	const onPinMessage = async (messageId: number) => {
		try {
			if (!channelData || !id) {
				return;
			}

			const res = await pinMessage(messageId, +id);
			if (!res.success) {
				return;
			}
			await onMarkAsRead(+id);
		} catch (error) {
			console.error('Error pinning message: ', error);
		}
	};

	const onSuspendUser = (userId: number) => {
		onOpen(userId, false);
	};

	const unSuspendUser = (userId: number) => {
		mutate({ userId, isBanned: false, deleteMessage: false });
	};

	const onSuspendAndPurgeMessagesUser = (userId: number) => {
		onOpen(userId, true);
	};

	const onReact = async (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => {
		try {
			if (!reactedEmojiId) {
				const res = await createChannelMessageReaction({
					channelMessageId: messageId,
					reactionEmojiId: emojiId,
				});
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'add');
				return;
			}
			if (emojiId === reactedEmojiId) {
				const res = await removeChannelMessageReaction(messageId);
				if (!res.success) {
					return;
				}
				updateMessageReactionsList(emojiId, messageId, 'remove');
				return;
			}
			await updateChannelMessageReaction({
				channelMessageId: messageId,
				reactionEmojiId: emojiId,
			});
		} catch (error) {
			console.log('onReact error: ', error);
		}
	};

	return (
		<>
			<InfiniteScroll
				dataLength={combinedMessages.length}
				refreshFunction={fetchNextPage}
				next={fetchNextPage}
				hasMore={!!hasNextPage}
				height={`calc(100vh - ${replyMessage ? 230 : 160}px)`}
				scrollThreshold={500}
				loader={null}
				inverse={true}
				style={{
					paddingTop: '40px',
					display: 'flex',
					flexDirection: 'column-reverse',
				}}>
				{isFetching && !isFetchingNextPage ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<span ref={scrollRef}></span>
						{combinedMessages?.map((elem, index, arr) => (
							<MessageWithAddons
								key={elem.id}
								messageType={elem.type}
								arr={arr}
								data={elem}
								index={index}
								onEditMessage={onEditMessage}
								onSetDeleteMessage={setMessageToDelete}
								onSetReplyMessage={onSetReplyMessage}
								onSetPinMessage={setMessageToPin}
								allowFullAccess={
									user?.userId === elem.senderId ||
									user?.isAdmin
								}
								includeEdit={user?.userId === elem.senderId}
								lastReadTime={
									lastReadTime === DEFAULT_LAST_READ_TIME
										? undefined
										: lastReadTime
								}
								includePin={user?.isAdmin}
								hideNewDividerForNewUser={true}
								enableMentions={true}
								disableMenu={disableMenu}
								onSuspendUser={onSuspendUser}
								onSuspendAndPurgeMessagesUser={
									onSuspendAndPurgeMessagesUser
								}
								unSuspendUser={unSuspendUser}
								chatType={ChatType.CHANNEL}
								onReact={onReact}
								includeUserInfoPopup={true}
							/>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<span ref={topScrollRef}></span>
						<StartOfServerChat channelData={channelData} />
					</>
				)}
			</InfiniteScroll>

			<DeleteMessageConfirmModal
				isOpen={!!messageToDelete}
				onClose={() => setMessageToDelete(null)}
				messageToDelete={messageToDelete}
				onDeleteMessage={onDeleteMessage}
				chatType={ChatType.CHANNEL}
				currentUserId={user?.userId}
			/>
			<PinMessageConfirmModal
				isOpen={!!messageToPin}
				onClose={() => setMessageToPin(null)}
				message={messageToPin}
				action={onPinMessage}
				variant="add"
				chatType={ChatType.CHANNEL}
			/>
		</>
	);
};
