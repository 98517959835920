import { useQueryClient } from 'react-query';
import { ApiResponse } from '../../../types';
import { MessengerQueryKeys } from '../queries/query-keys';
import { UserByIdData } from '../types';

export const useUpdateUserInfoById = () => {
	const queryClient = useQueryClient();

	const mutate = (userId: number, updatedData: Partial<UserByIdData>) =>
		queryClient.setQueryData<ApiResponse<UserByIdData> | undefined>(
			[MessengerQueryKeys.GET_USER_BY_ID, userId],
			oldData => {
				if (oldData?.value) {
					return {
						...oldData,
						value: {
							...oldData?.value,
							...updatedData,
						},
					};
				}
				return oldData;
			},
		);

	return { mutate };
};
