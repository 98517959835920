import React, { ReactNode, useMemo } from 'react';
import { useChatUserStore } from '../../../../../store';
import { IChatUser } from '../../../../../types';
import { useGetUserById } from '../../../queries';
import { RoleInfoPopup } from '../../../Server/components/roles/RoleInfoPopup';
import { ChatType, UserByIdData } from '../../../types';

interface IUserInfoPopupWrapperProps {
	userInfoId: number | null;
	receiver?: UserByIdData | null;
	chatType: ChatType;
	children: ReactNode;
}

export const UserInfoPopupWrapper: React.FC<IUserInfoPopupWrapperProps> = ({
	userInfoId,
	receiver,
	chatType,
	children,
}) => {
	const { user } = useChatUserStore();

	const isNotCurrentUserOrReceiver =
		Boolean(userInfoId) && !receiver && user?.userId !== userInfoId;
	const isReceiver =
		!!userInfoId && !!receiver && user?.userId !== userInfoId;
	const isCurrentUser = user?.userId === userInfoId;

	const { data, isFetched } = useGetUserById(
		userInfoId,
		isNotCurrentUserOrReceiver,
	);
	const isDataFetched = useMemo(
		() => (!receiver && user?.userId !== userInfoId ? isFetched : true),
		[receiver, user?.userId, userInfoId, isFetched],
	);

	const getDataByUserType = (
		isNotCurrentUserOrReceiver: boolean,
		isReceiver: boolean,
		isCurrentUser: boolean,
		fetchedData?: UserByIdData,
		receiverData?: UserByIdData | null,
		currentUserData?: IChatUser | null,
	) => {
		switch (true) {
			case isNotCurrentUserOrReceiver:
				return {
					about: fetchedData?.about || '',
					userStatus: fetchedData?.userStatus || 2,
					displayName: fetchedData?.displayName || '',
					nickName: fetchedData?.nickName || '',
					avatarUrl: fetchedData?.avatarUrl || null,
					isAdmin: !!fetchedData?.isAdmin,
					isBannedInChannels: !!fetchedData?.isBannedInChannel,
					isFriends: !!fetchedData?.isFriend,
					isBlocked: !!fetchedData?.isBlocked,
					isBlockedMe: !!fetchedData?.isBlockingMe,
					requestSentByMe: !!fetchedData?.isFriendRequestSent,
					email: fetchedData?.email || null,
					requestSentToMe: false,
				};
			case isReceiver:
				return {
					about: receiverData?.about || '',
					userStatus: receiverData?.userStatus || 2,
					displayName: receiverData?.displayName || '',
					nickName: receiverData?.nickName || '',
					avatarUrl: receiverData?.avatarUrl || null,
					isAdmin: !!receiverData?.isAdmin,
					isBannedInChannels: !!receiverData?.isBannedInChannel,
					isFriends: !!receiverData?.isFriend,
					isBlocked: !!receiverData?.isBlocked,
					isBlockedMe: !!receiverData?.isBlockingMe,
					requestSentByMe: !!receiverData?.isFriendRequestSent,
					email: receiverData?.email || null,
					requestSentToMe: false,
				};

			case isCurrentUser:
				return {
					about: currentUserData?.about || '',
					userStatus: 1,
					displayName: currentUserData?.displayName || '',
					nickName: currentUserData?.nickName || '',
					avatarUrl: currentUserData?.avatarUrl || null,
					isAdmin: !!currentUserData?.isAdmin,
					isBannedInChannels: false,
					isFriends: false,
					isBlocked: false,
					isBlockedMe: false,
					requestSentByMe: false,
					email: currentUserData?.email || null,
					requestSentToMe: false,
				};

			default:
				return null;
		}
	};

	const dataByUserType = useMemo(
		() =>
			getDataByUserType(
				isNotCurrentUserOrReceiver,
				isReceiver,
				isCurrentUser,
				data?.value,
				receiver,
				user,
			),
		[userInfoId, receiver, user?.userId, data?.value, isFetched],
	);

	return (
		<RoleInfoPopup
			isCurrentUser={isCurrentUser}
			userId={userInfoId || 0}
			about={dataByUserType?.about || ''}
			displayName={dataByUserType?.displayName || ''}
			avatarStoragePath={dataByUserType?.avatarUrl || null}
			isAdmin={!!dataByUserType?.isAdmin}
			isBannedInChannel={!!dataByUserType?.isBannedInChannels}
			isCurrentUserAdmin={user?.isAdmin}
			isFriends={!!dataByUserType?.isFriends}
			isBlocked={!!dataByUserType?.isBlocked}
			isBlockedMe={!!dataByUserType?.isBlockedMe}
			requestSentToMe={!!dataByUserType?.requestSentToMe}
			nickName={dataByUserType?.nickName || ''}
			requestSentByMe={!!dataByUserType?.requestSentByMe}
			isLoaded={isDataFetched}
			userStatus={dataByUserType?.userStatus || 2}
			email={dataByUserType?.email || null}
			popupSide="right"
			chatType={chatType}>
			{children}
		</RoleInfoPopup>
	);
};
