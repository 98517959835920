import { ApiResponseEmpty } from '../../../types';
import { API_CHAT } from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useMutation, useQueryClient } from 'react-query';
import { MessengerQueryKeys } from './query-keys';

export const useEditChannel = () => {
	const queryClient = useQueryClient();

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{
			channelId: number;
			name: string;
			topic: string;
		}
	>({
		mutationKey: [MessengerQueryKeys.EDIT_CHANNEL],
		mutationFn: payload => API_CHAT.put('/api/admin/channel', payload),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message || 'Error while editing channel',
				);
				return;
			}
			Alerter.success(data.messages?.[0]?.message || 'Channel edited!');
			// queryClient.invalidateQueries(MessengerQueryKeys.GET_SERVER_CATEGORIES)
		},
		onError(error) {
			console.log(error, 'edit-channel error');
		},
	});
};

// queryClient.setQueryData(MessengerQueryKeys.GET_SERVER_CATEGORIES, (oldData: Updater<ApiResponse<{
// 	serverCategories: ServerCategoryData[]
// }>, any>) => {
//
// 	const changedCategories = oldData?.value?.serverCategories?.filter((category) => {
// 		return category.channels.map((channel) => {
// 			if (channel.id === payload.channelId) {
// 				return {
// 					...channel,
// 					payload.name,
// 					payload.topic,
// 				};
// 			}
// 		});
// 	});
// 	console.log(changedCategories);
//
// });
