import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';

export const useGetPandaDocDocumentQuery = () => {
	return useQuery<ApiResponse<string>>(
		['get-panda-doc-document'],
		() => API_IDENTITY.post('api/user/agreement/generate', {}),
		{ refetchOnWindowFocus: false },
	);
};
