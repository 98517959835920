import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const editChannelSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	topic: yup.string().required(ErrorMessages.REQUIRED),
	// isPrivate: yup.boolean().required(ErrorMessages.REQUIRED),
});

export type EditChannelForm = yup.InferType<typeof editChannelSchema>;
export const editChannelResolver = yupResolver(editChannelSchema);
