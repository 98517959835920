import { create } from 'zustand';
import { UserChannelData } from '../pages/Messenger/types';

interface IState {
	allChannelUsersCount: number;
	allChannelUsers: UserChannelData[];
	setAllChannelUsers: (users: UserChannelData[]) => void;
	setAllChannelUsersCount: (count: number) => void;
	resetAllChannelUsers: () => void;
	updateAllChannelUsers: (
		id: number,
		updates: Partial<UserChannelData>,
	) => void;
	addToAllChannelUsers: (users: UserChannelData[]) => void;
}

export const useServerUsersAllList = create<IState>(set => ({
	allChannelUsersCount: 0,
	allChannelUsers: [],
	setAllChannelUsers: users => set({ allChannelUsers: users }),
	addToAllChannelUsers: users =>
		set(state => ({
			allChannelUsers: [...state.allChannelUsers, ...users],
		})),
	setAllChannelUsersCount: count => set({ allChannelUsersCount: count }),
	resetAllChannelUsers: () => set({ allChannelUsers: [] }),
	updateAllChannelUsers: (id, updatedData) =>
		set(state => ({
			allChannelUsers: state.allChannelUsers.map(user =>
				user.userId === id ? { ...user, ...updatedData } : user,
			),
		})),
}));
