import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { LeftPaginationArrowIcon } from '../../../../../../assets';

interface IExpandedButton {
	onAction: VoidFunction;
	isExpanded: boolean;
}

export const ExpandedButton: React.FC<IExpandedButton> = ({
	onAction,
	isExpanded,
}) => {
	return (
		<IconButton
			zIndex={5}
			pos={'absolute'}
			right={'-15px'}
			top={'15'}
			onClick={onAction}
			size={'sm'}
			bg={'inactiveBlue'}
			color={'white'}
			_hover={{
				bg: 'blue.200',
			}}
			_active={{
				bg: 'blue.200',
			}}
			aria-label={'menu toggle'}
			icon={
				<Box
					transition={'transform .2s'}
					transform={[`rotate(${!isExpanded ? '180deg' : '0deg'})`]}>
					<LeftPaginationArrowIcon
						color={'currentcolor'}
						width={20}
						height={20}
					/>
				</Box>
			}
		/>
	);
};
