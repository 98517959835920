import { useEffect } from 'react';
import { API_CHAT } from '../../../../api/api.base';
import { useServerUsersOnlineList } from '../../../../store';
import { ApiResponse } from '../../../../types';
import {
	UserChannelData,
	UserPresenceItem,
	UserPresenceStatus,
} from '../../types';

export type PollingResult = {
	[key: string]: UserPresenceStatus;
};

export const usePollingServerUsers = (
	userIds: number[],
	enableFetch: boolean,
) => {
	const {
		onlineChannelUsers,
		setOnlineChannelUsers,
		setOnlineChannelUsersCount,
	} = useServerUsersOnlineList();

	useEffect(() => {
		let interval: NodeJS.Timeout;
		if (enableFetch) {
			interval = setInterval(async () => {
				try {
					const res: ApiResponse<{
						existUsersStatus: UserPresenceItem[];
						newOnlineUsers: UserChannelData[];
					}> = await API_CHAT.post(
						'/api/app/users/sidebar/online-status',
						userIds,
					);
					if (!res.success || !res.value) {
						return;
					}

					const mappedData =
						res.value.existUsersStatus.reduce(
							(result: PollingResult, obj: UserPresenceItem) => {
								result[`${obj.userId}`] = obj.status;
								return result;
							},
							{},
						) || {};
					const filteredFromOffline = onlineChannelUsers.filter(
						elem =>
							mappedData[elem.userId] ===
							UserPresenceStatus.ONLINE,
					);
					const newOnlineUsers = [
						...res.value.newOnlineUsers,
						...filteredFromOffline,
					];
					setOnlineChannelUsers(newOnlineUsers);
					setOnlineChannelUsersCount(newOnlineUsers?.length || 0);
				} catch (error) {
					console.log('polling server sidebar error:', error);
				}
			}, 45 * 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [userIds, enableFetch, onlineChannelUsers?.length]);
};
