import { PropertyStatus, PropertyType } from '../types';

export const PropertyTypeOptions = [
	{
		value: PropertyType.House,
		label: 'House',
	},
	{
		value: PropertyType.Apartment,
		label: 'Apartment',
	},
];

export const PropertyStatusOptions = [
	{
		value: PropertyStatus.MoveInReady,
		label: 'Move-in Ready',
	},
	{
		value: PropertyStatus.UnderConstruction,
		label: 'Under Construction',
	},
];

export const appendProperty = {
	name: '',
	squareFeet: '',
	bedrooms: '',
	bathrooms: '',
	monthlyRent: '',
	deposit: '',
};

export const defaultPropertyValues = {
	name: '',
	type: 0,
	label: 0,
	ownerId: 0,
	units: [],
	location: {
		address: '',
		longitude: 0,
		latitude: 0,
	},
	images: [],
	imageIdsToDelete: [],
	pinnedImageId: undefined,
};
