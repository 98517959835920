import { useCallback, useState } from 'react';
import { Message } from 'ably';
import { SOCKET_EVENTS } from '../../constants';
import {
	FriendRequestData,
	NewFriendAddedEventData,
	FriendInRequestEventData,
	UserPresenceStatus,
} from '../../types';
import { useChatUserStore } from '../../../../store';

export const useHandlePendingTabSocketEvents = () => {
	const [incoming, setIncoming] = useState<FriendRequestData[]>([]);
	const [outgoing, setOutgoing] = useState<FriendRequestData[]>([]);

	const { user } = useChatUserStore();

	const handlePendingTabSocketEvents = useCallback((event: Message) => {
		switch (event.name) {
			case SOCKET_EVENTS.NEW_FRIEND_ADDED_EVENT:
				{
					const newFriendData: {
						Value: NewFriendAddedEventData;
					} = JSON.parse(event.data);

					setIncoming(prev =>
						prev.filter(
							item =>
								item.senderId !== newFriendData.Value.SenderId,
						),
					);
					setOutgoing(prev =>
						prev.filter(
							item =>
								item.senderId !== newFriendData.Value.SenderId,
						),
					);
				}
				break;
			case SOCKET_EVENTS.NEW_FRIEND_REQUEST_IN_EVENT:
				{
					const incomingRequest: {
						Value: FriendInRequestEventData;
					} = JSON.parse(event.data);
					const {
						FriendRequestId,
						UserId,
						DisplayName,
						AvatarUrl,
						UserStatus,
						IsAdmin,
						Email,
					} = incomingRequest.Value;
					const payload: FriendRequestData = {
						receiverAvatarUrl: user?.avatarUrl || null,
						receiverDisplayName: user?.displayName || '',
						receiverIsAdmin: user?.isAdmin || false,
						receiverId: user?.userId || 0,
						receiverUserStatus: UserPresenceStatus.ONLINE,
						receiverEmail: user?.email || null,
						requestId: FriendRequestId,
						senderAvatarUrl: AvatarUrl,
						senderDisplayName: DisplayName,
						senderUserStatus: UserStatus,
						senderId: UserId,
						senderIsAdmin: IsAdmin,
						senderEmail: Email || null,
					};
					if (payload.senderId) {
						setIncoming([...incoming, { ...payload }]);
					}
				}
				break;
			case SOCKET_EVENTS.FRIEND_REQUEST_DELETED_EVENT:
				{
					const deletedRequest: {
						Value: { FriendRequestId: number };
					} = JSON.parse(event.data);
					setOutgoing(prev =>
						prev.filter(
							req =>
								req.requestId !==
								deletedRequest.Value.FriendRequestId,
						),
					);
					setIncoming(prev =>
						prev.filter(
							elem =>
								elem.requestId !==
								deletedRequest.Value.FriendRequestId,
						),
					);
				}
				break;
			case SOCKET_EVENTS.FRIEND_REQUEST_DISMISS_EVENT:
				{
					const friendsListData: {
						Value: { SenderUserId: number };
					} = JSON.parse(event.data);
					setIncoming(prev =>
						prev.filter(
							item =>
								item.senderId !==
								friendsListData.Value.SenderUserId,
						),
					);
				}
				break;

			default:
				break;
		}
	}, []);

	return {
		handlePendingTabSocketEvents,
		incoming,
		setIncoming,
		outgoing,
		setOutgoing,
	};
};
