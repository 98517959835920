import React from 'react';
import { ListItem, Text, Avatar, chakra, HStack } from '@chakra-ui/react';
// @ts-ignore
import { getSubstringIndex } from './utils';

interface ISuggestionProps {
	id: string;
	focused: boolean;
	ignoreAccents: boolean;
	index: number;
	query: string;
	suggestion: {
		display: string;
		id: string;
		nickName: string;
		photoURL?: string;
	};
	onClick: VoidFunction;
	onMouseEnter: VoidFunction;
}

export const Suggestion: React.FC<ISuggestionProps> = ({
	id,
	focused,
	ignoreAccents,
	index,
	onClick,
	onMouseEnter,
	query,
	suggestion,
}) => {
	const rest = { onClick, onMouseEnter };

	const renderContent = () => {
		const display = getDisplay();
		const highlightedDisplay = renderHighlightedDisplay(display);

		return highlightedDisplay;
	};

	const getDisplay = () => {
		if (typeof suggestion === 'string') {
			return suggestion;
		}

		const { id, display } = suggestion;

		if (id === undefined || !display) {
			return id;
		}

		return display;
	};

	const renderHighlightedDisplay = (display: string) => {
		const i = getSubstringIndex(display, query, ignoreAccents);

		if (i === -1) {
			return <span>{display}</span>;
		}

		return (
			<Text>
				{display.substring(0, i)}
				<b>{display.substring(i, i + query.length)}</b>
				{display.substring(i + query.length)}
			</Text>
		);
	};

	return (
		<ListItem
			id={id}
			role="option"
			aria-selected={focused}
			padding="5px 20px"
			borderRadius="4px"
			mb="5px"
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			backgroundColor={focused ? 'dropBlue' : 'mainBg'}
			cursor="pointer"
			color={focused ? 'white' : 'black'}
			{...rest}>
			<HStack spacing="10px">
				<Avatar boxSize="30px" src={suggestion.photoURL || ''} />
				{renderContent()}
			</HStack>
			<Text fontSize="14px" fontWeight={700}>
				<chakra.span color="blue.100">
					{suggestion.nickName}
				</chakra.span>
			</Text>
		</ListItem>
	);
};
