import { create } from 'zustand';

interface IState {
	showPrompt: boolean;
	setShowNotificationsPrompt: (value: boolean) => void;
	showSettingsPrompt: boolean;
	setShowSettingsPrompt: (value: boolean) => void;
	token: string | null;
	setToken: (value: string | null) => void;
}

export const useNotificationsPermissionsStore = create<IState>(set => ({
	showPrompt: false,
	showSettingsPrompt: false,
	token: null,
	setShowNotificationsPrompt: val =>
		set(() => ({
			showPrompt: val,
		})),
	setShowSettingsPrompt: val =>
		set(() => ({
			showSettingsPrompt: val,
		})),
	setToken: val =>
		set(() => ({
			token: val,
		})),
}));
