import React, { useRef } from 'react';
import {
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Box,
	useDisclosure,
	useOutsideClick,
} from '@chakra-ui/react';
import { HappyFaceIcon } from '../../../assets';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

interface IEmojiPicker {
	onChange: (value: string) => void;
	isButtonLoading?: boolean;
}

export const EmojiPicker: React.FC<IEmojiPicker> = ({
	onChange,
	isButtonLoading,
}) => {
	const { onToggle } = useDisclosure();

	const popoverRef = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref: popoverRef,
		handler: onToggle,
	});

	return (
		<Box>
			<Popover isLazy={true} initialFocusRef={popoverRef}>
				<PopoverTrigger>
					<IconButton
						aria-label="Emoji selector"
						bg="mainBg"
						isLoading={isButtonLoading}
						onClick={onToggle}>
						<HappyFaceIcon />
					</IconButton>
				</PopoverTrigger>
				<PopoverContent
					ref={popoverRef}
					border="none"
					width={'auto'}
					borderRadius={'10px'}>
					<PopoverBody p={0}>
						<Picker
							data={data}
							onEmojiSelect={(emoji: any) => {
								onChange(emoji.native);
							}}
						/>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
};
