import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { BusinessEntityPayload } from '../../types';
import { CruzQueryKeys } from '../query-keys';

export const useCreateBusinessEntity = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, BusinessEntityPayload>({
		mutationFn: payload =>
			API_CTRL.post('/api/app/business-entity', payload),
		onSuccess: data => {
			if (data.success) {
				queryClient.invalidateQueries([
					CruzQueryKeys.BusinessEntities,
					false,
				]);
				queryClient.invalidateQueries(
					CruzQueryKeys.BusinessEntitySelector,
				);
				Alerter.success('Created successfully');
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
