import { Box, Button, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import React from 'react';
import { UnitItem } from './UnitItem';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PropertyFormType } from '../../../../validations';
import { appendProperty } from '../../../../constants';
import { ItemList } from '../../../../../../components';

export const ManageUnit: React.FC = () => {
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext<PropertyFormType>();

	const { append, remove, fields } = useFieldArray({
		name: 'units',
		control,
		keyName: 'unitKey',
	});

	return (
		<Box bg={'white'} borderRadius={'8px'}>
			<Flex
				borderBottom={'1px solid #F1F4F9'}
				align={'center'}
				p={'16px'}
				justify={'space-between'}>
				<Text fontWeight={600} color={'blue.100'}>
					Unit Details
				</Text>
				<Button
					onClick={() => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						append(appendProperty);
					}}
					variant={'link'}
					color={'secondaryButton.500'}
					leftIcon={<AddIcon />}>
					Add Unit
				</Button>
			</Flex>
			<VStack
				sx={{
					'.chakra-divider': {
						marginTop: 0,
						marginBottom: 0,
					},
				}}
				divider={<Divider borderColor={'mainBg'} />}
				align={'stretch'}>
				<ItemList
					items={fields}
					renderItem={(item, index) => (
						<UnitItem
							hasTenant={item.hasTenant}
							unitId={item?.id}
							control={control}
							nestedIndex={index}
							register={register}
							errors={errors}
							onRemove={() => remove(index)}
						/>
					)}
					keyExtractor={item => item.unitKey}
				/>
			</VStack>
		</Box>
	);
};
