import { API_IDENTITY } from '../api/api.base';
import { ApiResponse, ICurrentUser } from '../types';

export const getCurrentUserRequest = async (): Promise<ICurrentUser> => {
	const response = await API_IDENTITY.get('/api/user/current', {});
	return response.value;
};

export const getCurrentUserRequestErrorHandling = async (): Promise<
	ApiResponse<ICurrentUser>
> => {
	const response = await API_IDENTITY.get('/api/user/current', {});
	return response;
};
