import React from 'react';
import { IconButton, Flex, Text, chakra } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';

interface IMessageInputReplyProps {
	name: string;
	onCloseReply: VoidFunction;
}

export const MessageInputReply: React.FC<IMessageInputReplyProps> = ({
	name,
	onCloseReply,
}) => {
	return (
		<Flex
			alignItems="center"
			justify="space-between"
			h={'40px'}
			p={'10px 20px'}
			bg="blue.100"
			borderRadius="10px 10px 0px 0px">
			<Text fontSize="14px" color="white">
				Replying to{' '}
				<chakra.span fontWeight={700} color="success">
					{name}
				</chakra.span>
			</Text>
			<IconButton
				aria-label="Cancel reply"
				variant="ghost"
				colorScheme="blackAlpha"
				onClick={onCloseReply}>
				<IoMdClose color="white" size={20} />
			</IconButton>
		</Flex>
	);
};
