import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	userId: number | null;
	withPurge: boolean;
	onSuccessAction?: VoidFunction;
	// setUserId: (value: string) => void;
	onClose: VoidFunction;
	onOpen: (
		userId: number,
		withPurge: boolean,
		onSuccessAction?: VoidFunction,
	) => void;
}

export const useSuspendModalStore = create<IState>(set => ({
	isOpen: false,
	userId: null,
	withPurge: false,
	onSuccessAction: undefined,
	onOpen: (userId, withPurge, onSuccessAction) =>
		set(() => ({ isOpen: true, withPurge, userId, onSuccessAction })),
	onClose: () =>
		set(() => ({
			isOpen: false,
			receiverId: null,
			withWipeout: false,
			userId: null,
			onSuccessAction: undefined,
		})),
}));
