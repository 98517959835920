import React from 'react';
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	chakra,
} from '@chakra-ui/react';
import ReactSelect, { Props } from 'react-select';
import { stylesConfig } from './styles';

interface ICustomSelect extends Props {
	errMsg?: string;
	label?: string;
	isRequired?: boolean;
}

export const Select: React.FC<ICustomSelect> = ({
	errMsg,
	label,
	isRequired,
	...props
}) => {
	return (
		<FormControl isInvalid={!!errMsg}>
			{label ? (
				<FormLabel
					fontSize={'16px'}
					lineHeight={'26px'}
					fontWeight={500}
					color="blue300"
					mb="8px">
					{label}{' '}
					{isRequired ? (
						<chakra.span color="#FF3636">*</chakra.span>
					) : null}
				</FormLabel>
			) : null}
			<ReactSelect
				aria-invalid={Boolean(errMsg)}
				styles={stylesConfig()}
				components={{
					IndicatorSeparator: () => null,
				}}
				{...props}
			/>
			<FormErrorMessage>{errMsg}</FormErrorMessage>
		</FormControl>
	);
};
