import React from 'react';
import { Box, Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { SingleValue } from 'react-select';
import { ItemList } from '../../../../components';
import { CustomReactSelect } from '../Select/CustomReactSelect';
import {
	INVOICE_STATUS_FILTER_OPTIONS,
	INVOICE_UNPAID_FILTER_OPTIONS,
} from '../../constants';
import pluralize from 'pluralize';
import { formatDollars } from '../../../../utils';
import { transformAmount } from '../../utils';
import { useUpdateInvoicesStatus } from '../../queries';
import { InvoiceFilterStatus, InvoiceStatusEnum } from '../../types';

const BUTTONS = [
	{
		name: 'Mark as Paid',
		status: InvoiceStatusEnum.Paid,
	},
	// {
	// 	name: 'Mark as Unpaid',
	// 	status: InvoiceStatusEnum.UnPaid,
	// },
	{
		name: 'Mark as Cancelled',
		status: InvoiceStatusEnum.Cancelled,
	},
];

interface IInvoiceTabHeader {
	totalAmount: number;
	totalCount: number;
	selectedInvoices: Set<number>;
	statusFilter: number;
	setStatusFilter: (value: number) => void;
	isEmpty: boolean;
}

export const InvoiceTabHeader: React.FC<IInvoiceTabHeader> = ({
	totalAmount,
	totalCount,
	selectedInvoices,
	setStatusFilter,
	statusFilter,
	isEmpty,
}) => {
	const isShowSelect = [
		InvoiceFilterStatus.UnpaidAll,
		InvoiceFilterStatus.UnpaidOverdue,
		InvoiceFilterStatus.UnpaidOnTime,
	].includes(statusFilter);
	const isDisabledButton = isEmpty || !Array.from(selectedInvoices).length;

	const { mutate, isLoading } = useUpdateInvoicesStatus();

	const onUpdateStatus = (status: number) => {
		mutate({
			invoicesIds: [...Array.from(selectedInvoices)],
			status,
		});
	};

	return (
		<Flex
			direction={{ base: 'column', lg: 'row' }}
			mt={'16px'}
			mb={'12px'}
			align={{ base: 'flex-start', lg: 'center' }}
			justifyContent="space-between"
			flexWrap={'wrap'}
			gap={{ base: '16px', lg: '8px' }}>
			<Flex
				direction={{ base: 'column', lg: 'row' }}
				w={{ base: 'full', lg: 'auto' }}
				flexWrap={'wrap'}
				gap={{ base: '16px', lg: '8px' }}>
				<Flex
					direction={{ base: 'column', md: 'row' }}
					gap={'8px'}
					w={{ base: 'full', lg: 'auto' }}>
					<ItemList
						items={BUTTONS}
						renderItem={item => (
							<Button
								onClick={() => {
									onUpdateStatus(item.status);
								}}
								isDisabled={isDisabledButton || isLoading}
								variant={'ctrl-secondary'}
								w={{ base: 'full', lg: 'auto' }}>
								{item.name}
							</Button>
						)}
						keyExtractor={item => item.name}
					/>
				</Flex>
				<Stack
					direction={{ base: 'column', md: 'row' }}
					w={{ base: 'full', lg: 'auto' }}>
					<Box w={{ base: 'full', lg: '140px' }}>
						<CustomReactSelect
							onChange={(newValue: SingleValue<any>) => {
								if (newValue) {
									setStatusFilter(newValue.value);
								}
							}}
							value={INVOICE_STATUS_FILTER_OPTIONS.find(
								it => it.value === statusFilter,
							)}
							options={INVOICE_STATUS_FILTER_OPTIONS}
						/>
					</Box>
					{isShowSelect ? (
						<Box w={{ base: 'full', lg: '140px' }}>
							<CustomReactSelect
								onChange={(newValue: SingleValue<any>) => {
									if (newValue) {
										setStatusFilter(newValue.value);
									}
								}}
								value={INVOICE_UNPAID_FILTER_OPTIONS.find(
									it => it.value === statusFilter,
								)}
								options={INVOICE_UNPAID_FILTER_OPTIONS}
							/>
						</Box>
					) : null}
				</Stack>
			</Flex>

			<Box>
				<Text variant={'bodyMedium'}>
					{`${totalCount} ${pluralize('Invoice', totalCount)} Found`}
				</Text>
				<HStack spacing={'4px'}>
					<Text variant={'bodyMedium'} fontWeight={600}>
						Total Amount:
					</Text>
					<Text variant={'bodyMedium'}>
						{formatDollars.format(
							transformAmount(totalAmount, true),
						)}
					</Text>
				</HStack>
			</Box>
		</Flex>
	);
};
