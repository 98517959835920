import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Box,
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CustomAsyncSelect, CustomReactSelect } from '../../components';
import { DatePicker, ItemList } from '../../../../components';
import {
	defaultInvoiceValues,
	INVOICE_OPTIONS,
	INVOICE_STATUS_OPTIONS,
	PAYMENT_METHOD_OPTIONS,
} from '../../constants';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { InvoiceFormType, invoiceResolver } from '../../validations';
import {
	InvoiceStatusEnum,
	LoadOptionsType,
	PaymentMethodEnum,
	TenantStatus,
} from '../../types';
import { InvoiceFieldItem, InvoiceItemsWrapper } from './components';
import { transformAmount } from '../../utils';
import debounce from 'lodash.debounce';
import { getTenantsExtendSelector, useCreateInvoice } from '../../queries';
import { generateDescription } from './utils';
import { formatDollars } from '../../../../utils';
import { useInvoiceModalStore } from '../../store';

interface IInvoiceModal {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const InvoiceModal: React.FC<IInvoiceModal> = ({ isOpen, onClose }) => {
	const tenantInfo = useInvoiceModalStore(state => state.tenant);

	const { reset, control, handleSubmit, setValue, watch, clearErrors } =
		useForm<InvoiceFormType>({
			resolver: invoiceResolver,
			defaultValues: defaultInvoiceValues,
			shouldUnregister: true,
		});

	const { fields, append, remove } = useFieldArray({
		control,
		keyName: 'invoice_key',
		name: 'invoiceItems',
	});

	const createInvoice = useCreateInvoice();

	const onReset = useCallback(() => {
		reset(defaultInvoiceValues);
		onClose();
	}, []);

	const onSubmit = (values: InvoiceFormType) => {
		const formData = new FormData();

		if (values.invoiceItems?.length !== 0) {
			values.invoiceItems?.forEach((it, index) => {
				if (it.id) {
					formData.append(`InvoiceItems[${index}].id`, String(it.id));
				}
				formData.append(
					`InvoiceItems[${index}].description`,
					it.description,
				);
				formData.append(
					`InvoiceItems[${index}].amount`,
					String(transformAmount(Number(it.amount))),
				);
			});
		}
		formData.append('TenantId', String(values.tenant?.value));
		formData.append('InvoiceType', String(values.invoiceType?.value));
		formData.append('InvoiceStatus', String(values.invoiceStatus?.value));
		formData.append('PaymentMethod', String(values.paymentMethod?.value));
		formData.append('InvoiceDate', values.invoiceDate);
		formData.append('InvoiceDueDate', values.invoiceDueDate);
		// formData.append('IsSendToEmail', String(values.isSendToEmail));

		if (
			values.invoiceStatus?.value === InvoiceStatusEnum.Paid &&
			values.paidDate
		) {
			formData.append('PaidDate', values.paidDate);
		}

		createInvoice.mutate(formData, {
			onSuccess: data => {
				if (data.success) {
					onReset();
				}
			},
		});
	};

	const [
		paymentMethod,
		invoiceDate,
		invoiceDueDate,
		invoiceType,
		tenant,
		invoiceStatus,
		invoiceItems,
	] = watch([
		'paymentMethod',
		'invoiceDate',
		'invoiceDueDate',
		'invoiceType',
		'tenant',
		'invoiceStatus',
		'invoiceItems',
	]);

	const isCanGenerateDesc =
		!!invoiceDate && !!invoiceDueDate && !!invoiceType && !!tenant;

	const isCheckboxDisabled =
		paymentMethod?.value === PaymentMethodEnum.Stripe;

	const totalAmount = invoiceItems?.reduce(
		(acc, item) => acc + Number(item?.amount || 0),
		0,
	);

	const onGenerateDescription = useCallback(() => {
		if (invoiceDate && invoiceDueDate && invoiceType && tenant) {
			const desc = generateDescription({
				invoiceDate,
				invoiceDueDate,
				propertyName: tenant.propertyName,
				unitName: tenant.unitName,
				invoiceType: invoiceType.label,
			});

			setValue('invoiceItems.0.description', desc);
			clearErrors('invoiceItems.0.description');
		}
	}, [invoiceDate, invoiceDueDate, invoiceType, tenant, setValue]);

	const loadOptions: LoadOptionsType = useCallback(
		debounce((inputValue, callback) => {
			getTenantsExtendSelector(inputValue, [TenantStatus.Active]).then(
				resp => callback(resp || []),
			);
		}, 500),
		[],
	);

	useEffect(() => {
		if (
			paymentMethod?.value &&
			paymentMethod.value === PaymentMethodEnum.Stripe
		) {
			setValue('isSendToEmail', true);
		}
	}, [paymentMethod?.value]);

	useEffect(() => {
		setValue('paidDate', '');
		clearErrors('paidDate');
	}, [invoiceStatus]);

	useEffect(() => {
		if (tenantInfo) {
			reset({
				...defaultInvoiceValues,
				tenant: {
					unitName: tenantInfo.unitName,
					propertyName: tenantInfo.propertyName,
					label: tenantInfo.name,
					value: tenantInfo.id,
				},
			});
		}
	}, [tenantInfo]);

	const isTenantStripeConnect = useMemo(
		() => !!tenantInfo?.isStripeConnected || !!tenant?.isStripeConnected,
		[tenantInfo, tenant],
	);

	return (
		<Modal
			scrollBehavior="inside"
			closeOnOverlayClick={false}
			isCentered={true}
			isOpen={isOpen}
			onClose={onReset}>
			<ModalOverlay />
			<ModalContent
				bg="mainBg"
				maxW="832px"
				w={{ base: '90vw', lg: '100%' }}>
				<ModalHeader
					px="16px"
					fontSize="20px"
					fontWeight="700"
					lineHeight="24px">
					Add Invoice
				</ModalHeader>
				<ModalCloseButton color="blue.200" />
				<ModalBody p="16px">
					<form onSubmit={handleSubmit(onSubmit)}>
						<VStack align={'stretch'} spacing={'16px'}>
							{/*INVOICE DETAILS*/}
							<Box bg={'white'} borderRadius={'8px'}>
								<Box
									borderBottom={'1px solid #F1F4F9'}
									p={'16px'}>
									<Text color={'blue.100'} fontWeight={600}>
										Invoice Details
									</Text>
								</Box>
								<VStack
									align={'stretch'}
									p={'16px'}
									spacing={'16px'}>
									<Stack
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing={'16px'}>
										<Controller
											render={({
												field: { value, onChange },
												fieldState: { error },
											}) => (
												<CustomAsyncSelect
													defaultOptions={true}
													isDisabled={
														!!tenantInfo?.id
													}
													loadOptions={loadOptions}
													errMsg={error?.message}
													value={value}
													onChange={onChange}
													placeholder={
														'Search or Select Tenant'
													}
													label={'Tenant'}
												/>
											)}
											name={'tenant'}
											control={control}
										/>
										<Box
											w={'full'}
											display={{
												base: 'none',
												md: 'block',
											}}
										/>
									</Stack>
									<Stack
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing={'16px'}>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<DatePicker
													errorMsg={error?.message}
													onSetValue={onChange}
													value={value}
													label={'Invoice Date'}
												/>
											)}
											name={'invoiceDate'}
											control={control}
										/>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<DatePicker
													errorMsg={error?.message}
													onSetValue={onChange}
													value={value}
													label={'Due Date'}
												/>
											)}
											name={'invoiceDueDate'}
											control={control}
										/>
									</Stack>
									<Stack
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing={'16px'}>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<CustomReactSelect
													errMsg={error?.message}
													value={value}
													onChange={onChange}
													label={'Invoice Type'}
													placeholder={'Select Type'}
													options={INVOICE_OPTIONS}
													isSearchable={false}
												/>
											)}
											name={'invoiceType'}
											control={control}
										/>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<CustomReactSelect
													value={value}
													onChange={onChange}
													errMsg={error?.message}
													label={'Status'}
													placeholder={
														'Select Status'
													}
													options={
														INVOICE_STATUS_OPTIONS
													}
													isSearchable={false}
												/>
											)}
											name={'invoiceStatus'}
											control={control}
										/>
									</Stack>
									<Stack
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing={'16px'}>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<CustomReactSelect
													value={value}
													onChange={onChange}
													errMsg={error?.message}
													label={'Payment Method'}
													placeholder={
														'Select Method'
													}
													options={
														PAYMENT_METHOD_OPTIONS
													}
													isOptionDisabled={option =>
														option.value ===
															PaymentMethodEnum.Stripe &&
														!isTenantStripeConnect
													}
													isSearchable={false}
													isDisabled={!tenant?.value}
												/>
											)}
											name={'paymentMethod'}
											control={control}
										/>
										<Controller
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<DatePicker
													isDisabled={
														Number(
															invoiceStatus?.value,
														) !==
														InvoiceStatusEnum.Paid
													}
													errorMsg={error?.message}
													onSetValue={onChange}
													value={value}
													maxDate={new Date()}
													label={'Paid Date'}
												/>
											)}
											name={'paidDate'}
											control={control}
										/>
									</Stack>
									{/*TODO: return after refactoring mail accounts*/}
									{/*{paymentMethod ? (*/}
									{/*	<Controller*/}
									{/*		render={({*/}
									{/*			field: { value, onChange },*/}
									{/*		}) => (*/}
									{/*			<Checkbox*/}
									{/*				isDisabled={*/}
									{/*					isCheckboxDisabled*/}
									{/*				}*/}
									{/*				isChecked={value}*/}
									{/*				onChange={onChange}*/}
									{/*				alignSelf={'flex-start'}*/}
									{/*				variant={*/}
									{/*					'cruzctrl-checkbox'*/}
									{/*				}>*/}
									{/*				Send to the tenant&apos;s*/}
									{/*				email*/}
									{/*			</Checkbox>*/}
									{/*		)}*/}
									{/*		name={'isSendToEmail'}*/}
									{/*		control={control}*/}
									{/*	/>*/}
									{/*) : null}*/}
								</VStack>
							</Box>
							{/*	INVOICE ITEMS*/}
							<InvoiceItemsWrapper
								onAppend={() => {
									append({
										description: '',
										amount: null,
									});
								}}>
								<VStack
									p={'16px'}
									align={'stretch'}
									spacing={'16px'}>
									<ItemList
										items={fields}
										renderItem={(_, index) => (
											<InvoiceFieldItem
												onGenerateDescription={
													onGenerateDescription
												}
												isCanGenerateDesc={
													isCanGenerateDesc
												}
												onRemove={() => {
													remove(index);
												}}
												isCanRemove={fields.length > 1}
												index={index}
												control={control}
											/>
										)}
										keyExtractor={item => item.invoice_key}
									/>
									{/*TOTAL AMOUNT*/}
									<HStack
										alignSelf={'flex-end'}
										spacing={'8px'}>
										<Text
											color={'blue.100'}
											fontWeight={600}>
											Total:
										</Text>
										<Text
											color={'blue.100'}
											fontWeight={600}>
											{formatDollars.format(
												totalAmount || 0,
											)}
										</Text>
									</HStack>
								</VStack>
							</InvoiceItemsWrapper>

							{/*	SUBMIT BUTTON*/}
							<Button
								isLoading={createInvoice.isLoading}
								isDisabled={createInvoice.isLoading}
								type={'submit'}
								maxW={{ base: '100%', md: '200px' }}
								w={'full'}
								alignSelf={'flex-end'}
								variant={'cruzctrl-solid'}
								h={'48px'}>
								Submit
							</Button>
						</VStack>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
