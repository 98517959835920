import React, { useState, useEffect, useMemo } from 'react';
import { Text, Center } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useChannel } from 'ably/react';
import { DirectFriendBase, NormalControls } from '../components';
import { SearchWithPaginationPayload } from '../../types';
import { Loader } from '../../../../components';
import { useGetFriends } from '../../queries';
import { useChatUserStore } from '../../../../store';
import { usePolling } from '../../hooks';
import { useHandleAllTabSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry } from '../../../../utils';

interface IAllTabProps {
	index: number;
}

export const AllTab: React.FC<IAllTabProps> = ({ index }) => {
	const [loading, setLoading] = useState(true);
	const { user } = useChatUserStore();

	const { mutateAsync: getFriendsRequest } = useGetFriends();

	const { allFriends, handleAllTabSocketEvents, setAllFriends } =
		useHandleAllTabSocketEvents();

	useChannel(
		{
			channelName: `private-user:${user?.userId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handleAllTabSocketEvents(message);
		},
	);

	const userIds = useMemo(() => {
		return allFriends.map(friend => friend.userId);
	}, [allFriends.length]);

	const onGetFriends = async (data: SearchWithPaginationPayload) => {
		try {
			setLoading(true);
			const res = await getFriendsRequest(data);

			if (!res.value || !res.success) {
				return;
			}

			setAllFriends(res.value.friends);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const currentPresenceStatus = usePolling(userIds, index === 1);

	useEffect(() => {
		if (index === 1) {
			onGetFriends({ currentPage: 1, pageSize: 50 });
		}
	}, [index]);

	return (
		<>
			{loading ? (
				<Loader centerHeight="500px" />
			) : (
				<>
					{allFriends.length ? (
						<>
							<Text
								mt="20px"
								mb="10px"
								color="blue.200"
								fontSize="16px"
								fontWeight={700}>
								ALL - {allFriends.length}
							</Text>
							<InfiniteScroll
								dataLength={allFriends.length}
								next={() => {
									console.log('more');
									// getMoreAllFriends();
								}}
								hasMore={false}
								height={'calc(100vh - 120px)'}
								loader={<Loader spinnerSize="md" />}>
								{allFriends.map(elem => (
									<DirectFriendBase
										key={elem.userId}
										name={elem.displayName}
										photoURL={elem.avatarStoragePath}
										id={elem.userId}
										isAdmin={elem.isAdmin}
										email={elem.email}
										userStatus={
											currentPresenceStatus
												? currentPresenceStatus[
														`${elem.userId}`
												  ]
												: elem.userStatus
										}
										controls={
											<NormalControls
												friendId={elem.userId}
											/>
										}
									/>
								))}
							</InfiniteScroll>
						</>
					) : (
						<Center height="500px">
							<Text color="blue.50">No friends yet</Text>
						</Center>
					)}
				</>
			)}
		</>
	);
};
