import { create } from 'zustand';
import { TenantSelectType } from '../types';

type StoreType = {
	isOpen: boolean;
	tenant: TenantSelectType | null;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
	onSetTenant: (value: TenantSelectType) => void;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
	tenant: null,
};

export const useInvoiceModalStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false, tenant: null }),
	onSetTenant: value => set({ isOpen: true, tenant: value }),
}));
