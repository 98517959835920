import React, { useState } from 'react';
import { Text } from '@chakra-ui/react';
import { AlertDialog } from '../../common-components';
import { useDeleteAllMessagesModalStore } from '../../../../store';
import { deleteAllConversationMessages } from '../../helpers';
import { Alerter } from '../../../../utils';

export const DeleteAllMessagesConfirmModal: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { isOpen, onClose, conversationId } =
		useDeleteAllMessagesModalStore();

	const onDeleteMessages = async (conversationId: number) => {
		try {
			setIsLoading(true);

			const result = await deleteAllConversationMessages(conversationId);

			if (!result?.success) {
				return;
			}

			Alerter.success('Successfully deleted all messages');

			onClose();
		} catch (error) {
			console.log(error, 'delete messages error');
			Alerter.error('Error while deleting');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<AlertDialog
			isOpen={isOpen}
			acceptCallback={() => {
				if (conversationId) {
					onDeleteMessages(conversationId);
				}
			}}
			acceptButtonText="Yes"
			cancelButtonText="No"
			onClose={onClose}
			title="Delete message"
			acceptButtonProps={{
				isLoading,
			}}>
			<Text color="blue.200" textAlign="center" my="20px">
				Are you sure you want to delete all messages with this user?
			</Text>
		</AlertDialog>
	);
};
