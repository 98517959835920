import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useDeleteDirectMessageReaction = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ messageId: number; conversationId: number }
	>({
		mutationKey: [MessengerQueryKeys.DELETE_DIRECT_MESSAGE_REACTION],
		mutationFn: payload =>
			API_CHAT.delete(
				`/api/app/conversation/${payload.conversationId}/messages/${payload.messageId}/reaction`,
				{},
			),
	});
};
