/* eslint-disable indent */
import { useQueryClient } from 'react-query';
import { ApiResponse } from '../../../types';
import { MessengerQueryKeys } from '../queries/query-keys';
import { ServerCategoryData, ServerChannelData } from '../types';

export const useFetchedChannelDataById = (
	id?: number,
): ServerChannelData | undefined => {
	const queryClient = useQueryClient();

	const fetchedData:
		| ApiResponse<{ serverCategories: ServerCategoryData[] }>
		| undefined = queryClient.getQueryData(
		MessengerQueryKeys.GET_SERVER_CATEGORIES,
	);

	const categories = fetchedData?.value?.serverCategories;

	for (const category of categories || []) {
		const foundObject = category?.channels?.find(obj => obj.id === id);

		if (foundObject) {
			return foundObject;
		}
	}

	return undefined;
};
