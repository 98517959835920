import placeholder from '../../../assets/images/property-placeholder.png';
import { PaymentMethodEnum, PropertyImageType } from '../types';
import { Alerter } from '../../../utils';

export const getImageToDisplay = (data: PropertyImageType[]) => {
	const mainImage = data.find(it => it.isPinned);
	return mainImage
		? mainImage?.thumbnailUrl
		: data.length
		? data[0].thumbnailUrl
		: placeholder;
};

export async function downloadFile(
	url: string,
	defaultName: string,
): Promise<void> {
	try {
		const response = await fetch(url);

		if (!response.ok) {
			Alerter.error('Error');
		}

		const blob = await response.blob();

		const link = document.createElement('a');
		const blobUrl = window.URL.createObjectURL(blob);

		link.download = defaultName;
		link.href = blobUrl;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		window.URL.revokeObjectURL(blobUrl);
	} catch (error) {
		console.error(`Error: ${error}`);
	}
}

export const getPaymentMethodNameByType = (value: number) => {
	switch (value) {
		case PaymentMethodEnum.Stripe:
			return 'Stripe';
		case PaymentMethodEnum.Cash:
			return 'Cash';
		case PaymentMethodEnum.MoneyOrder:
			return 'Money Order';
		case PaymentMethodEnum.PersonalCheck:
			return 'Personal Check';
		default:
			return '';
	}
};

export const transformAmount = (amount: number, isConverting?: boolean) => {
	if (isConverting) {
		return Math.round(amount) / 100;
	} else {
		return Math.round(amount * 100);
	}
};

export const generateTimeOptions = (minInMinutes = 5) => {
	const timeOptions = [];

	for (let hour = 0; hour < 24; hour++) {
		for (let minute = 0; minute < 60; minute += minInMinutes) {
			const period = hour < 12 ? 'AM' : 'PM';
			const adjustedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 0 to 12 for 12 AM/PM
			const formattedHour = adjustedHour.toString().padStart(2, '0');
			const formattedMinute = minute.toString().padStart(2, '0');
			const timeValue = `${formattedHour}:${formattedMinute} ${period}`;

			timeOptions.push({
				label: timeValue,
				value: timeValue,
			});
		}
	}

	return timeOptions;
};
