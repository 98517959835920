import { ErrorInfo } from 'ably';
import * as Sentry from '@sentry/react';

export function filterAblyErrorsToSentry(err: ErrorInfo) {
	switch (err.code) {
		case 80003: // Generic Disconnection Error
		case 80017: // Connection closed
		case 80016: // Operation on superseded connection
			return;
		default: {
			console.log(err, 'ably error');
			Sentry.captureException(err);
		}
	}
}
