import React, { useRef } from 'react';
import {
	AlertDialog as ChakraAlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonProps,
	ModalBodyProps,
	ModalContentProps,
	ModalFooterProps,
	ModalHeaderProps,
	Text,
} from '@chakra-ui/react';

type Props = {
	acceptButtonText?: string;
	acceptCallback: VoidFunction;
	cancelButtonText?: string;
	title: string;
	acceptButtonProps?: ButtonProps;
	isOpen: boolean;
	onClose: VoidFunction;
	isLoading?: boolean;
	subTitle: string;
	additionalText?: string;
	bodyProps?: ModalBodyProps;
	footerProps?: ModalFooterProps;
	cancelButtonProps?: ButtonProps;
	contentProps?: ModalContentProps;
	headerProps?: ModalHeaderProps;
};

export const AlertConfirmDialog: React.FC<Props> = ({
	acceptButtonText = 'Delete',
	acceptCallback,
	cancelButtonText = 'Cancel',
	additionalText,
	title,
	acceptButtonProps,
	isOpen,
	onClose,
	isLoading,
	subTitle,
	bodyProps,
	footerProps,
	cancelButtonProps,
	contentProps,
	headerProps,
}) => {
	const cancelRef = useRef<HTMLButtonElement | null>(null);

	return (
		<ChakraAlertDialog
			isCentered={true}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent bg="white" {...contentProps}>
					<AlertDialogHeader
						px={'20px'}
						fontSize="18px"
						fontWeight="600"
						textAlign="left"
						{...headerProps}>
						{title}
					</AlertDialogHeader>

					<AlertDialogBody py="0" px="20px" {...bodyProps}>
						<Text fontWeight={'600'}>{subTitle}</Text>
						{additionalText ? (
							<Text mt={'16px'}>{additionalText}</Text>
						) : null}
					</AlertDialogBody>

					<AlertDialogFooter
						px={'24px'}
						gap={'10px'}
						justifyContent={'stretch'}
						{...footerProps}>
						<Button
							w={'full'}
							ref={cancelRef}
							size={'lg'}
							variant="cruzctrl-outlined"
							onClick={onClose}
							{...cancelButtonProps}>
							{cancelButtonText}
						</Button>
						<Button
							w={'full'}
							h={'48px'}
							variant={'ctrl-destructive'}
							isLoading={isLoading}
							isDisabled={isLoading}
							onClick={acceptCallback}
							{...acceptButtonProps}>
							{acceptButtonText}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</ChakraAlertDialog>
	);
};
