import { create } from 'zustand';
import { IChatUser } from '../types';

interface IState {
	user: IChatUser | null;
	setChatUser: (user: IChatUser | null) => void;
}

export const useChatUserStore = create<IState>(set => ({
	user: null,
	setChatUser: user =>
		set(() => ({
			user,
		})),
}));
