import { Alerter } from '../../../../utils';

import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { TenantSelectType } from '../../types';
import { transformTenantsToOptions } from '../../utils';

export const getTenantsExtendSelector = async (
	searchString: string,
	statuses?: Array<number>,
) => {
	try {
		const response: ApiResponse<{
			tenants: TenantSelectType[];
		}> = await API_CTRL.get('/api/app/selector/tenants-extended', {
			SearchString: searchString,
			Statuses: statuses,
		});
		if (response.success) {
			return transformTenantsToOptions(response.value.tenants);
		} else {
			Alerter.error(response.errors[0].message);
		}
	} catch (err) {
		console.log(err, '@@@ getTenantsExtendSelector');
		Alerter.error('Something went wrong');
	}
};
