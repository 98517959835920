import { useCallback } from 'react';
import { Message } from 'ably';
import { SOCKET_EVENTS } from '../../constants';
import {
	useChatUserStore,
	useServerUsersOnlineList,
	useServerUsersAllList,
} from '../../../../store';

export const useHandleRolesSocketMessages = () => {
	const { user, setChatUser } = useChatUserStore();
	const { updateOnlineChannelUsers } = useServerUsersOnlineList();

	const { updateAllChannelUsers } = useServerUsersAllList();

	const handleRolesSocketMessages = useCallback((event: Message) => {
		switch (event.name) {
			case SOCKET_EVENTS.CHANNEL_BLOCK_USER_EVENT:
				{
					const suspendUserResponse: {
						Value: { UserId: number; IsBlocked: boolean };
					} = JSON.parse(event.data);
					const { UserId, IsBlocked } = suspendUserResponse.Value;
					if (user?.userId === UserId) {
						setChatUser({ ...user, isBannedInChannel: IsBlocked });
					}
					updateOnlineChannelUsers(UserId, {
						isBannedInChannel: IsBlocked,
					});
					updateAllChannelUsers(UserId, {
						isBannedInChannel: IsBlocked,
					});
				}
				break;
			case SOCKET_EVENTS.CHANNEL_BLOCK_FULL_USER:
				{
					const suspendUserResponse: {
						Value: { UserId: number; IsBlocked: boolean };
					} = JSON.parse(event.data);
					const { UserId } = suspendUserResponse.Value;
					if (user?.userId === UserId) {
						setChatUser({ ...user, isBannedInChannel: true });
					}
					updateOnlineChannelUsers(UserId, {
						isBannedInChannel: true,
					});
					updateAllChannelUsers(UserId, { isBannedInChannel: true });
				}
				break;

			default:
				break;
		}
	}, []);

	return { handleRolesSocketMessages };
};
