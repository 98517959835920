import React from 'react';
import { Divider, Flex, Box } from '@chakra-ui/react';
import { NewDividerBadgeIcon } from '../../../assets';

export const NewDivider: React.FC = () => {
	return (
		<Flex alignItems="center" mb="20px">
			<Divider borderColor="dangerButton.500" flex={1} />
			<Box flex={0.05} minW="30px" maxW="40px">
				<NewDividerBadgeIcon />
			</Box>
		</Flex>
	);
};
