import {
	Flex,
	IconButton,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';

import { FC, useState } from 'react';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { Loader } from '../../../../components';
import { useDownloadFile } from '../../hooks';
import { colors } from '../../../../theme';

interface ViewAttachmentPhotoModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	imgPathLarge?: string;
	imgPathOriginal?: string;
	name?: string;
	canDownload: boolean;
}

export const ViewAttachmentPhotoModal: FC<ViewAttachmentPhotoModalProps> = ({
	onClose,
	isOpen,
	imgPathLarge,
	imgPathOriginal,
	name,
	canDownload = false,
}) => {
	const [loading, setLoading] = useState(true);
	const { downloadByLink, loading: downloadLoading } = useDownloadFile();

	const onCloseActions = () => {
		onClose();
		setLoading(true);
	};
	return (
		<Modal isOpen={isOpen} onClose={onCloseActions} isCentered={true}>
			<ModalOverlay
				bg={'rgba(74, 89, 145, 0.60)'}
				backdropFilter={'blur(10px)'}
			/>
			<ModalContent
				width={'auto'}
				maxW={'95vw'}
				my={'40px'}
				maxH={'calc(100vh - 60px)'}>
				<ModalHeader isTruncated={true}>
					<Flex alignItems={'center'}>
						{canDownload ? 'Attached image' : name}
						<Flex ml={'auto'} gap={'10px'}>
							{!canDownload ? null : (
								<IconButton
									aria-label={'download-image'}
									isLoading={downloadLoading}
									onClick={() => {
										if (imgPathOriginal) {
											downloadByLink(
												imgPathOriginal,
												'image',
												name,
											);
										}
									}}
									icon={<DownloadIcon fontSize="20px" />}
								/>
							)}
							<IconButton
								aria-label={'close-modal'}
								icon={
									<CloseIcon
										boxSize={'16px'}
										color={colors.blue[100]}
									/>
								}
								onClick={onCloseActions}
							/>
						</Flex>
					</Flex>
				</ModalHeader>
				<ModalBody paddingBottom={'20px'}>
					{loading ? (
						<Loader
							centerProps={{
								width: '400px',
								height: '400px',
							}}
						/>
					) : null}
					<Image
						display={loading ? 'none' : 'block'}
						src={imgPathLarge}
						alt={''}
						maxW={'90vw'}
						maxH={'calc(100vh - 200px)'}
						onLoad={() => setLoading(false)}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
