import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const createChannelSchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	topic: yup.string(),
	// isPrivate: yup.boolean().required(ErrorMessages.REQUIRED),
});

export type CreateChannelForm = yup.InferType<typeof createChannelSchema>;
export const createChannelResolver = yupResolver(createChannelSchema);
