import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';
import { TenantFormType } from '../../validations';
import { transformAmount } from '../../utils';

type BillingCycleToCreate = {
	invoiceName: string;
	invoiceType?: number;
	amount?: number | null;
	invoiceDate: string;
	dueDays?: number;
	paymentMethod?: number;
	billingCycle?: number;
};

interface UpdateTenantPayload {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	leaseId: number;
	billingCyclesToCreate: Array<BillingCycleToCreate>;
	existBillingCycles: Array<number>;
}

interface IPayload {
	tenantId: number;
	data: UpdateTenantPayload;
}

export const useUpdateTenant = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, IPayload>({
		mutationFn: ({ tenantId, data }) =>
			API_CTRL.put(`/api/app/tenants/${tenantId}/update`, data),
		onSuccess: (data, { tenantId }) => {
			if (data.success) {
				Alerter.success('Updated successfully');
				queryClient.invalidateQueries([
					CruzQueryKeys.TenantDetails,
					tenantId,
				]);
				queryClient.invalidateQueries([CruzQueryKeys.CurrentTenants]);
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};

export const transformDataToUpdateTenantPayload = (
	values: TenantFormType,
	leaseId: number,
): UpdateTenantPayload => {
	const billingCyclesToCreate: Array<BillingCycleToCreate> = [];
	const existBillingCycles: Array<number> = [];
	if (values.billingCycles?.length) {
		values.billingCycles.forEach(billingCycle => {
			if (billingCycle.id) {
				existBillingCycles.push(billingCycle.id);
			} else {
				billingCyclesToCreate.push({
					invoiceName: billingCycle.invoiceName,
					dueDays: billingCycle.dueDays,
					paymentMethod: billingCycle.paymentMethod?.value,
					amount: transformAmount(Number(billingCycle.amount)),
					billingCycle: billingCycle.billingCycle?.value,
					invoiceDate: billingCycle.invoiceDate,
					invoiceType: billingCycle.invoiceType?.value,
				});
			}
		});
	}

	return {
		firstName: values.firstName.trim(),
		lastName: values.lastName.trim(),
		email: values.email,
		phone: values.phone,
		leaseId: leaseId,
		billingCyclesToCreate: billingCyclesToCreate,
		existBillingCycles: existBillingCycles,
	};
};
