import OneHundredBigReaction from '../../../assets/reactions/100_percent_big.png';
import BicepsBigReaction from '../../../assets/reactions/biceps_big.png';
import EyesBigReaction from '../../../assets/reactions/eyes_big.png';
import FireBigReaction from '../../../assets/reactions/fire_big.png';
import MoneyMouthFaceBigReaction from '../../../assets/reactions/money_mouth_face_big.png';
import ThumbUpBigReaction from '../../../assets/reactions/thumb_up_big.png';
import OneHundredMediumReaction from '../../../assets/reactions/100_percent_medium.png';
import BicepsMediumReaction from '../../../assets/reactions/biceps_medium.png';
import EyesMediumReaction from '../../../assets/reactions/eyes_medium.png';
import FireMediumReaction from '../../../assets/reactions/fire_medium.png';
import MoneyMouthFaceMediumReaction from '../../../assets/reactions/money_mouth_face_medium.png';
import ThumbUpMediumReaction from '../../../assets/reactions/thumb_up_medium.png';
import { MessageReactionType } from '../types';

export const AVAILABLE_REACTIONS = [
	{
		id: 1,
		name: 'thumb up',
		img: ThumbUpBigReaction,
	},
	{
		id: 2,
		name: 'eyes',
		img: EyesBigReaction,
	},
	{
		id: 3,
		name: 'money mouth face',
		img: MoneyMouthFaceBigReaction,
	},
	{
		id: 4,
		name: 'hundred percent',
		img: OneHundredBigReaction,
	},
	{
		id: 5,
		name: 'biceps',
		img: BicepsBigReaction,
	},
	{
		id: 6,
		name: 'eyes',
		img: FireBigReaction,
	},
];

export const REACTION_LIST_MEDIUM = {
	[`${MessageReactionType.ThumbsUp}`]: ThumbUpMediumReaction,
	[`${MessageReactionType.Eyes}`]: EyesMediumReaction,
	[`${MessageReactionType.MoneyMouthFace}`]: MoneyMouthFaceMediumReaction,
	[`${MessageReactionType.OneHundred}`]: OneHundredMediumReaction,
	[`${MessageReactionType.Biceps}`]: BicepsMediumReaction,
	[`${MessageReactionType.Fire}`]: FireMediumReaction,
};

export const REACTION_LIST_BIG = {
	[`${MessageReactionType.ThumbsUp}`]: ThumbUpBigReaction,
	[`${MessageReactionType.Eyes}`]: EyesBigReaction,
	[`${MessageReactionType.MoneyMouthFace}`]: MoneyMouthFaceBigReaction,
	[`${MessageReactionType.OneHundred}`]: OneHundredBigReaction,
	[`${MessageReactionType.Biceps}`]: BicepsBigReaction,
	[`${MessageReactionType.Fire}`]: FireBigReaction,
};
