import { create } from 'zustand';

interface IState {
	amount: number;
	setAmount: (value: number) => void;
}

export const usePendingFriendRequestsStore = create<IState>(set => ({
	amount: 0,
	setAmount: value =>
		set(() => ({
			amount: value,
		})),
}));
