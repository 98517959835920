import { FC, ReactNode } from 'react';
import { BreadCrumbs } from '../../components';
import { BREADCRUMBS_ITEMS, MyProfileItems, MyProfileRoutes } from './helper';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';

interface MyProfileProps {
	children?: ReactNode;
}

const MyProfile: FC<MyProfileProps> = ({ children }) => {
	const location = useLocation();
	const locationSplitted = location.pathname.split('/');
	const navigate = useNavigate();

	const goBack = () => {
		if (locationSplitted.length > 2) {
			navigate(-1);
		}
	};

	return (
		<Box p={{ base: '25px 20px', lg: '25px 60px' }}>
			<Box ml={'5px'}>
				<BreadCrumbs items={BREADCRUMBS_ITEMS} />
			</Box>
			<Button p={0} _hover={{ bg: 'none' }} onClick={goBack}>
				{locationSplitted.length > 2 ? (
					<ChevronLeftIcon mr={'5px'} boxSize={6} />
				) : null}
				<Text
					fontSize={'24px'}
					fontWeight={'700'}
					color={'blue.200'}
					ml={'5px'}>
					{
						MyProfileItems[
							locationSplitted[
								locationSplitted.length - 1
							] as MyProfileRoutes
						]
					}
				</Text>
			</Button>
			<Center mt={'70px'}>
				<Box
					bg={'white'}
					p={{ base: '20px 40px', lg: '60px' }}
					borderRadius={'10px'}
					w={{ base: '400px', lg: '620px' }}>
					<Flex gap={'16px'} flexDirection={'column'}>
						{children}
					</Flex>
				</Box>
			</Center>
		</Box>
	);
};

export default MyProfile;
