import React from 'react';
import { Box, HStack, IconButton, Text } from '@chakra-ui/react';
import {
	BoldIcon,
	CodeIcon,
	CrossedIcon,
	DoubleQuotesIcon,
	EditorEyeIcon,
	ItalicIcon,
} from '../../../assets';
import { AnimatePresence, motion } from 'framer-motion';
import { BooleanObject } from '../types';
import { TextStylingPatterns } from '../constants/editor';

interface IEditorProps {
	replaceSelection: (replacement: string, selectedText: string) => void;
	toggleQuoteOnLine: () => void;
	appliedStyles: BooleanObject;
	selectedText: string | null;
	showPreview: boolean;
}
export const TextEditor: React.FC<IEditorProps> = ({
	replaceSelection,
	selectedText,
	appliedStyles,
	toggleQuoteOnLine,
	showPreview,
}) => {
	const {
		isBold,
		isItalic,
		isCrossedOut,
		isHighlighted,
		isHighlightedCode,
		isHided,
	} = appliedStyles;
	return (
		<AnimatePresence>
			{selectedText || showPreview ? (
				<motion.div
					id="editor-box"
					style={{ marginLeft: 0 }}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.15 }}>
					<Box
						position="absolute"
						top="-50px"
						zIndex={10}
						bg="blue.200"
						borderRadius="5px">
						<HStack gap={0}>
							<IconButton
								onClick={() => {
									replaceSelection(
										TextStylingPatterns.Bold,
										selectedText || '',
									);
								}}
								bg={
									selectedText && isBold
										? 'blue.50'
										: 'transparent'
								}
								borderLeftRadius="5px"
								borderRightRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="bold"
								icon={<BoldIcon />}
							/>
							<IconButton
								onClick={() => {
									replaceSelection(
										TextStylingPatterns.Italic,
										selectedText || '',
									);
								}}
								bg={
									selectedText && isItalic
										? 'blue.50'
										: 'transparent'
								}
								borderRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="italic"
								icon={<ItalicIcon />}
							/>
							<IconButton
								onClick={() => {
									replaceSelection(
										TextStylingPatterns.CrossedOut,
										selectedText || '',
									);
								}}
								bg={
									selectedText && isCrossedOut
										? 'blue.50'
										: 'transparent'
								}
								borderRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="crossed-out"
								icon={<CrossedIcon />}
							/>
							<Text color="white" fontSize={20}>
								|
							</Text>
							<IconButton
								onClick={() => {
									toggleQuoteOnLine();
								}}
								bg={
									selectedText && isHighlighted
										? 'blue.50'
										: 'transparent'
								}
								borderRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="double-quotes"
								icon={<DoubleQuotesIcon />}
							/>
							<IconButton
								onClick={() => {
									replaceSelection(
										TextStylingPatterns.HighlightedCode,
										selectedText || '',
									);
								}}
								bg={
									selectedText && isHighlightedCode
										? 'blue.50'
										: 'transparent'
								}
								borderRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="code"
								icon={<CodeIcon />}
							/>
							<IconButton
								onClick={() => {
									replaceSelection(
										TextStylingPatterns.Hided,
										selectedText || '',
									);
								}}
								bg={
									selectedText && isHided
										? 'blue.50'
										: 'transparent'
								}
								borderRightRadius="5px"
								borderLeftRadius={0}
								_hover={{ bg: 'blue.50' }}
								aria-label="eye"
								icon={<EditorEyeIcon />}
							/>
						</HStack>
						<Box
							left="48.5%"
							bottom="-5px"
							transform={['translateX(50%)', 'rotate(45deg)']}
							w="10px"
							h="10px"
							bg="blue.200"
							position="absolute"
						/>
					</Box>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};
