import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Loader } from '../../../../components';
import { LayoutWrapper } from './LayoutWrapper';
import { Sidebar } from './Sidebar/Sidebar';

const SuspenseLoader = (
	<div>
		<Loader centerProps={{ minH: '100vh' }} />
	</div>
);

export const CruzLayout: React.FC = () => {
	return (
		<LayoutWrapper>
			<Flex minH="100vh" flexDirection={{ base: 'column', md: 'row' }}>
				<Sidebar />
				<Box flex={1} h={'100dvh'} overflowY={'auto'} bg={'#F1F4F9'}>
					<Suspense fallback={SuspenseLoader}>
						<Outlet />
					</Suspense>
				</Box>
			</Flex>
		</LayoutWrapper>
	);
};
