import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const createCategorySchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
});

export type CreateCategoryForm = yup.InferType<typeof createCategorySchema>;
export const createCategoryResolver = yupResolver(createCategorySchema);
