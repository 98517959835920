import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RoleItem } from './RoleItem';
import { UserChannelData, UserPresenceStatus } from '../../../types';
import { useChatUserStore } from '../../../../../store';
import { Loader } from '../../../../../components';

interface ICategoryProps {
	name: string;
	items: UserChannelData[];
	count: string;
	isOnlineUsersSection?: boolean;
	fetchNextPage?: VoidFunction;
	hasNextPage?: boolean;
	isFetchingNextPage?: boolean;
}

export const Category: React.FC<ICategoryProps> = ({
	name,
	items,
	count,
	isOnlineUsersSection,
	fetchNextPage,
	hasNextPage,
	isFetchingNextPage,
}) => {
	const { user } = useChatUserStore();

	if (!items?.length) {
		return null;
	}

	return (
		<Flex flexDirection="column">
			<Text
				fontSize="16px"
				textTransform="uppercase"
				color="blue.50"
				my="10px"
				fontWeight={700}>
				{name} - {count}
			</Text>
			<InfiniteScroll
				dataLength={items.length}
				next={() => fetchNextPage?.()}
				hasMore={!!hasNextPage}
				scrollThreshold={0.8}
				loader={null}
				scrollableTarget="server-users-sidebar-scroll"
				style={{
					minHeight: '40px',
				}}>
				<>
					{items.map(elem => (
						<RoleItem
							isUserBlocked={elem.isBlocked}
							isCurrentUser={user?.userId === elem.userId}
							key={elem.userId}
							isCurrentUserAdmin={user?.isAdmin}
							presenceStatus={
								isOnlineUsersSection
									? UserPresenceStatus.ONLINE
									: UserPresenceStatus.OFFLINE
							}
							isForceOffline={!isOnlineUsersSection}
							{...elem}
						/>
					))}
					{isFetchingNextPage ? (
						<Loader centerHeight="40px" spinnerSize="sm" />
					) : null}
				</>
			</InfiniteScroll>
		</Flex>
	);
};
