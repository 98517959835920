import {
	ArchivedTenantSelectType,
	CustomDropdownOptionType,
	EntitySelectType,
	GeocodeFieldValue,
	IdNameType,
	OptionType,
	PlacesAutocompleteResponse,
	TenantSelectType,
	UnoccupiedPropertyType,
} from '../types';
import { MailBoxItemType } from '../types/mailnox.type';

export const transformIdNameToOptions = (data?: IdNameType[]): OptionType[] => {
	if (data?.length) {
		return data.map(it => ({
			value: it.id,
			label: it.name,
		}));
	}
	return [];
};

export const transformGeocodeValueToOption = (value?: GeocodeFieldValue) => {
	if (value?.address) {
		return {
			value: {
				latitude: value.latitude,
				longitude: value.longitude,
			},
			label: value.address,
		};
	}
};

export const transformEntityToOptions = (
	data?: EntitySelectType[],
): OptionType[] => {
	if (data?.length) {
		return data.map(it => ({
			value: it.id,
			label: it.entityName,
		}));
	}
	return [];
};

export const transformEntityValueToOption = (
	value?: number,
	data?: EntitySelectType[],
) => {
	const findItem = data?.find(it => it.id === value);
	if (findItem) {
		return {
			label: findItem.entityName,
			value: findItem.id,
		};
	}
};

export const transformPlacesDataToOptions = (
	data?: PlacesAutocompleteResponse[] | null,
): OptionType[] => {
	if (data && data?.length) {
		return data.map(it => ({
			label: it.description,
			value: it.placeId,
		}));
	}
	return [];
};

export const transformTenantsToOptions = (data?: TenantSelectType[]) => {
	if (data && data?.length) {
		return data.map(it => ({
			label: it.name,
			value: it.id,
			propertyName: it.propertyName,
			unitName: it.unitName,
			isStripeConnected: it.isStripeConnected,
		}));
	}
	return [];
};

export const transformTenantsPropertyOptions = (
	data?: UnoccupiedPropertyType[],
): OptionType[] => {
	if (data?.length) {
		return data.map(it => ({
			value: it.id,
			label: it.name,
			isStripeConnected: it.isStripeConnected,
		}));
	}
	return [];
};

export const transformArchivedTenantsToOptions = (
	data?: ArchivedTenantSelectType[],
) => {
	if (data && data?.length) {
		return data.map(it => ({
			label: it.name,
			value: it.id,
			phoneNumber: it.phoneNumber,
			email: it.email,
		}));
	}
	return [];
};

export const transformCustomFieldsToOptions = (
	data: CustomDropdownOptionType[],
) => {
	return data.map(it => ({
		label: it.name,
		value: it.id,
		colour: it.colour,
	}));
};
