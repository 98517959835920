import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useDeleteChannelMessageReaction = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [MessengerQueryKeys.DELETE_CHANNEL_MESSAGE_REACTION],
		mutationFn: id =>
			API_CHAT.delete(`/api/app/channel/messages/${id}/reaction`, {}),
	});
};
