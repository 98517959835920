import { useEffect, useState } from 'react';
import { SearchUsersByNameData } from '../types';
import { IChatUser } from '../../../types';
import { getNonFriendUsersByName, getUsersByName } from '../helpers';

export const useSuggestionUsers = (
	debounceQuery: string,
	user?: IChatUser | null,
	isNonFriendsSearch?: boolean,
) => {
	const [items, setItems] = useState<SearchUsersByNameData[]>([]);

	const [isLoading, setIsLoading] = useState(false);
	const [isFetched, setIsFetched] = useState(false);

	useEffect(() => {
		(async function () {
			if (debounceQuery.length && user) {
				try {
					setIsLoading(true);
					const getUsersFunction = isNonFriendsSearch
						? getNonFriendUsersByName
						: getUsersByName;

					const res = await getUsersFunction(debounceQuery);

					if (!res?.success) {
						return;
					}
					setItems(
						res.value.users.filter(
							suggestionUser =>
								suggestionUser.userId !== user.userId,
						),
					);
				} catch (err) {
					console.log(err, 'ERROR');
				} finally {
					setIsLoading(false);
					setIsFetched(true);
				}
			}
			if (!debounceQuery.length && user) {
				return setItems([]);
			}
		})();
		if (isFetched) {
			setIsFetched(false);
		}
	}, [debounceQuery, user]);

	return {
		items,
		isLoading,
		setItems,
		isFetched,
	};
};
