import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Text,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	VStack,
	Button,
	IconButton,
	Box,
	AspectRatio,
	useDisclosure,
	Link,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { LockIcon, PlayIcon } from '../../assets';
import { Controls } from '..';
import { PlayerButtonsSize } from '../CustomPlayer/types';
import { BUTTONS_SIZE_VARIANTS } from '../CustomPlayer/constants';

const VideoBox: React.FC = () => {
	const [currentTime, setCurrentTime] = useState<number>(0);
	const [duration, setDuration] = useState<number>(0);
	const [volume, setVolume] = useState(0.7);
	const [playing, setPlaying] = useState(false);
	const [controlsHided, setControlsHided] = useState(false);

	const userAgent = navigator.userAgent.toLowerCase();

	const isIOS = /iphone|ipad|ipod/.test(userAgent);

	const {
		isOpen: volumeIsOpen,
		onOpen: volumeOnOpen,
		onClose: volumeOnClose,
	} = useDisclosure();

	const playerRef = useRef<ReactPlayer>(null);
	const boxRef = useRef<any>(null);

	let hideControlsTimeout: any;

	const togglePlay = useCallback(() => {
		setPlaying((prevState: boolean) => !prevState);
	}, []);

	const handleSeek = useCallback(
		(seconds: number) => {
			playerRef.current?.seekTo(seconds);
		},
		[playerRef],
	);
	const handleMouseMove = useCallback(() => {
		setControlsHided(false);
		clearTimeout(hideControlsTimeout);
		hideControlsTimeout = setTimeout(() => {
			setControlsHided(true);
		}, 2000); // Hide controls after 2 seconds of inactivity
	}, [hideControlsTimeout, controlsHided]);

	const toggleFullscreen = useCallback(() => {
		const fullscreenElement: any =
			document['fullscreenElement'] ||
			document.mozFullScreenElement ||
			document.webkitFullscreenElement ||
			document.msFullscreenElement;

		if (!fullscreenElement) {
			if (boxRef.current.requestFullscreen) {
				boxRef.current.requestFullscreen();
			} else if (boxRef.current.mozRequestFullScreen) {
				boxRef.current.mozRequestFullScreen();
			} else if (boxRef.current.webkitRequestFullscreen) {
				boxRef.current.webkitRequestFullscreen();
			} else if (boxRef.current.msRequestFullscreen) {
				boxRef.current.msRequestFullscreen();
			}
			// setIsFullscreen(true);
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			// setIsFullscreen(false);
		}
	}, [boxRef, document]);

	useEffect(() => {
		boxRef.current.addEventListener('mousemove', handleMouseMove);
		return () => {
			clearTimeout(hideControlsTimeout);
		};
	}, []);

	useEffect(() => {
		const handleKeyPress = (event: any) => {
			if (event.code === 'Space') {
				togglePlay();
			}
		};

		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [playing]);

	return (
		<AspectRatio
			ref={boxRef}
			ratio={16 / 9}
			borderRadius={'10px'}
			overflow={'hidden'}>
			<Box>
				<ReactPlayer
					ref={playerRef}
					url={'https://vimeo.com/824136261'}
					height={'100vh'}
					width={'100vw'}
					controls={false}
					playing={playing}
					volume={volume}
					onError={e => console.log(e)}
					controlsList="nodownload"
					onProgress={progress => {
						setCurrentTime(progress.playedSeconds);
					}}
					onDuration={time => {
						setDuration(time);
					}}
					playIcon={
						<IconButton
							w="64px"
							h="64px"
							icon={
								<PlayIcon
									isFilled={true}
									fillColor="black"
									color="black"
								/>
							}
							aria-label={'Play'}
						/>
					}
					light={!(playing || currentTime > 0)}
				/>
				<Controls
					controlsHided={controlsHided}
					playing={playing}
					volumeIsOpen={volumeIsOpen}
					currentTime={currentTime}
					duration={duration}
					volume={volume}
					handleSeek={handleSeek}
					togglePlay={togglePlay}
					volumeOnClose={volumeOnClose}
					volumeOnOpen={volumeOnOpen}
					toggleFullscreen={toggleFullscreen}
					setVolume={setVolume}
					playerButtonsSizeProps={
						BUTTONS_SIZE_VARIANTS[`${PlayerButtonsSize.BIG}`]
					}
				/>
			</Box>
		</AspectRatio>
	);
};

interface IVideoProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const VideoInstructionModal: React.FC<IVideoProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Modal
			isCentered={true}
			scrollBehavior={'inside'}
			isOpen={isOpen}
			onClose={onClose}>
			<ModalOverlay
				bg={'rgba(74, 89, 145, 0.60)'}
				backdropFilter={'blur(10px)'}
			/>
			<ModalContent maxW={'760px'}>
				<Button
					onClick={onClose}
					top={'-50px'}
					position={'absolute'}
					bg="dropBlue"
					color="blue.50"
					// leftIcon={
					// 	<Box transform={'rotate(180deg)'}>
					// 		<ArrowRightIcon
					// 			width={'20'}
					// 			height={'20px'}
					// 			color={'#4A5991'}
					// 		/>
					// 	</Box>
					// }
				>
					Close
				</Button>
				<ModalBody
					p={{
						base: '20px',
						md: '40px',
					}}
					borderRadius={'10px'}>
					<VStack align={'stretch'} spacing={'20px'}>
						<Flex
							alignSelf={'center'}
							align={'center'}
							bg="blue.50"
							borderRadius={'10px'}
							p={'10px'}>
							<Flex
								bg="dropBlue"
								boxSize={'44px'}
								align={'center'}
								justify={'center'}
								borderRadius={'10px'}>
								<LockIcon />
							</Flex>
							<Text
								fontWeight={'700'}
								ml={'10px'}
								color={'white'}
								variant={{
									base: 'bodyMedium',
									md: 'bodyLarge',
								}}>
								This section is not available to you yet
							</Text>
						</Flex>
						<Text
							textAlign={'center'}
							fontWeight={'700'}
							color="blue.50"
							variant={'bodyLarge'}>
							Watch the video instruction on how to access the
							software
						</Text>

						<VideoBox />

						<Button
							_hover={{
								textDecoration: 'none',
							}}
							isExternal={true}
							href={'http://section8formula.com/softwareupgrade'}
							as={Link}
							colorScheme={'successButton'}
							h={'59px'}>
							Buy Software
						</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
