/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useCallback } from 'react';
import {
	Avatar,
	Box,
	Button,
	Center,
	CloseButton,
	Flex,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { deleteToken, getMessaging } from 'firebase/messaging';
import { LinkItems, SUPER_USER_ID } from '../../../constants';
import { NavItem } from './NavItem';
import { Claim, ICurrentUser } from '../../../types';
import {
	useChatUserStore,
	useVideoInstructionStore,
	useNotificationsPermissionsStore,
} from '../../../store';
import {
	DropdownMenu,
	DropdownMenuTrigger,
} from '../../../pages/Messenger/common-components/Menu/Menu';
import { UserMenu } from './UserMenu';
import { NavAccordion } from '../../NavAccordion/NavAccordion';
import {
	CtrlLogo,
	DealFinder,
	RightPaginationArrowIcon,
	S8SmallLogo,
} from '../../../assets';
import { useDeleteDeviceToken } from '../../../pages/Messenger/queries';

interface IMenuContent {
	onClose: VoidFunction;
	isLoading: boolean;
	data?: ICurrentUser;
}

export const MenuContent: React.FC<IMenuContent> = ({
	onClose,
	isLoading,
	data,
}) => {
	const fullName = `${data?.firstName} ${data?.lastName}`;
	const claimsIds = data?.claimsIds || [];
	const isCommunityAvailable = data?.isCommunityAvailable;
	const isCommunityInstructionCompleted =
		data?.isCommunityInstructionCompleted;

	const { onOpen } = useVideoInstructionStore();
	const { setChatUser } = useChatUserStore();
	const { token } = useNotificationsPermissionsStore();
	const deleteDeviceTokenMutation = useDeleteDeviceToken();

	const getFilteredNavLinks = useCallback(() => {
		return LinkItems.filter(elem => {
			if (elem.id === Claim.Messenger) {
				if (claimsIds.includes(elem.id)) {
					return true;
				} else {
					return false;
				}
			}
			return true;
		});
	}, [claimsIds]);

	const location = useLocation();
	const { signOut } = useAuth();

	const onSignOut = async () => {
		setChatUser(null);
		try {
			await deleteToken(getMessaging());
		} catch (error) {
			console.log(error, 'deleteToken(getMessaging()) menu content');
		}
		if (token) {
			deleteDeviceTokenMutation.mutate(token);
		}
		await signOut();
	};

	const accordionItems = getFilteredNavLinks().filter(item =>
		Boolean(item.accordion),
	);

	const isSuperAdmin = data?.userId === SUPER_USER_ID;

	return (
		<Flex
			direction="column"
			align="space-between"
			px={'20px'}
			bg="blue.100"
			transition=".3s"
			w={{ base: 'full' }}
			overflowX="hidden"
			h="100vh"
			pos="fixed">
			<Flex
				justifyContent="space-between"
				alignItems="center"
				minH={'80px'}>
				<Flex alignItems={'center'}>
					<S8SmallLogo />
				</Flex>
				<CloseButton
					color="white"
					display={{ base: 'flex' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack>
				{accordionItems.length ? (
					<NavAccordion
						icon={<DealFinder />}
						title={'Deal Finder'}
						menuExpanded={true}>
						{accordionItems.map(item => (
							<NavItem
								onAction={
									!claimsIds.includes(item.id)
										? onOpen
										: undefined
								}
								isActive={location.pathname === item.link}
								key={item.text}
								icon={item.icon}
								link={item.link}
								isDisabled={
									!claimsIds.includes(item.id)
									// true
									// (permissionId === UserPermission.CourseOnly &&
									// 	item.link !== '/courses') ||
									// isLoading
								}
								menuExpanded={true}
								heightPadding={'8px'}>
								{item.text}
							</NavItem>
						))}
					</NavAccordion>
				) : null}
				{getFilteredNavLinks()
					.filter(item => Boolean(!item.accordion))
					.map(item => {
						// TODO change later claim to coaching
						const isCoachingTab = item.id === Claim.Coaching;
						// TODO change later claim to coaching

						if (isSuperAdmin && isCoachingTab) {
							return null;
						}
						const isCommunity = item.text === 'Community';
						const link = isCommunity
							? !isCommunityInstructionCompleted
								? '/instruction'
								: item.link
							: item.link;

						return (
							<NavItem
								onAction={
									!claimsIds.includes(item.id) && item.id > 0
										? onOpen
										: undefined
								}
								isActive={location.pathname === link}
								showAvailableBadge={
									isCommunity &&
									isCommunityAvailable &&
									!isCommunityInstructionCompleted
								}
								key={item.text}
								icon={item.icon}
								link={link}
								isDisabled={
									isCommunity
										? !isCommunityAvailable
										: isCoachingTab
										? !isCommunityInstructionCompleted ||
										  !isCommunityAvailable
										: Boolean(
												!claimsIds.includes(item.id) ||
													item.disabledBadgeText,
										  )
									// true
									// (permissionId === UserPermission.CourseOnly &&
									// 	item.link !== '/courses') ||
									// isLoading
								}
								disabledBadgeText={item.disabledBadgeText}
								menuExpanded={true}
								isInAccordion={item.accordion}
								heightPadding={'16px'}>
								{item.text}
							</NavItem>
						);
					})}
			</VStack>

			<Box mt={'auto'} mb="120px">
				<VStack spacing={'4px'} align={'stretch'}>
					<Button
						p={'3px'}
						to={'/cruz-ctrl/dashboard'}
						as={Link}
						h={'48px'}
						_hover={{
							bg: 'blue.200',
						}}
						color={'white'}
						bg={'transparent'}
						justifyContent={'space-between'}>
						<CtrlLogo height={20} width={90} />
						<RightPaginationArrowIcon
							width={20}
							height={20}
							fill={'currentcolor'}
						/>
					</Button>
				</VStack>
				<DropdownMenu>
					{isLoading ? (
						<Center>
							<Spinner size="lg" color="white" />
						</Center>
					) : (
						<Box>
							<DropdownMenuTrigger asChild={true}>
								<Flex
									cursor={'pointer'}
									align="center"
									justify="space-between">
									<Avatar
										name={fullName}
										// src={
										// 	data?.imageUrl ? data.imageUrl : ''
										// }
										boxSize={'50px'}
									/>
									<Flex
										direction="column"
										ml={'15px'}
										minWidth="100px"
										flex={1}>
										<Text color="white" fontSize="16px">
											{fullName}
										</Text>
										<Text
											color="gray.400"
											fontSize="12px"
											isTruncated={true}>
											{data?.email}
										</Text>
									</Flex>
								</Flex>
							</DropdownMenuTrigger>
						</Box>
					)}
					<UserMenu onSignOut={onSignOut} />
				</DropdownMenu>
			</Box>
		</Flex>
	);
};
