import { API_CHAT } from '../../../api/api.base';
import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';

export const useDeleteFriend = () => {
	return useMutation<ApiResponseEmpty, unknown, number>(
		(userId: number) =>
			API_CHAT.delete(`/api/app/friend/delete?userId=${userId}`, {}),
		{
			onSuccess(data) {
				if (data.success) {
					Alerter.success('Friend removed successfully!');
				}
			},
			onError(error) {
				Alerter.error(
					'Something went wrong when removing the user from friends',
				);
				console.log(error, 'er');
			},
		},
	);
};
