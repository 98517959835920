import React from 'react';

export const RefreshIcon2: React.FC = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.166 4.27182C15.9338 5.55976 17.0827 7.64604 17.0827 10.0007C17.0827 13.9127 13.9114 17.084 9.99935 17.084H9.58268M5.83268 15.7295C4.0649 14.4415 2.91602 12.3553 2.91602 10.0007C2.91602 6.08863 6.08733 2.91732 9.99935 2.91732H10.416M10.8327 18.6673L9.16602 17.0007L10.8327 15.334M9.16602 4.66732L10.8327 3.00065L9.16602 1.33398"
				stroke="#4A5991"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
