import { useMutation } from 'react-query';
import { Alerter } from '../../../utils';
import { API_IDENTITY } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useUpdateChannelsBanStatus = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ userId: number; isBanned: boolean; deleteMessage: boolean }
	>({
		mutationFn: payload =>
			API_IDENTITY.patch('/api/app/admin/users/ban-channels', payload),

		onSuccess(data, { isBanned }) {
			if (data.success) {
				Alerter.success(
					`User ${isBanned ? 'suspended' : 'activated'} in channels`,
				);
			} else {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Error while updating user channels suspend status',
				);
			}
		},
		onError(error) {
			Alerter.error(error as any);
		},
	});
};
