import {
	MessageReactionActionType,
	MessageReactionItem,
	MessageReactionType,
	ReactionUpdateItemEvent,
} from '../types';

export const updateReactionsOnDelete = (
	reactions: MessageReactionItem[],
	emojiId: number,
	reactionsCount: number,
	userIdReacted: number,
	currentUserId?: number,
) => {
	const isCurrentUserReacted = currentUserId === userIdReacted;

	return reactions
		.map(reaction => {
			if (reaction.emojiId === emojiId) {
				return {
					emojiId: reaction.emojiId,
					count: reactionsCount,
					userReacted: !isCurrentUserReacted,
				};
			}
			return reaction;
		})
		.filter(reaction => reaction.count !== 0);
};

export const updateReactionsOnCreate = (
	reactions: MessageReactionItem[],
	emojiId: number,
	reactionsCount: number,
	userIdReacted: number,
	currentUserId?: number,
) => {
	const isCurrentUserReacted = currentUserId === userIdReacted;
	const updatedReactions = reactions.map(reaction => {
		if (reaction.emojiId === emojiId) {
			return {
				emojiId: reaction.emojiId,
				count: reactionsCount,
				userReacted: isCurrentUserReacted,
			};
		}
		return reaction;
	});
	const isNewReaction = !updatedReactions.some(
		reaction => reaction.emojiId === emojiId,
	);

	const newMessageReaction = {
		emojiId: emojiId,
		count: 1,
		userReacted: isCurrentUserReacted,
	};

	return isNewReaction
		? [...updatedReactions, newMessageReaction]
		: updatedReactions;
};

export const updateReactionsOnEdit = (
	reactions: MessageReactionItem[],
	createReaction: ReactionUpdateItemEvent,
	deleteReaction: ReactionUpdateItemEvent,
	userIdReacted: number,
	currentUserId?: number,
) => {
	const isCurrentUserReacted = currentUserId === userIdReacted;
	const updatedReactions = reactions
		.map(reaction => {
			if (reaction.emojiId === createReaction.EmojiId) {
				return {
					emojiId: reaction.emojiId,
					count: createReaction.Count,
					userReacted: isCurrentUserReacted,
				};
			}
			if (reaction.emojiId === deleteReaction.EmojiId) {
				return {
					emojiId: reaction.emojiId,
					count: deleteReaction.Count,
					userReacted: !isCurrentUserReacted,
				};
			}
			return reaction;
		})
		.filter(reaction => reaction.count !== 0);

	const isNewReaction = !updatedReactions.some(
		reaction => reaction.emojiId === createReaction.EmojiId,
	);

	const newMessageReaction = {
		emojiId: createReaction.EmojiId,
		count: createReaction.Count,
		userReacted: isCurrentUserReacted,
	};

	return isNewReaction
		? [...updatedReactions, newMessageReaction]
		: updatedReactions;
};

export const updateDirectUserReactionsOnCreate = (
	reactions: MessageReactionItem[],
	emojiId: MessageReactionType,
) => {
	const updatedReactions = reactions.map(reaction => {
		if (reaction.emojiId === emojiId) {
			return {
				emojiId: reaction.emojiId,
				count: ++reaction.count,
				userReacted: true,
			};
		}
		return reaction;
	});
	const isNewReaction = !updatedReactions.some(
		reaction => reaction.emojiId === emojiId,
	);

	const newMessageReaction = {
		emojiId: emojiId,
		count: 1,
		userReacted: true,
	};

	return isNewReaction
		? [...updatedReactions, newMessageReaction]
		: updatedReactions;
};

export const updateDirectUserReactionsOnEdit = (
	reactions: MessageReactionItem[],
	emojiId: MessageReactionType,
) => {
	const updatedReactions = reactions
		.map(reaction => {
			if (reaction.emojiId === emojiId) {
				return {
					emojiId: reaction.emojiId,
					count: ++reaction.count,
					userReacted: true,
				};
			}
			if (reaction.userReacted) {
				return {
					emojiId: reaction.emojiId,
					count: --reaction.count,
					userReacted: false,
				};
			}
			return reaction;
		})
		.filter(reaction => reaction.count !== 0);

	const isNewReaction = !updatedReactions.some(
		reaction => reaction.emojiId === emojiId,
	);

	const newMessageReaction = {
		emojiId,
		count: 1,
		userReacted: true,
	};

	return isNewReaction
		? [...updatedReactions, newMessageReaction]
		: updatedReactions;
};

export const updateDirectUserReactionsOnDelete = (
	reactions: MessageReactionItem[],
	emojiId: MessageReactionType,
) => {
	return reactions
		.map(reaction => {
			if (reaction.emojiId === emojiId) {
				return {
					emojiId: reaction.emojiId,
					count: --reaction.count,
					userReacted: false,
				};
			}
			return reaction;
		})
		.filter(reaction => reaction.count !== 0);
};

export const updateDirectUserSelfReactions = (
	reactions: MessageReactionItem[],
	emojiId: MessageReactionType,
	actionType: MessageReactionActionType,
) => {
	switch (actionType) {
		case MessageReactionActionType.CREATE:
			return updateDirectUserReactionsOnCreate(reactions, emojiId);
		case MessageReactionActionType.DELETE:
			return updateDirectUserReactionsOnDelete(reactions, emojiId);
		default:
			return updateDirectUserReactionsOnEdit(reactions, emojiId);
	}
};
