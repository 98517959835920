import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useMarkAsReadChannel = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ channelId: number; dateTime: string }
	>({
		mutationKey: [MessengerQueryKeys.MARK_AS_READ_CHANNEL],
		mutationFn: payload =>
			API_CHAT.post('/api/app/channel/messages/read', payload),

		onError(error) {
			console.log(error, 'mark-as-read-channel error');
		},
	});
};
