import React, { PropsWithChildren } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { PlusIcon } from '../../../../../assets';

interface IProps {
	onAppend: VoidFunction;
}

export const InvoiceItemsWrapper: React.FC<PropsWithChildren<IProps>> = ({
	onAppend,
	children,
}) => {
	return (
		<Box bg={'white'} borderRadius={'8px'}>
			<Flex
				borderBottom={'1px solid #F1F4F9'}
				p={'16px'}
				align={'center'}
				justify={'space-between'}>
				<Text color={'blue.100'} fontWeight={600}>
					Invoice Items
				</Text>
				<Button
					onClick={onAppend}
					size={'sm'}
					color={'blue.50'}
					leftIcon={<PlusIcon />}
					variant={'link'}>
					Add Invoice Item
				</Button>
			</Flex>
			{children}
		</Box>
	);
};
