import React from 'react';
import { BaseIconType } from '../../types';

export const RightPaginationArrowIcon: React.FC<BaseIconType> = ({
	fill,
	width = 24,
	height = 23,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9 17.25L15 11.5L9 5.75"
				stroke={fill || '#1C2959'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
