/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, {
	useState,
	SetStateAction,
	Dispatch,
	useCallback,
	useImperativeHandle,
	useRef,
	useEffect,
	useMemo,
	BaseSyntheticEvent,
} from 'react';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { MessageInputReply } from './MessageInputReply';
import { ReplyMessage } from '../types';
import { EmojiPicker } from '../common-components';
import { MentionsInput, Mention } from '../../../components/Mentions';
import { queryMentionUsers } from '../utils';
import { useChatUserStore } from '../../../store';
import { MessageAttachmentPicker } from './Message/MessageAttachmentPicker';
import { USER_PERSISTED_KEY } from '../../../constants';
import { useDynamicDebounce, usePersistData } from '../../../hooks';
import { MessageAttachmentViewer } from './Message/MessageAttachmentViewer';
import { useTextEditor } from '../hooks/useTextEditor';
import { TextEditor } from './TextEditor';

interface IMessageInputProps {
	onSubmit?: (value: string, file: File | null) => void;
	replyMessage: ReplyMessage | null;
	setReplyMessage: Dispatch<SetStateAction<ReplyMessage | null>>;
	sendingMessageLoading?: boolean;
	enableMentions?: boolean;
	chatId?: string | null;
	chatType: string;
}
export interface IMessageInputRef {
	focusInput: VoidFunction;
}

export const MessageInput = React.forwardRef<
	IMessageInputRef,
	IMessageInputProps
>(
	(
		{
			replyMessage,
			setReplyMessage,
			onSubmit,
			sendingMessageLoading,
			enableMentions = true,
			chatId,
			chatType,
		},
		ref,
	) => {
		const { storageLoading, getPersistedValue, updatePersistedValue } =
			usePersistData(USER_PERSISTED_KEY);

		const [message, setMessage] = useState('');
		const [attachment, setAttachment] = useState<File | null>(null);

		const inputRef = useRef<HTMLInputElement>(null);

		const updateStorageValue = (newValue: string) => {
			if (!chatId) {
				return;
			}
			updatePersistedValue(chatType, { [`${chatId}`]: newValue });
		};

		useDynamicDebounce(message, 500, updateStorageValue);

		const { user } = useChatUserStore();

		const {
			selectedText,
			appliedStyles,
			handleTextSelection,
			replaceSelection,
			toggleQuoteOnLine,
			showPreview,
			setShowPreview,
		} = useTextEditor(inputRef, message, setMessage);

		useEffect(() => {
			if (chatId && !storageLoading) {
				const draftMessageForId = getPersistedValue(
					chatType,
					chatId,
				) as string;
				setMessage(draftMessageForId || '');
			}
		}, [chatId, storageLoading]);

		const handleSubmit = useCallback(
			async (e: React.FormEvent<HTMLFormElement>) => {
				e.preventDefault();

				if (!attachment && message.trim() === '') {
					return;
				}
				if (sendingMessageLoading) {
					return;
				}
				onSubmit?.(message, attachment);
				setMessage('');
				setAttachment(null);
			},
			[sendingMessageLoading, message, attachment],
		);

		useImperativeHandle(ref, () => ({
			focusInput: () => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			},
		}));

		const renderAttachmentViewer = useMemo(
			() => (
				<MessageAttachmentViewer
					file={attachment}
					removeFile={() => setAttachment(null)}
					isMessageReplyTo={Boolean(replyMessage)}
				/>
			),
			[attachment, replyMessage],
		);

		return (
			<Box position="relative" mt="auto">
				{replyMessage ? (
					<MessageInputReply
						name={replyMessage.sentBy}
						onCloseReply={() => setReplyMessage(null)}
					/>
				) : null}
				<Flex align="center">
					<Box flex={1}>
						<form onSubmit={handleSubmit}>
							<HStack
								p="10px 15px 10px 15px"
								bg="white"
								borderRadius="10px"
								position="relative">
								{renderAttachmentViewer}
								<MessageAttachmentPicker
									isFileAlreadyUploaded={Boolean(attachment)}
									onFileUploadCb={(file: File) =>
										setAttachment(file)
									}
								/>
								<TextEditor
									toggleQuoteOnLine={toggleQuoteOnLine}
									replaceSelection={replaceSelection}
									selectedText={selectedText}
									appliedStyles={appliedStyles}
									showPreview={showPreview}
								/>
								<MentionsInput
									inputRef={inputRef}
									onSelect={(event: BaseSyntheticEvent) => {
										handleTextSelection(event);
									}}
									value={message}
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									onChange={(val: any) =>
										setMessage(val.target.value)
									}
									forceSuggestionsAboveCursor={true}
									placeholder="Enter message"
									onKeyDown={(
										ev: React.KeyboardEvent<HTMLInputElement>,
									) => {
										if (
											ev.key === 'Enter' &&
											!ev.shiftKey
										) {
											handleSubmit(ev as any);
										}
									}}>
									<Mention
										displayTransform={(
											id: string,
											display: string,
										) => `@${display}`}
										trigger="@"
										data={
											enableMentions
												? (
														q: string,
														callback: (
															val: any[],
														) => void,
												  ) =>
														queryMentionUsers(
															q,
															callback,
															user?.userId,
															user?.isAdmin,
														)
												: []
										}
										appendSpaceOnAdd={true}
										// isLoading={true}
									/>
								</MentionsInput>
								<Button
									onClick={() =>
										setShowPreview(prevState => !prevState)
									}>
									Aa
								</Button>
								<EmojiPicker
									onChange={emoji => {
										setMessage(prev =>
											`${prev.trim()} ${emoji}`.trim(),
										);
									}}
									isButtonLoading={sendingMessageLoading}
								/>
							</HStack>
						</form>
					</Box>
				</Flex>
			</Box>
		);
	},
);

MessageInput.displayName = 'MessageInput';
