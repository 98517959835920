import React, { ChangeEvent, FC, memo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { PaperClipIcon } from '../../../../assets/messenger-icons/PaperClipIcon';
import { Alerter } from '../../../../utils';
import { checkFileType } from '../../utils';
import { FileAttachmentType } from '../../constants';

interface MessageAttachmentPickerProps {
	isFileAlreadyUploaded: boolean;
	onFileUploadCb?: (file: File) => void;
}

export const MessageAttachmentPicker: FC<MessageAttachmentPickerProps> = memo(
	({ isFileAlreadyUploaded, onFileUploadCb }) => {
		const fileInputRef = React.useRef<HTMLInputElement>(null);

		const handleFile = (
			file: File,
			maxSize: number,
			errorMessage: string,
		) => {
			if (file && file.size > maxSize) {
				Alerter.error(errorMessage);
				if (!fileInputRef.current) {
					return;
				}

				fileInputRef.current.value = '';
				return;
			}
			onFileUploadCb?.(file);

			if (!fileInputRef.current) {
				return;
			}

			fileInputRef.current.value = '';
		};

		const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
			const uploadedFile = e?.target?.files?.[0];

			const fileType = checkFileType(uploadedFile?.type || '');
			const isImageOrDoc =
				fileType === FileAttachmentType.DOC ||
				fileType === FileAttachmentType.IMAGE;
			const isVideo = fileType === FileAttachmentType.VIDEO;

			if (!uploadedFile) {
				return;
			}

			if (isImageOrDoc) {
				const maxSizeInBytes = 25 * 1024 * 1024;
				handleFile(
					uploadedFile,
					maxSizeInBytes,
					'File size exceeds 25MB',
				);
				return;
			}
			if (isVideo) {
				const maxSizeInBytes = 50 * 1024 * 1024;
				handleFile(
					uploadedFile,
					maxSizeInBytes,
					'File size exceeds 50MB',
				);
				return;
			}
		};

		return (
			<>
				{!isFileAlreadyUploaded ? (
					<IconButton
						aria-label={'upload-attachment'}
						icon={<PaperClipIcon />}
						onClick={() => {
							fileInputRef.current?.click();
						}}
					/>
				) : null}
				<input
					onChange={handleFileUpload}
					accept=".png, .jpg, .jpeg, .pdf, .mp4, .mov"
					ref={fileInputRef}
					type={'file'}
					style={{ display: 'none' }}
				/>
			</>
		);
	},
);
