import { useCallback, useState } from 'react';
import { Message } from 'ably';
import { SOCKET_EVENTS } from '../../constants';
import { NewFriendAddedEventData, UserData } from '../../types';
import { useChatUserStore } from '../../../../store';

export const useHandleAllTabSocketEvents = () => {
	const { user } = useChatUserStore();
	const [allFriends, setAllFriends] = useState<UserData[]>([]);

	const handleAllTabSocketEvents = useCallback((event: Message) => {
		switch (event.name) {
			case SOCKET_EVENTS.NEW_FRIEND_ADDED_EVENT:
				{
					const newFriendData: {
						Value: NewFriendAddedEventData;
					} = JSON.parse(event.data);

					const {
						SenderId,
						IsAdmin,
						DisplayName,
						AvatarPath,
						UserStatus,
						Email,
					} = newFriendData.Value;

					if (SenderId === user?.userId) {
						return;
					}

					const payload = {
						userId: SenderId,
						displayName: DisplayName,
						avatarStoragePath: AvatarPath,
						about: null,
						isAdmin: IsAdmin,
						isEveryoneAndHereDisabled: false,
						isHighlightsDisabled: false,
						isRoleMentionDisabled: false,
						isServerMuted: false,
						userStatus: UserStatus || 2,
						email: Email,
					};

					setAllFriends(prev => [...prev, payload]);
				}
				break;
			case SOCKET_EVENTS.USER_BLOCKED_EVENT:
				{
					const unblockedUser: {
						Value: { BlockedId: number };
					} = JSON.parse(event.data);

					setAllFriends(prev =>
						prev.filter(
							item =>
								item.userId !== unblockedUser.Value.BlockedId,
						),
					);
				}
				break;
			case SOCKET_EVENTS.FRIEND_DELETED_EVENT:
				{
					const deletedFriendRequest: {
						Value: {
							InitiatorUserId: number;
							DeletedUserId: number;
						};
					} = JSON.parse(event.data);

					setAllFriends(prev =>
						prev.filter(
							req =>
								req.userId !==
								deletedFriendRequest.Value.DeletedUserId,
						),
					);
					setAllFriends(prev =>
						prev.filter(
							req =>
								req.userId !==
								deletedFriendRequest.Value.InitiatorUserId,
						),
					);
				}
				break;
			default:
				break;
		}
	}, []);

	return { handleAllTabSocketEvents, allFriends, setAllFriends };
};
