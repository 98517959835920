import React from 'react';

export const LeftPaginationArrowIcon: React.FC<{
	color?: string;
	width?: number;
	height?: number;
}> = ({ color, width, height }) => {
	return (
		<svg
			width={width || '24'}
			height={height || '23'}
			viewBox="0 0 24 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 5.75L9 11.5L15 17.25"
				stroke={color || '#1C2959'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
