import React from 'react';
import { Box, Avatar, Text, HStack, Skeleton } from '@chakra-ui/react';
import { UserByIdData } from '../../types';
import { AdminVerificationSmall } from '../../../../assets';

interface IFriendInfoSidebarProps extends Partial<UserByIdData> {
	isLoaded: boolean;
	isCurrentUserAdmin?: boolean;
}

export const FriendInfoSidebar: React.FC<IFriendInfoSidebarProps> = ({
	about,
	// createdAt,
	displayName,
	// email,
	nickName,
	// userId,
	// photoId,
	avatarUrl,
	isLoaded,
	isAdmin,
	isCurrentUserAdmin,
	email,
}) => {
	// const isLoaded = Boolean(nickName);

	return (
		<Box p="10px 32px">
			<Box height="75px" width="100%" bg="#F7E7D1" borderRadius="4px" />
			{/* <Skeleton borderRadius="50%" isLoaded={isLoaded}> */}
			<Avatar
				boxSize="100px"
				mt="-40px"
				ml="10px"
				mb="10px"
				src={isLoaded ? avatarUrl || '' : ''}
			/>
			{/* </Skeleton> */}
			<Box
				p="15px 20px"
				bg="blue.100"
				borderRadius="4px 4px 0px 0px"
				mb="1px">
				{isAdmin ? (
					<Skeleton isLoaded={isLoaded}>
						<HStack mb="8px">
							<AdminVerificationSmall width="24" height="24" />
							<Text fontSize="16px" color="inactiveText">
								Admin
							</Text>
						</HStack>
					</Skeleton>
				) : null}
				<Skeleton isLoaded={isLoaded}>
					<Text
						fontSize="20px"
						color="white"
						fontWeight={700}
						mb="10px">
						{displayName || '-'}
					</Text>
				</Skeleton>
				<Skeleton isLoaded={isLoaded}>
					<Text color="blue.50" fontSize="16px">
						{nickName || '-'}
					</Text>
				</Skeleton>
				{isCurrentUserAdmin && email ? (
					<Skeleton isLoaded={isLoaded} mt="10px">
						<Text w="100%" color="inactiveText" isTruncated={true}>
							{email}
						</Text>
					</Skeleton>
				) : null}
			</Box>
			<Box p="15px 20px" bg="blue.100" borderRadius="0px 0px 4px 4px">
				<Text color="white" mb="10px">
					About
				</Text>
				<Skeleton isLoaded={isLoaded}>
					<Text color="blue.50" whiteSpace="pre-wrap">
						{about || '-'}
					</Text>
				</Skeleton>
			</Box>
		</Box>
	);
};
