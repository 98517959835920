import { Flex, useRadioGroup } from '@chakra-ui/react';
import { CustomRadioButton } from '../../../components';
import { FC } from 'react';

export enum ADD_NEW_TENANT_OPTIONS_VALUES {
	ADD_NEW_TENANT = 1,
	SELECT_EXISTING_TENANT = 2,
}

export const ADD_NEW_TENANT_OPTIONS_LABELS = {
	[ADD_NEW_TENANT_OPTIONS_VALUES.ADD_NEW_TENANT]: 'Add New Tenant',
	[ADD_NEW_TENANT_OPTIONS_VALUES.SELECT_EXISTING_TENANT]:
		'Select Archived Tenant',
} as const;

const RADIO_OPTIONS = [
	{
		value: ADD_NEW_TENANT_OPTIONS_VALUES.ADD_NEW_TENANT,
		label: ADD_NEW_TENANT_OPTIONS_LABELS[
			ADD_NEW_TENANT_OPTIONS_VALUES.ADD_NEW_TENANT
		],
	},
	{
		value: ADD_NEW_TENANT_OPTIONS_VALUES.SELECT_EXISTING_TENANT,
		label: ADD_NEW_TENANT_OPTIONS_LABELS[
			ADD_NEW_TENANT_OPTIONS_VALUES.SELECT_EXISTING_TENANT
		],
	},
];

interface AddNewTenantActionsProps {
	onChangeFn: (val: string) => void;
}

export const AddNewTenantActions: FC<AddNewTenantActionsProps> = ({
	onChangeFn,
}) => {
	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'tenant-action',
		defaultValue: String(ADD_NEW_TENANT_OPTIONS_VALUES.ADD_NEW_TENANT),
		onChange: val => onChangeFn(val),
	});

	const group = getRootProps();

	return (
		<Flex
			{...group}
			flexDirection={{ base: 'column', xl: 'row' }}
			gap={{ base: '15px', xl: '' }}>
			{RADIO_OPTIONS.map(option => {
				const value = String(option.value);
				const radio = getRadioProps({ value });
				return (
					<CustomRadioButton key={option.value} {...radio}>
						{option.label}
					</CustomRadioButton>
				);
			})}
		</Flex>
	);
};
