import { BusinessEntityDetails } from '../types';

export function getEntityValues(data: BusinessEntityDetails) {
	return {
		entityName: data.businessEntity.entityName,
		ownerName: data.businessEntity.ownerName,
		// mailbox: {
		// 	value: data.businessEntity.mailbox?.id,
		// 	label: data.businessEntity.mailbox?.email,
		// },
		location: data.businessEntity.location,
		timeZoneId: data.businessEntity.timeZoneId,
	};
}
