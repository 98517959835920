export enum MyProfileRoutes {
	MY_PROFILE = 'my-profile',
	NAME = 'name',
	PHONE = 'phone',
	PASSWORD = 'password',
}

export const BREADCRUMBS_ITEMS = [
	{
		label: 'Home',
		route: '/',
	},
	{
		label: 'My S8 Profile',
		route: null,
	},
];

export const MyProfileItems: Record<MyProfileRoutes, string> = {
	[MyProfileRoutes.MY_PROFILE]: 'My S8 Profile',
	[MyProfileRoutes.NAME]: 'Name',
	[MyProfileRoutes.PHONE]: 'Phone',
	[MyProfileRoutes.PASSWORD]: 'Password',
};
