import MarkdownIt from 'markdown-it';

export function mentionPlugin(md: MarkdownIt) {
	md.inline.ruler.before('text', 'mention', state => {
		const startPos = state.pos;
		const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
		regex.lastIndex = startPos;
		const match = regex.exec(state.src);

		if (match && match.index === startPos) {
			const token = state.push('mention', '', 0);
			token.content = match[0];

			state.pos += match[0].length;
			return true;
		}

		return false;
	});
}
