import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const editCategorySchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
});

export type EditCategoryForm = yup.InferType<typeof editCategorySchema>;
export const editCategoryResolver = yupResolver(editCategorySchema);
