import { useCallback, useState } from 'react';
import { Message } from 'ably';
import { SOCKET_EVENTS } from '../../constants';
import { UserData } from '../../types';

export const useHandleBlockedTabSocketEvents = () => {
	const [blockedUsers, setBlockedUsers] = useState<UserData[]>([]);

	const handleBlockedTabSocketEvents = useCallback((event: Message) => {
		switch (event.name) {
			case SOCKET_EVENTS.USER_UNBLOCKED_EVENT:
				{
					const unblockedUser: {
						Value: { UserId: number };
					} = JSON.parse(event.data);

					setBlockedUsers(prev =>
						prev.filter(
							user => user.userId !== unblockedUser.Value.UserId,
						),
					);
				}
				break;

			default:
				break;
		}
	}, []);

	return { handleBlockedTabSocketEvents, blockedUsers, setBlockedUsers };
};
