import { useEffect, useState } from 'react';

export const useDynamicDebounce = (
	value: string,
	delay = 500,
	cb?: (value: string) => void,
) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	const updateDebouncedValue = (newValue: string) => {
		setDebouncedValue(newValue);
		cb?.(newValue);
	};

	useEffect(() => {
		if (value.length === debouncedValue.length) {
			return;
		}

		if (value.length > debouncedValue.length) {
			// delay on input some new symbols
			const handler = setTimeout(
				() => updateDebouncedValue(value),
				delay,
			);

			return () => {
				clearTimeout(handler);
			};
		} else {
			// immediately change on removing from string
			updateDebouncedValue(value);
		}
	}, [value, delay]);

	return debouncedValue;
};
