import { Flex, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { colors } from '../../../theme';

interface ProfileFormNavBtnProps {
	name: string;
	value?: string;
	nextRoute?: string;
}

export const ProfileFormNavBtn: FC<ProfileFormNavBtnProps> = ({
	name,
	value,
	nextRoute,
}) => {
	return (
		<Link
			as={nextRoute ? RouterLink : undefined}
			to={nextRoute || ''}
			_hover={{ textDecoration: 'none' }}>
			<Flex
				bg={'inherit'}
				transition={'all .1s ease-in'}
				borderRadius={'10px'}
				alignItems={'center'}
				padding={'10px 16px'}
				color={'blue.200'}
				_hover={{
					color: 'white',
					bg: 'blue.200',
					border: '1px solid #1C2959',
				}}
				border={`1px solid ${colors.dropBlue}`}
				justifyContent={'start'}
				w={'100%'}
				gap={'10px'}>
				<Text
					fontSize={'14px'}
					w={'auto'}
					mr={{ base: 'auto', lg: '0' }}
					minW={'70px'}
					textAlign={'start'}
					color={'inherit'}>
					{name}
				</Text>
				<Text
					display={{ base: 'none', lg: 'block' }}
					whiteSpace={'nowrap'}
					textOverflow={'ellipsis'}
					overflow={'hidden'}
					color={'inherit'}
					mr={'auto'}>
					{value}
				</Text>
				{nextRoute ? (
					<ChevronRightIcon color={'inherit'} boxSize={6} />
				) : null}
			</Flex>
		</Link>
	);
};
