import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { CalendarIcon } from '../../../assets';
import dayjs from 'dayjs';

interface IProps {
	value?: string | null;
	variant?: 'left' | 'right';
}

export const FilterButtonTrigger = React.forwardRef<null, IProps>(
	({ value, variant = 'left', ...rest }, ref) => {
		const placeholder = variant === 'left' ? 'Start Date' : 'End Date';

		const leftRadius = variant === 'left' ? '30px' : '0px';
		const rightRadius = variant === 'left' ? '0px' : '30px';
		const borderLeft = variant === 'left' ? 'none' : '1px solid #BDCDE9';

		return (
			<Button
				borderLeft={borderLeft}
				justifyContent={'flex-start'}
				borderLeftRadius={leftRadius}
				borderRightRadius={rightRadius}
				display={'flex'}
				alignItems={'center'}
				p={'8px 6px'}
				bg={'mainBg'}
				minW={'100px'}
				size={'sm'}
				ref={ref}
				{...rest}
				variant={'unstyled'}>
				<HStack spacing={'4px'}>
					<CalendarIcon width={16} height={16} />
					<Text
						color={value ? 'blue.300' : 'inactiveText'}
						variant={'bodySmall'}>
						{value
							? dayjs(value).format('MM/DD/YYYY')
							: placeholder}
					</Text>
				</HStack>
			</Button>
		);
	},
);

FilterButtonTrigger.displayName = 'FilterButtonTrigger';
