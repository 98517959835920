import React from 'react';

interface IProps {
	width?: number;
	height?: number;
	fill?: string;
}
export const LockIcon: React.FC<IProps> = ({ width, height, fill }) => {
	return (
		<svg
			width={width || '25'}
			height={height || '24'}
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.5 10V8C17.5 5.23858 15.2614 3 12.5 3C9.73858 3 7.5 5.23858 7.5 8V10M12.5 14.5V16.5M9.3 21H15.7C17.3802 21 18.2202 21 18.862 20.673C19.4265 20.3854 19.8854 19.9265 20.173 19.362C20.5 18.7202 20.5 17.8802 20.5 16.2V14.8C20.5 13.1198 20.5 12.2798 20.173 11.638C19.8854 11.0735 19.4265 10.6146 18.862 10.327C18.2202 10 17.3802 10 15.7 10H9.3C7.61984 10 6.77976 10 6.13803 10.327C5.57354 10.6146 5.1146 11.0735 4.82698 11.638C4.5 12.2798 4.5 13.1198 4.5 14.8V16.2C4.5 17.8802 4.5 18.7202 4.82698 19.362C5.1146 19.9265 5.57354 20.3854 6.13803 20.673C6.77976 21 7.61984 21 9.3 21Z"
				stroke={fill || '#4A5991'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
