import { create } from 'zustand';

type StoreType = {
	isOpen: boolean;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
};

export const useConversationModalStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false }),
}));
