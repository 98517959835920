import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const profileSchema = yup.object().shape({
	firstName: yup.string().required('This field is required'),
	lastName: yup.string().required('This field is required'),
	phoneNumber: yup.string(),
});

export type MyProfileForm = yup.InferType<typeof profileSchema>;
export const profileResolver = yupResolver(profileSchema);

const phoneSchema = yup.object().shape({
	phone: yup
		.string()
		.matches(/^\d{10}$/, 'Phone number must be 10 digits')
		.required('This field is required'),
});

export type MyProfilePhoneForm = yup.InferType<typeof phoneSchema>;
export const profilePhoneResolver = yupResolver(phoneSchema);
