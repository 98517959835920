import { Dispatch, SetStateAction } from 'react';

export const imageFileToDataUrl = (
	setImageSrc: Dispatch<SetStateAction<string>>,
	file?: File,
) => {
	if (!file) {
		return;
	}

	const imageSrc = '';

	const reader = new FileReader();
	reader.addEventListener('load', () => {
		setImageSrc(reader.result?.toString() || '');
	});
	reader.readAsDataURL(file);

	if (imageSrc) {
		return imageSrc;
	}
};
