import React from 'react';
import ReactSelect, { Props } from 'react-select';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { OptionType } from '../../types';
import { stylesConfig } from './stylesConfig';

interface ICustomSelect extends Props<OptionType> {
	errMsg?: string;
	label?: string;
	size?: 'md' | 'lg';
}

export const CustomReactSelect: React.FC<ICustomSelect> = ({
	errMsg,
	label,
	size = 'md',
	...props
}) => {
	return (
		<FormControl isInvalid={!!errMsg}>
			{label ? (
				<FormLabel
					noOfLines={1}
					mr={0}
					fontSize={'12px'}
					lineHeight={'18px'}
					color={props.isDisabled ? 'textGray.200' : 'inactiveText'}
					fontWeight={500}>
					{label}
				</FormLabel>
			) : null}
			<ReactSelect
				aria-invalid={Boolean(errMsg)}
				styles={stylesConfig(size)}
				components={{
					IndicatorSeparator: () => null,
				}}
				{...props}
			/>
			<FormErrorMessage
				fontSize={'10px'}
				lineHeight={'12px'}
				color={'#DC2D2D'}
				mt={'4px'}>
				{errMsg}
			</FormErrorMessage>
		</FormControl>
	);
};
