import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import React from 'react';

interface IEmptyListMessageProps {
	text: string;
	wrapperProps?: FlexProps;
	textProps?: TextProps;
}

export const EmptyListMessage: React.FC<IEmptyListMessageProps> = ({
	text,
	wrapperProps,
	textProps,
}) => {
	return (
		<Flex h="100%" align="center" justify="center" {...wrapperProps}>
			<Text variant="bodyMedium" color="blue.50" {...textProps}>
				{text}
			</Text>
		</Flex>
	);
};
