import React, { useMemo, useState } from 'react';
import {
	Box,
	Popover,
	PopoverTrigger,
	IconButton,
	PopoverContent,
	PopoverBody,
	Text,
	HStack,
	Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { PinnedMessagesList } from './PinnedMessagesList';
import { PinIcon } from '../../../../../assets';
import { PinMessageConfirmModal } from '../../../modals';
import { ChatType, EditMessageDisplayData } from '../../../types';
import {
	useChatUserStore,
	usePinnedMessagesPopupOpenStore,
} from '../../../../../store';
import {
	useGetChannelPinnedMessages,
	useUnPinAllChannelMessages,
	useUnPinChannelMessage,
} from '../../../queries';
import { useQueryClient } from 'react-query';
import { MessengerQueryKeys } from '../../../queries/query-keys';

export const PinnedMessagesPopup: React.FC = () => {
	const [messageToUnPin, setMessageToUnPin] =
		useState<EditMessageDisplayData | null>(null);

	const { id } = useParams();
	const { user } = useChatUserStore();
	const queryClient = useQueryClient();
	const { isOpen, onClose, onOpen } = usePinnedMessagesPopupOpenStore();
	const { mutateAsync: unPinMessage } = useUnPinChannelMessage();
	const { mutateAsync: unPinAllMessage, isLoading: unPinMessageLoading } =
		useUnPinAllChannelMessages();

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isLoading: infiniteLoading,
	} = useGetChannelPinnedMessages(id ? +id : null, isOpen);

	const pinnedMessages = useMemo(
		() => data?.pages?.flatMap(page => page?.messages || []) || [],
		[data],
	);

	const onUnPinAllMessages = async (channelId?: string) => {
		try {
			if (!channelId) {
				return;
			}
			const res = await unPinAllMessage(+channelId);
			if (!res.success) {
				return;
			}
			queryClient.invalidateQueries([
				MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES,
				+channelId,
			]);
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	const onUnPinMessage = async (messageId: number) => {
		try {
			if (!id) {
				return;
			}
			const res = await unPinMessage(messageId);
			if (!res.success) {
				return;
			}
			queryClient.invalidateQueries([
				MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES,
				+id,
			]);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box>
			<Popover
				isLazy={true}
				isOpen={isOpen}
				closeOnBlur={false}
				closeOnEsc={true}
				onClose={onClose}
				onOpen={onOpen}>
				<PopoverTrigger>
					<IconButton aria-label="Notifications" bg="white">
						<PinIcon />
					</IconButton>
				</PopoverTrigger>
				<PopoverContent minW="450px">
					<PopoverBody p="20px" bg="mainBg">
						<HStack
							justifyContent="space-between"
							alignItems="center"
							mb="10px"
							rowGap="8px">
							<Text
								fontSize="20px"
								color="blue.200"
								fontWeight={700}>
								Pinned messages
							</Text>
							{pinnedMessages?.length && user?.isAdmin ? (
								<Button
									bg="dangerButton.500"
									colorScheme="red"
									p="15px 20px"
									ml="0px"
									isLoading={unPinMessageLoading}
									onClick={() => onUnPinAllMessages(id)}
									fontWeight={500}>
									Unpin all
								</Button>
							) : null}
						</HStack>
						<PinnedMessagesList
							// channelId={id}
							onSetMessageToUnPin={setMessageToUnPin}
							isAdmin={!!user?.isAdmin}
							pinnedMessages={pinnedMessages}
							fetchNextPage={fetchNextPage}
							hasNextPage={hasNextPage}
							infiniteLoading={infiniteLoading}
						/>
					</PopoverBody>
				</PopoverContent>
			</Popover>
			<PinMessageConfirmModal
				isOpen={!!messageToUnPin}
				onClose={() => setMessageToUnPin(null)}
				message={messageToUnPin}
				action={onUnPinMessage}
				variant="remove"
				chatType={ChatType.CHANNEL}
			/>
		</Box>
	);
};
