import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export function isNewDay(date1: Date, date2: Date) {
	return !dayjs(date1).isSame(dayjs(date2), 'day');
}

export function chatDividerFormat(date: Date) {
	return dayjs(date).format('MMMM D, YYYY');
}

export function getTimeDifference(date1: Date, date2: Date) {
	return dayjs(date1).diff(dayjs(date2), 'minutes');
}

export function getTime(date: Date) {
	return dayjs(date).format('hh:mm A');
}

export function getTimeAndDate(date: Date) {
	return dayjs(date).format('MM.DD.YYYY, hh:mm A');
}

export function isDateBetween(
	dateToCheck?: Date,
	startDate?: Date,
	endDate?: Date,
) {
	if (!dateToCheck) {
		return false;
	}
	const date = dayjs(dateToCheck);
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	return date.isBetween(start, end, 'seconds', '(]');
}
