/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { UnreadMessagesBanner } from '../../messages-components';

interface IServerMessagesUnreadBannerProps {
	topScrollRef: React.RefObject<HTMLSpanElement>;
	onMarkAsRead: VoidFunction;
	lastReadTime?: string;
	unreadCount: number;
}

export const ServerMessagesUnreadBanner: React.FC<
	IServerMessagesUnreadBannerProps
> = ({ topScrollRef, onMarkAsRead, lastReadTime, unreadCount }) => {
	if (unreadCount && unreadCount > 0 && lastReadTime) {
		return (
			<UnreadMessagesBanner
				count={unreadCount}
				onBannerClick={() => topScrollRef.current?.scrollIntoView()}
				onMarkAsRead={onMarkAsRead}
				lastReadDate={new Date(lastReadTime)}
			/>
		);
	}

	return null;
};
