import React from 'react';
import { Box, Flex, IconButton, Image, Skeleton } from '@chakra-ui/react';
import { ClearIcon } from '../../../../../../assets/icons/ClearIcon';
import { CheckIcon } from '../../../../../../assets';

interface IImageItem {
	imageSrc?: string;
	onRemove: VoidFunction;
	onSelect: VoidFunction;
	isSelected: boolean;
}

export const ImageItem: React.FC<IImageItem> = ({
	imageSrc,
	isSelected,
	onSelect,
	onRemove,
}) => {
	return (
		<Box
			borderRadius={'8px'}
			overflow={'hidden'}
			h={'144px'}
			pos={'relative'}>
			<Image
				fallback={
					<Skeleton borderRadius={'8px'} h={'144px'} w={'full'} />
				}
				objectFit={'contain'}
				alt={'property-image'}
				src={imageSrc}
				w={'full'}
				h={'full'}
			/>
			<IconButton
				onClick={onRemove}
				h={'24px'}
				minW={'24px'}
				top={'10px'}
				right={'10px'}
				color={'white'}
				variant={'tab-selected'}
				pos={'absolute'}
				aria-label={'remove'}
				icon={<ClearIcon color={'currentcolor'} />}
			/>
			<Flex
				onClick={onSelect}
				as={'button'}
				pos={'absolute'}
				bottom={'8px'}
				left={'8px'}
				border={'2px solid #08BB4B'}
				boxSize={'24px'}
				borderRadius={'full'}
				justify={'center'}
				align={'center'}
				bg={isSelected ? 'success' : 'white'}>
				{isSelected ? <CheckIcon stroke={'white'} /> : null}
			</Flex>
		</Box>
	);
};
