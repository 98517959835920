/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import Downshift from 'downshift';
import {
	Avatar,
	AvatarBadge,
	Box,
	Flex,
	Input,
	InputProps,
	List,
	ListItem,
	Text,
} from '@chakra-ui/react';
import { useDebounce } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { HREFs } from '../../constants';
import { useSuggestionUsers } from '../../hooks';
import { useChatUserStore } from '../../../../store';
import { SearchUsersByNameData } from '../../types';

export interface IAutocompleteInputRef {
	resetValue: () => void;
}

interface IDirectAutoCompleteProps extends InputProps {
	onSelectItem?: (user: SearchUsersByNameData | null) => void;
	onResetItems?: () => void;
	withRedirect?: boolean;
	inputProps?: InputProps;
	isNonFriendsSearch?: boolean;
}

interface IItem {
	photoURL?: string;
	displayName: string;
	isOnline?: boolean;
	email?: string | null;
	isCurrentUserAdmin?: boolean;
}

const Item: React.FC<IItem> = ({
	displayName,
	photoURL,
	isOnline,
	email,
	isCurrentUserAdmin,
}) => (
	<Flex flex={1} align="center">
		<Avatar boxSize="25px" src={photoURL}>
			<AvatarBadge
				display={isOnline ? 'flex' : 'none'}
				bottom={0}
				right="4px"
				border="none"
				boxSize="8px"
				bg="success"
			/>
		</Avatar>
		<Text ml="10px" maxW="60%" noOfLines={1}>
			{displayName}
		</Text>
		{isCurrentUserAdmin && email ? (
			<Text
				maxW="40%"
				ml="5px"
				fontSize="14px"
				lineHeight="16px"
				color="textInactive"
				isTruncated={true}>
				{email}
			</Text>
		) : null}
	</Flex>
);

export const DirectAutoComplete = forwardRef<
	IAutocompleteInputRef,
	IDirectAutoCompleteProps
>(({ withRedirect, inputProps, onSelectItem, isNonFriendsSearch }, ref) => {
	const [searchQuery, setSearchQuery] = useState('');

	const inputRef = useRef<HTMLInputElement>(null);

	const debounceQuery = useDebounce(searchQuery);
	const navigate = useNavigate();

	const { user } = useChatUserStore();

	const { items, setItems, isFetched } = useSuggestionUsers(
		debounceQuery,
		user,
		isNonFriendsSearch,
	);

	const onReset = useCallback(() => {
		setSearchQuery('');
		setItems([]);
	}, []);

	useImperativeHandle(ref, () => ({
		resetValue: () => {
			onReset();
		},
	}));

	const isListEmpty = useMemo(
		() =>
			!!searchQuery?.length &&
			searchQuery === debounceQuery &&
			isFetched &&
			!items?.length,
		[searchQuery, isFetched, items],
	);

	return (
		<Downshift
			onOuterClick={() => onReset()}
			onInputValueChange={inputValue => {
				if (!inputValue) {
					onSelectItem?.(null);
				}
				setSearchQuery(inputValue);
			}}
			onSelect={item => {
				if (!item) {
					onReset();
					onSelectItem?.(null);
					return;
				}
				if (!withRedirect) {
					onSelectItem?.(item);
					setSearchQuery(item.displayName);
					return;
				}
				navigate(HREFs.DIRECT + '/' + item.userId);

				onReset();
			}}
			itemToString={() => ''}>
			{({
				getRootProps,
				getItemProps,
				getMenuProps,
				isOpen,
				getInputProps,
				highlightedIndex,
			}) => (
				<Box {...getRootProps()} position={'relative'} w={'full'}>
					<Input
						bg={'mainBg'}
						placeholder={'Find a user'}
						_placeholder={{
							color: '#7E788F',
							fontWeight: '500',
						}}
						{...(getInputProps({
							ref: inputRef,
							value: searchQuery,
						}) as InputProps)}
						{...inputProps}
					/>
					<Box
						bg="transparent"
						w="100%"
						position="absolute"
						zIndex={50}>
						<List
							{...getMenuProps()}
							maxHeight="150px"
							bg={'gray.200'}
							spacing={'5px'}
							mt={'5px'}
							overflowY="auto">
							{isOpen ? (
								isListEmpty ? (
									<Flex
										bg="gray.200"
										h="45px"
										align="center"
										justify="center">
										<Text
											fontSize="14px"
											lineHeight="16px"
											color="textInactive">
											Not found
										</Text>
									</Flex>
								) : (
									items.map((item, index) => (
										<ListItem
											key={item.userId}
											px={'10px'}
											py={'10px'}
											cursor="pointer"
											backgroundColor={
												highlightedIndex === index
													? 'lightgrey'
													: 'white'
											}
											{...getItemProps({
												key: item.userId,
												item,
												index,
											})}>
											<Item
												isOnline={false}
												displayName={item.displayName}
												photoURL={
													item?.avatarStoragePath ||
													''
												}
												isCurrentUserAdmin={
													user?.isAdmin
												}
												email={item.email}
											/>
										</ListItem>
									))
								)
							) : null}
						</List>
					</Box>
				</Box>
			)}
		</Downshift>
	);
});

DirectAutoComplete.displayName = 'DirectAutoComplete';
