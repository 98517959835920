import React, { useEffect } from 'react';
import {
	chakra,
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormErrorMessageProps,
	FormLabel,
	FormLabelProps,
	Input,
	InputGroup,
	InputGroupProps,
	InputLeftElement,
	InputProps,
	InputRightElement,
	InputLeftElementProps,
} from '@chakra-ui/react';
import { Callback, StateChangeOptions } from 'downshift';
import { colors } from '../../theme';

interface IInputProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	formLabelProps?: FormLabelProps;
	leftIcon?: JSX.Element;
	rightIcon?: JSX.Element;
	onRightIconClick?: VoidFunction | null;
	clearSelection?: (cb?: Callback) => void;
	name?: string;
	clearValues?: string[];
	disabledInputs?: {
		[key: string]: boolean;
	};
	toggleMenu?: (
		otherStateToSet?: Partial<StateChangeOptions<any>> | undefined,
		cb?: Callback | undefined,
	) => void;
	isRequired?: boolean;
	inputGroupProps?: InputGroupProps;
	errorMessageProps?: FormErrorMessageProps;
	leftElementProps?: InputLeftElementProps;
}

export const InputText = React.forwardRef<HTMLInputElement, IInputProps>(
	(
		{
			errorMsg,
			label,
			leftIcon,
			rightIcon,
			formControlProps,
			formLabelProps,
			onRightIconClick,
			clearSelection,
			clearValues,
			disabledInputs,
			toggleMenu,
			isRequired,
			type = 'text',
			inputGroupProps,
			errorMessageProps,
			leftElementProps,
			...rest
		},
		ref,
	) => {
		// in this hook, we filter the clearValues array, and if it contains the name of the component, we clear its input completely
		useEffect(() => {
			const result = clearValues?.filter(val => val === rest.name);
			if (result) {
				if (result[0] === rest.name) {
					if (clearSelection) {
						clearSelection();
					}
				}
			}
		}, [clearValues]);

		return (
			<FormControl isInvalid={!!errorMsg} {...formControlProps}>
				{label ? (
					<FormLabel
						noOfLines={1}
						mb="8px"
						mr={0}
						fontSize="14px"
						lineHeight="16px"
						fontWeight={500}
						{...formLabelProps}>
						{label}{' '}
						{isRequired ? (
							<chakra.span color="#FF3636">*</chakra.span>
						) : null}
					</FormLabel>
				) : null}
				<InputGroup {...inputGroupProps}>
					{leftIcon ? (
						<InputLeftElement h="100%" {...leftElementProps}>
							{leftIcon}
						</InputLeftElement>
					) : null}
					<Input
						type={type}
						border="2px solid transparent"
						w={'full'}
						_invalid={{
							boxShadow: '0px 0px 0px 1px #FF3636',
							borderColor: '#FF3636',
						}}
						_placeholder={{
							color: '#7E788F',
						}}
						height="40px"
						fontSize={'16px'}
						fontWeight={500}
						bg={'white'}
						color={'blue.300'}
						borderRadius={'10px'}
						_hover={{
							border: `2px solid ${colors.dropBlue}`,
						}}
						_focus={{
							border: `2px solid ${colors.successButton['500']}`,
							boxShadow: 'none',
						}}
						ref={ref}
						isDisabled={
							disabledInputs && rest.name
								? disabledInputs[rest.name]
								: false
						}
						{...rest}
					/>
					{rightIcon ? (
						<InputRightElement
							cursor={
								disabledInputs && rest.name
									? disabledInputs[rest.name]
										? 'not-allowed'
										: 'cursor'
									: 'cursor'
							}
							onClick={() => {
								if (onRightIconClick) {
									onRightIconClick();
								} else {
									if (toggleMenu) {
										toggleMenu();
									}
								}
							}}
							h={'100%'}>
							{rightIcon}
						</InputRightElement>
					) : null}
				</InputGroup>
				<FormErrorMessage {...errorMessageProps}>
					{errorMsg}
				</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
