import React, { memo } from 'react';
import { Avatar, Box, HStack, VStack, Text } from '@chakra-ui/react';
import { Author } from '../../pages/Course/types';

export const InstructorBox: React.FC<Author> = memo(
	({ name, description, imageLink }) => (
		<Box w="full" bg="white" p="15px" mt="20px" borderRadius="10px">
			<HStack mb="16px" spacing="10px">
				<Avatar w="50px" h="50px" src={imageLink} />
				<VStack alignItems="flex-start" spacing={0}>
					<Text fontSize="16px" fontWeight={700} lineHeight="25px">
						{name}
					</Text>
					<Text fontSize="14px" lineHeight="22px">
						Instructor
					</Text>
				</VStack>
			</HStack>
			<Text fontSize="16px">{description}</Text>
		</Box>
	),
);

InstructorBox.displayName = 'InstructorBox';
