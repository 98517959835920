export const patternsArray = ['*', '~', '`', '%'];

export function dealWithDuplicates(delimiters: string[]) {
	const result = [...delimiters],
		alreadyCombined: string[] = [];
	result.forEach((elem, index) => {
		if (
			index + 1 < result.length &&
			elem === result[index + 1] &&
			!alreadyCombined.includes(elem)
		) {
			alreadyCombined.push(elem);
			result[index] = elem + elem;
			result.splice(index + 1, 1);
		}
	});
	return result;
}

export const getSurroundSubstring = (
	message: string,
	startPos: number | null,
	endPos: number | null,
) => {
	if (!startPos || !endPos) {
		return [];
	}

	const delimiters = [];
	let start = startPos,
		end = endPos;

	let charPrev = message[start - 1];
	let charAfter = message[end];

	while (charPrev === charAfter) {
		if (
			start === 0 ||
			end === message.length ||
			!patternsArray.includes(charPrev) ||
			!patternsArray.includes(charAfter)
		) {
			break;
		}
		delimiters.push(charPrev);
		--start;
		++end;
		charPrev = message[start - 1];
		charAfter = message[end];
	}

	return dealWithDuplicates(delimiters);
};

export function isLineStartsWithQuote(
	text: string,
	selectionStart: number,
	selectionEnd: number,
) {
	const lineStart = text.lastIndexOf('\n', selectionStart - 1) + 1;

	let lineEnd = text.indexOf('\n', selectionEnd);

	if (lineEnd === -1) {
		lineEnd = text.length;
	}
	const currentLine = text.substring(lineStart, lineEnd);
	return currentLine.trim().startsWith('>');
}
