import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';

const BusinessEntitySchema = yup.object().shape({
	entityName: yup.string().required(ErrorMessages.REQUIRED),
	ownerName: yup.string().required(ErrorMessages.REQUIRED),
	// mailbox: yup
	// 	.object()
	// 	.shape({
	// 		value: yup.number(),
	// 		label: yup.string(),
	// 	})
	// 	.nullable(),
	// .test('custom', ErrorMessages.REQUIRED, function (value) {
	// 	return value !== null && !isNaN(value?.value);
	// }),
	location: yup
		.object()
		.shape({
			address: yup.string().required(ErrorMessages.REQUIRED),
			longitude: yup.number().required(ErrorMessages.REQUIRED),
			latitude: yup.number().required(ErrorMessages.REQUIRED),
		})
		.default(undefined)
		.required(ErrorMessages.REQUIRED),
	timeZoneId: yup.string().required(ErrorMessages.REQUIRED),
});

export type BusinessEntityFormType = yup.InferType<typeof BusinessEntitySchema>;

export const businessEntityResolver = yupResolver(BusinessEntitySchema);
