import React from 'react';
import { Box, Center, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { formatFileSize } from '../../../../../../utils';
import { FileIcon, RefreshIcon2, TrashIcon } from '../../../../../../assets';

interface IFilePreview {
	file: File;
	onRefresh: VoidFunction;
	onRemove: VoidFunction;
}

export const FilePreview: React.FC<IFilePreview> = ({
	file,
	onRefresh,
	onRemove,
}) => {
	return (
		<Flex
			borderRadius={'8px'}
			border={'1px solid #E4EAF5'}
			px={'16px'}
			py={'8px'}
			justify={'space-between'}
			align={'center'}>
			<HStack spacing={'8px'}>
				<Center
					borderRadius={'full'}
					bg={'lightBlue.100'}
					minW={'40px'}
					minH={'40px'}>
					<FileIcon strokeColor={'#0F1B49'} />
				</Center>
				<Box>
					<Text
						noOfLines={1}
						fontWeight={600}
						color={'blue.50'}
						variant={'bodyMedium'}>
						{file.name}
					</Text>
					<Text variant={'bodySmall'} color={'inactiveText'}>
						{formatFileSize(file.size)}
					</Text>
				</Box>
			</HStack>
			<HStack spacing={'8px'}>
				<IconButton
					onClick={onRefresh}
					variant={'ghost'}
					size={'sm'}
					aria-label={'refresh'}
					icon={<RefreshIcon2 />}
				/>
				<IconButton
					onClick={onRemove}
					variant={'ghost'}
					size={'sm'}
					aria-label={'remove'}
					icon={
						<TrashIcon
							height={'22'}
							width={'22'}
							strokeColor={'#4A5991'}
						/>
					}
				/>
			</HStack>
		</Flex>
	);
};
