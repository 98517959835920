import { FC } from 'react';

interface ClearIconProps {
	height?: string;
	width?: string;
	color?: string;
}

export const ClearIcon: FC<ClearIconProps> = ({
	height = '14',
	width = '14',
	color = '#0F1B49',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13 1L1 13M1 1L13 13"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
