import React from 'react';
import {
	HStack,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	Button,
	useDisclosure,
	Box,
} from '@chakra-ui/react';
import { FiMessageSquare, FiMoreVertical } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useBlockUser, useDeleteFriend } from '../../../../queries';

interface INormalControlsProps {
	friendId: number;
}

export const NormalControls: React.FC<INormalControlsProps> = ({
	friendId,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { isLoading: blockUserLoading, mutateAsync: blockUser } =
		useBlockUser();
	const { isLoading: deleteFriendLoading, mutateAsync: deleteFriend } =
		useDeleteFriend();

	const onBlock = async () => {
		try {
			await blockUser(friendId);
			onClose();
		} catch (error) {
			console.log(error, 'block error');
		}
	};

	const onFriendDelete = async () => {
		try {
			await deleteFriend(friendId);
			onClose();
		} catch (error) {
			console.log(error, 'block error');
		}
	};

	return (
		<HStack>
			<IconButton
				aria-label="Message"
				bg="mainBg"
				borderRadius="50%"
				as={Link}
				to={`${friendId}`}
				color="blue.50">
				<FiMessageSquare />
			</IconButton>
			<Box overflow={'hidden'}>
				<Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
					<PopoverTrigger>
						<IconButton
							aria-label="Message"
							bg="mainBg"
							borderRadius="50%"
							color="blue.50">
							<FiMoreVertical />
						</IconButton>
					</PopoverTrigger>
					<PopoverContent border="none">
						<PopoverBody
							p="20px"
							bg="mainBg"
							boxShadow="0px 4px 8px 0px rgba(28, 41, 89, 0.40)"
							borderRadius="10px">
							<HStack
								flexWrap={'wrap'}
								gap={'10px'}
								justifyContent={'center'}>
								<Button
									bg="dangerButton.500"
									colorScheme="red"
									p="15px 20px"
									isLoading={blockUserLoading}
									onClick={onBlock}
									fontWeight={500}>
									Block User
								</Button>
								<Button
									bg="dangerButton.500"
									colorScheme="red"
									p="15px 20px"
									isLoading={deleteFriendLoading}
									onClick={onFriendDelete}
									fontWeight={500}>
									Delete Friend
								</Button>
							</HStack>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Box>
		</HStack>
	);
};
