import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';

interface IPayload {
	tenantId: number;
	data: FormData;
}

export const useReactivateTenant = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, IPayload>({
		mutationFn: ({ tenantId, data }) =>
			API_CTRL.post(`/api/app/tenants/${tenantId}/reactivate`, data),
		onSuccess: (data, { tenantId }) => {
			if (data.success) {
				Alerter.success('Reactivated successfully');
				queryClient.invalidateQueries([CruzQueryKeys.CurrentTenants]);
				queryClient.invalidateQueries([CruzQueryKeys.ArchivedTenants]);
				queryClient.invalidateQueries([
					CruzQueryKeys.TenantDetails,
					tenantId,
				]);
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
