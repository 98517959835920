import { Flex, IconButton, Image, Text, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FileIcon } from '../../../../../assets';
import { ViewAttachmentPhotoModal } from '../../../modals/ViewAttachmentPhotoModal/ViewAttachmentPhotoModal';
import React, { FC, useState } from 'react';
import {
	MessageAttachmentFile,
	MessageAttachmentImage,
	MessageAttachmentVideo,
} from '../../../types';
import { CustomPlayer, Loader } from '../../../../../components';
import { useDownloadFile } from '../../../hooks';
import { PlayerButtonsSize } from '../../../../../components/CustomPlayer/types';

interface MessageAttachmentsProps {
	messageAttachmentFiles: MessageAttachmentFile[];
	messageAttachmentImages: MessageAttachmentImage[];
	messageAttachmentVideos: MessageAttachmentVideo[];
	disabledAttachmentView?: boolean;
}

export const MessageAttachments: FC<MessageAttachmentsProps> = ({
	messageAttachmentImages,
	messageAttachmentFiles,
	disabledAttachmentView,
	messageAttachmentVideos,
}) => {
	const { onOpen, isOpen, onClose } = useDisclosure();
	const { downloadByLink, loading: downloadLoading } = useDownloadFile();

	const { userAgent } = navigator;
	const isSafari =
		userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1;
	const [isVideoReady, setIsVideoReady] = useState(isSafari ? true : false);

	if (
		!messageAttachmentFiles?.length &&
		!messageAttachmentImages?.length &&
		!messageAttachmentVideos?.length
	) {
		return null;
	}

	return (
		<>
			{messageAttachmentFiles.map(attachment => (
				<Flex
					key={attachment.link}
					as={Link}
					to={attachment.link}
					target={'_blank'}
					bg={'dropBlue'}
					maxW={'250px'}
					h={'60px'}
					borderRadius={'5px'}
					p={'10px'}
					alignItems={'center'}
					gap={'10px'}>
					<IconButton
						aria-label={'download file'}
						borderRadius={'50%'}
						bg={'#0F1B49B2'}
						_hover={{}}
						icon={<FileIcon strokeColor={'white'} />}
					/>
					<Text isTruncated={true} color={'blue.200'}>
						{attachment.fileName}
					</Text>
				</Flex>
			))}
			{messageAttachmentImages.map(attachment => (
				<React.Fragment key={attachment.link}>
					<Image
						onClick={disabledAttachmentView ? undefined : onOpen}
						cursor={disabledAttachmentView ? 'default' : 'pointer'}
						src={attachment.thumbnailLink || attachment.link}
						alt={''}
						maxW={'300px'}
						w={'100%'}
					/>
					<ViewAttachmentPhotoModal
						isOpen={isOpen}
						onClose={onClose}
						imgPathLarge={attachment.largeImageLink}
						imgPathOriginal={attachment.link}
						name={
							attachment.storagePath?.split('/images/')?.[1] || ''
						}
						canDownload={true}
					/>
				</React.Fragment>
			))}
			{messageAttachmentVideos.map(attachment => (
				<Flex
					key={attachment.id}
					maxH="300px"
					h="300px"
					w="auto"
					minW="200px"
					position="relative">
					{isVideoReady ? null : (
						<Flex
							position="absolute"
							w="100%"
							maxW="500px"
							height="300px"
							alignItems="center"
							justify="center">
							<Loader
								centerProps={{
									width: '100%',
									height: '100%',
								}}
							/>
						</Flex>
					)}
					<CustomPlayer
						videoLink={attachment.link}
						downloadLoading={downloadLoading}
						playerButtonsSize={PlayerButtonsSize.MEDIUM}
						enableSpaceBarPlayToggle={false}
						preload="metadata"
						onDownload={() => {
							if (attachment.link) {
								downloadByLink(
									attachment.link,
									'video',
									attachment.fileName,
								);
							}
						}}
						setIsVideoReady={bool => setIsVideoReady(bool)}
						errorCoverProps={{
							maxW: '500px',
						}}
						wrapperProps={{
							opacity: isVideoReady ? 1 : 0,
							height: '300px',
							bg: 'black',
							maxHeight: '300px',
							width: {
								base: 'auto',
								lg: 'auto',
							},
							maxWidth: '500px',
							borderRadius: '0px',
						}}
					/>
				</Flex>
			))}
		</>
	);
};
