enum RealEstateExperience {
	Beginner = 1,
	Intermediate = 2,
	Advanced = 3,
}

enum SoonLookingPeriod {
	Now = 1,
	OneMonth = 2,
	ThreePlusMonths = 3,
}

enum CashAvailableToInvestSize {
	ZeroFive = 1,
	FiveTen = 2,
	TenTwentyFive = 3,
	TwentyFivePlus = 4,
}

export const realEstateExperienceOptions = [
	{
		label: 'Beginner (I own 0 properties)',
		value: RealEstateExperience.Beginner,
	},
	{
		label: 'Intermediate (I own 2-3 properties)',
		value: RealEstateExperience.Intermediate,
	},
	{
		label: 'Advanced ( I own 5 plus properties)',
		value: RealEstateExperience.Advanced,
	},
];

export const soonLookingPeriodOptions = [
	{
		label: 'Now',
		value: SoonLookingPeriod.Now,
	},
	{
		label: '1 month',
		value: SoonLookingPeriod.OneMonth,
	},
	{
		label: '3 plus months',
		value: SoonLookingPeriod.ThreePlusMonths,
	},
];

export const investmentAmountOptions = [
	{ label: '0-5k', value: CashAvailableToInvestSize.ZeroFive },
	{ label: '5-10k', value: CashAvailableToInvestSize.FiveTen },
	{ label: '10-25k', value: CashAvailableToInvestSize.TenTwentyFive },
	{ label: '25k+', value: CashAvailableToInvestSize.TwentyFivePlus },
];
