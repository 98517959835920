import React, { useState } from 'react';
import { Button, Checkbox } from '@chakra-ui/react';
import { FilePreview } from './FilePreview';
import { InputText } from '../../../../../../components';

interface IProps {
	file: File;
	onRefresh: VoidFunction;
	onRemove: VoidFunction;
	setFileToUpload?: (value: File) => void;
	onClose: VoidFunction;
	onUploadFile?: (value: File, cb?: VoidFunction) => void;
	isLoading?: boolean;
	uploadBtnText?: string;
}

export const UploadFile: React.FC<IProps> = ({
	onRemove,
	file,
	onRefresh,
	setFileToUpload,
	onClose,
	onUploadFile,
	isLoading,
	uploadBtnText,
}) => {
	const [isChecked, setIsChecked] = useState(false);
	const [value, setValue] = useState(
		file.name.replace(/\.(pdf|jpeg|png|jpg)$/, ''),
	);

	const uploadFile = () => {
		const payload =
			isChecked && !!value.length
				? new File(
						[file],
						`${value}${file.name.match(/\.\w+$/)?.[0]}`,
						{
							type: file.type,
						},
				  )
				: file;
		if (setFileToUpload) {
			setFileToUpload?.(payload);
			onClose();
		}
		if (onUploadFile) {
			onUploadFile?.(payload, onClose);
		}
	};

	return (
		<>
			<FilePreview
				file={file}
				onRefresh={onRefresh}
				onRemove={onRemove}
			/>
			<Checkbox
				checked={isChecked}
				onChange={e => {
					setIsChecked(e.target.checked);
				}}
				alignSelf={'flex-start'}
				variant={'cruzctrl-checkbox'}>
				Rename file
			</Checkbox>
			{isChecked ? (
				<InputText
					value={value}
					onChange={event => {
						setValue(event.target.value);
					}}
					label={'File Name'}
				/>
			) : null}
			<Button
				isLoading={isLoading}
				isDisabled={isLoading}
				onClick={uploadFile}
				maxW={'200px'}
				w={'full'}
				alignSelf={'center'}
				variant={'cruzctrl-solid'}>
				{uploadBtnText}
			</Button>
		</>
	);
};
