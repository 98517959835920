import React from 'react';
import {
	Box,
	Avatar,
	Text,
	Button,
	chakra,
	HStack,
	Flex,
} from '@chakra-ui/react';
import { AdminVerificationSmall } from '../../../../assets';
import { UserByIdData } from '../../types';
import {
	useAddFriendByNickname,
	useBlockUser,
	useDeleteFriend,
	useUnBlockUser,
} from '../../queries';
import { useUpdateUserInfoById } from '../../hooks';

interface IStartOfDirectChatProps {
	receiver?: UserByIdData | null;
	receiverId?: string;
}

export const StartOfDirectChat: React.FC<IStartOfDirectChatProps> = ({
	receiver,
	receiverId,
}) => {
	const {
		nickName,
		isFriendRequestSent,
		isFriend,
		isBlockingMe,
		isBlocked,
		displayName,
		avatarUrl,
		isAdmin,
	} = receiver || {};

	const { mutate: updateUserInfoById } = useUpdateUserInfoById();

	const { isLoading: unBlockLoading, mutateAsync: unBlockUser } =
		useUnBlockUser();
	const { isLoading: blockLoading, mutateAsync: blockUser } = useBlockUser();
	const { isLoading: addFriendLoading, mutateAsync: addFriendByNickname } =
		useAddFriendByNickname();
	const { isLoading: deleteFriendLoading, mutateAsync: deleteFriend } =
		useDeleteFriend();

	const unBlock = async () => {
		try {
			if (!receiverId) {
				return;
			}
			await unBlockUser(+receiverId);
			updateUserInfoById(+receiverId, { isBlocked: false });
		} catch (error) {
			console.log(error, 'unblock error');
		}
	};

	const block = async () => {
		try {
			if (!receiverId) {
				return;
			}
			await blockUser(+receiverId);
			updateUserInfoById(+receiverId, { isBlocked: true });
		} catch (error) {
			console.log(error, 'block error');
		}
	};

	const requestFriendship = async () => {
		try {
			if (!nickName || !receiverId) {
				return;
			}
			await addFriendByNickname(nickName);
			updateUserInfoById(+receiverId, { isFriendRequestSent: true });
		} catch (error) {
			console.log(error, 'friend request error');
		}
	};

	const unFriend = async () => {
		try {
			if (!receiverId) {
				return;
			}
			await deleteFriend(+receiverId);
			updateUserInfoById(+receiverId, { isFriend: false });
		} catch (error) {
			console.log(error, 'unfriend error');
		}
	};

	return (
		<Box mb="20px">
			<Avatar boxSize="100px" mb="20px" src={avatarUrl || ''} />
			<Flex alignItems="center" gap="5px" mb="15px">
				<Text fontSize="25px" color="blue.200" fontWeight={700}>
					{displayName}
				</Text>
				{isAdmin ? (
					<>
						<AdminVerificationSmall width="24" height="24" />
						<Text fontSize="16px" color="inactiveText">
							Admin
						</Text>
					</>
				) : null}
			</Flex>
			{isBlockingMe ? null : (
				<Text mb="20px" fontSize="16px" color="blue.200">
					This will be your personal message history with{' '}
					<chakra.span fontWeight={700}>{displayName}</chakra.span>
				</Text>
			)}
			{isBlockingMe ? null : (
				<HStack>
					{isFriendRequestSent && !isFriend ? (
						<Text color="blue.50">Friend request sent</Text>
					) : (
						<>
							{!isBlocked ? (
								<Button
									bg="blue.100"
									colorScheme="blue"
									p="15px 20px"
									height="auto"
									fontWeight={500}
									isLoading={
										addFriendLoading || deleteFriendLoading
									}
									onClick={
										isFriend ? unFriend : requestFriendship
									}>
									{isFriend ? 'Remove friend' : 'Add Friend'}
								</Button>
							) : null}
						</>
					)}
					{!isAdmin ? (
						<Button
							bg="dangerButton.500"
							colorScheme="red"
							height="auto"
							p="15px 20px"
							fontWeight={500}
							isLoading={unBlockLoading || blockLoading}
							onClick={isBlocked ? unBlock : block}>
							{isBlocked ? 'Unblock' : 'Block'}
						</Button>
					) : null}
				</HStack>
			)}
		</Box>
	);
};
