import React, { ReactNode, isValidElement } from 'react';

interface IItemList<T> {
	items: T[];
	renderItem: (item: T, index: number) => ReactNode;
	keyExtractor: (item: T, index: number) => number | string;
	ListEmptyComponent?: ReactNode;
}

export function ItemList<T>({
	items,
	renderItem,
	keyExtractor,
	ListEmptyComponent,
}: IItemList<T>): JSX.Element | null {
	if (items.length === 0 && isValidElement(ListEmptyComponent)) {
		return ListEmptyComponent;
	}

	return (
		<>
			{items?.map((item, index) => (
				<React.Fragment key={keyExtractor(item, index)}>
					{renderItem(item, index)}
				</React.Fragment>
			))}
		</>
	);
}
