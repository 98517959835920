/* eslint-disable indent */
import React from 'react';
import {
	Button,
	chakra,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import {
	OnboardingForm,
	onboardingResolver,
} from '../../pages/Messenger/validation';
import { useCustomCampaignMutation } from './queries';
import { Select } from './components';
import {
	investmentAmountOptions,
	realEstateExperienceOptions,
	soonLookingPeriodOptions,
} from './constants';
import { SelectOption } from '../../types';
import { transformSelectOptions } from './utils';

interface IOnboardingFormModalProps {
	isOpen: boolean;
}

export const OnboardingFormModal: React.FC<IOnboardingFormModalProps> = ({
	isOpen,
}) => {
	const { handleSubmit, control } = useForm<OnboardingForm>({
		resolver: onboardingResolver,
	});

	const customCampaignMutation = useCustomCampaignMutation();

	const onSubmit = (data: OnboardingForm) => {
		customCampaignMutation.mutate(data);
	};

	return (
		<Modal
			isOpen={isOpen}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			onClose={() => {}}
			size="xl">
			<ModalOverlay
				bg={'rgba(74, 89, 145, 0.60)'}
				backdropFilter={'blur(10px)'}
			/>
			<ModalContent>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody
						p={{
							base: '20px',
							md: '40px',
						}}
						borderRadius={'10px'}>
						<Text fontSize="24px" color="blue.300" fontWeight={700}>
							Section 8 Course Promo Onboarding Form
						</Text>
						<Text color="blue.300" fontSize="16px" mt="8px">
							Please fill out the form below and then move to the
							2nd step of the onboarding process.
						</Text>
						<Text
							color="blue.300"
							fontSize="14px"
							fontWeight={400}
							mt="16px"
							mb="24px">
							<chakra.span color="#FF3636">*</chakra.span> -
							required selection
						</Text>
						<VStack spacing="16px" mb="40px">
							<Controller
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<Select
										onChange={newValue => {
											onChange(
												(newValue as SelectOption)
													.value,
											);
										}}
										value={transformSelectOptions(
											soonLookingPeriodOptions,
											value,
										)}
										options={soonLookingPeriodOptions}
										errMsg={error?.message}
										isRequired={true}
										label="How soon do you want to get started?"
										placeholder="Select"
									/>
								)}
								name={'soonLookingId'}
								control={control}
							/>
							<Controller
								name="cashAvailableToInvestId"
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<Select
										onChange={newValue => {
											onChange(
												(newValue as SelectOption)
													.value,
											);
										}}
										value={transformSelectOptions(
											investmentAmountOptions,
											value,
										)}
										isRequired={true}
										options={investmentAmountOptions}
										errMsg={error?.message}
										label="How much capital do you have available to deploy?"
										placeholder="Select"
									/>
								)}
							/>
							<Controller
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<Select
										onChange={newValue => {
											onChange(
												(newValue as SelectOption)
													.value,
											);
										}}
										value={transformSelectOptions(
											realEstateExperienceOptions,
											value,
										)}
										options={realEstateExperienceOptions}
										isRequired={true}
										errMsg={error?.message}
										label="What’s your real estate experience?"
										placeholder="Select"
									/>
								)}
								name={'realEstateExperienceId'}
								control={control}
							/>
						</VStack>
						<Button
							colorScheme="green"
							bg="success"
							w="100%"
							height="60px"
							type="submit"
							isLoading={customCampaignMutation.isLoading}>
							Submit
						</Button>
					</ModalBody>
				</form>
			</ModalContent>
		</Modal>
	);
};
