import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';
import { MessengerQueryKeys } from './query-keys';

export const useDeleteUserChannelMessageByAdmin = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: messageId =>
			API_CHAT.delete(
				`/api/admin/channel/message/${messageId}/delete`,
				{},
			),
		mutationKey: [MessengerQueryKeys.DELETE_CHANNEL_MESSAGE_BY_ADMIN],
		onSuccess(res) {
			if (!res.success) {
				Alerter.error(
					res.errors?.[0]?.message || 'Error while deleting message',
				);
				return;
			}
			Alerter.success('Message successfully deleted');
		},
		onError(error) {
			console.log(error, 'delete-channel-message-by-admin error');
			Alerter.error('Error while deleting message');
		},
	});
};
