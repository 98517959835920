/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback } from 'react';
import { BoxProps, Button, Flex, VStack } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { LinkItems, SUPER_USER_ID } from '../../../constants';
import { NavItem } from './NavItem';
import { ExpandedButton } from './ExpandedButton';
import { Claim, ICurrentUser } from '../../../types';
import { useVideoInstructionStore } from '../../../store';

import {
	CtrlLogo,
	CtrlSmallLogo,
	DealFinder,
	RightPaginationArrowIcon,
	S8MainLogo,
	S8SmallLogo,
} from '../../../assets';
import { SideBarFooter } from './SideBarFooter';
import { useSignOut } from '../../../utils';
import { NavAccordion } from '../../NavAccordion/NavAccordion';

interface SidebarProps extends BoxProps {
	onClose: VoidFunction;
	menuExpanded: boolean;
	toggleMenu: VoidFunction;
	data?: ICurrentUser;
	isLoading: boolean;
}

export const SidebarContent: React.FC<SidebarProps> = ({
	onClose,
	menuExpanded,
	toggleMenu,
	data,
	isLoading,
	...rest
}) => {
	const location = useLocation();

	const onSignOut = useSignOut();

	const {
		firstName,
		lastName,
		isCommunityAvailable,
		isCommunityInstructionCompleted,
	} = data || {};

	const fullName = `${firstName} ${lastName}`;
	const claimsIds = data?.claimsIds || [];
	const { onOpen } = useVideoInstructionStore();

	const getFilteredNavLinks = useCallback(() => {
		return LinkItems.filter(elem => {
			if (elem.id === Claim.Messenger) {
				if (claimsIds.includes(elem.id)) {
					return true;
				} else {
					return false;
				}
			}
			if (
				elem.id === Claim.Coaching &&
				!claimsIds.includes(Claim.Messenger)
			) {
				return false;
			}

			// if (
			// 	elem.id === Claim.TenantFinder &&
			// 	!claimsIds.includes(Claim.TenantFinder)
			// ) {
			// 	return false;
			// }

			return true;
		});
	}, [claimsIds]);

	const accordionItems = getFilteredNavLinks().filter(item =>
		Boolean(item.accordion),
	);

	const isSuperAdmin = data?.userId === SUPER_USER_ID;

	return (
		<Flex
			direction="column"
			align="space-between"
			py={'16px'}
			bg="blue.100"
			transition=".3s"
			w={{ base: 'full', md: menuExpanded ? '250px' : '120px' }}
			h="100vh"
			pos="fixed"
			zIndex={'99'}
			{...rest}>
			<Flex alignItems={'center'} justifyContent={'center'}>
				{menuExpanded ? <S8MainLogo /> : <S8SmallLogo />}
			</Flex>
			<Flex
				flexDirection={'column'}
				px={'10px'}
				overflowX={'hidden'}
				direction={'column'}
				className={'custom-scroll'}>
				<VStack flexGrow={1} mt={'26px'}>
					{accordionItems.length ? (
						<NavAccordion
							icon={<DealFinder />}
							title={'Deal Finder'}
							menuExpanded={menuExpanded}>
							{accordionItems.map(item => (
								<NavItem
									onAction={
										!claimsIds.includes(item.id)
											? onOpen
											: undefined
									}
									isActive={location.pathname === item.link}
									key={item.text}
									icon={item.icon}
									link={item.link}
									isDisabled={
										!claimsIds.includes(item.id)
										// true
										// (permissionId === UserPermission.CourseOnly &&
										// 	item.link !== '/courses') ||
										// isLoading
									}
									menuExpanded={menuExpanded}
									heightPadding={'8px'}>
									{item.text}
								</NavItem>
							))}
						</NavAccordion>
					) : null}

					{getFilteredNavLinks()
						.filter(item => Boolean(!item.accordion))
						.map(item => {
							// TODO change later claim to coaching
							const isCoachingTab = item.id === Claim.Coaching;
							// TODO change later claim to coaching

							const isTenantFinder =
								item.id === Claim.TenantFinder;

							if (isSuperAdmin && isCoachingTab) {
								return null;
							}

							const isCommunity = item.text === 'Community';
							const link = isCommunity
								? !isCommunityInstructionCompleted
									? '/instruction'
									: item.link
								: item.link;

							return (
								<NavItem
									onAction={
										// disable any action on coming soon items
										!claimsIds.includes(item.id) &&
										item.id > 0
											? onOpen
											: undefined
									}
									isActive={location.pathname === link}
									key={item.text}
									icon={item.icon}
									link={link}
									showAvailableBadge={
										isCommunity &&
										isCommunityAvailable &&
										!isCommunityInstructionCompleted
									}
									isDisabled={
										isCommunity
											? !isCommunityAvailable
											: isCoachingTab
											? !isCommunityInstructionCompleted ||
											  !isCommunityAvailable
											: isTenantFinder
											? !claimsIds.includes(item.id)
											: Boolean(
													!claimsIds.includes(
														item.id,
													) || item.disabledBadgeText,
											  )
										// true
										// (permissionId === UserPermission.CourseOnly &&
										// 	item.link !== '/courses') ||
										// isLoading
									}
									disabledBadgeText={item.disabledBadgeText}
									menuExpanded={menuExpanded}
									isInAccordion={item.accordion}
									heightPadding={'16px'}>
									{item.text}
								</NavItem>
							);
						})}
				</VStack>
			</Flex>
			<VStack mt={'auto'} px={'10px'} spacing={'8px'} align={'stretch'}>
				{claimsIds.includes(Claim.CruzControl) ? (
					<Button
						px={'10px'}
						to={'/cruz-ctrl/dashboard'}
						as={Link}
						h={'48px'}
						_hover={{
							bg: 'blue.200',
						}}
						color={'white'}
						bg={'transparent'}
						justifyContent={'space-between'}>
						{menuExpanded ? (
							<CtrlLogo height={20} width={90} />
						) : (
							<CtrlSmallLogo width={46} />
						)}
						<RightPaginationArrowIcon
							fill={'currentcolor'}
							width={20}
							height={20}
						/>
					</Button>
				) : null}
				{/*FOOTER*/}
				<SideBarFooter
					isLoading={isLoading}
					data={data}
					menuExpanded={menuExpanded}
					fullName={fullName}
					onSignOut={onSignOut}
				/>
			</VStack>
			<ExpandedButton menuExpanded={menuExpanded} onClick={toggleMenu} />
		</Flex>
	);
};
