import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';

const MessagingFormSchema = yup.object().shape({
	subject: yup.string(),
	message: yup
		.string()
		.test('custom', ErrorMessages.REQUIRED, function (value) {
			return (
				!!value && value.replace(/<(.|\n)*?>/g, '').trim().length !== 0
			);
		}),
	files: yup.array().of(
		yup.object().shape({
			file: yup.mixed<File>(),
		}),
	),
});

export type MessagingFormType = yup.InferType<typeof MessagingFormSchema>;

export const messagingFormResolver = yupResolver(MessagingFormSchema);
