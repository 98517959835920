import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ServerMessagesUnreadBanner } from './messages/ServerMessagesUnreadBanner';
import { ServerMessagesList } from './messages/ServerMessagesList';
import { MessageInput, IMessageInputRef } from '../messages-components';

import { useChatUserStore } from '../../../store';
import {
	ReplyMessage,
	ChannelType,
	ServerChannelData,
	ChatType,
} from '../types';
import { checkFileType, extractMentionsIds, getVideoDetails } from '../utils';
import { colors } from '../../../theme';
import {
	DEFAULT_LAST_READ_TIME,
	ERROR_CHANNEL_NOT_EXIST,
	FileAttachmentType,
} from '../constants';
import { Alerter } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useMarkAsReadChannel, useSendChannelMessage } from '../queries';
import { useCheckMarkAsRead } from '../../../store/useCheckMarkAsRead';
import { useFetchedChannelDataById } from '../hooks';
import { AxiosProgressEvent } from 'axios';
import { UploadProgressState } from '../../../types';
import { UploadProgress } from '../common-components';

interface IServerMessagesCoreProps {
	id?: string;
	channelData?: ServerChannelData | null;
	loadingChannelInfo: boolean;
}

export const ServerMessagesCore: React.FC<IServerMessagesCoreProps> = memo(
	function ServerMessagesCore({ id, channelData, loadingChannelInfo }) {
		const navigate = useNavigate();
		const { setMarkChannelId } = useCheckMarkAsRead();
		const { user } = useChatUserStore();
		const { mutateAsync: markAsReadChannel } = useMarkAsReadChannel();
		const { mutateAsync: sendChannelMessage } = useSendChannelMessage();
		const fetchedChannelData = useFetchedChannelDataById(Number(id));

		const [lastReadTime, setLastReadTime] = useState(
			DEFAULT_LAST_READ_TIME,
		);
		const [unreadCount, setUnreadCount] = useState(0);
		const [uploadProgress, setUploadProgress] =
			useState<UploadProgressState | null>(null);

		const [replyMessage, setReplyMessage] = useState<ReplyMessage | null>(
			null,
		);
		const [hasVideoAttachment, setHasVideoAttachment] = useState(false);
		const [sendingMessageLoading, setSendingMessageLoading] =
			useState(false);

		const onMarkAsRead = async (channelId: number) => {
			try {
				await markAsReadChannel({
					channelId: Number(channelId),
					dateTime: new Date().toISOString(),
				});
				setUnreadCount(0);
				setLastReadTime(new Date().toISOString());
				setMarkChannelId(channelId);
			} catch (error) {
				console.log(error);
			}
		};

		const handleUploadProgress = (event: AxiosProgressEvent) => {
			if (event.total) {
				setUploadProgress({
					progress: Math.round((100 * event.loaded) / event.total),
					totalSize: event.total,
				});
			}
		};

		const onSubmit = async (msg: string, file: File | null) => {
			try {
				if (!channelData || (!msg.length && !file)) {
					return;
				}
				setSendingMessageLoading(true);
				const mentionsIds = extractMentionsIds(msg);

				const fileType = checkFileType(file?.type || '');

				const isVideoType = fileType === FileAttachmentType.VIDEO;

				const {
					videoDuration = 0,
					videoWidth = 0,
					videoHeight = 0,
				} = file && isVideoType ? await getVideoDetails(file) : {};

				if (isVideoType) {
					setHasVideoAttachment(true);
				}

				// creating formData
				const formData = new FormData();
				formData.append('ChannelId', channelData.id.toString());
				formData.append('Text', msg);
				if (replyMessage) {
					formData.append(
						'ReplyToId',
						replyMessage?.messageId.toString(),
					);
				}
				if (mentionsIds?.length) {
					mentionsIds.forEach(elem => {
						formData.append('MentionId', elem.toString());
					});
				}
				if (fileType) {
					formData.append(fileType, file as Blob);
				}

				if (isVideoType) {
					formData.append(
						'VideoMetaData[0].Width',
						String(videoWidth),
					);
					formData.append(
						'VideoMetaData[0].Height',
						String(videoHeight),
					);
					formData.append(
						'VideoMetaData[0].Duration',
						String(videoDuration),
					);
				}

				const res = await sendChannelMessage({
					payload: formData,
					onUpload: handleUploadProgress,
				});
				if (replyMessage) {
					setReplyMessage(null);
				}
				if (
					!res?.success &&
					res?.errors?.[0]?.message === ERROR_CHANNEL_NOT_EXIST
				) {
					Alerter.error(
						res?.errors?.[0]?.message || 'Error sending message',
					);
					navigate({
						pathname: '/messenger/community',
					});
					return;
				}
				if (!res?.success) {
					Alerter.error(
						res?.errors?.[0]?.message || 'Error sending message',
					);
				}

				scrollRef.current?.scrollIntoView();
			} catch (error) {
				console.error('Error adding document: ', error);
				setUploadProgress(null);
			} finally {
				setSendingMessageLoading(false);
				setUploadProgress(null);
				setHasVideoAttachment(false);
			}
		};

		const onSetReplyMessage = (obj: ReplyMessage) => {
			setReplyMessage(obj);
			messageInputRef.current?.focusInput();
		};

		const scrollRef = useRef<HTMLSpanElement>(null);
		const topScrollRef = useRef<HTMLSpanElement>(null);
		const messageInputRef = useRef<IMessageInputRef>(null);

		useEffect(() => {
			setReplyMessage(null);
		}, [id]);

		const getTextForDisabledInput = (
			condition: boolean,
			isAnnouncementChannel: boolean,
			isChannelsBanned?: boolean,
		) => {
			if (!condition) {
				return ' ';
			}
			switch (condition) {
				case isAnnouncementChannel:
					return 'You cannot post messages in this channel';
				case isChannelsBanned:
					return "You've been suspended from writing messages";
				default:
					return ' ';
			}
		};

		const isAnnouncementChannel =
			channelData?.type === ChannelType.ANNOUNCEMENT ||
			fetchedChannelData?.type === ChannelType.ANNOUNCEMENT;

		const textForDisabledInput = useMemo(
			() =>
				getTextForDisabledInput(
					Boolean(channelData),
					isAnnouncementChannel,
					user?.isBannedInChannel,
				),
			[channelData?.id, user?.isBannedInChannel],
		);

		const shouldShowMessageInput =
			fetchedChannelData || channelData
				? isAnnouncementChannel
					? !!user?.isAdmin
					: user && !user?.isBannedInChannel
				: false;

		const disableMenuCondition = !user?.isBannedInChannel
			? isAnnouncementChannel
				? !user?.isAdmin
				: false
			: true;

		return (
			<Flex
				bg="mainBg"
				flexDirection="column"
				overflowY="auto"
				overflowX="hidden"
				px="20px"
				pb="20px"
				flex={1}
				position="relative">
				{lastReadTime !== DEFAULT_LAST_READ_TIME ? (
					<ServerMessagesUnreadBanner
						topScrollRef={topScrollRef}
						lastReadTime={lastReadTime}
						unreadCount={unreadCount}
						onMarkAsRead={() => {
							if (id) {
								onMarkAsRead(+id);
							}
						}}
					/>
				) : null}

				<ServerMessagesList
					key={id}
					onSetReplyMessage={onSetReplyMessage}
					replyMessage={replyMessage}
					scrollRef={scrollRef}
					topScrollRef={topScrollRef}
					channelData={channelData}
					id={id}
					disableMenu={disableMenuCondition}
					updateLastReadTime={(time: string) => setLastReadTime(time)}
					updateUnreadCount={setUnreadCount}
					lastReadTime={lastReadTime}
					onMarkAsRead={onMarkAsRead}
				/>

				{/* <TypingIndicator /> */}
				{shouldShowMessageInput ? (
					<MessageInput
						onSubmit={onSubmit}
						replyMessage={replyMessage}
						setReplyMessage={setReplyMessage}
						sendingMessageLoading={sendingMessageLoading}
						ref={messageInputRef}
						chatId={id}
						chatType={ChatType.CHANNEL}
					/>
				) : (
					<Box position="relative" py="18px" marginTop="auto">
						<Flex align="center" justify="center">
							<Text color={colors.inactiveBlue}>
								{textForDisabledInput}
							</Text>
						</Flex>
					</Box>
				)}
				{hasVideoAttachment && uploadProgress ? (
					<UploadProgress
						progressValue={uploadProgress?.progress}
						totalSize={uploadProgress?.totalSize}
						wrapperProps={{
							position: 'absolute',
							top: unreadCount ? '45px' : '0px',
							right: '20px',
							zIndex: 10,
						}}
					/>
				) : null}
			</Flex>
		);
	},
);
