import { Flex } from '@chakra-ui/react';
import { CtrlLogo, CtrlSmallLogo } from '../../../../../../assets';
import { ExpandedButton } from './ExpandedButton';
import React from 'react';

interface ISideBarHeader {
	isExpanded: boolean;
	onAction: VoidFunction;
}

export const SideBarHeader: React.FC<ISideBarHeader> = ({
	isExpanded,
	onAction,
}) => {
	return (
		<Flex py={'16px'} align={'center'} justify={'center'} pos={'relative'}>
			{isExpanded ? <CtrlLogo /> : <CtrlSmallLogo />}
			<ExpandedButton onAction={onAction} isExpanded={isExpanded} />
		</Flex>
	);
};
