import React from 'react';
import { Box, BoxProps, Progress, Text, VStack } from '@chakra-ui/react';
import { colors } from '../../../theme';

interface IUploadProgressProps {
	progressValue: number;
	totalSize: number;
	wrapperProps?: BoxProps;
}

export const UploadProgress: React.FC<IUploadProgressProps> = ({
	progressValue,
	totalSize,
	wrapperProps,
}) => {
	const totalSizeMB = totalSize / 1000000;
	const progressInMB = `${((totalSizeMB / 100) * progressValue).toFixed(
		1,
	)}MB / ${totalSizeMB.toFixed(1)}MB`;

	return (
		<Box borderRadius="10px" bg={colors.success} p="16px" {...wrapperProps}>
			<VStack alignItems="flex-start">
				<Text
					fontSize="14px"
					lineHeight="17px"
					fontWeight={600}
					color="white">
					Please wait, loading is in progress....
				</Text>
				<Progress
					width="100%"
					height="8px"
					my="8px"
					bg={colors.bgGray[100]}
					value={progressValue}
					borderRadius="20px"
					sx={{
						'> div': {
							bg: `${colors.blue[100]}`,
						},
					}}
				/>
				<Text fontSize="14px" lineHeight="17px" color="white">
					{progressInMB}
				</Text>
			</VStack>
		</Box>
	);
};
