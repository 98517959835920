/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { Message } from './Message';
import { NewDivider } from '../NewDivider';
import { DateDivider } from '../DateDivider';
import {
	EditMessageDisplayData,
	ReplyMessage,
	ConversationHistoryItem,
	ChatType,
	MessageReactionType,
	UserByIdData,
} from '../../types';
import { isDateBetween, checkForSameSender, isNewDay } from '../../utils';

interface IMessageWithAddonsProps {
	index: number;
	arr: ConversationHistoryItem[];
	lastReadTime?: string;
	data: ConversationHistoryItem;
	// replyTo?: ConversationHistoryItem;
	allowFullAccess?: boolean;
	onEditMessage: (messageId: number, newText: string) => void;
	onSetDeleteMessage: (message: EditMessageDisplayData) => void;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	onSetPinMessage?: (message: EditMessageDisplayData) => void;
	includePin?: boolean;
	includeEdit?: boolean;
	disableMenu?: boolean;
	hideNewDividerForNewUser?: boolean;
	enableMentions?: boolean;
	onSuspendUser?: (value: number) => void;
	onSuspendAndPurgeMessagesUser?: (value: number) => void;
	unSuspendUser?: (value: number) => void;
	messageType?: number;
	chatType: ChatType;
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	hideReactions?: boolean;
	disabledAttachmentView?: boolean;
	receiver?: UserByIdData | null;
	includeUserInfoPopup: boolean;
}

export const MessageWithAddons: React.FC<IMessageWithAddonsProps> = ({
	data,
	index,
	arr,
	lastReadTime,
	// replyTo,
	allowFullAccess,
	onEditMessage,
	onSetDeleteMessage,
	onSetReplyMessage,
	onSetPinMessage,
	includePin,
	disableMenu,
	hideNewDividerForNewUser,
	includeEdit,
	enableMentions,
	onSuspendAndPurgeMessagesUser,
	onSuspendUser,
	unSuspendUser,
	messageType,
	chatType,
	onReact,
	hideReactions,
	disabledAttachmentView,
	receiver,
	includeUserInfoPopup,
}) => {
	let showNewDivider = isDateBetween(
		lastReadTime ? new Date(lastReadTime) : undefined,
		new Date(data.sentAt),
		new Date(arr[Math.min(index + 1, arr.length - 1)].sentAt),
	);

	if (
		!lastReadTime &&
		index === arr.length - 1 &&
		lastReadTime !== null &&
		!hideNewDividerForNewUser
	) {
		showNewDivider = true;
	}

	const isSameSenderNext =
		index === 0
			? false
			: checkForSameSender(
					data,
					arr[
						Math.min(index - 1 < 0 ? 0 : index - 1, arr.length - 1)
					],
			  );

	const isSameSenderPrev =
		index === arr.length - 1
			? false
			: checkForSameSender(
					data,
					arr[Math.min(index + 1, arr.length - 1)],
			  );

	const showNewDay = isNewDay(
		new Date(data.sentAt),
		new Date(arr[Math.min(index + 1, arr.length - 1)].sentAt),
	);

	// if (arr.length === 1) {
	// 	showNewDay = true;
	// }

	return (
		<React.Fragment>
			<Message
				id={data.id}
				text={data.text}
				sentById={data.senderId}
				sentByDisplayName={data.senderName}
				isEdited={data.isEdited}
				type={messageType}
				allowFullAccess={allowFullAccess}
				replyToId={data.replyToId}
				sentByAvatarUrl={data?.avatarUrl}
				pinnedMessageId={data.pinToId || undefined}
				date={new Date(data.sentAt)}
				isSameSenderNext={isSameSenderNext}
				isSameSenderPrev={isSameSenderPrev}
				onEditMessage={onEditMessage}
				onSetDeleteMessage={onSetDeleteMessage}
				onSetReplyMessage={onSetReplyMessage}
				onSetPinMessage={onSetPinMessage}
				includePin={includePin}
				disableMenu={disableMenu}
				includeEdit={includeEdit}
				enableMentions={enableMentions}
				onSuspendAndPurgeMessagesUser={() =>
					onSuspendAndPurgeMessagesUser?.(data.senderId)
				}
				onSuspendUser={() => onSuspendUser?.(data.senderId)}
				onUnSuspendUser={() => unSuspendUser?.(data.senderId)}
				chatType={chatType}
				sentByAdmin={data.isAdmin}
				isSuspended={data.isBannedInChannel}
				isSenderDeleted={data.isSenderDeleted}
				onReact={onReact}
				hideReactions={hideReactions}
				messageAttachmentFiles={data.attachmentFiles}
				messageAttachmentImages={data.attachmentImages}
				disabledAttachmentView={disabledAttachmentView}
				reactions={data.reactions}
				messageAttachmentVideos={data.attachmentVideos}
				receiver={receiver}
				includeUserInfoPopup={includeUserInfoPopup}
			/>
			{showNewDivider ? (
				<NewDivider />
			) : showNewDay ? (
				<DateDivider date={new Date(data.sentAt)} />
			) : null}
		</React.Fragment>
	);
};
