import React from 'react';
import { useOutlet } from 'react-router-dom';
import { useChatUserStore } from '../../../store';

export const ServerPage: React.FC = () => {
	const outlet = useOutlet();
	const { user } = useChatUserStore();

	return <>{user?.userId ? outlet : null}</>;
};
