import {
	GeocodeFieldValue,
	IdNameType,
	PropertyImageType,
	UnitItemType,
} from './root.types';

export enum PropertyType {
	House = 1,
	Apartment = 2,
}

export enum PropertyStatus {
	MoveInReady = 1,
	UnderConstruction = 2,
}

export type IImageViewType = {
	id?: number;
	image?: string;
	imageUrl?: string;
	isPinned: boolean;
	thumbnailUrl?: string;
};
export type IImageToSendType = {
	image: File;
	isPinned: boolean;
};

export type PropertyDetailsType = {
	id: number;
	name: string;
	address: string;
	owner: IdNameType;
	availableUnitsCount: number;
	tenantsCount: number;
	totalMonthlyRent: number;
	units: IdNameType[];
	unitsCount: number;
	images: PropertyImageType[];
	label: number;
};

export type PropertyUnitDetailsTypeTenantInfo = {
	id: 0;
	firstName: string;
	lastName: string;
	avatarUrl: string;
	email: string;
	phoneNumber: string;
};

export type PropertyUnitDetailsType = {
	id: number;
	propertyId: number;
	name: string;
	squareFeet: number;
	bedrooms: number;
	bathrooms: number;
	monthlyRent: number;
	deposit: number;
	tenant: PropertyUnitDetailsTypeTenantInfo;
};

export type PropertiesResponseItem = {
	id: number;
	name: string;
	address: string;
	owner: string;
	available: number;
	openMaintenance: number;
	monthlyRent: number;
	units: number;
	tenants: number;
	images: PropertyImageType[];
};

export type PropertyExtendInfoType = {
	id: number;
	name: string;
	owner: IdNameType;
	type: number;
	label: number;
	location: GeocodeFieldValue;
	images: PropertyImageType[];
	units: UnitItemType[];
};

export type UnoccupiedPropertyType = {
	id: number;
	name: string;
	isStripeConnected: boolean;
};
