export const ReplyIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.50065 11.6668L3.33398 7.50016M3.33398 7.50016L7.50065 3.3335M3.33398 7.50016H8.66732C11.4676 7.50016 12.8677 7.50016 13.9373 8.04513C14.8781 8.5245 15.643 9.2894 16.1223 10.2302C16.6673 11.2998 16.6673 12.6999 16.6673 15.5002V16.6668"
			stroke="#0F1B49"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
