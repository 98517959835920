import React from 'react';
import { Flex } from '@chakra-ui/react';
import { ProfileFormNavBtn } from '../ProfileFormNavBtn';
import MyProfile from '../../MyProfile';

export const PasswordForm: React.FC = () => {
	return (
		<MyProfile>
			<Flex flexDirection={'column'} gap={'15px'}>
				<ProfileFormNavBtn
					nextRoute={`${
						import.meta.env.VITE_OIDC_AUTHORITY
					}/Account/Manage/ChangePassword?returnUrl=${
						import.meta.env.VITE_WEBSITE_BASE_URL
					}`}
					name={'Change Password'}
				/>
				<ProfileFormNavBtn
					nextRoute={`${
						import.meta.env.VITE_OIDC_AUTHORITY
					}/Account/ForgotPassword?returnUrl=${
						import.meta.env.VITE_WEBSITE_BASE_URL
					}`}
					name={'Reset Password'}
				/>
			</Flex>
		</MyProfile>
	);
};
