import { useQuery } from 'react-query';
import {
	getCurrentUserRequest,
	getCurrentUserRequestErrorHandling,
} from '../services';

export enum CurrentUserQueryKeys {
	CURRENT_USER = 'current-user',
	CURRENT_USER_ERROR_HANDLING = 'current-user-error-handling',
}

export const useGetCurrentUser = () => {
	return useQuery(CurrentUserQueryKeys.CURRENT_USER, getCurrentUserRequest, {
		refetchOnWindowFocus: false,
		staleTime: 36000000,
	});
};

export const useGetCurrentUserErrorHandling = () => {
	return useQuery(
		CurrentUserQueryKeys.CURRENT_USER_ERROR_HANDLING,
		getCurrentUserRequestErrorHandling,
		{
			refetchOnWindowFocus: false,
			staleTime: 36000000,
		},
	);
};
