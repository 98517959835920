import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const UpdateUserSchema = yup.object().shape({
	nickName: yup.string().required(ErrorMessages.REQUIRED),
	displayName: yup.string().required(ErrorMessages.REQUIRED),
	aboutMe: yup.string(),
	avatar: yup.mixed<File>(),
});

export type UpdateUserValuesType = yup.InferType<typeof UpdateUserSchema>;
export const updateUserResolver = yupResolver(UpdateUserSchema);
