import { Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { MobileNav } from '../../../../../../components/DashboardLayout/components/MobileNav';
import React, { FC } from 'react';
import { ICurrentUser } from '../../../../../../types';
import { MobileMenuContent } from './MobileMenuContent';

interface MobileSidebarProps {
	data: ICurrentUser | undefined;
	isLoading: boolean;
}

export const MobileSidebar: FC<MobileSidebarProps> = ({ data, isLoading }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				<DrawerContent>
					<MobileMenuContent
						onClose={onClose}
						isLoading={isLoading}
						data={data}
					/>
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</>
	);
};
