import { colors } from '../theme';

export const VerticalScrollbarStyles = {
	'&::WebkitScrollbar': {
		width: '4px',
	},
	'&::WebkitScrollbarTrack': {
		width: '6px',
	},
	'&::WebkitScrollbarThumb': {
		background: `${colors.bgGray[100]}`,
		borderRadius: '24px',
	},
};
