import React from 'react';

interface SettingsIconProps {
	strokeColor?: string;
	size?: string;
}

export const SettingsIcon: React.FC<SettingsIconProps> = ({
	strokeColor = 'currentColor',
	size = '24',
}) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
				stroke={strokeColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.1671 7.94004L19.1013 7.82619C18.9199 7.51217 18.8273 7.15475 18.8334 6.79216L18.8634 5.01333C18.8695 4.64981 18.6778 4.3116 18.3628 4.13007L15.6261 2.55307C15.3119 2.372 14.9243 2.37524 14.6131 2.56155L13.094 3.47113C12.7836 3.65704 12.4285 3.75522 12.0666 3.75522H11.9349C11.5722 3.75522 11.2164 3.65661 10.9055 3.46994L9.3794 2.55377C9.06716 2.36632 8.6777 2.36344 8.36272 2.54625L5.63473 4.12954C5.32124 4.31149 5.13075 4.64883 5.13685 5.01125L5.16681 6.79216C5.17291 7.15475 5.08031 7.51217 4.89892 7.82619L4.83403 7.93852C4.65254 8.25271 4.389 8.5116 4.07164 8.68748L2.51675 9.54914C2.19805 9.72576 2.00064 10.0617 2.00147 10.4261L2.00863 13.5779C2.00945 13.9401 2.20601 14.2735 2.52248 14.4495L4.0699 15.3104C4.38835 15.4875 4.65235 15.7483 4.83337 16.0646L4.9029 16.186C5.08174 16.4985 5.17293 16.8534 5.16685 17.2133L5.13692 18.9865C5.13078 19.35 5.32245 19.6882 5.63749 19.8698L8.37412 21.4468C8.68835 21.6278 9.07598 21.6246 9.38713 21.4383L10.9062 20.5287C11.2167 20.3428 11.5718 20.2446 11.9337 20.2446H12.0654C12.428 20.2446 12.7838 20.3432 13.0948 20.5299L14.6208 21.4461C14.9331 21.6335 15.3226 21.6364 15.6375 21.4536L18.3655 19.8703C18.679 19.6884 18.8695 19.351 18.8634 18.9886L18.8334 17.2077C18.8273 16.8451 18.9199 16.4877 19.1013 16.1737L19.1662 16.0613C19.3477 15.7471 19.6112 15.4882 19.9286 15.3124L21.4835 14.4507C21.8022 14.2741 21.9996 13.9381 21.9988 13.5738L21.9916 10.4219C21.9908 10.0598 21.7942 9.72638 21.4778 9.55032L19.9266 8.68741C19.6105 8.51156 19.348 8.25327 19.1671 7.94004Z"
				stroke={strokeColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
