import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useCreateDirectMessageReaction = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ conversationId: number; messageId: number; reactionEmojiId: number }
	>({
		mutationKey: [MessengerQueryKeys.CREATE_DIRECT_MESSAGE_REACTION],
		mutationFn: payload =>
			API_CHAT.post('/api/app/conversation/messages/reaction', payload),
	});
};
