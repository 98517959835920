/* eslint-disable indent */
import React, { PropsWithChildren, useCallback } from 'react';
import {
	Avatar,
	AvatarBadge,
	Box,
	Button,
	Text,
	Flex,
	HStack,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { ChatType, UserChannelData, UserPresenceStatus } from '../../../types';
import { RoleInfoPopupMenu } from './RoleInfoPopupMenu';
import {
	RadixPopover,
	RadixPopoverContent,
	RadixPopoverPortal,
	RadixPopoverTrigger,
} from '../../../common-components';
import './RoleInfoPopup.css';
import { AdminVerificationSmall } from '../../../../../assets';
import {
	useServerUsersOnlineList,
	useServerUsersAllList,
	useSuspendModalStore,
} from '../../../../../store';
import { Loader } from '../../../../../components';
import {
	useAddFriendById,
	useBlockUser,
	useDeleteFriend,
	useUnBlockUser,
	useUpdateChannelsBanStatus,
} from '../../../queries';
import { useUpdateUserInfoById } from '../../../hooks';

interface IRoleInfoPopup extends UserChannelData {
	isCurrentUser: boolean;
	isCurrentUserAdmin?: boolean | null;
	isLoaded?: boolean;
	chatType: ChatType;
	popupSide?: 'bottom' | 'left' | 'right' | 'top';
	isForceOffline?: boolean;
}

export const RoleInfoPopup: React.FC<PropsWithChildren<IRoleInfoPopup>> = ({
	children,
	avatarStoragePath,
	about,
	displayName,
	nickName,
	isCurrentUser,
	isBlocked,
	userId,
	isAdmin,
	isBannedInChannel,
	isCurrentUserAdmin,
	isFriends,
	requestSentByMe,
	isLoaded = false,
	popupSide = 'left',
	email,
	userStatus,
	chatType,
	isForceOffline = false,
}) => {
	const { onOpen } = useSuspendModalStore();
	const { updateOnlineChannelUsers } = useServerUsersOnlineList();
	const { updateAllChannelUsers } = useServerUsersAllList();

	const { mutate: updateUserInfoById } = useUpdateUserInfoById();
	const {
		mutateAsync: sendFriendRequestById,
		isLoading: sendFriendRequestLoading,
	} = useAddFriendById();
	const {
		mutateAsync: updateChannelsBanStatus,
		isLoading: isUpdateChannelsBanLoading,
	} = useUpdateChannelsBanStatus();
	const { mutateAsync: unBlockUser } = useUnBlockUser();
	const { isLoading: deleteFriendLoading, mutateAsync: deleteFriend } =
		useDeleteFriend();
	const { mutateAsync: blockUser } = useBlockUser();

	const isOnline = userStatus === UserPresenceStatus.ONLINE;
	const isDirectChat = chatType === ChatType.DIRECT;

	const onBlock = async (blockedUserId: number) => {
		try {
			const res = await blockUser(blockedUserId);
			if (!res.success) {
				return;
			}

			updateUserInfoById(blockedUserId, { isBlocked: true });
			updateOnlineChannelUsers(blockedUserId, { isBlocked: true });
		} catch (error) {
			console.log(error, '@@@onBlock');
		}
	};

	const unBlock = useCallback(async (receiverId: number) => {
		try {
			const res = await unBlockUser(receiverId);
			if (!res.success) {
				return;
			}

			updateUserInfoById(receiverId, { isBlocked: false });
			updateOnlineChannelUsers(receiverId, { isBlocked: false });
			updateAllChannelUsers(receiverId, { isBlocked: false });
		} catch (error) {
			console.log(error, '@@@unblock');
		}
	}, []);

	const onUpdateFriendStatus = async (
		receiverId: number,
		isFriend: boolean,
	) => {
		try {
			const res = isFriend
				? await deleteFriend(receiverId)
				: await sendFriendRequestById(receiverId);
			if (!res.success) {
				return;
			}

			updateUserInfoById(receiverId, {
				isFriendRequestSent: !isFriend,
				isFriend: false,
			});
			updateOnlineChannelUsers(receiverId, {
				requestSentByMe: !isFriend,
				isFriends: false,
			});
			updateAllChannelUsers(receiverId, {
				requestSentByMe: !isFriend,
				isFriends: false,
			});
		} catch (err) {
			console.log(err, '@@@onSendFriendRequest');
		}
	};

	const onUpdateSuspend = useCallback(
		async (userId: number, isBanned: boolean) => {
			try {
				const res = await updateChannelsBanStatus({
					userId,
					isBanned,
					deleteMessage: false,
				});
				if (!res.success) {
					return;
				}

				updateUserInfoById(userId, { isBannedInChannel: isBanned });
				updateOnlineChannelUsers(userId, {
					isBannedInChannel: isBanned,
				});
				updateAllChannelUsers(userId, {
					isBannedInChannel: isBanned,
				});
			} catch (error) {
				console.log(error, '@@@onUpdateSuspend');
			}
		},
		[],
	);

	const onSuccessSuspendAndPurge = (receiverId: number) => {
		updateUserInfoById(receiverId, { isBannedInChannel: true });
		updateOnlineChannelUsers(receiverId, { isBannedInChannel: true });
		updateAllChannelUsers(receiverId, { isBannedInChannel: true });
	};

	return (
		<Flex>
			<RadixPopover>
				<RadixPopoverTrigger asChild={true}>
					{children}
				</RadixPopoverTrigger>
				<RadixPopoverPortal>
					<RadixPopoverContent
						className={'role-info-popup-container'}
						sideOffset={5}
						side={popupSide}
						sticky="always">
						{isLoaded ? (
							<Box
								w={'340px'}
								border={'none'}
								maxH="100vh"
								overflowY="auto"
								bg="blue.100"
								p="10px">
								<Box
									h={'76px'}
									bg="#BED0DF"
									borderRadius={'4px'}
								/>
								<Box>
									<Avatar
										boxSize="100px"
										mt={'-50px'}
										src={avatarStoragePath || ''}>
										<AvatarBadge
											display={
												isForceOffline || !isOnline
													? 'none'
													: 'flex'
											}
											bg="success"
											borderColor="success"
											boxSize="16px"
											right="12px"
											bottom="8px"
										/>
									</Avatar>

									<Box borderRadius={'4px'} mt={'20px'}>
										<Box
											p="15px 20px"
											bg="blue.200"
											borderRadius="4px 4px 0px 0px"
											mb="1px">
											{isAdmin ? (
												<HStack>
													<AdminVerificationSmall
														width="24"
														height="24"
													/>
													<Text
														color="inactiveText"
														fontSize="16px">
														Admin
													</Text>
												</HStack>
											) : null}
											<Text
												fontSize="20px"
												color="white"
												fontWeight={700}
												mb="10px">
												{displayName}
											</Text>
											<Text
												color="blue.50"
												fontSize="16px">
												{nickName || ''}
											</Text>
											{isCurrentUserAdmin && email ? (
												<Text
													w="100%"
													color="inactiveText"
													isTruncated={true}>
													{email}
												</Text>
											) : null}
											{isCurrentUserAdmin &&
											isBannedInChannel ? (
												<Text
													mt="10px"
													color="valueRed"
													fontWeight={600}>
													User suspended
												</Text>
											) : null}
										</Box>
										{about ? (
											<Box
												p="15px 20px"
												bg="blue.200"
												mb="1px">
												<Text
													fontSize="16px"
													color={'white'}>
													About me
												</Text>
												<Text
													fontSize="16px"
													color="blue.50"
													whiteSpace="pre-wrap">
													{about}
												</Text>
											</Box>
										) : null}
										{!isCurrentUser ? (
											<React.Fragment>
												<Flex
													mt={'10px'}
													align={'center'}
													justify={'space-between'}>
													{!isBlocked &&
													requestSentByMe &&
													!isFriends ? (
														<Text color="blue.50">
															Friend request sent
														</Text>
													) : null}
													{!isBlocked &&
													!requestSentByMe &&
													!isFriends ? (
														<Button
															onClick={() =>
																onUpdateFriendStatus(
																	userId,
																	false,
																)
															}
															isLoading={
																sendFriendRequestLoading ||
																deleteFriendLoading
															}
															colorScheme="successButton"
															size="sm">
															Send Friend Request
														</Button>
													) : null}
													{!isBlocked && isFriends ? (
														<Button
															onClick={() =>
																onUpdateFriendStatus(
																	userId,
																	true,
																)
															}
															isLoading={
																sendFriendRequestLoading ||
																deleteFriendLoading
															}
															colorScheme="dangerButton"
															size="sm">
															Remove From Friends
														</Button>
													) : null}

													<RoleInfoPopupMenu
														isUserBlocked={
															isBlocked
														}
														onBlock={() =>
															onBlock(userId)
														}
														unBlock={() =>
															unBlock(userId)
														}
														userId={userId}
														isAdmin={isAdmin}
													/>
												</Flex>
												{!isDirectChat &&
												isCurrentUserAdmin &&
												!isAdmin ? (
													<Button
														mt="10px"
														w="full"
														leftIcon={
															isBannedInChannel ? undefined : (
																<CloseIcon
																	fontSize="11px"
																	color="white"
																/>
															)
														}
														isLoading={
															isUpdateChannelsBanLoading
														}
														onClick={() =>
															onUpdateSuspend(
																userId,
																!isBannedInChannel,
															)
														}
														bg={
															isBannedInChannel
																? 'transparent'
																: 'valueRed'
														}
														colorScheme={
															isBannedInChannel
																? 'green'
																: 'red'
														}
														color={
															isBannedInChannel
																? 'success'
																: 'white'
														}
														borderWidth="1px"
														borderColor={
															isBannedInChannel
																? 'success'
																: 'valueRed'
														}>
														{`${
															isBannedInChannel
																? 'Activate'
																: 'Suspend'
														}`}
													</Button>
												) : null}
												{!isDirectChat &&
												isCurrentUserAdmin &&
												!isAdmin &&
												!isBannedInChannel ? (
													<Button
														mt="10px"
														w="full"
														onClick={() => {
															onOpen(
																userId,
																true,
																() =>
																	onSuccessSuspendAndPurge(
																		userId,
																	),
															);
														}}
														borderWidth="1px"
														borderColor="valueRed"
														bg="transparent"
														color="valueRed">
														Suspend and delete all
														messages
													</Button>
												) : null}
											</React.Fragment>
										) : null}
									</Box>
								</Box>
							</Box>
						) : (
							<Loader
								spinnerSize="md"
								spinnerProps={{
									color: 'white',
									emptyColor: 'blue.50',
								}}
								centerProps={{
									padding: '10px',
									bg: 'blue.100',
									borderRadius: '6px',
								}}
							/>
						)}
					</RadixPopoverContent>
				</RadixPopoverPortal>
			</RadixPopover>
		</Flex>
	);
};
