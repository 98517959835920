import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	channelId: string | null;
	channelName: string | null;
	categoryName: string | null;
	categoryId: string | null;
	onClose: VoidFunction;
	onOpen: VoidFunction;
	setChannelId: (value: {
		channelId: string;
		categoryId: string | null;
		channelName: string;
		categoryName: string | null;
	}) => void;
}

export const useDuplicateChannelModalStore = create<IState>(set => ({
	isOpen: false,
	channelId: null,
	categoryId: null,
	channelName: null,
	categoryName: null,
	setChannelId: data =>
		set(() => ({
			channelId: data.channelId,
			categoryId: data.categoryId,
			channelName: data.channelName,
			categoryName: data.categoryName,
		})),
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () =>
		set(() => ({
			isOpen: false,
			channelId: null,
			categoryId: null,
			channelName: null,
			categoryName: null,
		})),
}));
