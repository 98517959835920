import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TenantFormType } from '../../../validations';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { InputText } from '../../../../../components';
import {
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
} from '../../../constants';
import { CustomAsyncSelect, InputPhone } from '../../../components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
	ADD_NEW_TENANT_OPTIONS_VALUES,
	AddNewTenantActions,
} from './AddNewTenantActions';
import {
	ArchivedTenantSelectType,
	LoadOptionsType,
	OptionType,
} from '../../../types';
import debounce from 'lodash.debounce';
import { getArchivedTenantsSelector } from '../../../queries/selector/getArchiverTenantsSelector';

interface TenantCreationOrSelectionBlockProps {
	isSelectingExistingTenant: boolean;
	setIsSelectingExistingTenant: React.Dispatch<React.SetStateAction<boolean>>;
	register: UseFormRegister<TenantFormType>;
	errors: FieldErrors<TenantFormType>;
	showTenantActionsBlock: boolean;
	selectedArchivedTenant: ArchivedTenantSelectType | null;
	setSelectedArchivedTenant: React.Dispatch<
		React.SetStateAction<ArchivedTenantSelectType | null>
	>;
}

export const TenantCreationOrSelectionBlock: FC<
	TenantCreationOrSelectionBlockProps
> = ({
	isSelectingExistingTenant,
	register,
	setIsSelectingExistingTenant,
	errors,
	showTenantActionsBlock,
	setSelectedArchivedTenant,
	selectedArchivedTenant,
}) => {
	const [selectOptions, setSelectOptions] = useState<Array<OptionType>>([]);

	const loadOptions: LoadOptionsType = useCallback(
		debounce((inputValue, callback) => {
			getArchivedTenantsSelector(inputValue).then(resp =>
				callback(resp || []),
			);
		}, 500),
		[],
	);
	useEffect(() => {
		loadOptions('', e => {
			setSelectOptions(e);
		});
	}, []);

	return (
		<>
			{showTenantActionsBlock ? (
				<Box borderBottom="1px solid #F1F4F9" p="16px">
					<AddNewTenantActions
						onChangeFn={val => {
							if (
								Number(val) ===
								ADD_NEW_TENANT_OPTIONS_VALUES.SELECT_EXISTING_TENANT
							) {
								setIsSelectingExistingTenant(true);
								return;
							}
							setIsSelectingExistingTenant(false);
							setSelectedArchivedTenant(null);
						}}
					/>
				</Box>
			) : null}
			{showTenantActionsBlock && isSelectingExistingTenant ? (
				<>
					<Flex p={'16px 16px 0 16px'} maxW={'267px'}>
						<CustomAsyncSelect
							isDisabled={false}
							loadOptions={loadOptions}
							// errMsg={error?.message}
							value={
								selectedArchivedTenant
									? ({
											value: selectedArchivedTenant?.id,
											label: selectedArchivedTenant?.name,
									  } as OptionType)
									: null
							}
							onChange={newValue => {
								const newVal: {
									value: number;
									label: string;
									email: string;
									phoneNumber: string;
								} = { ...newValue } as any;
								setSelectedArchivedTenant({
									id: newVal.value,
									email: newVal.email,
									phoneNumber: newVal.phoneNumber,
									name: newVal.label,
								});
							}}
							placeholder={'Search or Select Tenant'}
							label={'Tenant'}
							defaultOptions={selectOptions}
						/>
					</Flex>
					<Stack
						p="16px"
						direction={{
							base: 'column',
							md: 'row',
						}}
						spacing="16px">
						<InputText
							{...register('firstName')}
							errorMsg={errors.firstName?.message}
							{...inputStyles}
							placeholder="Enter First Name"
							label="First Name"
							errorMessageProps={errorMsgStyles}
							formLabelProps={inputLabelStyles}
							isDisabled={true}
						/>
						<InputText
							{...register('lastName')}
							errorMsg={errors.lastName?.message}
							{...inputStyles}
							placeholder="Enter Last Name"
							label="Last Name"
							errorMessageProps={errorMsgStyles}
							formLabelProps={inputLabelStyles}
							isDisabled={true}
						/>
						<InputText
							{...register('email')}
							errorMsg={errors.email?.message}
							{...inputStyles}
							placeholder="Enter Email"
							label="Email"
							errorMessageProps={errorMsgStyles}
							formLabelProps={inputLabelStyles}
							isDisabled={true}
						/>
						<InputPhone
							{...register('phone')}
							errorMsg={errors.phone?.message}
							placeholder="Enter Phone"
							label="Phone"
							isDisabled={true}
							{...inputStyles}
						/>
					</Stack>
				</>
			) : (
				<Stack
					p="16px"
					direction={{
						base: 'column',
						md: 'row',
					}}
					spacing="16px">
					<InputText
						{...register('firstName')}
						errorMsg={errors.firstName?.message}
						{...inputStyles}
						placeholder="Enter First Name"
						label="First Name"
						errorMessageProps={errorMsgStyles}
						formLabelProps={inputLabelStyles}
					/>
					<InputText
						{...register('lastName')}
						errorMsg={errors.lastName?.message}
						{...inputStyles}
						placeholder="Enter Last Name"
						label="Last Name"
						errorMessageProps={errorMsgStyles}
						formLabelProps={inputLabelStyles}
					/>
					<InputText
						{...register('email')}
						errorMsg={errors.email?.message}
						{...inputStyles}
						placeholder="Enter Email"
						label="Email"
						errorMessageProps={errorMsgStyles}
						formLabelProps={inputLabelStyles}
					/>
					<InputPhone
						{...register('phone')}
						errorMsg={errors.phone?.message}
						placeholder="Enter Phone"
						label="Phone"
					/>
				</Stack>
			)}
		</>
	);
};
