import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
	baseStyle: {
		color: 'blue.300',
		fontWeight: 500,
		lineHeight: '19px',
	},
	variants: {
		bodyXl: {
			fontSize: '24px',
			lineHeight: '30px',
		},
		bodyLarge: {
			fontSize: '20px',
			lineHeight: '24px',
		},
		bodyBig: {
			fontSize: '18px',
			lineHeight: '22px',
		},
		bodyMedium: {
			fontSize: '14px',
			lineHeight: '17px',
		},
		bodySmall: {
			fontSize: '12px',
			lineHeight: '15px',
		},
	},
};
