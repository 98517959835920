import { deleteToken, getMessaging } from 'firebase/messaging';
import { useChatUserStore, useNotificationsPermissionsStore } from '../store';
import { userManager } from './oidc';
import { useDeleteDeviceToken } from '../pages/Messenger/queries';

export const useSignOut = () => {
	const { setChatUser } = useChatUserStore();
	const { token } = useNotificationsPermissionsStore();
	const deleteDeviceTokenMutation = useDeleteDeviceToken();

	const onSignOut = async () => {
		try {
			setChatUser(null);
			try {
				await deleteToken(getMessaging());
			} catch (error) {
				console.log(error, 'deletion of token failed');
			}
			if (token) {
				deleteDeviceTokenMutation.mutate(token);
			}
			await userManager.signoutRedirect({
				extraQueryParams: {
					returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
				},
			});
		} catch (err) {
			console.log(err, '@@@ onSignOut');
		}
	};

	return onSignOut;
};
