import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';
import { PropertyStatus } from '../../types';

export const useCreateProperty = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, FormData>({
		mutationFn: payload => API_CTRL.post('/api/app/properties', payload),
		onSuccess: (response, payload) => {
			if (response.success) {
				Alerter.success('Created successfully');
				const statusId = payload.get('Label');
				queryClient.refetchQueries([
					Number(statusId) === PropertyStatus.MoveInReady
						? CruzQueryKeys.PropertiesMoveInReady
						: CruzQueryKeys.PropertiesUnderConstruction,
				]);
			} else {
				Alerter.error(response.errors[0].message);
			}
		},
	});
};
