import React from 'react';
import {
	Box,
	Center,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
} from '@chakra-ui/react';

import { FilePickerButton } from './FilePickerButton';
import { IImageViewType } from '../../../../types';
import { ItemList } from '../../../../../../components';
import { ImageItem } from './ImageItem';

interface IPropertyModal {
	isOpen: boolean;
	onClose: VoidFunction;
	onSetImages: (value: string) => void;
	onSetImagesToSend: (value: File) => void;
	items: IImageViewType[];
	onSelectImage: (value: number) => void;
	onRemoveItem: (value: number) => void;
	onPinImageById: (id: number) => void;
	onSetIdsToDelete: (id: number) => void;
}

export const ManageImagesModal: React.FC<IPropertyModal> = ({
	isOpen,
	onClose,
	onSetImages,
	items,
	onRemoveItem,
	onSelectImage,
	onSetImagesToSend,
	onPinImageById,
	onSetIdsToDelete,
}) => {
	return (
		<Modal
			closeOnOverlayClick={false}
			scrollBehavior={'inside'}
			isCentered={true}
			isOpen={isOpen}
			onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg={'mainBg'} maxW={'950px'}>
				<ModalHeader
					px={'16px'}
					fontSize={'20px'}
					fontWeight={'700'}
					lineHeight={'24px'}>
					Manage Property Images
				</ModalHeader>
				<ModalCloseButton color={'blue.200'} />
				<ModalBody p={'16px'}>
					<Box bg={'white'} borderRadius={'8px'}>
						<Box borderBottom={'1px solid #F1F4F9'} p={'16px'}>
							<Text fontWeight={600}>Images</Text>
						</Box>
						<Box p={'16px'}>
							{!items.length ? (
								<Center h={'96px'}>
									<Text
										color={'inactiveText'}
										variant={'bodyMedium'}>
										No Images
									</Text>
								</Center>
							) : (
								<SimpleGrid
									gridTemplateColumns={
										'repeat(auto-fill, minmax(216px,1fr))'
									}
									gap={'8px'}>
									<ItemList
										items={items}
										renderItem={(item, index) => (
											<ImageItem
												imageSrc={
													item?.id
														? item?.imageUrl
														: item?.image
												}
												onRemove={() => {
													if (item?.id) {
														onSetIdsToDelete(
															item.id,
														);
													}
													onRemoveItem(index);
												}}
												onSelect={() => {
													if (item?.id) {
														onPinImageById(item.id);
													}
													onSelectImage(index);
												}}
												isSelected={item.isPinned}
											/>
										)}
										keyExtractor={(_, index) =>
											`image-${index}`
										}
									/>
								</SimpleGrid>
							)}
						</Box>
					</Box>
					<Flex mt={'16px'} justify={'flex-end'}>
						<FilePickerButton
							onSetFileToUpload={onSetImagesToSend}
							onSetValue={onSetImages}
						/>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
