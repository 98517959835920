import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	conversationId: number | null;
	onClose: VoidFunction;
	onOpen: VoidFunction;
	setConversationId: (value: number) => void;
}

export const useDeleteAllMessagesModalStore = create<IState>(set => ({
	isOpen: false,
	conversationId: null,
	setConversationId: id => set(() => ({ conversationId: id })),
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () => set(() => ({ isOpen: false, receiverId: null })),
}));
