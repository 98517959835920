import { create } from 'zustand';
import { OptionType } from '../types';

type StoreType = {
	isOpen: boolean;
	tenantId: number | null;
	isReactivate: boolean;
	propertyData: PropertyDataType | null;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
	onSetTenantId: (value: number, isReactivate?: boolean) => void;
	onSetPropertyData: (data: PropertyDataType | null) => void;
};

type PropertyDataType = {
	property: OptionType;
	unit: OptionType;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
	tenantId: null,
	isReactivate: false,
	propertyData: null,
};

export const useTenantModalStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () =>
		set({
			isOpen: true,
		}),
	onClose: () =>
		set({
			isOpen: false,
			tenantId: null,
			propertyData: null,
			isReactivate: false,
		}),
	onSetTenantId: (value, isReactivate = false) =>
		set({
			isOpen: true,
			tenantId: value,
			isReactivate,
		}),
	onSetPropertyData: data => set({ propertyData: data, isOpen: true }),
}));
