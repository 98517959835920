import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';

const PropertySchema = yup.object().shape({
	name: yup.string().required(ErrorMessages.REQUIRED),
	type: yup.number().positive(ErrorMessages.REQUIRED),
	label: yup.number().positive(ErrorMessages.REQUIRED),
	ownerId: yup.number().positive(ErrorMessages.REQUIRED),
	location: yup
		.object()
		.shape({
			address: yup.string().required(ErrorMessages.REQUIRED),
			longitude: yup.number().required(ErrorMessages.REQUIRED),
			latitude: yup.number().required(ErrorMessages.REQUIRED),
		})
		.test('check_location', ErrorMessages.REQUIRED, function (value) {
			return value?.address !== '';
		}),
	units: yup
		.array()
		.of(
			yup.object().shape({
				id: yup.number().optional(),
				hasTenant: yup.boolean().optional(),
				name: yup.string().required(ErrorMessages.REQUIRED),
				squareFeet: yup
					.number()
					.typeError(ErrorMessages.REQUIRED)
					.min(0, ErrorMessages.POSITIVE_OR_ZERO),
				bedrooms: yup
					.number()
					.typeError(ErrorMessages.REQUIRED)
					.min(0, ErrorMessages.POSITIVE_OR_ZERO)
					.integer('Only integer number'),
				bathrooms: yup
					.number()
					.typeError(ErrorMessages.REQUIRED)
					.min(0, ErrorMessages.POSITIVE_OR_ZERO),
				monthlyRent: yup
					.number()
					.typeError(ErrorMessages.REQUIRED)
					.min(0, ErrorMessages.POSITIVE_OR_ZERO),
				deposit: yup
					.number()
					.typeError(ErrorMessages.REQUIRED)
					.min(0, ErrorMessages.POSITIVE_OR_ZERO),
			}),
		)
		.min(1, 'Min one unit required'),
	images: yup.array().of(
		yup.object().shape({
			image: yup.mixed(),
			isPinned: yup.boolean(),
		}),
	),
	imageIdsToDelete: yup.array(yup.number()).default([]),
	pinnedImageId: yup.number(),
});

export type PropertyFormType = yup.InferType<typeof PropertySchema>;

export const propertyResolver = yupResolver(PropertySchema);
