import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { Alerter } from '../../../../utils';
import { PlacesAutocompleteResponse } from '../../types';
import { transformPlacesDataToOptions } from '../../utils';

export const getPlaces = async (input?: string) => {
	try {
		const response: ApiResponse<{
			places: PlacesAutocompleteResponse[];
		}> = await API_CTRL.get('/api/geography/google/places/autocomplete', {
			Input: input,
			Type: 'geocode',
			Components: 'country:us',
		});
		if (response.success) {
			// console.log(response, 'RESPONSE');
			return transformPlacesDataToOptions(response.value?.places);
		} else {
			Alerter.error(response.errors[0].message);
		}
	} catch (err) {
		console.log(err, '@@@getPlaces');
		Alerter.error('Something went wrong');
	}
};
