import axios from 'axios';
import { useState } from 'react';

export const useDownloadFile = () => {
	const [loading, setLoading] = useState(false);

	const downloadByLink = async (
		fileUrl: string,
		defaultName: string,
		name?: string,
	) => {
		try {
			setLoading(true);
			const res = await axios.get(fileUrl, {
				responseType: 'blob',
			});

			if (res.status !== 200) {
				return;
			}

			const contentType = res.headers['content-type'];

			const extension = contentType.split('/')[1];

			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				name || `${defaultName}.${extension}`,
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.log('download err: ', error);
		} finally {
			setLoading(false);
		}
	};

	return { downloadByLink, loading };
};
