import { SelectOption } from '../../types';

export const transformSelectOptions = (
	options: SelectOption[],
	value?: string | number,
) => {
	if (!value) {
		return;
	}
	return {
		value,
		label: options.find(el => el.value === value)?.label,
	};
};
