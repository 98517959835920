import {
	BillingCycleEnum,
	InvoiceTypeEnum,
	TenantDetailsType,
	TenantStatus,
} from '../types';
import {
	BILLING_CYCLE_OPTIONS,
	INVOICE_OPTIONS,
	PAYMENT_METHOD_OPTIONS,
} from '../constants';
import { transformAmount } from './global.utils';

export const getTenantStatusColorById = (value: number) => {
	switch (value) {
		case TenantStatus.Active:
			return 'success';
		case TenantStatus.Pending:
			return 'dashboardYellow';
		case TenantStatus.Archive:
			return 'valueRed';
		case TenantStatus.LeaseExpired:
			return 'textGray.200';
		default:
			return '';
	}
};

export const getTenantStatusNameById = (value: number) => {
	switch (value) {
		case TenantStatus.Active:
			return 'Active';
		case TenantStatus.Pending:
			return 'Pending';
		case TenantStatus.Archive:
			return 'Archived';
		case TenantStatus.LeaseExpired:
			return 'Expired';
		default:
			return '';
	}
};

export const getInvoiceNameById = (value: number) => {
	switch (value) {
		case InvoiceTypeEnum.Trillium:
			return 'Trillium';
		case InvoiceTypeEnum.Private:
			return 'Private';
		case InvoiceTypeEnum.Section8:
			return 'Section 8';
		case InvoiceTypeEnum.SecurityDeposit:
			return 'Security Deposit';
		default:
			return '';
	}
};

export const getBillingCycleNameById = (value: number) => {
	switch (value) {
		case BillingCycleEnum.OneOff:
			return 'One-off';
		case BillingCycleEnum.Monthly:
			return 'Monthly';
		case BillingCycleEnum.Quarterly:
			return 'Quarterly';
		case BillingCycleEnum.Annually:
			return 'Annually';
		default:
			return '';
	}
};

export const getTenantValues = (data: TenantDetailsType) => {
	const { lease } = data;
	return {
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		phone: data.phone,
		property: lease
			? {
					value: lease.property.propertyId,
					label: lease.property.propertyName,
			  }
			: null,
		unit: lease
			? {
					value: lease.unitId,
					label: lease.unitName,
			  }
			: null,
		leaseStartDate: lease?.leaseStartDate || '',
		leaseEndDate: lease?.leaseEndDate || '',
		billingCycles: lease?.billings?.length
			? [...lease.billings].reverse().map(it => ({
					...it,
					invoiceType: INVOICE_OPTIONS.find(
						item => item.value === it.invoiceType,
					),
					paymentMethod: PAYMENT_METHOD_OPTIONS.find(
						item => item.value === it.paymentMethod,
					),
					billingCycle: BILLING_CYCLE_OPTIONS.find(
						item => item.value === it.billingCycle,
					),
					amount: transformAmount(it.amount, true),
			  }))
			: [],
		leaseAgreement: undefined,
		// isSentLeaseAgreement: false,
	};
};
