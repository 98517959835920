import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse, TrackUploadProgress } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useCreateDirectConversation = () => {
	return useMutation<ApiResponse<number>, unknown, TrackUploadProgress>({
		mutationKey: [MessengerQueryKeys.CREATE_DIRECT_CONVERSATION],
		mutationFn: ({ payload, onUpload }) =>
			API_CHAT.post('/api/app/conversation/create', payload, {
				headers: { 'Content-Type': 'multipart/form-data' },
				onUploadProgress: onUpload,
			}),
	});
};
