import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	channelId: number | null;
	categoryId: number | null;
	onClose: VoidFunction;
	onOpen: VoidFunction;
	setChannelId: (value: {
		channelId: number;
		categoryId: number | null;
	}) => void;
}

export const useDeleteChannelModalStore = create<IState>(set => ({
	isOpen: false,
	channelId: null,
	categoryId: null,
	setChannelId: data =>
		set(() => ({
			channelId: data.channelId,
			categoryId: data.categoryId,
		})),
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () =>
		set(() => ({
			isOpen: false,
			channelId: null,
			categoryId: null,
		})),
}));
