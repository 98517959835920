import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';
import { MessengerQueryKeys } from './query-keys';

export const useUnPinChannelMessage = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: messageId =>
			API_CHAT.put(`/api/admin/channel/message/${messageId}/unpin`, {}),
		mutationKey: [MessengerQueryKeys.UN_PIN_CHANNEL_MESSAGE],
		onSuccess(res) {
			if (!res.success) {
				Alerter.error(
					res.errors?.[0]?.message ||
						'Error while removing pinned message',
				);
				return;
			}

			Alerter.success('Message successfully unpinned');
		},
		onError(error) {
			console.log(error, 'un-pin-message error');
			Alerter.error('Error while removing pinned message');
		},
	});
};
