/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import {
	Avatar,
	Box,
	Button,
	Center,
	CloseButton,
	Flex,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { deleteToken, getMessaging } from 'firebase/messaging';
import {
	CtrlSmallLogo,
	MainLogoIcon,
	RightPaginationArrowIcon,
	SettingsIcon,
} from '../../../../../../assets';
import { NavAccordion } from '../../../../../../components';
import { NavItem } from '../../../../../../components/DashboardLayout/components/NavItem';
import {
	DropdownMenu,
	DropdownMenuTrigger,
} from '../../../../../../pages/Messenger/common-components/Menu/Menu';
import { UserMenu } from '../../../../../../components/DashboardLayout/components/UserMenu';
import {
	useChatUserStore,
	useVideoInstructionStore,
	useNotificationsPermissionsStore,
} from '../../../../../../store';
import { SUPER_USER_ID } from '../../../../../../constants';
import { Claim, ICurrentUser } from '../../../../../../types';
import { CruzCtrlLinkItems } from './helper';
import { useDeleteDeviceToken } from '../../../../../../pages/Messenger/queries';

interface IMenuContent {
	onClose: VoidFunction;
	isLoading: boolean;
	data?: ICurrentUser;
}

export const MobileMenuContent: React.FC<IMenuContent> = ({
	onClose,
	isLoading,
	data,
}) => {
	const fullName = `${data?.firstName} ${data?.lastName}`;
	const isCommunityAvailable = data?.isCommunityAvailable;
	const isCommunityInstructionCompleted =
		data?.isCommunityInstructionCompleted;

	const { onOpen } = useVideoInstructionStore();
	const { setChatUser } = useChatUserStore();
	const { token } = useNotificationsPermissionsStore();

	const deleteDeviceTokenMutation = useDeleteDeviceToken();

	const location = useLocation();
	const { signOut } = useAuth();

	const onSignOut = async () => {
		setChatUser(null);
		if (token) {
			deleteDeviceTokenMutation.mutate(token);
		}
		try {
			await deleteToken(getMessaging());
		} catch (error) {
			console.log(error, 'deleteToken mobileMenuContent');
		}
		await signOut();
	};

	const accordionItems = CruzCtrlLinkItems.filter(item =>
		Boolean(item.accordion),
	);
	const baseItems = CruzCtrlLinkItems.filter(item =>
		Boolean(!item.accordion),
	);

	const isSuperAdmin = data?.userId === SUPER_USER_ID;

	return (
		<Flex
			direction="column"
			align="space-between"
			px={'20px'}
			bg="blue.100"
			transition=".3s"
			w={{ base: 'full' }}
			overflowX="hidden"
			h="100%"
			pos="fixed">
			<Flex
				justifyContent="space-between"
				alignItems="center"
				minH={'80px'}>
				<Flex alignItems={'center'}>
					<CtrlSmallLogo />
				</Flex>
				<CloseButton
					color="white"
					display={{ base: 'flex' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack>
				{baseItems.map(item => {
					// TODO change later claim to coaching
					const isCoachingTab = item.id === Claim.Coaching;
					// TODO change later claim to coaching

					if (isSuperAdmin && isCoachingTab) {
						return null;
					}
					const isCommunity = item.text === 'Community';
					const link = isCommunity
						? !isCommunityInstructionCompleted
							? '/instruction'
							: item.link
						: item.link;

					return (
						<NavItem
							onAction={onClose}
							isActive={location.pathname === link.split('?')[0]}
							showAvailableBadge={
								isCommunity &&
								isCommunityAvailable &&
								!isCommunityInstructionCompleted
							}
							key={item.text}
							icon={item.icon}
							link={link}
							isDisabled={false}
							menuExpanded={true}
							isInAccordion={item.accordion}
							heightPadding={'16px'}>
							{item.text}
						</NavItem>
					);
				})}
				{accordionItems.length ? (
					<NavAccordion
						icon={<SettingsIcon strokeColor={'white'} />}
						title={'Configurations'}
						menuExpanded={true}>
						{accordionItems.map(item => (
							<NavItem
								onAction={onClose}
								isActive={location.pathname === item.link}
								key={item.text}
								icon={item.icon}
								link={item.link}
								isDisabled={false}
								menuExpanded={true}
								heightPadding={'8px'}>
								{item.text}
							</NavItem>
						))}
					</NavAccordion>
				) : null}
			</VStack>

			<Box mt={'auto'} mb="120px">
				<VStack spacing={'4px'} align={'stretch'}>
					<Button
						px={'3px'}
						to={'/'}
						as={Link}
						h={'48px'}
						_hover={{
							bg: 'blue.200',
						}}
						color={'white'}
						bg={'transparent'}
						justifyContent={'space-between'}>
						<MainLogoIcon />
						<RightPaginationArrowIcon
							width={20}
							height={20}
							fill={'currentcolor'}
						/>
					</Button>
				</VStack>
				<DropdownMenu>
					{isLoading ? (
						<Center>
							<Spinner size="lg" color="white" />
						</Center>
					) : (
						<Box>
							<DropdownMenuTrigger asChild={true}>
								<Flex
									cursor={'pointer'}
									align="center"
									justify="space-between">
									<Avatar
										name={fullName}
										// src={
										// 	data?.imageUrl ? data.imageUrl : ''
										// }
										boxSize={'50px'}
									/>
									<Flex
										direction="column"
										ml={'15px'}
										minWidth="100px"
										flex={1}>
										<Text color="white" fontSize="16px">
											{fullName}
										</Text>
										<Text
											color="gray.400"
											fontSize="12px"
											isTruncated={true}>
											{data?.email}
										</Text>
									</Flex>
								</Flex>
							</DropdownMenuTrigger>
						</Box>
					)}
					<UserMenu onSignOut={onSignOut} />
				</DropdownMenu>
			</Box>
		</Flex>
	);
};
