export type IdNameType = {
	id: number;
	name: string;
};
export type GeocodeFieldValue = {
	address: string;
	longitude: number;
	latitude: number;
	placeId?: string;
};

export type UnitItemType = {
	id: number;
	name: string;
	squareFeet: number;
	bedrooms: number;
	bathrooms: number;
	monthlyRent: number;
	deposit: number;
	hasTenant: boolean;
};

export type PropertyImageType = {
	id: number;
	imageUrl: string;
	thumbnailUrl: string;
	isPinned: boolean;
};

export type FileType = {
	id: number;
	documentUrl: string;
	fileName: string;
	fileSize: number;
};

export enum PaymentMethodEnum {
	Stripe = 1,
	Cash = 2,
	MoneyOrder = 3,
	PersonalCheck = 4,
}

export enum InvoiceTypeEnum {
	Private = 1,
	Trillium = 2,
	Section8 = 3,
	SecurityDeposit = 4,
}

export enum BillingCycleEnum {
	OneOff = 1,
	Monthly = 2,
	Quarterly = 3,
	Annually = 4,
}
