import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import { IRouteItem } from '../../../../constants';

interface ISidebarItem extends Omit<IRouteItem, 'id'> {
	isExpanded: boolean;
	flexProps?: FlexProps;
	textProps?: TextProps;
	activeColor?: string;
}

export const SidebarItem: React.FC<ISidebarItem> = ({
	name,
	isExpanded,
	icon,
	to,
	flexProps,
	textProps,
	activeColor = 'blue.200',
}) => {
	return (
		<NavLink to={to}>
			{({ isActive }) => (
				<Flex
					justify={isExpanded ? 'flex-start' : 'center'}
					align={'center'}
					_hover={{
						bg: 'blue.200',
					}}
					transition={'all .2s'}
					color={'white'}
					borderRadius={'8px'}
					px={isExpanded ? '10px' : '16px'}
					py={'16px'}
					bg={isActive ? activeColor : 'transparent'}
					{...flexProps}>
					{icon ? <Box>{icon}</Box> : null}
					{isExpanded ? (
						<Text
							fontWeight={'600'}
							ml={'10px'}
							color={'white'}
							{...textProps}>
							{name}
						</Text>
					) : null}
				</Flex>
			)}
		</NavLink>
	);
};
