import { FC } from 'react';

interface CheckIconProps {
	stroke?: string;
}

export const CheckIcon: FC<CheckIconProps> = ({ stroke = '#08BB4B' }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20 6L9 17L4 12"
			stroke={stroke}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
