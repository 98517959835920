import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';

export const useCheckTOSStatusQuery = () => {
	return useQuery<
		ApiResponse<{
			isAgreementSigned: boolean;
			isCampaignFieldsSet: boolean;
		}>
	>(
		['check-tos-status'],
		() => API_IDENTITY.get('/api/user/current/agreement-status', {}),
		{
			refetchOnWindowFocus: false,
		},
	);
};
