// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
	BaseSyntheticEvent,
	Dispatch,
	RefObject,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { TextStylingPatterns } from '../constants/editor';
import { BooleanObject } from '../types';
import { getSurroundSubstring, isLineStartsWithQuote } from '../utils';

export const useTextEditor = (
	inputRef: RefObject<HTMLInputElement>,
	message: string,
	setMessage: Dispatch<SetStateAction<string>>,
) => {
	const [selectedText, setSelectedText] = useState<string | null>('');
	const [showPreview, setShowPreview] = useState(false);
	const [appliedStyles, setAppliedStyles] = useState<BooleanObject>({
		isBold: false,
		isItalic: false,
		isCrossedOut: false,
		isHighlighted: false,
		isHighlightedCode: false,
		isHided: false,
	});

	const handleClickOutside = (event: MouseEvent) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target as Node)
		) {
			// Clicked outside the input
			setSelectedText(null);
		}
	};

	const toggleQuoteOnLine = useCallback(() => {
		if (!selectedText?.length || !inputRef?.current) {
			return;
		}
		const selectionStart = inputRef.current.selectionStart;
		const selectionEnd = inputRef.current.selectionEnd;

		const lineStart =
			message.lastIndexOf('\n', selectionStart || 0 - 1) + 1;
		let lineEnd = message.indexOf('\n', selectionEnd || 0);

		if (lineEnd === -1) {
			lineEnd = message.length;
		}

		const currentLine = message.substring(lineStart, lineEnd);

		if (currentLine.startsWith('>')) {
			const modifiedLine = currentLine.substring(1);

			const newText =
				message.substring(0, lineStart) +
				modifiedLine +
				message.substring(lineEnd);

			setMessage(newText);
		} else {
			const modifiedLine = '>' + currentLine;
			const newText =
				message.substring(0, lineStart) +
				modifiedLine +
				message.substring(lineEnd);
			setMessage(newText);
		}
	}, [inputRef, message, selectedText, setMessage]);

	const handleTextSelection = useCallback(
		(event: BaseSyntheticEvent) => {
			const { selectionStart, selectionEnd, value } = event.target;
			if (!value) {
				return;
			}

			const selectionText = value.substring(selectionStart, selectionEnd);

			if (!selectionText?.length) {
				setSelectedText(null);
				return;
			}

			const surroundedPatterns = getSurroundSubstring(
				value,
				selectionStart,
				selectionEnd,
			);
			//getSurroundSubstring: getting selection with surrounded markdown
			//to get styles applied to selection to show it in TextEditor component

			const isBold = surroundedPatterns.includes(
				TextStylingPatterns.Bold,
			);
			const isItalic = surroundedPatterns.includes(
				TextStylingPatterns.Italic,
			);
			const isCrossedOut = surroundedPatterns.includes(
				TextStylingPatterns.CrossedOut,
			);
			const isHighlighted = isLineStartsWithQuote(
				value,
				selectionStart,
				selectionEnd,
			);
			const isHighlightedCode = surroundedPatterns.includes(
				TextStylingPatterns.HighlightedCode,
			);
			const isHided = surroundedPatterns.includes(
				TextStylingPatterns.Hided,
			);

			setAppliedStyles(prevState => ({
				...prevState,
				isBold,
				isItalic,
				isCrossedOut,
				isHighlighted,
				isHighlightedCode,
				isHided,
			}));

			setSelectedText(selectionText);
		},
		[message],
	);

	const replaceSelection = useCallback(
		(replacement: string, selectedText: string) => {
			if (!selectedText?.length || !inputRef?.current) {
				return;
			}

			const {
				selectionEnd: endPos,
				selectionStart: startPos,
				value,
			} = inputRef.current;

			const surroundedPatterns = getSurroundSubstring(
				value,
				startPos,
				endPos,
			);

			let beforeText = value.substring(
				0,
				startPos === null ? undefined : startPos,
			);
			let afterText = value.substring(endPos || 0, value.length);

			let newText = '';

			if (surroundedPatterns.includes(replacement)) {
				const lengthOfSurroundedDelimiters =
						surroundedPatterns.join('').length,
					delimitersWithoutReplacement = surroundedPatterns.filter(
						elem => elem !== replacement,
					);
				beforeText = value.substring(
					0,
					startPos === null
						? undefined
						: startPos - lengthOfSurroundedDelimiters,
				);
				afterText = value.substring(
					endPos === null ? 0 : endPos + lengthOfSurroundedDelimiters,
					value.length,
				);

				newText = `${beforeText}${delimitersWithoutReplacement
					.toReversed()
					.join(
						'',
					)}${selectedText}${delimitersWithoutReplacement.join(
					'',
				)}${afterText}`;
			} else {
				newText = `${beforeText}${replacement}${selectedText}${replacement}${afterText}`;
			}

			setMessage(newText);
			inputRef.current.value = newText;
			setSelectedText(null);
		},
		[inputRef, message, setMessage],
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return {
		selectedText,
		appliedStyles,
		handleTextSelection,
		replaceSelection,
		toggleQuoteOnLine,
		showPreview,
		setShowPreview,
	};
};
