import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const deleteChannelSchema = yup.object().shape({
	confirmWord: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.matches(/DELETE/, 'Incorrect'),
});

export type DeleteChannelForm = yup.InferType<typeof deleteChannelSchema>;
export const deleteChannelResolver = yupResolver(deleteChannelSchema);
