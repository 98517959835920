export const getVideoDetails = async (file: File) => {
	const video = document.createElement('video');
	const videoUrl = URL.createObjectURL(file);

	video.src = videoUrl;

	await new Promise(resolve => {
		video.addEventListener('loadedmetadata', resolve);
	});

	const videoDuration = Math.floor(video.duration);
	const videoWidth = video.videoWidth;
	const videoHeight = video.videoHeight;
	URL.revokeObjectURL(videoUrl);
	video.remove();

	return { videoDuration, videoWidth, videoHeight };
};
