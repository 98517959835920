import { DesktopSidebar } from './DesktopSidebar/DesktopSidebar';
import { useGetCurrentUser } from '../../../../../hooks';
import { MobileSidebar } from './MobileSidebar/MobileSidebar';

export const Sidebar = () => {
	const { data, isLoading } = useGetCurrentUser();

	return (
		<>
			<DesktopSidebar data={data} isLoading={isLoading} />
			<MobileSidebar data={data} isLoading={isLoading} />
		</>
	);
};
