import React from 'react';
import { Button, useTab } from '@chakra-ui/react';

interface ICustomTabProps {
	children?: React.ReactNode;
}

export const SettingsModalCustomTab = React.forwardRef<null, ICustomTabProps>(
	(props, ref) => {
		const tabProps = useTab({ ...props, ref });
		const isSelected = !!tabProps['aria-selected'];

		return (
			<Button
				bg={isSelected ? 'blue.200' : 'transparent'}
				borderRadius="4px"
				padding="15px 10px"
				textAlign="left"
				color="white"
				height="auto"
				justifyContent="flex-start"
				colorScheme="blackAlpha"
				{...tabProps}>
				{tabProps.children}
			</Button>
		);
	},
);

SettingsModalCustomTab.displayName = 'SettingsModalCustomTab';
