import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from 'react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';

interface IPayload {
	invoicesIds: Array<number>;
	status: number;
}

export const useUpdateInvoicesStatus = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, IPayload>({
		mutationFn: payload =>
			API_CTRL.put('/api/app/invoices/status', payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Updated successfully');
				queryClient.invalidateQueries([CruzQueryKeys.Invoices]);
				queryClient.invalidateQueries([CruzQueryKeys.InvoiceOverview]);
				queryClient.invalidateQueries([CruzQueryKeys.TenantInvoices]);
				queryClient.invalidateQueries([
					CruzQueryKeys.TenantInvoiceOverview,
				]);
			} else {
				Alerter.error(response.errors[0].message);
			}
		},
	});
};
