import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import './MessageContent.css';
import { markdownInstance } from '../../../utils';

interface IMessageContentProps {
	text: string;
	isEdited?: boolean;
	numberOfLines?: number;
	enableBreaks?: boolean;
}

export const MessageContent: React.FC<IMessageContentProps> = ({
	text,
	isEdited,
	numberOfLines,
	enableBreaks = false,
}) => {
	const renderedMarkdown = useMemo(() => {
		const mainMarkdown = markdownInstance
			.set({ breaks: enableBreaks })
			.render(`${text || ''}`, {});
		const isEditedMark = isEdited
			? '<span class="edited-mark">(edited)<span>'
			: '';
		return `${mainMarkdown}${isEditedMark}`;
	}, [text, isEdited]);

	return (
		<Box
			fontWeight={500}
			fontSize="16px"
			lineHeight="24px"
			w="100%"
			noOfLines={numberOfLines}
			sx={{
				'> p': {
					wordBreak: 'break-word',
				},
				'> p:not(:first-of-type)': {
					mt: '12px',
				},
				'> pre': {
					borderRadius: '2px',
					bg: 'dropBlueOpacity.500',
					borderWidth: '1px',
					borderColor: 'dropBlueOpacity.600',
					px: '2px',
					lineHeight: '21px',
					whiteSpace: 'break-spaces',
				},
			}}
			dangerouslySetInnerHTML={{
				__html: renderedMarkdown,
			}}
		/>
	);
};
