import React, { Children, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, UnorderedList, Text } from '@chakra-ui/react';
import { getSuggestionHtmlId } from './utils';
import { Suggestion } from './Suggestion';
import { Loader } from '../Loader/Loader';

const SuggestionsOverlay = ({
	id,
	suggestions = {},
	a11ySuggestionsListLabel,
	focusIndex,
	// position,
	// left,
	// right,
	// top,
	scrollFocusedIntoView,
	isLoading,
	isOpened,
	onSelect = () => null,
	ignoreAccents,
	containerRef,
	children,
	onMouseDown,
	onMouseEnter,
}) => {
	const [ulElement, setUlElement] = useState(undefined);

	useEffect(() => {
		if (
			!ulElement ||
			ulElement.offsetHeight >= ulElement.scrollHeight ||
			!scrollFocusedIntoView
		) {
			return;
		}
		const scrollTop = ulElement.scrollTop;

		let { top, bottom } =
			ulElement.children[focusIndex].getBoundingClientRect();
		const { top: topContainer } = ulElement.getBoundingClientRect();
		top = top - topContainer + scrollTop;
		bottom = bottom - topContainer + scrollTop;

		if (top < scrollTop) {
			ulElement.scrollTop = top;
		} else if (bottom > ulElement.offsetHeight) {
			ulElement.scrollTop = bottom - ulElement.offsetHeight;
		}
	}, [focusIndex, scrollFocusedIntoView, ulElement]);

	const renderSuggestions = () => {
		const suggestionsToRender = (
			<UnorderedList
				ref={setUlElement}
				id={id}
				role="listbox"
				margin="0"
				backgroundColor="white"
				aria-label={a11ySuggestionsListLabel}
				listStyleType="none">
				{Object.values(suggestions).reduce(
					(accResults, { results, queryInfo }) => [
						...accResults,
						...results.map((result, index) =>
							renderSuggestion(
								result,
								queryInfo,
								accResults.length + index,
							),
						),
					],
					[],
				)}
			</UnorderedList>
		);

		return suggestionsToRender;
	};

	const renderSuggestion = (result, queryInfo, index) => {
		const isFocused = index === focusIndex;
		const { childIndex, query } = queryInfo;
		// const { renderSuggestion } =
		// 	Children.toArray(children)[childIndex].props;

		return (
			<Suggestion
				key={`${childIndex}-${getID(result)}`}
				id={getSuggestionHtmlId(id, index)}
				query={query}
				index={index}
				ignoreAccents={ignoreAccents}
				// renderSuggestion={renderSuggestion}
				suggestion={result}
				focused={isFocused}
				onClick={() => select(result, queryInfo)}
				onMouseEnter={() => handleMouseEnter(index)}
			/>
		);
	};

	const renderLoadingIndicator = () => {
		if (!isLoading) {
			return;
		}

		return <Loader spinnerSize="sm" />;
	};

	const handleMouseEnter = (index, ev) => {
		if (onMouseEnter) {
			onMouseEnter(index);
		}
	};

	const select = (suggestion, queryInfo) => {
		onSelect(suggestion, queryInfo);
	};

	const getID = suggestion => {
		if (typeof suggestion === 'string') {
			return suggestion;
		}
		return suggestion.id;
	};

	if (!isOpened) {
		return null;
	}

	return (
		<Box
			position="absolute"
			// left={left}
			// right={right}
			// top={top}
			zIndex={20}
			left={0}
			bottom="65px"
			w="100%"
			onMouseDown={onMouseDown}
			backgroundColor="white"
			padding="10px"
			borderRadius="10px"
			ref={containerRef}>
			<Text
				color="blue.200"
				fontWeight={700}
				fontSize="14px"
				textTransform="uppercase"
				mb="5px">
				Members
			</Text>
			{renderSuggestions()}
			{renderLoadingIndicator()}
		</Box>
	);
};

SuggestionsOverlay.propTypes = {
	id: PropTypes.string.isRequired,
	suggestions: PropTypes.object.isRequired,
	a11ySuggestionsListLabel: PropTypes.string,
	focusIndex: PropTypes.number,
	position: PropTypes.string,
	left: PropTypes.number,
	right: PropTypes.number,
	top: PropTypes.number,
	scrollFocusedIntoView: PropTypes.bool,
	isLoading: PropTypes.bool,
	isOpened: PropTypes.bool.isRequired,
	onSelect: PropTypes.func,
	ignoreAccents: PropTypes.bool,
	customSuggestionsContainer: PropTypes.func,
	containerRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current:
				typeof Element === 'undefined'
					? PropTypes.any
					: PropTypes.instanceOf(Element),
		}),
	]),
};

export default SuggestionsOverlay;
