export type PlayerButtonsSizeItem = {
	mainPlayPauseButtonSize: string;
	mainPlayPauseIconSize: string;
	bottomPlayPauseButtonSize: string;
	bottomPlayPauseIconSize: string;
	currentTimeText: string;
	volumeButtonSize: string;
	volumeIconSize: string;
	fullscreenButtonSize: string;
	fullscreenIconSize: string;
	volumeTrackWidth: string;
	volumeTrackPadding: string;
	downloadButtonSize: string;
	downloadIconSize: string;
	bottomBarHeight: string;
};

export type PlayerButtonsSizeVariants = {
	[key: string]: PlayerButtonsSizeItem;
};

export enum PlayerButtonsSize {
	SMALL = 'small',
	MEDIUM = 'medium',
	BIG = 'big',
}
