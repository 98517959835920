import {
	Button,
	Center,
	Divider,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { UploadFile } from './components/UploadFile';
import { Alerter, formatFileSize } from '../../../../utils';
import { useDropzone } from 'react-dropzone';
import { MAX_SIZE_25 } from '../../constants';
import pluralize from 'pluralize';

const defaultAccept = {
	'application/pdf': ['.pdf'],
};

interface IProps {
	isOpen: boolean;
	onClose: VoidFunction;
	setFileToUpload?: (value: File) => void;
	onUploadFile?: (value: File, cb?: VoidFunction) => void;
	isLoading?: boolean;
	maxFileSize?: number;
	accept?: Record<string, string[]>;
	allowFileTypes?: Array<string>;
	modalTitle?: string;
	uploadBtnText?: string;
}

export const UploadFileModal: React.FC<IProps> = ({
	isOpen,
	onClose,
	setFileToUpload,
	onUploadFile,
	isLoading,
	maxFileSize = MAX_SIZE_25,
	accept = defaultAccept,
	allowFileTypes = ['PDF'],
	modalTitle = 'Upload File',
	uploadBtnText = 'Upload',
}) => {
	const [file, setFile] = useState<File>();
	const onDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles[0] && acceptedFiles[0].size > maxFileSize) {
			Alerter.error(
				`The file size must not exceed ${formatFileSize(
					maxFileSize,
				)}, but you have ${formatFileSize(acceptedFiles[0].size)}`,
			);
			return;
		}
		setFile(acceptedFiles[0]);
	}, []);

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		accept: accept,
	});

	const onReset = useCallback(() => {
		setFile(undefined);
		onClose();
	}, []);

	return (
		<Modal
			closeOnOverlayClick={false}
			scrollBehavior={'inside'}
			isCentered={true}
			isOpen={isOpen}
			onClose={onReset}>
			<ModalOverlay />
			<ModalContent
				bg={'white'}
				maxW={'432px'}
				w={{ base: '90vw', lg: '100%' }}>
				<ModalHeader
					px={'16px'}
					fontSize={'20px'}
					fontWeight={'700'}
					lineHeight={'24px'}>
					{modalTitle}
				</ModalHeader>
				<ModalCloseButton color={'blue.200'} />
				<ModalBody p={'16px'}>
					<VStack spacing={'16px'} align={'stretch'}>
						{file ? (
							<UploadFile
								uploadBtnText={uploadBtnText}
								isLoading={isLoading}
								onUploadFile={onUploadFile}
								key={file.name}
								onClose={onReset}
								setFileToUpload={setFileToUpload}
								file={file}
								onRefresh={open}
								onRemove={() => {
									setFile(undefined);
								}}
							/>
						) : (
							<>
								<Center
									bg={'#EBF0F9'}
									h={'160px'}
									borderRadius={'8px'}
									border={'1px dashed #BDCDE9'}
									{...getRootProps()}>
									<input {...getInputProps()} />
									<Text
										variant={'bodyMedium'}
										color={'inactiveText'}>
										Drop file here...
									</Text>
								</Center>
								<Text
									textAlign={'center'}
									fontWeight={600}
									variant={'bodyMedium'}
									color={'inactiveText'}>
									or
								</Text>
								<Button
									onClick={open}
									alignSelf={'center'}
									variant={'ctrl-secondary'}>
									Select File
								</Button>
								<HStack
									divider={
										<Divider
											h={'15px'}
											borderColor={'dropBlue'}
											orientation="vertical"
										/>
									}
									alignSelf={'center'}
									spacing={'8px'}>
									<Text
										color={'inactiveText'}
										variant={'bodySmall'}>
										{`File ${pluralize(
											'type',
											allowFileTypes.length,
										)}: ${allowFileTypes.join(', ')}`}
									</Text>
									<Text
										color={'inactiveText'}
										variant={'bodySmall'}>
										{`File size: max ${formatFileSize(
											maxFileSize,
										)}`}
									</Text>
								</HStack>
							</>
						)}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
