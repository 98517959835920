import { useMutation } from 'react-query';
import { Alerter } from '../../../utils';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useIgnoreFriendRequest = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (id: number) =>
			API_CHAT.post('/api/app/friend/ignore-request', {
				friendRequestId: id,
			}),
		onSuccess(data) {
			if (!data.success) {
				return Alerter.error('Error while unblocking');
			}
			Alerter.success('Request declined');
		},

		onError(error) {
			console.log('useIgnoreFriendRequest', error);
		},
	});
};
