import { useInfiniteQuery } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ReactionsModalListResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

const PAGE_SIZE = 10;

export const useGetChannelMessageReactions = (
	messageId: number | null,
	enableFetch: boolean,
) => {
	const fetchReactions = async ({ pageParam = 1 }) => {
		const res: ApiResponse<ReactionsModalListResponse> = await API_CHAT.get(
			'/api/app/channel/messages/reaction',
			{
				pageSize: PAGE_SIZE,
				currentPage: pageParam,
				messageId,
			},
		);
		return res.value.reactionItemsInfo;
	};

	return useInfiniteQuery({
		queryKey: [MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS, messageId],
		queryFn: ({ pageParam }) =>
			fetchReactions({
				pageParam,
			}),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.length === PAGE_SIZE ? allPages.length + 1 : null;
		},
		enabled: enableFetch && !!messageId,
		refetchOnWindowFocus: false,
	});
};
