import React from 'react';
import { Avatar, Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import { LikeIcon } from '../../../../../../assets/messenger-icons/LikeIcon';
import { colors } from '../../../../../../theme';
import {
	useGetChannelMessageReactionsPreview,
	useGetDirectMessageReactions,
} from '../../../../queries';
import { ChatType } from '../../../../types';
import pluralize from 'pluralize';

interface IMessageMenuReactionsListProps {
	onOpen: VoidFunction;
	messageId?: number;
	chatType?: ChatType;
	isMenuOpen: boolean;
	hasReactions?: boolean;
}

export const MessageMenuReactionsList: React.FC<
	IMessageMenuReactionsListProps
> = ({ onOpen, messageId, chatType, isMenuOpen, hasReactions }) => {
	const {
		data: channelMessageReactionsList,
		isFetching: isChannelMessageReactionsFetching,
	} = useGetChannelMessageReactionsPreview(
		messageId || null,
		chatType === ChatType.CHANNEL && isMenuOpen && !!hasReactions,
	);

	const {
		data: directMessageReactionsList,
		isFetching: isDirectMessageReactionsFetching,
	} = useGetDirectMessageReactions(
		messageId || null,
		chatType === ChatType.DIRECT && isMenuOpen && !!hasReactions,
	);

	const reactionsData =
		directMessageReactionsList || channelMessageReactionsList;
	const reactionsDataFetching =
		isChannelMessageReactionsFetching || isDirectMessageReactionsFetching;

	const skeletonCondition = reactionsDataFetching && hasReactions;

	if (skeletonCondition) {
		return <Skeleton height="40px" borderRadius="6px" />;
	}
	if (!reactionsData?.value?.reactionItemsInfo?.length) {
		return null;
	}

	const {
		reactionItemsInfo: channelReactions,
		count: channelReactionsCount,
	} = reactionsData.value;

	return (
		<Flex
			onClick={onOpen}
			as={Button}
			w={'100%'}
			justifyContent={'start'}
			p={'10px'}
			borderRadius={'4px'}
			bg={colors.mainBg}
			_hover={{
				bg: colors.dropBlue,
				borderColor: colors.dropBlue,
			}}>
			<LikeIcon width={'20'} height={'18'} />
			<Text fontWeight={'600'} color={'blue.200'} ml={'10px'}>
				{channelReactionsCount}{' '}
				{pluralize('reaction', channelReactionsCount)}
			</Text>
			<Box ml={'20px'}>
				{channelReactions.slice(0, 5).map((item, index, arr) => (
					<Avatar
						key={index}
						src={item.avatarUrl || ''}
						name={item.displayName || ''}
						pos={'relative'}
						border={`1px solid ${colors.mainBg}`}
						left={`-${index * 7}px`}
						zIndex={arr.length - index}
						boxSize={'25px'}
						bg={colors.blue[50]}
						color="white"
						sx={{
							'> div': {
								fontSize: '10px',
							},
						}}
					/>
				))}
			</Box>
		</Flex>
	);
};
