import { FileType, InvoiceTypeEnum, PropertyImageType } from './root.types';

export enum TenantStatus {
	Pending = 1,
	Active = 2,
	Archive = 3,
	LeaseExpired = 4,
}
// ============= TENANT ===============

export type TenantLeasePropertyType = {
	propertyId: number;
	propertyName: string;
	address: string;
	images: PropertyImageType[];
	ownerName: string;
};

export type BillingCycleItemType = {
	id: number;
	invoiceName: string;
	invoiceType: number;
	amount: number;
	invoiceDate: string;
	dueDays: number;
	paymentMethod: number;
	billingCycle: number;
};

export type TenantLeaseType = {
	id: number;
	unitId: number;
	unitName: string;
	leaseStartDate: string;
	leaseEndDate: string;
	leaseAgreement: FileType | null;
	isSentLeaseAgreement: boolean;
	isSignatureConfirmed: boolean;
	property: TenantLeasePropertyType;
	billings: BillingCycleItemType[] | null;
	documents: FileType[];
	payments: TenantDetailsPayments;
	isStripeConnected: boolean;
};

type TenantDetailsInvoiceAmount = {
	type: InvoiceTypeEnum;
	amount: number;
};

export type TenantDetailsInvoice = TenantDetailsInvoiceAmount | null;

export interface TenantDetailsPayments {
	rentAmount: number;
	totalPaid: number;
	totalUnpaid: number;
	totalOverDue: number;
	paidInvoice: TenantDetailsInvoice;
	unpaidInvoice: TenantDetailsInvoice;
	overdueInvoice: TenantDetailsInvoice;
}

export type TenantDetailsType = {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	status: number;
	lease: TenantLeaseType;
};

// ============= TENANT ===============

export type TenantsResponseItem = {
	id: number;
	name: string;
	status: number;
	propertyName: string;
	unitName: string;
	email: string;
	phoneNumber: string;
};
