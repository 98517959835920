import { Box, BoxProps, ButtonProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface VerticalButtonGroupProps extends BoxProps {
	children: ReactNode | null | undefined;
}

export const VerticalButtonGroup: React.FC<VerticalButtonGroupProps> = ({
	children,
	...rest
}) => {
	if (children == null) {
		return null; // or any other fallback for null/undefined children
	}

	const validChildren = React.Children.toArray(children).filter(Boolean); // Exclude null elements

	const count = validChildren.length;

	return (
		<Box
			display="flex"
			flexDirection="column"
			border="1px solid"
			borderColor="gray.200"
			borderRadius="md"
			overflow="hidden"
			{...rest}>
			{validChildren.map((child, index) => {
				const isFirst = index === 0;
				const isLast = index === count - 1;

				return React.cloneElement(
					child as React.ReactElement<ButtonProps>,
					{
						borderTopRadius: isFirst ? '4px' : '0',
						borderBottomRadius: isLast ? '4px' : '0',
						borderLeft: '0',
						borderRight: '0',
						borderBottom: index !== count - 1 ? '1px solid' : '0',
						borderColor: 'mainBg',
					},
				);
			})}
		</Box>
	);
};
