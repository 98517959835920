import { create } from 'zustand';
import { UserChannelData } from '../pages/Messenger/types';

interface IState {
	onlineChannelUsersCount: number;
	onlineChannelUsers: UserChannelData[];
	setOnlineChannelUsers: (users: UserChannelData[]) => void;
	setOnlineChannelUsersCount: (count: number) => void;
	resetOnlineChannelUsers: () => void;
	updateOnlineChannelUsers: (
		id: number,
		updates: Partial<UserChannelData>,
	) => void;
	addToOnlineChannelUsers: (users: UserChannelData[]) => void;
}

export const useServerUsersOnlineList = create<IState>(set => ({
	onlineChannelUsersCount: 0,
	onlineChannelUsers: [],
	setOnlineChannelUsers: users => set({ onlineChannelUsers: users }),
	addToOnlineChannelUsers: users =>
		set(state => ({
			onlineChannelUsers: [...state.onlineChannelUsers, ...users],
		})),
	setOnlineChannelUsersCount: count =>
		set({ onlineChannelUsersCount: count }),
	resetOnlineChannelUsers: () => set({ onlineChannelUsers: [] }),
	updateOnlineChannelUsers: (id, updatedData) =>
		set(state => ({
			onlineChannelUsers: state.onlineChannelUsers.map(user =>
				user.userId === id ? { ...user, ...updatedData } : user,
			),
		})),
}));
