import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { UnoccupiedPropertyType } from '../../types';
import { useQuery } from 'react-query';

interface IPayload {
	searchString?: string;
	isEnabled?: boolean;
}

export const useGetUnoccupiedPropertySelector = (payload: IPayload) => {
	return useQuery({
		queryKey: [CruzQueryKeys.UnoccupiedPropertySelector],
		queryFn: (): Promise<
			ApiResponse<{
				items: UnoccupiedPropertyType[];
			}>
		> =>
			API_CTRL.get('/api/app/selector/property/extended/unoccupied', {
				SearchString: payload.searchString,
			}),
		enabled: !!payload.isEnabled,
	});
};
