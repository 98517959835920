import React from 'react';
import { Box, Card, CardBody, Stack, Text } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { getDatasetForGaugeChart } from '../../utils';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IStatGaugeItemProps {
	name: string;
	value: string | number;
}

const options = {
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: true,
			text: 'Chart',
		},
		tooltip: {
			enabled: false,
		},
	},
};

export const StatGaugeItem: React.FC<IStatGaugeItemProps> = ({
	name,
	value,
}) => {
	const data = {
		labels: [''],
		datasets: [getDatasetForGaugeChart(value)],
	};

	return (
		<Box>
			<Card maxW="sm">
				<CardBody p="0px">
					<Stack mb="10px" px="5px">
						<Text textAlign="start" fontWeight={600}>
							{name}
						</Text>

						<Doughnut data={data} options={options}></Doughnut>

						<Text fontSize="xl" fontWeight={600} textAlign="center">
							{value}
						</Text>
					</Stack>
				</CardBody>
			</Card>
		</Box>
	);
};
