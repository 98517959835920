export const PaperClipIcon = () => {
	return (
		<svg
			width="21"
			height="22"
			viewBox="0 0 21 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M19.1518 9.89897L10.1361 18.9146C8.08589 20.9648 4.76177 20.9648 2.71152 18.9146C0.661265 16.8643 0.661265 13.5402 2.71152 11.49L11.7271 2.47435C13.094 1.10751 15.31 1.10751 16.6769 2.47434C18.0437 3.84118 18.0437 6.05726 16.6769 7.42409L8.01482 16.0862C7.3314 16.7696 6.22336 16.7696 5.53994 16.0862C4.85653 15.4027 4.85653 14.2947 5.53994 13.6113L13.1413 6.00988"
				stroke="#0F1B49"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
