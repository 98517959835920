import React, { PropsWithChildren } from 'react';
import { BlockMessageComponent } from './BlockMessageComponent';
import { useUnBlockUser } from '../../queries';
import { useUpdateUserInfoById } from '../../hooks';

interface IProps {
	receiverId?: string;
	isBlockedMe?: boolean;
	isReceiverBlocked?: boolean;
}

export const BlockMessageWrapper: React.FC<PropsWithChildren<IProps>> = ({
	children,
	receiverId,
	isBlockedMe,
	isReceiverBlocked,
}) => {
	const { isLoading, mutateAsync: unBlockUser } = useUnBlockUser();
	const { mutate: updateUserInfoById } = useUpdateUserInfoById();

	const unBlock = async () => {
		try {
			if (!receiverId) {
				return;
			}
			await unBlockUser(Number(receiverId));

			updateUserInfoById(+receiverId, { isBlocked: false });
		} catch (e) {
			console.log('cancel unblock user error', e);
		}
	};

	return (
		<>
			{isBlockedMe || isReceiverBlocked ? (
				<BlockMessageComponent
					unBlock={unBlock}
					isLoading={isLoading}
					meBlocked={isBlockedMe}
				/>
			) : (
				children
			)}
		</>
	);
};
