export const XCircleIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1121_3230)">
			<path
				d="M12.4993 7.50008L7.49935 12.5001M7.49935 7.50008L12.4993 12.5001M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001Z"
				stroke="#C14B4B"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1121_3230">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
