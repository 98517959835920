import { useQuery, useQueryClient } from 'react-query';

type PersistDataStorage = {
	[key: string]: PersistItem;
};

type PersistItem = {
	[key: string]: string | number | boolean | null;
};

export const usePersistData = (storageKey: string) => {
	const queryClient = useQueryClient();

	const getStorageData = (): PersistDataStorage => {
		const storedData = localStorage.getItem(storageKey);
		return storedData ? JSON.parse(storedData) : {};
	};

	const { data: storageData, isLoading: storageLoading } =
		useQuery<PersistDataStorage>({
			queryKey: storageKey,
			queryFn: getStorageData,
			refetchOnMount: false,
		});

	const updateLocalStorage = (updatedData: PersistDataStorage) => {
		localStorage.setItem(storageKey, JSON.stringify(updatedData));
	};

	const getPersistedValue = (
		key: string,
		id: string,
	): string | number | boolean | null => {
		const data = storageData?.[key];
		return data ? data[id] : null;
	};

	const getPersistedKeyData = (key: string): PersistItem | null => {
		const data = storageData?.[key];
		return data || null;
	};

	const updatePersistedValue = (key: string, updatedValues: PersistItem) => {
		const newData = { ...storageData };
		newData[key] = { ...(newData[key] || {}), ...updatedValues };
		updateLocalStorage(newData);
		queryClient.setQueryData<PersistDataStorage>(storageKey, newData);
	};

	const removePersistedData = () => {
		localStorage.removeItem(storageKey);
		queryClient.invalidateQueries(storageKey);
	};

	const newUserReset = (userId: number) => {
		const newUser = {
			user: {
				id: userId,
			},
		};
		updateLocalStorage(newUser);
		queryClient.setQueryData<PersistDataStorage>(storageKey, newUser);
	};

	return {
		storageData,
		storageLoading,
		getPersistedValue,
		updatePersistedValue,
		removePersistedData,
		newUserReset,
		getPersistedKeyData,
	};
};
