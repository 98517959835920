export const ReplyVector = () => (
	<svg
		width="35"
		height="9"
		viewBox="0 0 35 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path d="M35 1H5C2.79086 1 1 2.79086 1 5V9" stroke="#BDCDE9" />
	</svg>
);
