import React from 'react';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { GroupBase } from 'react-select';
import { OptionType } from '../../types';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { stylesConfig } from './stylesConfig';

interface ICustomAsyncSelect
	extends AsyncProps<OptionType, boolean, GroupBase<OptionType>> {
	errMsg?: string;
	label?: string;
	size?: 'md' | 'lg';
}

export const CustomAsyncSelect: React.FC<ICustomAsyncSelect> = ({
	errMsg,
	label,
	size = 'md',
	...props
}) => {
	return (
		<FormControl isInvalid={!!errMsg}>
			{label ? (
				<FormLabel
					fontSize={'12px'}
					lineHeight={'18px'}
					color={props.isDisabled ? 'textGray.200' : 'inactiveText'}
					fontWeight={500}>
					{label}
				</FormLabel>
			) : null}
			<AsyncSelect
				aria-invalid={Boolean(errMsg)}
				styles={stylesConfig(size)}
				{...props}
			/>
			<FormErrorMessage
				fontSize={'10px'}
				lineHeight={'12px'}
				color={'#DC2D2D'}
				mt={'4px'}>
				{errMsg}
			</FormErrorMessage>
		</FormControl>
	);
};
