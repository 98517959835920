import React from 'react';

export const ArrowRightIcon: React.FC<{
	color?: string;
	width?: string;
	height?: string;
}> = ({ color = '#7E788F', width = '14', height = '10' }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.6665 5H12.3332M12.3332 5L8.33317 1M12.3332 5L8.33317 9"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
