import * as yup from 'yup';
import { ErrorMessages } from '../../constants';
import { yupResolver } from '@hookform/resolvers/yup';

const DropdownFieldSchema = yup.object().shape({
	name: yup.string().trim().required(ErrorMessages.REQUIRED),
	options: yup.array().of(
		yup.object().shape({
			id: yup.number().optional(),
			colour: yup.string().required(ErrorMessages.REQUIRED),
			name: yup.string().trim().required(ErrorMessages.REQUIRED),
		}),
	),
});

export const dropdownFieldResolver = yupResolver(DropdownFieldSchema);

export type DropdownFieldFormType = yup.InferType<typeof DropdownFieldSchema>;
