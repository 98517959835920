import React from 'react';
import { Divider, Text, Flex } from '@chakra-ui/react';
import { chatDividerFormat } from '../utils';

interface IDateDividerProps {
	date: Date;
}

export const DateDivider: React.FC<IDateDividerProps> = ({ date }) => {
	return (
		<Flex align="center" gap="20px">
			<Divider borderColor="dropBlue" />
			<Text color="dropBlue" fontSize="12px" flexShrink={0} noOfLines={1}>
				{chatDividerFormat(date)}
			</Text>
			<Divider borderColor="dropBlue" />
		</Flex>
	);
};
