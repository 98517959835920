import React from 'react';
import { LeftPaginationArrowIcon } from '../../../assets';
import { IconButton } from '@chakra-ui/react';

interface IExpandedButton {
	menuExpanded: boolean;
	onClick: VoidFunction;
}
export const ExpandedButton: React.FC<IExpandedButton> = ({
	menuExpanded,
	onClick,
}) => {
	return (
		<IconButton
			onClick={onClick}
			display={{ base: 'none', md: 'inline-flex' }}
			transition={'transform .2s'}
			right={-4}
			top={'32px'}
			transform={`translateY(-50%) rotate(${
				menuExpanded ? '0' : '180deg'
			})`}
			position={'absolute'}
			background={'blue.50'}
			aria-label={'Expand icon'}
			icon={
				<LeftPaginationArrowIcon
					color={'#FFFFFF'}
					width={20}
					height={20}
				/>
			}
			zIndex={'100'}
			size={'sm'}
			_hover={{ bg: 'blue.200' }}
		/>
	);
};
