import { Route, Routes } from 'react-router-dom';
import React from 'react';

import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy';

const TermsOfService = reactLazyWithRetry(
	() => import('./pages/TermsOfService/Tos'),
);
const PrivacyPolicy = reactLazyWithRetry(
	() => import('./pages/PrivacyPolicy/PrivacyPolicy'),
);
const StripeRedirect = reactLazyWithRetry(
	() => import('./pages/StripeRedirect/StripeRedirect'),
);

export enum PUBLIC_ROUTES_PATHS {
	TOS = '/terms-of-service',
	PP = '/privacy-policy',
	STR = '/stripe-redirect',
}

export const PublicRoutes = () => {
	return (
		<Routes>
			<Route
				path={PUBLIC_ROUTES_PATHS.TOS}
				element={<TermsOfService />}
			/>
			<Route path={PUBLIC_ROUTES_PATHS.PP} element={<PrivacyPolicy />} />
			<Route
				path={PUBLIC_ROUTES_PATHS.STR}
				element={<StripeRedirect />}
			/>
		</Routes>
	);
};
