import {
	CaseIcon,
	DashboardIcon,
	InvoicesIcon,
	PlannerIcon,
	PropertyManagement2,
	UsersIcon,
} from '../../../../../../assets';
import { Claim } from '../../../../../../types';

export const CruzCtrlLinkItems = [
	{
		id: Claim.CruzControl,
		text: 'Dashboard',
		icon: DashboardIcon,
		link: '/cruz-ctrl/dashboard',
		accordion: false,
	},
	{
		id: Claim.CruzControl,
		text: 'Properties',
		icon: PropertyManagement2,
		link: '/cruz-ctrl/properties?tabIndex=0',
		accordion: false,
	},
	{
		id: Claim.CruzControl,
		text: 'Tenants',
		icon: UsersIcon,
		link: '/cruz-ctrl/tenants?tabIndex=0',
		accordion: false,
	},
	{
		id: Claim.CruzControl,
		text: 'Invoices',
		icon: InvoicesIcon,
		link: '/cruz-ctrl/invoices',
		accordion: false,
	},
	{
		id: Claim.CruzControl,
		text: 'Planner',
		icon: PlannerIcon,
		link: '/cruz-ctrl/planner',
		accordion: false,
	},

	// {
	// 	id: Claim.CruzControl,
	// 	icon: MailIcon,
	// 	text: 'Mail Accounts',
	// 	link: '/cruz-ctrl/mail-accounts',
	// 	accordion: true,
	// },
	{
		id: Claim.CruzControl,
		icon: CaseIcon,
		text: 'Business Entity',
		link: '/cruz-ctrl/business-entity',
		accordion: true,
	},
	// {
	// 	id: Claim.CruzControl,
	// 	icon: DatabaseIcon,
	// 	text: 'Data Source',
	// 	link: '/cruz-ctrl/data-source',
	// 	accordion: true,
	// },
	// {
	// 	id: Claim.CruzControl,
	// 	icon: UserAccountIcon,
	// 	text: 'Users',
	// 	link: '/cruz-ctrl/users',
	// 	accordion: true,
	// },
];
