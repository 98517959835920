import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { Alerter } from '../../../../utils';
import { PlaceByIdResponse } from '../../types';

export const getPlaceById = async (placeId: string) => {
	try {
		const response: ApiResponse<PlaceByIdResponse> = await API_CTRL.get(
			`/api/geography/google/places/${placeId}/details`,
			{},
		);
		if (response.success) {
			return {
				latitude: response.value.geometry.location.latitude,
				longitude: response.value.geometry.location.longitude,
			};
		} else {
			Alerter.error(response.errors[0].message);
		}
	} catch (err) {
		console.log(err, '@@@getPlaceById');
		Alerter.error('Something went wrong');
	}
};
