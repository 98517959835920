export const inputLabelStyles = {
	fontSize: '12px',
	lineHeight: '18px',
	color: 'inactiveText',
};

export const inputStyles = {
	border: '1px solid #BDCDE9',
	_hover: {
		background: '#EBF0F9',
	},
	_focus: {
		borderColor: '#0F1B49',
		boxShadow: 'none',
	},
	_disabled: {
		background: '#F0F0F0',
		border: '1px solid #8E8E8E',
		color: '#8E8E8E',
	},
	fontSize: '14px',
	lineHeight: '21px',
	fontWeight: 500,
};

export const errorMsgStyles = {
	fontSize: '10px',
	lineHeight: '12px',
	color: '#DC2D2D',
	marginTop: '4px',
};

export const menuListStyles = {
	fontSize: '14px',
	borderRadius: '4px',
	_hover: {
		color: 'white',
		bg: 'blue.100',
	},
	fontWeight: 500,
};

export const toolTipStyles = {
	bg: 'blue.200',
	fontWeight: 500,
	fontSize: '12px',
	borderRadius: '5px',
	color: 'white',
};
