import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const editMessageSchema = yup.object().shape({
	text: yup.string().required(ErrorMessages.REQUIRED),
});

export type EditMessageForm = yup.InferType<typeof editMessageSchema>;
export const editMessageResolver = yupResolver(editMessageSchema);
