export const navItemColorPicker = (
	isActive: boolean,
	menuExpanded: boolean,
	isInAccordion: boolean,
) => {
	if (isActive) {
		if (isInAccordion) {
			if (menuExpanded) {
				return 'blue.100';
			}
			return 'blue.100';
		} else {
			return 'blue.200';
		}
	}
	return undefined;
};
