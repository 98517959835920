import React, { FC } from 'react';

interface TenantFinderIconProps {
	color?: string;
	size?: string;
}

export const TenantFinderIcon: FC<TenantFinderIconProps> = ({
	color = 'currentColor',
	size = 24,
}) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M12 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22 21L20 19M20.6667 17.3333C20.6667 18.622 19.622 19.6667 18.3333 19.6667C17.0447 19.6667 16 18.622 16 17.3333C16 16.0447 17.0447 15 18.3333 15C19.622 15 20.6667 16.0447 20.6667 17.3333Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
