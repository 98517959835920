import React from 'react';
import { HStack, IconButton, Img, StackProps } from '@chakra-ui/react';
import { colors } from '../../../../../../theme';
import { AVAILABLE_REACTIONS } from '../../../../constants';
import { MessageReactionType } from '../../../../types';

interface IMessageReactionsPickerProps {
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	userReactedId?: MessageReactionType | null;
	sectionProps?: StackProps;
	id?: number;
}

export const MessageReactionsPicker: React.FC<IMessageReactionsPickerProps> = ({
	onReact,
	userReactedId,
	sectionProps,
	id,
}) => {
	return (
		<HStack justifyContent="space-between" {...sectionProps}>
			{AVAILABLE_REACTIONS.map(reaction => {
				const isPicked = userReactedId === reaction.id;
				return (
					<IconButton
						key={reaction.id}
						aria-label={`reaction-${reaction.name}`}
						bg={isPicked ? colors.blue[100] : colors.dropBlue}
						borderRadius="50%"
						transition="background 0.3s ease"
						icon={
							<Img
								src={reaction.img}
								alt={reaction.name}
								boxSize="24px"
								draggable={false}
							/>
						}
						_hover={{
							bg: colors.blue[50],
						}}
						onClick={() => {
							if (id) {
								onReact?.(id, reaction.id, userReactedId);
							}
						}}
					/>
				);
			})}
		</HStack>
	);
};
