export enum ErrorMessages {
	REQUIRED = 'This field is required',
	EMAIL_INVALID = 'Email is invalid',
	ADD_YOURSELF = "You can't add yourself",
	ALREADY_FRIENDS = 'You are already friends',
	NO_USERS_FOUND = 'No users found',
	REQUEST_SENT = 'Request successfully sent',
	REQUEST_ALREADY_SENT = 'Request already sent',
	INCOMING_REQUEST = 'You already have incoming friendship request from this user',
}
