import {
	Box,
	Button,
	Flex,
	IconButton,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InvoiceFormType } from '../../../validations';
import { ClearIcon } from '../../../../../assets';
import { InputText } from '../../../../../components';
import {
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
} from '../../../constants';
import { InputPrice } from '../../../components';

interface IInvoiceFieldItem {
	index: number;
	control: Control<InvoiceFormType>;
	onRemove: VoidFunction;
	isCanRemove: boolean;
	isCanGenerateDesc: boolean;
	onGenerateDescription: VoidFunction;
}

export const InvoiceFieldItem: React.FC<IInvoiceFieldItem> = ({
	index,
	control,
	onRemove,
	isCanRemove,
	isCanGenerateDesc,
	onGenerateDescription,
}) => {
	return (
		<VStack align={'stretch'} spacing={'16px'}>
			<Flex align={'center'} justify={'space-between'}>
				<Text
					color={'blue.100'}
					fontWeight={600}
					variant={'bodyMedium'}>
					{`Invoice Item ${index + 1}`}
				</Text>
				{isCanRemove ? (
					<IconButton
						onClick={onRemove}
						size={'sm'}
						variant={'ghost'}
						icon={<ClearIcon />}
						aria-label={'remove'}
					/>
				) : null}
			</Flex>
			<Stack
				direction={{
					base: 'column',
					md: 'row',
				}}
				spacing={'16px'}>
				<VStack flex={1} spacing={'16px'}>
					<Controller
						render={({
							field: { onChange, value },
							fieldState: { error },
						}) => (
							<InputText
								{...inputStyles}
								errorMsg={error?.message}
								value={value}
								onChange={onChange}
								placeholder="Enter Description"
								label="Description"
								errorMessageProps={errorMsgStyles}
								formLabelProps={inputLabelStyles}
							/>
						)}
						name={`invoiceItems.${index}.description`}
						control={control}
					/>
					{index === 0 ? (
						<Button
							onClick={onGenerateDescription}
							isDisabled={!isCanGenerateDesc}
							alignSelf={'flex-start'}
							variant={'ctrl-secondary'}
							w={{ base: 'full', md: 'auto' }}>
							Generate description
						</Button>
					) : null}
				</VStack>

				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<Box w={{ base: 'full', md: '172px' }}>
							<InputPrice
								value={value}
								errorMsg={error?.message}
								onChange={onChange}
								label={'Amount'}
								allowNegative={false}
								placeholder={'Enter Amount'}
							/>
						</Box>
					)}
					name={`invoiceItems.${index}.amount`}
					control={control}
				/>
			</Stack>
		</VStack>
	);
};
