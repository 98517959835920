import { useMutation } from 'react-query';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';
import { API_CHAT } from '../../../api/api.base';
import { useNotificationsPermissionsStore } from '../../../store';

export const useDeleteDeviceToken = () => {
	const { setToken } = useNotificationsPermissionsStore();
	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationKey: [MessengerQueryKeys.DELETE_DEVICE_TOKEN],
		mutationFn: payload =>
			API_CHAT.delete(
				`/api/app/push-notification/token/delete?DeviceToken=${payload}`,
			),

		onError(error) {
			console.log(error, 'save device token error');
		},
		onSettled(data, error, variables) {
			setToken(null);
		},
	});
};
