import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, HStack, Image, useDisclosure } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import mainPlaceholder from '../../../../../../assets/images/property-placeholder.png';
import thumbnailPlaceholder from '../../../../../../assets/images/thumbnail-property-placeholder.png';
import { CameraIcon } from '../../../../../../assets';
import { ManageImagesModal } from './ManageImagesModal';
import { ItemList } from '../../../../../../components';
import {
	IImageToSendType,
	IImageViewType,
	PropertyImageType,
} from '../../../../types';
import { PropertyFormType } from '../../../../validations';

const placeholderImagesData = new Array(4).fill(undefined);

const getImageSrc = (
	images: IImageViewType[],
	index: number,
): string | undefined => {
	const { id, image, thumbnailUrl } = images[index] || {};

	return id ? thumbnailUrl : image;
};

interface IManageImages {
	data: PropertyImageType[];
}

export const MangeImages: React.FC<IManageImages> = ({ data }) => {
	const { setValue, getValues } =
		useFormContext<
			Pick<
				PropertyFormType,
				'images' | 'imageIdsToDelete' | 'pinnedImageId'
			>
		>();

	const { isOpen, onClose, onOpen } = useDisclosure();

	const [images, setImages] = useState<Array<IImageViewType>>(
		data?.length ? data : [],
	);

	const [imagesToSend, setImagesToSend] = useState<Array<IImageToSendType>>(
		[],
	);

	const imgWithId = images.filter(it => it?.id);

	const onPinImageById = useCallback(
		(id: number) => {
			const findElement = images.find(it => it?.id === id);
			if (findElement && !findElement.isPinned) {
				setValue('pinnedImageId', id);
			}
		},
		[images],
	);

	const onSetIdsToDelete = useCallback(
		(id: number) => {
			const ids = getValues('imageIdsToDelete');
			const pinnedImageId = getValues('pinnedImageId');
			const findElement = images.find(it => it?.id === id);
			if (Number(pinnedImageId) === id) {
				setValue('pinnedImageId', undefined);
			}
			if (findElement) {
				setValue('imageIdsToDelete', [...ids, id]);
			}
		},
		[images, getValues],
	);

	const onSetImagesToDisplay = useCallback((image: string) => {
		setImages(prev => {
			return [
				...prev,
				{
					image: image,
					isPinned: !prev.length,
				},
			];
		});
	}, []);

	const onSetImagesToSend = useCallback(
		(image: File) => {
			setImagesToSend(prev => {
				return [
					...prev,
					{
						image: image,
						isPinned: !images.length,
					},
				];
			});
		},
		[images],
	);

	const mainImage = images.filter(it => it.isPinned)[0];

	const othersImages = images.filter(it => !it.isPinned).slice(0, 4);

	const onSelectImage = (value: number) => {
		setImages(prev => {
			return prev.map((it, index) => ({
				...it,
				isPinned: index === value,
			}));
		});
		setImagesToSend(prev => {
			return prev.map((it, index) => {
				return {
					...it,
					isPinned:
						index ===
						(imgWithId.length ? value - imgWithId.length : value),
				};
			});
		});
	};

	const onRemoveItem = (value: number) => {
		setImages(prev => prev.filter((_, index) => index !== value));
		setImagesToSend(prev =>
			prev.filter(
				(_, index) =>
					index !==
					(imgWithId.length ? value - imgWithId.length : value),
			),
		);
	};

	useEffect(() => {
		setValue('images', imagesToSend);
	}, [imagesToSend]);

	return (
		<Box>
			<Box
				pos={'relative'}
				overflow={'hidden'}
				w={'216px'}
				borderRadius={'8px'}
				h={'198px'}>
				<Image
					alt={'main-property-image'}
					h={'144px'}
					objectFit={'contain'}
					src={
						mainImage?.id
							? mainImage.thumbnailUrl
							: mainImage?.image
					}
					fallbackSrc={mainPlaceholder}
					w={'full'}
				/>
				<HStack spacing={0}>
					<ItemList
						items={placeholderImagesData}
						renderItem={(_, index) => (
							<Image
								objectFit={'contain'}
								alt={'property-image'}
								boxSize={'54px'}
								src={getImageSrc(othersImages, index)}
								fallbackSrc={thumbnailPlaceholder}
							/>
						)}
						keyExtractor={(_, index) => `image-${index}`}
					/>
					<Flex
						onClick={onOpen}
						top={'8px'}
						right={'8px'}
						borderRadius={'full'}
						type={'button'}
						as={'button'}
						bg={'white'}
						boxSize={'32px'}
						pos={'absolute'}
						align={'center'}
						justify={'center'}>
						<CameraIcon />
					</Flex>
				</HStack>
			</Box>
			<ManageImagesModal
				onSetIdsToDelete={onSetIdsToDelete}
				onPinImageById={onPinImageById}
				onSetImagesToSend={onSetImagesToSend}
				onSelectImage={onSelectImage}
				onRemoveItem={onRemoveItem}
				items={images}
				onSetImages={onSetImagesToDisplay}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</Box>
	);
};
