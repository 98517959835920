import React from 'react';

export const ErrorIcon: React.FC = () => {
	return (
		<svg
			width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M34.3573 6.75185C33.4346 5.00785 30.5653 5.00785 29.6426 6.75185L5.64264 52.0852C5.42659 52.4915 5.31949 52.9468 5.33176 53.4069C5.34403 53.8669 5.47525 54.3159 5.71265 54.7101C5.95004 55.1043 6.28551 55.4304 6.68636 55.6564C7.08722 55.8824 7.53979 56.0007 7.99997 55.9998H56C56.4598 56.0008 56.9121 55.8826 57.3127 55.6567C57.7133 55.4308 58.0485 55.105 58.2856 54.711C58.5228 54.317 58.6538 53.8682 58.6659 53.4085C58.678 52.9488 58.5707 52.4938 58.3546 52.0878L34.3573 6.75185ZM34.6666 47.9998H29.3333V42.6665H34.6666V47.9998ZM29.3333 37.3332V23.9998H34.6666L34.6693 37.3332H29.3333Z"
				fill="#FF3636"
			/>
		</svg>
	);
};
