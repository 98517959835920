import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { AlertDialog } from '../../common-components';
import { ChatType, EditMessageDisplayData } from '../../types';
import { MessageWithAvatar } from '../../messages-components';

interface IDeleteMessageConfirmModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	messageToDelete: EditMessageDisplayData | null;
	onDeleteMessage: (value: number, byAdmin?: boolean) => void;
	chatType: ChatType;
	currentUserId?: number;
}

export const DeleteMessageConfirmModal: React.FC<
	IDeleteMessageConfirmModalProps
> = ({
	isOpen,
	onClose,
	messageToDelete,
	onDeleteMessage,
	chatType,
	currentUserId,
}) => {
	return (
		<AlertDialog
			isOpen={isOpen}
			acceptCallback={() => {
				if (messageToDelete) {
					onDeleteMessage(
						messageToDelete.id,
						Boolean(currentUserId !== messageToDelete.sentById),
					);
				}
			}}
			acceptButtonText="Yes"
			cancelButtonText="No"
			onClose={onClose}
			title="Delete message">
			<Text color="blue.200" textAlign="center" my="20px">
				Are you sure you want to delete this message?
			</Text>
			{messageToDelete ? (
				<Box mt="10px" borderRadius="10px" bg="white" p="5px 20px">
					<MessageWithAvatar
						isEdit={false}
						avatarImage={messageToDelete.avatarImage}
						sentByDisplayName={messageToDelete.sentByDisplayName}
						text={messageToDelete.text}
						isEdited={messageToDelete.isEdited}
						isSameSenderNext={true}
						showMenu={false}
						date={messageToDelete.date}
						type={messageToDelete.type}
						replyToId={messageToDelete.replyToId}
						id={messageToDelete.id}
						isAdmin={messageToDelete.isAdmin}
						chatType={chatType}
						messageAttachmentImages={
							messageToDelete.attachmentImages
						}
						messageAttachmentFiles={messageToDelete.attachmentFiles}
						messageAttachmentVideos={
							messageToDelete.attachmentVideos
						}
						disabledAttachmentView={true}
						reactions={messageToDelete.reactions}
						sentById={messageToDelete.sentById}
					/>
				</Box>
			) : null}
		</AlertDialog>
	);
};
