export enum InvoiceStatusEnum {
	UnPaid = 1,
	Paid = 2,
	Cancelled = 3,
}

export enum DateFilterEnum {
	CurrentMonth = 1,
	PreviousMonth,
	AllTime,
	SpecificDates,
}

export enum InvoiceFilterStatus {
	Paid = 1,
	UnpaidAll = 2,
	UnpaidOnTime = 3,
	UnpaidOverdue = 4,
}

export type InvoiceOverviewType = {
	paid: number;
	unpaid: number;
	overdue: number;
};

export enum InvoiceFieldOrder {
	Number = 1,
	Tenant = 2,
	InvoiceDate = 3,
	DueDate = 4,
	PaidDate = 5,
	Amount = 6,
}
export enum InvoiceMainFilterEnum {
	Property = 1,
	Name = 2,
}

export type InvoiceItemType = {
	id: number;
	number: string;
	tenantFullName: string;
	invoiceDate: string;
	dueDate: string;
	paidDate: string | null;
	amount: number;
	paymentMethod: number;
	status: number;
	isOverdue: boolean;
};

export type InvoiceDetailsType = {
	id: number;
	tenantId: number;
	tenantName: string;
	invoiceDate: string;
	dueDate: string;
	invoiceType: number;
	invoiceStatus: number;
	paymentMethod: number;
	paidDate: string;
	invoiceItems: Array<{
		id: number;
		description: string;
		amount: number;
	}>;
	isSentToEmail: boolean;
	number: string;
};
