import React from 'react';
import {
	InputGroup,
	Input,
	InputRightElement,
	Flex,
	IconButton,
} from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';
import { PinnedMessagesPopup } from './PinnedMessagesPopup/PinnedMessagesPopup';
import { useMembersSidebarOpenStore } from '../../../../store';
import { UserCircleIcon } from '../../../../assets';

interface IChannelHeaderProps {
	search: string;
	setSearch: (value: string) => void;
	// isAdmin: boolean;
}
export const ChannelHeader: React.FC<IChannelHeaderProps> = ({
	search,
	setSearch,
	// isAdmin,
}) => {
	const { onToggle } = useMembersSidebarOpenStore();
	return (
		<Flex
			width="100%"
			gap="10px"
			justify="flex-end"
			align="center"
			bg="mainBg"
			p="10px">
			<PinnedMessagesPopup />
			{/* <IconButton aria-label="Notifications" bg="white">
						<BellIcon />
					</IconButton>
					<IconButton aria-label="Notifications" bg="white">
						<UserPlusIcon />
					</IconButton> */}
			{/* {isAdmin ? (
				<> */}
			<IconButton
				aria-label="Notifications"
				bg="white"
				onClick={onToggle}>
				<UserCircleIcon />
			</IconButton>
			<InputGroup width="230px">
				<Input
					value={search}
					onChange={event => {
						setSearch(event.currentTarget.value);
					}}
					placeholder="Search"
					p="10px, 10px, 10px, 20px"
					bg="white"
					border="none"
					borderRadius="4px"
					_placeholder={{ color: 'inactiveText' }}
				/>
				<InputRightElement>
					<BiSearch size={22} />
				</InputRightElement>
			</InputGroup>
			{/* </>
			) : null} */}
		</Flex>
	);
};
