import { create } from 'zustand';
import { CardModalData } from '../types';

type StoreType = {
	isOpen: boolean;
	data: CardModalData | null;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
	onSetCardData: (data: CardModalData) => void;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
	data: null,
};

export const useCardModalStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false, data: null }),
	onSetCardData: data => set({ isOpen: true, data }),
}));
