import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const requestFriendshipSchema = yup.object().shape({
	searchQuery: yup.string().required(ErrorMessages.REQUIRED),
});

export type RequestFriendshipForm = yup.InferType<
	typeof requestFriendshipSchema
>;
export const requestFriendshipResolver = yupResolver(requestFriendshipSchema);
