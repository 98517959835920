import React from 'react';
import { MessageContent } from './MessageContent';
import { MessageEdit } from './MessageEdit';

interface IEditAndTextBlockProps {
	isEdit: boolean;
	text: string;
	onCancelEdit?: VoidFunction;
	onSubmitEdit: (newText: string) => void;
	isEdited?: boolean;
	enableMentions?: boolean;
}

export const EditAndTextBlock: React.FC<IEditAndTextBlockProps> = ({
	isEdit,
	onCancelEdit,
	text,
	onSubmitEdit,
	isEdited,
	enableMentions,
}) => {
	return (
		<>
			{isEdit ? (
				<MessageEdit
					text={text}
					onCancelEdit={onCancelEdit}
					onSubmitEdit={onSubmitEdit}
					enableMentions={enableMentions}
				/>
			) : (
				<MessageContent
					text={text}
					isEdited={isEdited}
					enableBreaks={true}
				/>
			)}
		</>
	);
};
