import { useMutation } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty, TrackUploadProgress } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useSendDirectMessage = () => {
	return useMutation<ApiResponseEmpty, unknown, TrackUploadProgress>({
		mutationKey: [MessengerQueryKeys.SEND_DIRECT_MESSAGE],
		mutationFn: ({ payload, onUpload }) =>
			API_CHAT.post(
				'/api/app/conversation/messages/private/send',
				payload,
				{
					headers: { 'Content-Type': 'multipart/form-data' },
					onUploadProgress: onUpload,
				},
			),
	});
};
