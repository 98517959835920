import { FileAttachmentType, SUPPORTED_FILE_TYPES_LIST } from '../constants';

export const checkFileType = (type: string) => {
	switch (true) {
		case SUPPORTED_FILE_TYPES_LIST.image.includes(type):
			return FileAttachmentType.IMAGE;
		case SUPPORTED_FILE_TYPES_LIST.doc.includes(type):
			return FileAttachmentType.DOC;
		case SUPPORTED_FILE_TYPES_LIST.video.includes(type):
			return FileAttachmentType.VIDEO;
		default:
			break;
	}
};
