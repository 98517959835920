import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	onClose: VoidFunction;
	onOpen: VoidFunction;
}

export const useVideoInstructionStore = create<IState>(set => ({
	isOpen: false,
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () => set(() => ({ isOpen: false })),
}));
