import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VStack, Text, Flex, Button, useToast, HStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { PlusIcon } from '../../../../assets';
import {
	requestFriendshipResolver,
	RequestFriendshipForm,
} from '../../validation';
import { ErrorMessages } from '../../validation/messages';
import { useAddFriendByNickname } from '../../queries';
import { DirectAutoComplete, IAutocompleteInputRef } from '../components';
import { SearchUsersByNameData } from '../../types';

interface IAddFriendTabProps {
	index: number;
}

export const AddFriendTab: React.FC<IAddFriendTabProps> = ({ index }) => {
	const toast = useToast();

	const [loading, setLoading] = useState(false);
	const inputRef = useRef<IAutocompleteInputRef>(null);

	const { mutateAsync: addFriendByNickname } = useAddFriendByNickname();

	const {
		watch,
		reset,
		formState: { errors },
		handleSubmit,
		setError,
		setValue,
		clearErrors,
	} = useForm<RequestFriendshipForm>({
		resolver: requestFriendshipResolver,
	});

	const searchQueryState = watch('searchQuery');

	const onSubmit = async (data: RequestFriendshipForm) => {
		try {
			setLoading(true);

			const res = await addFriendByNickname(data.searchQuery);

			if (!res.success) {
				setError('searchQuery', {
					type: 'custom',
					message:
						res.errors?.[0]?.message ||
						ErrorMessages.NO_USERS_FOUND,
				});
				return;
			}
			setError('root.success', {
				type: 'custom',
				message: ErrorMessages.REQUEST_SENT,
			});

			setValue('searchQuery', '');

			if (!inputRef?.current) {
				return;
			}
			inputRef.current.resetValue();
		} catch (error) {
			console.error('Error adding document: ', error);
			toast({ status: 'error', title: 'Error sending request' });
		} finally {
			setLoading(false);
		}
	};

	const isMessageExist = useMemo(
		() => !!errors.searchQuery?.message || !!errors.root?.success?.message,
		[errors.searchQuery, errors.root],
	);

	const onSelectSuggestion = (user: SearchUsersByNameData | null) => {
		if (!user) {
			setValue('searchQuery', '');
			return;
		}
		if (isMessageExist) {
			clearErrors();
		}
		setValue('searchQuery', user.nickName);
	};

	useEffect(() => {
		if (index === 4) {
			reset({
				searchQuery: '',
			});

			return () => {
				inputRef?.current?.resetValue();
			};
		}
	}, [index]);

	return (
		<>
			<VStack alignItems={'start'} mt={'30px'} ml={'20px'}>
				<Text
					fontWeight={'700'}
					fontSize={'16px'}
					color="blue.200"
					textTransform="uppercase">
					Add friends
				</Text>
				<Text fontWeight={'500'} fontSize={'16px'} color="blue.200">
					You can add friends by username
				</Text>
			</VStack>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					direction="column"
					p={'20px'}
					bg={'white'}
					mt={'20px'}
					mb="10px"
					borderRadius={'10px'}
					alignItems={'baseline'}
					columnGap="2px">
					<HStack w="full" spacing={'10px'}>
						<DirectAutoComplete
							onSelectItem={onSelectSuggestion}
							isNonFriendsSearch={true}
							ref={inputRef}
							inputProps={{
								h: '50px',
								placeholder: 'Search',
								color: 'black',
								p: '10px, 10px, 10px, 20px',
								bg: 'mainBg',
								borderRadius: '4px',
								border: '1px solid transparent',
								fontWeight: 500,
								fontSize: '16px',
								_focusVisible: {
									borderWidth: '1px',
									borderColor: 'blue.500',
								},
							}}
						/>
						<Button
							p={'16px 20px'}
							h={'50px'}
							colorScheme="blue"
							bg="blue.100"
							type="submit"
							rightIcon={<PlusIcon />}
							isDisabled={!searchQueryState}
							isLoading={loading}
							_hover={{
								bg: 'blue.50',
								_disabled: {
									bg: 'blue.100',
								},
							}}>
							Send Request
						</Button>
					</HStack>
					{isMessageExist ? (
						<Text
							color={errors.root?.success ? 'success' : 'red.500'}
							variant="bodySmall">
							{errors.searchQuery?.message ||
								errors.root?.success?.message}
						</Text>
					) : null}
				</Flex>
			</form>
		</>
	);
};
