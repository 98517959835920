/* eslint-disable indent */

import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { DatePicker, ItemList, Loader } from '../../../../components';
import { BASE_BILLING_CYCLE, defaultTenantValues } from '../../constants';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { TenantFormType, tenantResolver } from '../../validations';
import {
	transformDataToUpdateTenantPayload,
	useCreateTenant,
	useGetTenantDetails,
	useGetUnitsSelector,
	useGetUnoccupiedPropertySelector,
	useReactivateTenant,
	useUpdateTenant,
} from '../../queries';
import { PlusIcon } from '../../../../assets';
import {
	getTenantValues,
	transformAmount,
	transformIdNameToOptions,
	transformTenantsPropertyOptions,
} from '../../utils';
import { CustomReactSelect } from '../../components';
import {
	BillingCycleItem,
	TenantCreationOrSelectionBlock,
	UploadLeaseAgreement,
} from './components';
import { useTenantModalStore } from '../../store';
import { ArchivedTenantSelectType } from '../../types';

interface ITenantModal {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const TenantModal: React.FC<ITenantModal> = ({ isOpen, onClose }) => {
	const tenantId = useTenantModalStore(state => state.tenantId);
	const isReactivate = useTenantModalStore(state => state.isReactivate);
	const propertyData = useTenantModalStore(state => state.propertyData);

	const { data: tenantData, isLoading: tenantDataLoading } =
		useGetTenantDetails(Number(tenantId));

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		control,
		watch,
		setValue,
	} = useForm<TenantFormType>({
		resolver: tenantResolver,
		defaultValues: defaultTenantValues,
		shouldUnregister: true,
	});

	// console.log(errors, 'errors');

	const [property, unit, leaseStartDate, leaseEndDate] = watch([
		'property',
		'unit',
		'leaseStartDate',
		'leaseEndDate',
	]);
	const leaseAgreement = watch('leaseAgreement');

	const isShowUploadInput = !!(
		property &&
		unit &&
		leaseStartDate &&
		leaseEndDate &&
		((isReactivate && !!tenantId) || (!isReactivate && !tenantId))
	);

	// BASE MODAL TEXT AND STATE
	const isAddingNewTenant = !tenantId && !isReactivate;
	const MODAL_TITLE = isAddingNewTenant
		? 'Add Tenant'
		: isReactivate
		? 'Reactivate Tenant'
		: 'Edit Tenant';
	//=====================================

	// TENANT FORM VIEW CONFIGURATOR
	const [isSelectingExistingTenant, setIsSelectingExistingTenant] =
		useState(false);
	const [selectedArchivedTenant, setSelectedArchivedTenant] =
		useState<ArchivedTenantSelectType | null>(null);
	useEffect(() => {
		setValue('email', String(selectedArchivedTenant?.email));
		setValue(
			'firstName',
			String(selectedArchivedTenant?.name.split(' ')[0]),
		);
		setValue(
			'lastName',
			String(selectedArchivedTenant?.name.split(' ')[1]),
		);
		setValue('phone', String(selectedArchivedTenant?.phoneNumber));
	}, [selectedArchivedTenant]);
	useEffect(() => {
		if (!isSelectingExistingTenant) {
			setValue('email', '');
			setValue('firstName', '');
			setValue('lastName', '');
			setValue('phone', '');
		}
	}, [isSelectingExistingTenant]);
	//===============================

	const isPropertyFieldDisabled = !!tenantId && !isReactivate;

	const { append, remove, fields } = useFieldArray({
		name: 'billingCycles',
		control,
		keyName: 'billingCycleKey',
	});

	// console.log(fields, 'FIELDS');

	const { data: properties, isLoading: propertyLoading } =
		useGetUnoccupiedPropertySelector({
			isEnabled: isOpen && (isAddingNewTenant || isReactivate),
		});

	const { data: unitsData, isLoading: unitsLoading } = useGetUnitsSelector({
		propertyId: Number(property?.value),
		isOccupied: false,
	});

	const onReset = useCallback(() => {
		reset(defaultTenantValues);
		setIsSelectingExistingTenant(false);
		setSelectedArchivedTenant(null);
		onClose();
	}, []);

	const createTenant = useCreateTenant();

	const updateTenant = useUpdateTenant();

	const reactivateTenant = useReactivateTenant();

	const onSubmit = (values: TenantFormType) => {
		const formData = new FormData();

		formData.append('FirstName', values.firstName.trim());
		formData.append('LastName', values.lastName.trim());
		formData.append('Email', values.email);
		formData.append('Phone', values.phone);

		if (values?.billingCycles?.length) {
			values.billingCycles.forEach((it, index) => {
				formData.append(
					`BillingCycles[${index}].invoiceName`,
					it.invoiceName,
				);
				formData.append(
					`BillingCycles[${index}].invoiceType`,
					String(it.invoiceType?.value),
				);
				formData.append(
					`BillingCycles[${index}].paymentMethod`,
					String(it.paymentMethod?.value),
				);
				formData.append(
					`BillingCycles[${index}].billingCycle`,
					String(it.billingCycle?.value),
				);
				formData.append(
					`BillingCycles[${index}].dueDays`,
					String(it.dueDays),
				);
				formData.append(
					`BillingCycles[${index}].amount`,
					String(transformAmount(Number(it.amount))),
				);
				formData.append(
					`BillingCycles[${index}].invoiceDate`,
					it.invoiceDate,
				);
			});
		}

		if (tenantId && tenantData?.value.lease?.id) {
			// UPDATE
			formData.append('LeaseId', String(tenantData.value.lease.id));

			updateTenant.mutate(
				{
					tenantId,
					data: transformDataToUpdateTenantPayload(
						values,
						tenantData?.value.lease.id,
					),
				},
				{
					onSuccess: data => {
						if (data.success) {
							onReset();
						}
					},
				},
			);
		} else {
			formData.append('PropertyId', String(values.property?.value));
			formData.append('UnitId', String(values.unit?.value));
			formData.append('LeaseStartDate', values.leaseStartDate);
			formData.append('LeaseEndDate', values.leaseEndDate);

			if (values.leaseAgreement) {
				formData.append('LeaseAgreement', values.leaseAgreement);
				// formData.append(
				// 	'IsSentLeaseAgreement',
				// 	String(values.isSentLeaseAgreement),
				// );
			}

			if (tenantId && isReactivate) {
				// REACTIVATE
				reactivateTenant.mutate(
					{
						tenantId,
						data: formData,
					},
					{
						onSuccess: data => {
							if (data.success) {
								onReset();
							}
						},
					},
				);
			} else {
				// ADD
				createTenant.mutate(formData, {
					onSuccess: data => {
						if (data.success) {
							onReset();
						}
					},
				});
			}
		}
	};

	useEffect(() => {
		if (isOpen && tenantData?.value) {
			reset(getTenantValues(tenantData.value));
		}
	}, [isOpen, tenantData?.value]);

	useEffect(() => {
		if (isOpen && propertyData) {
			setValue('property', {
				value: Number(propertyData.property.value),
				label: propertyData.property.label,
			});
			setValue('unit', {
				value: Number(propertyData.unit.value),
				label: propertyData.unit.label,
			});
		}
	}, [isOpen, propertyData]);

	return (
		<Modal
			returnFocusOnClose={false}
			closeOnOverlayClick={false}
			scrollBehavior="inside"
			isCentered={true}
			isOpen={isOpen}
			onClose={onReset}>
			<ModalOverlay />
			<ModalContent
				bg="mainBg"
				maxW="1050px"
				w={{ base: '90vw', lg: '100%' }}>
				<ModalHeader
					px="16px"
					fontSize="20px"
					fontWeight="700"
					color={'blue.200'}
					lineHeight="24px">
					{MODAL_TITLE}
				</ModalHeader>
				<ModalCloseButton color="blue.200" />
				<ModalBody p="16px">
					{tenantDataLoading ? (
						<Loader centerHeight={'400px'} />
					) : (
						<form onSubmit={handleSubmit(onSubmit)}>
							<VStack align="stretch" spacing="16px">
								<Box bg="white" borderRadius="8px">
									<Box
										borderBottom="1px solid #F1F4F9"
										p="16px">
										<Text
											fontWeight={600}
											color={'blue.100'}>
											Tenant Information
										</Text>
									</Box>

									<TenantCreationOrSelectionBlock
										isSelectingExistingTenant={
											isSelectingExistingTenant
										}
										register={register}
										errors={errors}
										setIsSelectingExistingTenant={
											setIsSelectingExistingTenant
										}
										showTenantActionsBlock={!!propertyData}
										selectedArchivedTenant={
											selectedArchivedTenant
										}
										setSelectedArchivedTenant={
											setSelectedArchivedTenant
										}
									/>
								</Box>
								<Box bg="white" borderRadius="8px">
									<Box
										borderBottom="1px solid #F1F4F9"
										p="16px">
										<Text
											fontWeight={600}
											color={'blue.100'}>
											Property
										</Text>
									</Box>
									<Stack
										p="16px"
										direction={{
											base: 'column',
											md: 'row',
										}}
										spacing="16px">
										<Controller
											control={control}
											name="property"
											render={({
												field: { value, onChange },
												fieldState: { error },
											}) => (
												<CustomReactSelect
													isDisabled={
														propertyLoading ||
														isPropertyFieldDisabled ||
														Boolean(propertyData)
													}
													onChange={newValue => {
														if (unit) {
															setValue(
																'unit',
																null,
															);
														}
														onChange(newValue);
													}}
													value={value}
													options={transformTenantsPropertyOptions(
														properties?.value.items,
													)}
													label="Property"
													placeholder="Search or Select Property"
													errMsg={error?.message}
												/>
											)}
										/>
										<Controller
											control={control}
											name="unit"
											render={({
												field: { value, onChange },
												fieldState: { error },
											}) => (
												<CustomReactSelect
													isDisabled={
														unitsLoading ||
														!property ||
														isPropertyFieldDisabled ||
														Boolean(propertyData)
													}
													isLoading={unitsLoading}
													onChange={onChange}
													value={value}
													label="Unit"
													placeholder="Select Unit"
													options={transformIdNameToOptions(
														unitsData?.value?.units,
													)}
													errMsg={error?.message}
												/>
											)}
										/>
										<Controller
											control={control}
											name="leaseStartDate"
											render={({
												field: { value, onChange },
												fieldState: { error },
											}) => (
												<DatePicker
													isDisabled={
														isPropertyFieldDisabled
													}
													label="Lease Start Date"
													value={value}
													onSetValue={onChange}
													errorMsg={error?.message}
												/>
											)}
										/>
										<Controller
											control={control}
											name="leaseEndDate"
											render={({
												field: { value, onChange },
												fieldState: { error },
											}) => (
												<DatePicker
													isDisabled={
														isPropertyFieldDisabled
													}
													label="Lease End Date"
													value={value}
													onSetValue={onChange}
													errorMsg={error?.message}
												/>
											)}
										/>
									</Stack>
									<VStack
										align={'stretch'}
										spacing={'16px'}
										pb={'16px'}
										px={'16px'}>
										<UploadLeaseAgreement
											removeFile={() => {
												setValue(
													'leaseAgreement',
													undefined,
												);
												// setValue(
												// 	'isSentLeaseAgreement',
												// 	false,
												// );
											}}
											file={leaseAgreement}
											isDisabled={!isShowUploadInput}
											setFileToUpload={value => {
												setValue(
													'leaseAgreement',
													value,
												);
											}}
										/>
										{/*{leaseAgreement ? (*/}
										{/*	<Controller*/}
										{/*		render={({*/}
										{/*			field: { value, onChange },*/}
										{/*		}) => (*/}
										{/*			<Checkbox*/}
										{/*				checked={value}*/}
										{/*				onChange={onChange}*/}
										{/*				alignSelf={'flex-start'}*/}
										{/*				variant={*/}
										{/*					'cruzctrl-checkbox'*/}
										{/*				}>*/}
										{/*				Send Lease Agreement*/}
										{/*			</Checkbox>*/}
										{/*		)}*/}
										{/*		name={'isSentLeaseAgreement'}*/}
										{/*		control={control}*/}
										{/*	/>*/}
										{/*) : null}*/}
									</VStack>
								</Box>
								<Box bg="white" borderRadius="8px">
									<HStack
										justifyContent="space-between"
										borderBottom="1px solid #F1F4F9"
										p="16px">
										<Text
											fontWeight={600}
											color={'blue.100'}>
											Billing Cycles
										</Text>
										<Button
											onClick={() =>
												append(BASE_BILLING_CYCLE)
											}
											type={'button'}
											variant={'link'}
											size={'sm'}
											color="blue.50"
											leftIcon={<PlusIcon />}>
											Add Billing Cycle
										</Button>
									</HStack>
									<ItemList
										items={fields}
										renderItem={(item, index) => (
											<BillingCycleItem
												key={item.billingCycleKey}
												control={control}
												register={register}
												index={index}
												errors={errors}
												onRemove={() => {
													remove(index);
												}}
												watch={watch}
												isDisabled={!!item?.id}
												isPaymentMethodDisabled={
													!property?.value
												}
												isStripeConnected={
													!!property?.isStripeConnected ||
													!!tenantData?.value?.lease
														?.isStripeConnected
												}
											/>
										)}
										keyExtractor={item =>
											item.billingCycleKey
										}
									/>
								</Box>
								<Button
									isLoading={
										createTenant.isLoading ||
										updateTenant.isLoading ||
										reactivateTenant.isLoading
									}
									isDisabled={
										createTenant.isLoading ||
										updateTenant.isLoading ||
										reactivateTenant.isLoading
									}
									type="submit"
									maxW={{ base: 'full', md: '200px' }}
									w="full"
									variant="cruzctrl-solid"
									alignSelf="flex-end">
									Submit
								</Button>
							</VStack>
						</form>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
