import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import MyProfile from '../../MyProfile';
import { InputText, Loader } from '../../../../components';
import { CurrentUserQueryKeys } from '../../../../hooks';
import { ApiResponseEmpty, ICurrentUser } from '../../../../types';
import {
	MyProfileForm,
	profileResolver,
} from './validation/profile.validation';
import { API_IDENTITY } from '../../../../api/api.base';
import { Alerter } from '../../../../utils';

export const NameForm: React.FC = () => {
	const queryClient = useQueryClient();
	const { data: userData, isLoading } = useQuery<ICurrentUser>(
		CurrentUserQueryKeys.CURRENT_USER,
	);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<MyProfileForm>({
		resolver: profileResolver,
	});

	const { mutate, isLoading: isMutationLoading } = useMutation<
		ApiResponseEmpty,
		any,
		MyProfileForm
	>(data => API_IDENTITY.post('/api/user/current/edit', data), {
		onSuccess(e) {
			queryClient.invalidateQueries(CurrentUserQueryKeys.CURRENT_USER);
			Alerter.success(e.messages[0]?.message);
		},
		onError(e) {
			Alerter.error(e);
		},
	});

	if (isLoading) {
		return <Loader centerHeight={'100vh'} />;
	}

	const onSubmit = (data: MyProfileForm) => {
		mutate({
			...data,
			phoneNumber: userData?.phoneNumber
				? String(userData.phoneNumber)
				: '',
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<MyProfile>
				<Text color={'blue.200'}>
					The name will be displayed in your account
				</Text>
				<InputText
					focusBorderColor="#08BB4B"
					{...register('firstName')}
					errorMsg={errors.firstName?.message}
					borderRadius={'10px'}
					h={'55px'}
					bg={'#F1F4F9'}
					label={'First Name'}
					placeholder={userData?.firstName}
				/>
				<InputText
					focusBorderColor="#08BB4B"
					borderRadius={'10px'}
					{...register('lastName')}
					errorMsg={errors.lastName?.message}
					h={'55px'}
					bg={'#F1F4F9'}
					label={'Last Name'}
					placeholder={userData?.lastName}
				/>
				<Button
					type={'submit'}
					h={'60px'}
					isDisabled={isMutationLoading}
					bg={'success'}
					color={'white'}>
					Change Name
				</Button>
			</MyProfile>
		</form>
	);
};
