import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { EntitySelectType } from '../../types';
import { useQuery } from 'react-query';

export const useGetBusinessEntitySelector = (searchString?: string) => {
	return useQuery({
		queryKey: [CruzQueryKeys.BusinessEntitySelector, searchString],
		queryFn: (): Promise<
			ApiResponse<{
				items: EntitySelectType[];
			}>
		> =>
			API_CTRL.get('/api/app/selector/business-entity', {
				SearchString: searchString,
			}),
	});
};
