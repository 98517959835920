import React from 'react';
import { UnreadMessagesBanner } from '../../messages-components';

interface IDirectMessagesUnreadBannerProps {
	topScrollRef: React.RefObject<HTMLSpanElement>;
	onMarkAsRead: VoidFunction;
	unreadCount: number;
	lastReadTime: string | null;
}

export const DirectMessagesUnreadBanner: React.FC<
	IDirectMessagesUnreadBannerProps
> = ({ onMarkAsRead, topScrollRef, unreadCount, lastReadTime }) => {
	if (unreadCount && unreadCount > 0) {
		return (
			<UnreadMessagesBanner
				count={unreadCount}
				lastReadDate={lastReadTime ? new Date(lastReadTime) : null}
				onMarkAsRead={onMarkAsRead}
				onBannerClick={() => topScrollRef.current?.scrollIntoView()}
			/>
		);
	}
	return null;
};
