import React from 'react';

export const DoubleQuotesIcon: React.FC = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.583 17.3203C3.553 16.2263 3 14.9993 3 13.0103C3 9.51027 5.457 6.37327 9.03 4.82227L9.923 6.20027C6.588 8.00427 5.936 10.3453 5.676 11.8213C6.213 11.5433 6.916 11.4463 7.605 11.5103C9.409 11.6773 10.831 13.1583 10.831 14.9993C10.831 15.9275 10.4623 16.8178 9.80587 17.4741C9.1495 18.1305 8.25926 18.4993 7.331 18.4993C6.81766 18.4948 6.31034 18.3882 5.83856 18.1858C5.36679 17.9834 4.93999 17.6892 4.583 17.3203ZM14.583 17.3203C13.553 16.2263 13 14.9993 13 13.0103C13 9.51027 15.457 6.37327 19.03 4.82227L19.923 6.20027C16.588 8.00427 15.936 10.3453 15.676 11.8213C16.213 11.5433 16.916 11.4463 17.605 11.5103C19.409 11.6773 20.831 13.1583 20.831 14.9993C20.831 15.9275 20.4623 16.8178 19.8059 17.4741C19.1495 18.1305 18.2593 18.4993 17.331 18.4993C16.8177 18.4948 16.3103 18.3882 15.8386 18.1858C15.3668 17.9834 14.94 17.6892 14.583 17.3203Z"
			fill="white"
		/>
	</svg>
);
