import { useMutation, useQueryClient } from 'react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';
import { MessengerQueryKeys } from './query-keys';

export const useUnPinAllChannelMessages = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: channelId =>
			API_CHAT.put(
				`/api/admin/channel/${channelId}/message/unpin-all`,
				{},
			),
		mutationKey: [MessengerQueryKeys.UN_PIN_ALL_CHANNEL_MESSAGES],
		onSuccess(res, channelId) {
			if (!res.success) {
				Alerter.error(
					res.errors?.[0]?.message ||
						'Error while removing pinned messages',
				);
				return;
			}
			queryClient.invalidateQueries([
				MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES,
				channelId,
			]);
			Alerter.success('Message successfully unpinned');
		},
		onError(error) {
			console.log(error, 'un-pin-all-message error');
			Alerter.error('Error while removing pinned messages');
		},
	});
};
