export const getDatasetForGaugeChart = (value: string | number) => {
	switch (value) {
		case 'Lowest':
			return {
				data: [1, 7],
				backgroundColor: ['#68D391', '#CBD5E0'],
				borderColor: ['#68D391', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		case 'Low':
			return {
				data: [2, 6],
				backgroundColor: ['#68D391', '#CBD5E0'],
				borderColor: ['#68D391', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		case 'Below Average':
			return {
				data: [3, 5],
				backgroundColor: ['#68D391', '#CBD5E0'],
				borderColor: ['#68D391', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		case 'Average':
			return {
				data: [4, 4],
				backgroundColor: ['#F6E05E', '#CBD5E0'],
				borderColor: ['#F6E05E', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		case 'Above Average':
			return {
				data: [5, 3],
				backgroundColor: ['#F6E05E', '#CBD5E0'],
				borderColor: ['#F6E05E', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
				width: 10,
			};
		case 'High':
			return {
				data: [6, 2],
				backgroundColor: ['#FC8181', '#CBD5E0'],
				borderColor: ['#FC8181', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		case 'Highest':
			return {
				data: [7, 1],
				backgroundColor: ['#FC8181', '#CBD5E0'],
				borderColor: ['#FC8181', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
		default:
			return {
				data: [3, 6],
				backgroundColor: ['#718096', '#CBD5E0'],
				borderColor: ['#718096', 'white'],
				borderWidth: [0, 3],
				hoverBorderWidth: [3, 0],
				hoverBorderColor: ['white', 'white'],
				circumference: 180,
				rotation: 270,
			};
	}
};
