import React, { useMemo, useState } from 'react';
import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react';
import { MessageMenu } from './components/MessageMenu/MessageMenu';
import {
	EditAndTextBlock,
	MessageReply,
	UserInfoPopupWrapper,
} from './components';
import {
	ChatType,
	MessageReactionType,
	MessageAttachmentFile,
	MessageAttachmentImage,
	MessageType,
	MessageReactionItem,
	MessageAttachmentVideo,
	UserByIdData,
} from '../../types';
import { AdminVerificationSmall } from '../../../../assets';
import { ReactionsList } from './components/ReactionsList';
import { MessageAttachments } from './components/MessageAttachments';
import { SUPER_USER_ID } from '../../../../constants';

interface IMessageWithAvatarProps {
	text: string;
	sentByDisplayName: string;
	date?: string;
	avatarImage: string;
	isSameSenderNext?: boolean;
	isEdit: boolean;
	onCloseEdit?: VoidFunction;
	onEditMessage?: (id: number, newText: string) => void;
	isEdited?: boolean;
	onOpenEdit?: VoidFunction;
	onDelete?: VoidFunction;
	onReply?: VoidFunction;
	onPin?: VoidFunction;
	allowFullAccess?: boolean;
	showMenu?: boolean;
	id: number;
	type?: MessageType;
	replyToId?: number | null;
	background?: string;
	includePin?: boolean;
	includeEdit?: boolean;
	isAdmin?: boolean;
	enableMentions?: boolean;
	onSuspendUser?: VoidFunction;
	onSuspendAndPurgeMessagesUser?: VoidFunction;
	onUnSuspendUser?: VoidFunction;
	chatType: ChatType;
	sentById?: number;
	isSenderDeleted?: boolean;
	isSuspended?: boolean;
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	hideReactions?: boolean;
	messageAttachmentFiles: MessageAttachmentFile[];
	messageAttachmentImages: MessageAttachmentImage[];
	disabledAttachmentView?: boolean;
	reactions?: MessageReactionItem[];
	messageAttachmentVideos: MessageAttachmentVideo[];
	isMessageHovered?: boolean;
	isMenuOpened?: boolean;
	onInteractWithMenu?: (bool: boolean) => void;
	onRemoveMessageHover?: VoidFunction;
	receiver?: UserByIdData | null;
	includeUserInfoPopup?: boolean;
}

export const MessageWithAvatar: React.FC<IMessageWithAvatarProps> = ({
	text,
	date,
	sentByDisplayName,
	avatarImage,
	isSameSenderNext,
	isEdit,
	onCloseEdit,
	onEditMessage,
	isEdited,
	onOpenEdit,
	onDelete,
	onReply,
	allowFullAccess,
	showMenu = true,
	id,
	type,
	onPin,
	includePin,
	includeEdit,
	isAdmin,
	enableMentions,
	onSuspendAndPurgeMessagesUser,
	onSuspendUser,
	onUnSuspendUser,
	replyToId,
	chatType,
	sentById,
	isSenderDeleted,
	isSuspended,
	onReact,
	hideReactions,
	messageAttachmentImages,
	messageAttachmentFiles,
	disabledAttachmentView,
	messageAttachmentVideos,
	reactions,
	isMessageHovered,
	isMenuOpened,
	onInteractWithMenu,
	onRemoveMessageHover,
	includeUserInfoPopup,
	receiver,
}) => {
	const [userInfoId, setUserInfoId] = useState<number | null>(null);
	const reactedObj = useMemo(
		() => reactions?.find(reaction => reaction.userReacted === true),
		[reactions],
	);
	const userReactedId = reactedObj ? reactedObj.emojiId : null;
	const isSuperUserOnServer =
		SUPER_USER_ID === sentById && chatType === ChatType.CHANNEL;
	return (
		<>
			{type === MessageType.Reply ? (
				<MessageReply
					replyToId={replyToId}
					chatType={chatType}
					receiver={receiver}
					includeUserInfoPopup={includeUserInfoPopup}
				/>
			) : null}
			<Flex
				gap="10px"
				align="flex-start"
				mb={isSameSenderNext ? 0 : '20px'}>
				<UserInfoPopupWrapper
					userInfoId={userInfoId}
					receiver={receiver}
					chatType={chatType}>
					<Button
						variant="ghost"
						w="50px"
						h="50px"
						minW="50px"
						px="0"
						cursor={includeUserInfoPopup ? 'pointer' : 'default'}
						_hover={{}}
						_active={{}}
						onClick={event => {
							if (!includeUserInfoPopup || !sentById) {
								event.preventDefault();
								return;
							}

							setUserInfoId(sentById);
						}}>
						<Avatar mt="4px" boxSize="50px" src={avatarImage} />
					</Button>
				</UserInfoPopupWrapper>
				<Flex flexDirection="column" w="100%">
					<Flex
						align="center"
						w="100%"
						position="relative"
						mb="10px"
						gap="5px">
						{isAdmin ? (
							<Box>
								<AdminVerificationSmall />
							</Box>
						) : null}
						<UserInfoPopupWrapper
							userInfoId={userInfoId}
							receiver={receiver}
							chatType={chatType}>
							<Button
								variant="ghost"
								px="0"
								h="auto"
								cursor={
									includeUserInfoPopup ? 'pointer' : 'default'
								}
								_hover={{
									textDecoration:
										!includeUserInfoPopup || !sentById
											? 'none'
											: 'underline',
								}}
								_active={{}}
								onClick={event => {
									if (!includeUserInfoPopup || !sentById) {
										event.preventDefault();
										return;
									}

									setUserInfoId(sentById);
								}}>
								<Text
									fontSize={
										isSuperUserOnServer ? '14px' : '16px'
									}
									fontWeight={700}
									color={
										isSuperUserOnServer
											? 'white'
											: 'blue.200'
									}
									backgroundColor={
										isSuperUserOnServer
											? 'inactiveBlue'
											: 'transparent'
									}
									borderRadius={
										isSuperUserOnServer ? '5px' : 'unset'
									}
									px={isSuperUserOnServer ? '4px' : '0px'}>
									{sentByDisplayName}
								</Text>
							</Button>
						</UserInfoPopupWrapper>
						<Text
							color="blue.50"
							mr="auto"
							fontSize="12px"
							noOfLines={1}>
							{date ? date : null}
						</Text>
						{showMenu ? (
							<MessageMenu
								onEdit={onOpenEdit}
								onDelete={onDelete}
								onReply={onReply}
								allowFullAccess={allowFullAccess}
								onPin={onPin}
								includePin={includePin}
								includeEdit={includeEdit}
								onSuspendAndPurgeMessagesUser={
									onSuspendAndPurgeMessagesUser
								}
								onSuspendUser={onSuspendUser}
								onUnSuspendUser={onUnSuspendUser}
								isAdmin={isAdmin}
								sentById={sentById}
								isSenderDeleted={isSenderDeleted}
								text={text}
								id={id}
								chatType={chatType}
								hasReactions={!!reactions?.length}
								onReact={onReact}
								userReactedId={userReactedId}
								isMessageHovered={isMessageHovered}
								isMenuOpened={isMenuOpened}
								onInteractWithMenu={(bool: boolean) =>
									onInteractWithMenu?.(bool)
								}
								onRemoveMessageHover={onRemoveMessageHover}
							/>
						) : null}
					</Flex>
					<Flex direction="column" rowGap="4px" w="100%">
						<EditAndTextBlock
							isEdit={isEdit}
							onCancelEdit={onCloseEdit}
							text={text}
							onSubmitEdit={text => onEditMessage?.(id, text)}
							isEdited={isEdited}
							enableMentions={enableMentions}
						/>
						<MessageAttachments
							messageAttachmentFiles={messageAttachmentFiles}
							messageAttachmentImages={messageAttachmentImages}
							disabledAttachmentView={disabledAttachmentView}
							messageAttachmentVideos={messageAttachmentVideos}
						/>
						{hideReactions ? null : (
							<ReactionsList
								isSuspended={isSuspended}
								messageId={id}
								onReact={onReact}
								reactions={reactions}
								userReactedId={userReactedId}
							/>
						)}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};
