import { Box, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { ClearIcon } from '../../../../../../assets';
import { InputText } from '../../../../../../components';
import React from 'react';
import {
	Control,
	Controller,
	FieldErrors,
	UseFormRegister,
} from 'react-hook-form';
import { PropertyFormType } from '../../../../validations';
import { AlertConfirmDialog, InputPrice } from '../../../../components';
import {
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
} from '../../../../constants';
import { WarningDialog } from '../WarningDialog';

interface IUnitItem {
	nestedIndex: number;
	errors: FieldErrors<PropertyFormType>;
	register: UseFormRegister<PropertyFormType>;
	onRemove: VoidFunction;
	control: Control<PropertyFormType>;
	unitId?: number;
	hasTenant?: boolean;
}

export const UnitItem: React.FC<IUnitItem> = ({
	nestedIndex,
	errors,
	register,
	onRemove,
	control,
	unitId,
	hasTenant,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenWarning,
		onOpen: onOpenWarning,
		onClose: onCloseWarning,
	} = useDisclosure();

	return (
		<Box p={'16px'}>
			<Flex align={'center'} justify={'space-between'}>
				<Text fontWeight={600}>{`Unit ${nestedIndex + 1}`}</Text>
				<IconButton
					onClick={() => {
						if (unitId && !hasTenant) {
							onOpen();
						} else if (unitId && hasTenant) {
							onOpenWarning();
						} else {
							onRemove();
						}
					}}
					size={'sm'}
					variant={'ghost'}
					icon={<ClearIcon />}
					aria-label={'remove'}
				/>
			</Flex>
			<Flex
				mt={'16px'}
				gap={'16px'}
				align={'stretch'}
				flexDirection={{ base: 'column', lg: 'row' }}>
				<InputText
					{...register(`units.${nestedIndex}.name`)}
					errorMsg={errors.units?.[nestedIndex]?.name?.message}
					w={{ base: '100%', lg: '240px' }}
					{...inputStyles}
					formLabelProps={inputLabelStyles}
					fontSize={'14px'}
					lineHeight={'21px'}
					placeholder={'Enter Unit Name'}
					label={'Unit Name'}
					errorMessageProps={errorMsgStyles}
				/>
				<InputText
					{...register(`units.${nestedIndex}.squareFeet`, {
						valueAsNumber: true,
					})}
					errorMsg={errors.units?.[nestedIndex]?.squareFeet?.message}
					type={'number'}
					{...inputStyles}
					formLabelProps={inputLabelStyles}
					fontSize={'14px'}
					lineHeight={'21px'}
					placeholder={'0'}
					label={'Square Feet'}
					errorMessageProps={errorMsgStyles}
				/>
				<InputText
					{...register(`units.${nestedIndex}.bedrooms`, {
						valueAsNumber: true,
					})}
					errorMsg={errors.units?.[nestedIndex]?.bedrooms?.message}
					type={'number'}
					formLabelProps={inputLabelStyles}
					{...inputStyles}
					fontSize={'14px'}
					lineHeight={'21px'}
					placeholder={'0'}
					label={'Bedrooms'}
					errorMessageProps={errorMsgStyles}
				/>
				<InputText
					{...register(`units.${nestedIndex}.bathrooms`, {
						valueAsNumber: true,
					})}
					errorMsg={errors.units?.[nestedIndex]?.bathrooms?.message}
					type={'number'}
					formLabelProps={inputLabelStyles}
					{...inputStyles}
					fontSize={'14px'}
					lineHeight={'21px'}
					placeholder={'0'}
					label={'Bathrooms'}
					errorMessageProps={errorMsgStyles}
				/>
				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<InputPrice
							value={value}
							errorMsg={error?.message}
							onChange={onChange}
							label={'Monthly Rent'}
							allowNegative={false}
							placeholder={'$0.00'}
						/>
					)}
					name={`units.${nestedIndex}.monthlyRent`}
					control={control}
				/>
				<Controller
					render={({
						field: { value, onChange },
						fieldState: { error },
					}) => (
						<InputPrice
							value={value}
							errorMsg={error?.message}
							onChange={onChange}
							label={'Deposit'}
							allowNegative={false}
							placeholder={'$0.00'}
						/>
					)}
					name={`units.${nestedIndex}.deposit`}
					control={control}
				/>

				{/*<InputText*/}
				{/*	{...register(`units.${nestedIndex}.deposit`, {*/}
				{/*		valueAsNumber: true,*/}
				{/*	})}*/}
				{/*	errorMsg={errors.units?.[nestedIndex]?.deposit?.message}*/}
				{/*	step={'0.01'}*/}
				{/*	type={'number'}*/}
				{/*	formLabelProps={labelStyle}*/}
				{/*	fontSize={'14px'}*/}
				{/*	lineHeight={'21px'}*/}
				{/*	placeholder={'0'}*/}
				{/*	label={'Deposit'}*/}
				{/*	errorMessageProps={errMsgStyle}*/}
				{/*/>*/}
			</Flex>
			<AlertConfirmDialog
				subTitle={'Do you want to delete this unit?'}
				acceptCallback={onRemove}
				title={'Delete unit'}
				isOpen={isOpen}
				onClose={onClose}
			/>
			<WarningDialog isOpen={isOpenWarning} onClose={onCloseWarning} />
		</Box>
	);
};
