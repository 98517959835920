/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { getUsersByName } from '../helpers';

export async function queryMentionUsers(
	q: string,
	callback: (val: any[]) => void,
	userId?: number,
	allowEveryone?: boolean,
) {
	if (!q) {
		return;
	}

	const isEvQuery = q.toLowerCase().startsWith('ev');

	const displayNameUsers = await getUsersByName(q);

	const usersWithAvatars = displayNameUsers?.value.users
		? displayNameUsers.value.users
				.filter(user => user.userId !== userId)
				.map(user => {
					if (user.avatarStoragePath) {
						return {
							display: user.displayName,
							photoURL: user.avatarStoragePath,
							id: user.userId,
							nickName: user.nickName,
						};
					} else {
						return {
							display: user.displayName,
							id: user.userId,
							nickName: user.nickName,
						};
					}
				})
		: [];

	const everyoneVariant =
		isEvQuery && allowEveryone
			? [
					{
						display: 'Everyone',
						id: 0,
						nickName: 'Everyone',
					},
			  ]
			: [];
	callback([...everyoneVariant, ...usersWithAvatars]);
}
