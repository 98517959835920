import { create } from 'zustand';

type StoreType = {
	isOpen: boolean;
	propertyId: number | null;
};

type Actions = {
	onOpen: VoidFunction;
	onClose: VoidFunction;
	onSetPropertyId: (value: number) => void;
};

const INITIAL_STATE: StoreType = {
	isOpen: false,
	propertyId: null,
};

export const usePropertyModalStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	onOpen: () => set({ isOpen: true }),
	onClose: () => set({ isOpen: false, propertyId: null }),
	onSetPropertyId: value => set({ isOpen: true, propertyId: value }),
}));
