import { Alerter } from '../../../../utils';

import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { ArchivedTenantSelectType } from '../../types';
import { transformArchivedTenantsToOptions } from '../../utils';

export const getArchivedTenantsSelector = async (searchString: string) => {
	try {
		const response: ApiResponse<{
			tenants: ArchivedTenantSelectType[];
		}> = await API_CTRL.get('/api/app/tenants/archived', {
			searchQuery: searchString,
			pageSize: 5,
		});
		if (response.success) {
			return transformArchivedTenantsToOptions(response.value.tenants);
		} else {
			Alerter.error(response.errors[0].message);
		}
	} catch (err) {
		console.log(err, '@@@ getArchivedAccountSelector');
		Alerter.error('Something went wrong');
	}
};
