/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Center, Text } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DirectFriendBase, NormalControls } from '../components';
import { Loader } from '../../../../components';
import { useGetOnlineFriends } from '../../queries';
import { SearchWithPaginationPayload, UserData } from '../../types';

interface IOnlineTabProps {
	index: number;
}

const PAGE_SIZE = 30;

export const OnlineTab: React.FC<IOnlineTabProps> = ({ index }) => {
	const { mutateAsync: getOnlineFriends } = useGetOnlineFriends();

	const [loading, setLoading] = useState(false);
	const [friends, setFriends] = useState<UserData[]>([]);

	const onGetFriends = async (data: SearchWithPaginationPayload) => {
		try {
			setLoading(true);
			const res = await getOnlineFriends(data);

			if (!res.value || !res.success) {
				return;
			}

			setFriends(res.value.friends);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (index === 0) {
			const friendsInterval = setInterval(
				() => onGetFriends({ pageSize: 50 }),
				60 * 1000,
			);

			onGetFriends({ pageSize: 50 });

			return () => {
				clearInterval(friendsInterval);
			};
		}
	}, [index]);

	return (
		<>
			{/* <DirectSearchBar /> */}
			{loading && !friends.length ? (
				<Loader centerHeight="500px" />
			) : (
				<>
					{/* {onlineFriends.length === 0 ? ( */}
					{friends.length === 0 ? (
						<Center height="500px">
							<Text color="blue.50">No friends online yet</Text>
						</Center>
					) : (
						<>
							<Text
								mt="20px"
								mb="10px"
								color="blue.200"
								fontSize="16px"
								fontWeight={700}>
								ONLINE - {friends.length}
							</Text>
							{/* {onlineFriends.map(it => ( */}
							<InfiniteScroll
								dataLength={friends.length}
								next={() => {}}
								hasMore={false}
								height={'calc(100vh - 120px)'}
								loader={<Loader spinnerSize="md" />}>
								{friends.map(elem => (
									<DirectFriendBase
										controls={
											<NormalControls
												friendId={+elem.userId}
											/>
										}
										key={+elem.userId}
										userStatus={elem.userStatus}
										name={elem.displayName}
										photoURL={elem.avatarStoragePath}
										id={+elem.userId}
										isAdmin={elem.isAdmin}
										email={elem.email}
									/>
								))}
							</InfiniteScroll>
						</>
					)}
				</>
			)}
		</>
	);
};
