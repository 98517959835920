import { ApiResponseEmpty } from '../../../types';
import { API_CHAT } from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useMutation, useQueryClient } from 'react-query';
import { MessengerQueryKeys } from './query-keys';

export const useDeleteChannel = () => {
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [MessengerQueryKeys.DELETE_CHANNEL],
		mutationFn: channelId =>
			API_CHAT.delete(`/api/admin/channel/${channelId}/delete`, {}),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message || 'Error while deleting channel',
				);
			}
			Alerter.success(data.messages?.[0]?.message || 'Channel deleted!');
			queryClient.invalidateQueries(
				MessengerQueryKeys.GET_SERVER_CATEGORIES,
			);
		},
		onError(error) {
			console.log(error, 'delete-channel error');
		},
	});
};
