import React from 'react';
import { Flex, Button, Text, Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { CheckCircleIcon } from '../../../assets';

interface IUnreadMessagesBannerProps {
	count: number;
	lastReadDate?: Date | null;
	onMarkAsRead?: VoidFunction;
	onBannerClick?: VoidFunction;
}

export const UnreadMessagesBanner: React.FC<IUnreadMessagesBannerProps> = ({
	count,
	lastReadDate,
	onMarkAsRead,
	onBannerClick,
}) => {
	return (
		<Flex
			padding="10px 20px"
			align="center"
			justify="space-between"
			w="100%"
			borderRadius="0px 0px 10px 10px"
			top="0"
			left="20px"
			bg="success"
			position="absolute"
			// top="0"
			width="calc(100% - 40px)"
			zIndex={20}>
			<Box as="button" onClick={onBannerClick} flex={1}>
				<Text
					fontSize="14px"
					color="white"
					textAlign="left"
					fontWeight={700}>
					{count} new {pluralize('message', count)}{' '}
					{lastReadDate
						? `since
					${dayjs(lastReadDate).format('hh:mm a')} on	${dayjs(lastReadDate).format(
								'MMMM DD, YYYY',
						  )}`
						: ''}
				</Text>
			</Box>
			<Button
				rightIcon={<CheckCircleIcon />}
				onClick={() => onMarkAsRead?.()}
				variant="link"
				fontSize="14px"
				color="white"
				fontWeight={700}>
				Mark as read
			</Button>
		</Flex>
	);
};
