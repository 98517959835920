import { create } from 'zustand';

interface IState {
	isOpen: boolean;
	onClose: VoidFunction;
	onOpen: VoidFunction;
	onToggle: VoidFunction;
}

export const useSidebarOpenStore = create<IState>(set => ({
	isOpen: true,
	onToggle: () => set(state => ({ isOpen: !state.isOpen })),
	onOpen: () => set(() => ({ isOpen: true })),
	onClose: () => set(() => ({ isOpen: false })),
}));
